import React, { useContext, useEffect, useState } from 'react';

import '../assets/CSS/template.css'
import MyContext from '../context/MyContext';
const Buttondesing = ({ setshowbuttonstyle, }) => {
   const {     buttoncolor,setButtoncolor, buttonborder,setbuttonborder,buttonbordercolor,setbuttonbordercolor,buttonborderradius,setButtonborderradios,}=useContext(MyContext);
    const [buttoncount,setButtoncount]=useState(0);
    const Handelbuttonstyle = () => {
        setshowbuttonstyle(false)
    };
    console.log('buttoncount',buttoncount,'buttonborderradius',buttonborderradius,'buttonborder',buttonborder,'buttoncolor',buttoncolor,'buttonbordercolor',buttonbordercolor);

      useEffect(()=>{
        switch(buttoncount){
            case 1:
                setButtonborderradios(8);
                setButtoncolor('rgb(236, 235, 235)')
                setbuttonbordercolor('rgb(220,213,213)');
                setbuttonborder(0);
            break;
            case 2: 
              setButtonborderradios(8);
                setButtoncolor('rgb(236, 235, 235)')
                setbuttonbordercolor('rgb(220,213,213)') 
              setbuttonborder(0);   

            break;
            case 3:
                setButtonborderradios(30);
                setButtoncolor('rgb(236, 235, 235)')
                setbuttonbordercolor('rgb(220,213,213)');
                setbuttonborder(0);   

             break ;
               case 4:
                setButtonborderradios(0);
                setButtoncolor('rgb(236, 235, 235)')
                setbuttonbordercolor('rgb(220,213,213)'); 
            setbuttonborder(0) ;   

            break;
            case 5: 
            setButtonborderradios(8);
            setButtoncolor('white')
            setbuttonbordercolor('rgb(220,213,213)'); 
            setbuttonborder(3) ;  
            break ;
            case 6:
                setButtonborderradios(8);
                setButtoncolor('white')
                setbuttonbordercolor('rgb(220,213,213)'); 
                setbuttonborder(1) ; 
             break ;
             case 7:
                setButtonborderradios(0);
                setButtoncolor('rgb(236, 235, 235)')
                setbuttonbordercolor('rgb(220,213,213)'); 
                setbuttonborder(1) ;       
        }
      },[buttoncount]);
    return (
        <>
            <div>
                <div>
                    <ul className='presets_presets__sNCDg'>
                        <li style={{display:'flex',gap:'0px 10px'}}>
                            <button class="epb-btn epb-104-base" style={{width:80}} onClick={()=>setButtoncount(1)}>Default</button>
                            <button class="epb-btn epb-104-base primary" style={{backgroundColor:'blue',color:'white',width:80}} onClick={()=>setButtoncount(1)}>Primary</button>
                        </li>
                        <li style={{display:'flex',gap:'0px 10px'}}>
                            <button class="epb-btn epb-104-base" style={{width:80}} onClick={()=>setButtoncount(2)}>Default</button>
                            <button class="epb-btn epb-104-base primary" style={{backgroundColor:'black',color:'white',width:80}} onClick={()=>setButtoncount(2)}>Primary</button>
                        </li>
                        <li style={{display:'flex',gap:'0px 10px'}}>
                            <button class="epb-btn epb-104-base" style={{borderRadius:30,width:80}} onClick={()=>setButtoncount(3)}>Default</button>
                            <button class="epb-btn epb-104-base primary" style={{backgroundColor:'black',color:'white',borderRadius:30,width:80}}onClick={()=>setButtoncount(3)}>Primary</button>
                        </li>
                        <li style={{display:'flex',gap:'0px 10px'}}>
                            <button class="epb-btn epb-104-base" style={{borderRadius:0,width:80}} onClick={()=>setButtoncount(4)}>Default</button>
                            <button class="epb-btn epb-104-base primary" style={{backgroundColor:'black',color:'white',borderRadius:0,width:80}} onClick={()=>setButtoncount(4)}>Primary</button>
                        </li>
                        <li style={{display:'flex',gap:'0px 10px'}}>
                            <button class="epb-btn epb-104-base" style={{border:'3px solid #eee',width:80,backgroundColor:'white'}} onClick={()=>setButtoncount(5)}>Default</button>
                            <button class="epb-btn epb-104-base primary" style={{backgroundColor:'black',color:'white',border:'3px sold black',borderColor:'black',width:80}} onClick={()=>setButtoncount(5)}>Primary</button>
                        </li>
                        <li style={{display:'flex',gap:'0px 10px'}}>
                            <button class="epb-btn epb-104-base" style={{borderWidth:1,width:80,backgroundColor:'white'}} onClick={()=>setButtoncount(6)}>Default</button>
                            <button class="epb-btn epb-104-base primary" style={{backgroundColor:'black',color:'white',borderWidth:1,width:80}} onClick={()=>setButtoncount(6)}>Primary</button>
                        </li>
                        <li style={{display:'flex',gap:'0px 10px'}}>
                            <button class="epb-btn epb-104-base" style={{width:80}} onClick={()=>setButtoncount(7)}>Default</button>
                            <button class="epb-btn epb-104-base primary" style={{backgroundColor:'#eee',color:'black',width:80}} onClick={()=>setButtoncount(7)}>Primary</button>
                        </li>
                    </ul>
                </div>
            </div>
            <button class="button" style={{ margin: "15px;" }} onClick={Handelbuttonstyle}>Customize Design</button>
            {/* </div> */}
        </>
    )
};

export default Buttondesing;