import React, { useContext } from 'react'
import Navbar from './navbar';
import Hero from './hero';
import Services from './service';
import Feature from './feature';
import FAQs from './FAQs';
import Footer from './footer';
import Button from './Button';
import TrustedBy from './trustedBy';
import Backbutton from './Backbutton';
import MyContext from '../context/MyContext';
import Memecoin from './MemeCoin/Memecoin';
import MemeTemplates from './MemeTemplates';

function Main(){
    const {introcolor}=useContext(MyContext)
   const { memecoin,modalShow,selectMemeCoinTemplates } = useContext(MyContext);

    return(

      <>
      
     
          
        
          {modalShow==true ? (
            selectMemeCoinTemplates!=0?<><Button></Button><Memecoin/></>:<MemeTemplates/>
          ) : (
            <div >
              <Button />
              <div class='container' style={{backgroundColor:`${introcolor}`,minWidth:'100%',padding:'50px',paddingTop:0}}>
          <Navbar className='mt-1' />
          <Hero />
          </div >
          <TrustedBy/>
          <Feature />
          <Services />
          <FAQs />
          <Footer /> 
          
            </div>
          )}
          

        
        </>
    )
};
export default Main;