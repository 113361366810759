import React, { useEffect } from 'react'
import { useContext } from 'react'
import MyContext from '../context/MyContext'
import { Link, useNavigate, useNavigation } from 'react-router-dom';
const ContentDesign = ({ headerText }) => {
    const{contentdesing,setContentdesing,
        section1, setsection1,
        section2, setsection2,
        section3, setsection3,
        section4, setsection4,
        section5, setsection5,
        section6, setsection6,
        section7, setsection7,setMemecoinsection,memecoinsection
    }=useContext(MyContext);

    const handelContent=()=>{
        setContentdesing(false)
    };
    const handeldesing=()=>{
      setContentdesing(true);
    };

    console.log('Content',contentdesing);
    useEffect(()=>{
        handeldesing();
        handelContent();
    },[])
    const goBack = () => {
       
        // window.history.back()
        setsection1(false);
        setsection2(false);
        setsection3(false);
        setsection4(false);
        setsection5(false);
        setsection6(false);
        setsection7(false);
        setContentdesing(false);
        setMemecoinsection(7)

      };
      console.log('Windoww',window.location.pathname)

     
      


      const GoBack=()=>{
        window.history.go(-1)
      };
      const handelsection1 = () => {

        setsection1(true);
        setsection2(false);
        setsection3(false);
        setsection4(false);
        setsection5(false);
        setsection6(false);
        setsection7(false);
        setContentdesing(false);

    };
    const handelsection2 = () => {

        setsection1(false);
        setsection2(true);
        setsection3(false);
        setsection4(false);
        setsection5(false);
        setsection6(false);
        setsection7(false);
        setContentdesing(false);

    };
    const handelsection3 = () => {

        setsection1(false);
        setsection2(false);
        setsection3(true);
        setsection4(false);
        setsection5(false);
        setsection6(false);
        setsection7(false);
        setContentdesing(false);

    };
    const handelsection4 = () => {

        setsection1(false);
        setsection2(false);
        setsection3(false);
        setsection4(true);
        setsection5(false);
        setsection6(false);
        setsection7(false);
        setContentdesing(false);

    };
    const handelsection5 = () => {

        setsection1(false);
        setsection2(false);
        setsection3(false);
        setsection4(false);
        setsection5(true);
        setsection6(false);
        setsection7(false);
        setContentdesing(false);

    };
    const handelsection6 = () => {

        setsection1(false);
        setsection2(false);
        setsection3(false);
        setsection4(false);
        setsection5(false);
        setsection6(true);
        setsection7(false);
        setContentdesing(false);

    };
    const handelsection7 = () => {
        setsection1(false);
        setsection2(false);
        setsection3(false);
        setsection4(false);
        setsection5(false);
        setsection6(false);
        setsection7(true);
        setContentdesing(false);
    };
     const Navigate= useNavigate();
      const handleNavigation = (event) => {
        const selectedOption = event.target.value;
    
        switch (selectedOption) {
            case 'Header':

            handelsection1(); 
            Navigate('/heroedit')
            
            break;
          case 'Hero':
            handelsection2();
            Navigate('/IntroEdit') 
            break;
          case 'Footer':
            handelsection7(); 
           Navigate('/FooterEdit')
           
            break;
            case 'Logos':
                handelsection3(); 
                Navigate('/Trustedit')
               
                break;
                case 'Feature':
            handelsection4(); 
            Navigate('/featureEdit')
           
            break;
            case 'Stats':
            handelsection5();
            Navigate('/LegacyEdit') 
           
            break;
            case 'Faqs':
           handelsection6();
           Navigate('/faqEdit')
            break;
            
        
          default:
            break;
        }
      };

      
    return (
        <>
            <div className='nav_nav__LpVJF sticky-top'>
                <div className='nav_main__HWvnb  ' >
                   <Link to='/save&edit'> 
                   <button  onClick={()=>goBack()}class="nav_back__+Ex8F"><svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.85355 3.14645C7.04882 3.34171 7.04882 3.65829 6.85355 3.85355L3.70711 7H12.5C12.7761 7 13 7.22386 13 7.5C13 7.77614 12.7761 8 12.5 8H3.70711L6.85355 11.1464C7.04882 11.3417 7.04882 11.6583 6.85355 11.8536C6.65829 12.0488 6.34171 12.0488 6.14645 11.8536L2.14645 7.85355C1.95118 7.65829 1.95118 7.34171 2.14645 7.14645L6.14645 3.14645C6.34171 2.95118 6.65829 2.95118 6.85355 3.14645Z" fill="currentColor" fill-rule="evenodd" clip-rule="evenodd"></path></svg></button>
                   </Link>
                    <button class="nav_item__Yxa31 nav_section__046fK" type="button" id="radix-:r3g:" aria-haspopup="menu" aria-expanded="false" data-state="closed">{headerText}<span class=""></span><span className='ms-2'>
                       {/* <select style={{width:270,borderStyle:"none",position:"relative",left:-15}}
                        class="nav_item__Yxa31 nav_section__046fK" id="radix-:r3g:" aria-haspopup="menu" aria-expanded="false" data-state="closed" onClick={handleNavigation}>  
                        <option selected style={{display:"none"}}>{headerText}</option>
                       <option value='Header' >Header</option>
                         <option value='Hero'>Hero</option>
                         <option value='Logos'>Logos</option>
                         <option value='Feature'>Feature</option>
                         <option value='Stats'>Stats</option>
                         <option value='Faqs'>FAQs</option>
                         <option value='Footer' >Footer</option>  


                       </select> */}
                    </span></button>
                    <div style={{ width: 10, flexShrink: 0 }}></div>
                    <button class="nav_sectionMenu__FXNmQ" type="button" id="radix-:r3i:" aria-haspopup="menu" aria-expanded="false" data-state="closed"><svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.625 2.5C8.625 3.12132 8.12132 3.625 7.5 3.625C6.87868 3.625 6.375 3.12132 6.375 2.5C6.375 1.87868 6.87868 1.375 7.5 1.375C8.12132 1.375 8.625 1.87868 8.625 2.5ZM8.625 7.5C8.625 8.12132 8.12132 8.625 7.5 8.625C6.87868 8.625 6.375 8.12132 6.375 7.5C6.375 6.87868 6.87868 6.375 7.5 6.375C8.12132 6.375 8.625 6.87868 8.625 7.5ZM7.5 13.625C8.12132 13.625 8.625 13.1213 8.625 12.5C8.625 11.8787 8.12132 11.375 7.5 11.375C6.87868 11.375 6.375 11.8787 6.375 12.5C6.375 13.1213 6.87868 13.625 7.5 13.625Z" fill="currentColor" fill-rule="evenodd" clip-rule="evenodd"></path></svg></button>
                </div>
                <div className='mode_mode__w2z3S'>
                    <button class={` ${contentdesing ==false? 'mode_active__2-8Yb':'hidden'}`} onClick={()=>handelContent()}>Content</button>
                    <button class={` ${contentdesing ==true? 'mode_active__2-8Yb':'hidden'}`} onClick={()=>handeldesing()}>Design</button>
                </div>
                <div style={{ height: 0, flexShrink: 0 }}></div>
            </div>
        </>
    )
}

export default ContentDesign