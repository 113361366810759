import React from "react";
import Modal from "react-bootstrap/Modal";
import Ratio from "react-bootstrap/Ratio";



function VideoModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{width:'100%'}}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Ratio aspectRatio={"16x9"}>
            <iframe
              width="1280"
              height="720"
               src="https://player.vimeo.com/video/1006856553"
              title="Elementor video placeholder"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </Ratio>
        </Modal.Body>
      </Modal>
    );
  };

  export default VideoModal;
  