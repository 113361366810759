

import React, { useContext, useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import WebPages from '../../Webpages';
import MyContext from "../../../context/MyContext";
import MainNavBar from "../../MainNavBar";
import LeagacyDesing from "./Legacydesing";
import ContentDesign from "../../ContentDesign";
import CaptionTitle from "../../CaptionTitle";
import { LeagacyAddbutton } from "./LeagacyAddbutton";
import Theme from "../../Theme";


const LegacyEdit = () => {
  const { contentdesing, mainnavbarbutton, byTheNumber, legacy, setleagacy, setByTheNumber, statsintro, setStatsinto,
    statsnumber1, setStatsnumber1,
    statsnumber2, setStatsnumber2,
    statsnumber3, setStatsnumber3,
    statsnumber4, setStatsnumber4,
    statsnumber5, setStatsnumber5,
    statsnumber6, setStatsnumber6,
    statsnumremove1, setStatsnumremove1,
    statsnumremove2, setStatsnumremove2,
    HandelLeagacy, removelegacynum,
    newlegacy, setNewlgacy,
    removelegacy, setRemovelegacy,
    newlegacy1, setNewlgacy1,
    statsnumremove3, setStatsnumremove3,
      stateOne,setStatsOne,
      divs, setDivs,  statethree,setStatsthree,
      statetwo,setStatstwo,
    statscaption, setStatsCaption } = useContext(MyContext);
  const [leagacyedit, setleagacyedit] = useState(true);

  const contentEditableRef1 = useRef(null);
  const [caretPosition1, setCaretPosition1] = useState(0);


  const contentEditableRef2 = useRef(null);
  const [caretPosition2, setCaretPosition2] = useState(0);
  const contentEditableRef3 = useRef(null);
  const [caretPosition3, setCaretPosition3] = useState(0);
  const contentEditableRef4 = useRef(null);
  const [caretPosition4, setCaretPosition4] = useState(0);
  const contentEditableRef5 = useRef(null);
  const [caretPosition5, setCaretPosition5] = useState(0);

  const handelnum2 = (e) => {
    setStatsnumber2(e.target.value)
  };
  const handelnum4 = (e) => {
    setStatsnumber4(e.target.value)
  };
  const handelnum6 = (e) => {
    setStatsnumber6(e.target.value)
  };
  const handleleagacy = (e) => {
    const content = contentEditableRef1.current.textContent;
    setleagacy(content);
    setCaretPosition1(getCaretPosition1());

  };

  const handleintro = (e) => {
    const content = contentEditableRef2.current.textContent;
    setStatsinto(content);
    setCaretPosition2(getCaretPosition2());

  };
  const handlenum1 = (e) => {
    const content = contentEditableRef3.current.textContent;
    setStatsnumber1(content);
    setCaretPosition3(getCaretPosition2());

  };
  const handlenum3 = (e) => {
    const content = contentEditableRef4.current.textContent;
    setStatsnumber3(content);
    setCaretPosition4(getCaretPosition4());

  };
  const handlenum5 = (e) => {
    const content = contentEditableRef5.current.textContent;
    setStatsnumber5(content);
    setCaretPosition5(getCaretPosition5());

  };

  useEffect(() => {
    setCaretToEnd1();
  }, [legacy]);

  useEffect(() => {
    setCaretToEnd2();
  }, [statsintro]);

  useEffect(() => {
    setCaretToEnd3();
  }, [statsnumber1]);
  useEffect(() => {
    setCaretToEnd4();
  }, [statsnumber3]);

  useEffect(() => {
    setCaretToEnd5();
  }, [statsnumber5]);


  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Backspace') {
        setCaretPosition1(getCaretPosition1());
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Backspace') {
        setCaretPosition2(getCaretPosition2());
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Backspace') {
        setCaretPosition3(getCaretPosition3());
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Backspace') {
        setCaretPosition4(getCaretPosition4());
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Backspace') {
        setCaretPosition5(getCaretPosition5());
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const getCaretPosition1 = () => {
    const selection = window.getSelection();
    if (selection && selection.rangeCount > 0) {
      return selection.getRangeAt(0).startOffset;
    }
    return 0;
  };
  const getCaretPosition2 = () => {
    const selection = window.getSelection();
    if (selection && selection.rangeCount > 0) {
      return selection.getRangeAt(0).startOffset;
    }
    return 0;
  };
  const getCaretPosition3 = () => {
    const selection = window.getSelection();
    if (selection && selection.rangeCount > 0) {
      return selection.getRangeAt(0).startOffset;
    }
    return 0;
  };
  const getCaretPosition4 = () => {
    const selection = window.getSelection();
    if (selection && selection.rangeCount > 0) {
      return selection.getRangeAt(0).startOffset;
    }
    return 0;
  };
  const getCaretPosition5 = () => {
    const selection = window.getSelection();
    if (selection && selection.rangeCount > 0) {
      return selection.getRangeAt(0).startOffset;
    }
    return 0;
  }

  const setCaretToEnd1 = () => {
    const range = document.createRange();
    const sel = window.getSelection();
    const lastChild = contentEditableRef1.current.lastChild;
    if (lastChild && lastChild.nodeType === Node.TEXT_NODE) {
      const textLength = lastChild.textContent.length;
      const offset = Math.min(caretPosition1, textLength);
      range.setStart(lastChild, offset);
      range.collapse(true);
      sel.removeAllRanges();
      sel.addRange(range);
    }
  };
  const setCaretToEnd2 = () => {

    const range = document.createRange();
    const sel = window.getSelection();
    const lastChild = contentEditableRef2.current.lastChild;
    if (lastChild && lastChild.nodeType === Node.TEXT_NODE) {
      const textLength = lastChild.textContent.length;
      const offset = Math.min(caretPosition2, textLength);
      range.setStart(lastChild, offset);
      range.collapse(true);
      sel.removeAllRanges();
      sel.addRange(range);
    }
  };
  const setCaretToEnd3 = () => {
    const range = document.createRange();
    const sel = window.getSelection();
    const lastChild = contentEditableRef3.current.lastChild;
    if (lastChild && lastChild.nodeType === Node.TEXT_NODE) {
      const textLength = lastChild.textContent.length;
      const offset = Math.min(caretPosition3, textLength);
      range.setStart(lastChild, offset);
      range.collapse(true);
      sel.removeAllRanges();
      sel.addRange(range);
    }
  };
  const setCaretToEnd4 = () => {
    const range = document.createRange();
    const sel = window.getSelection();
    const lastChild = contentEditableRef4.current.lastChild;
    if (lastChild && lastChild.nodeType === Node.TEXT_NODE) {
      const textLength = lastChild.textContent.length;
      const offset = Math.min(caretPosition4, textLength);
      range.setStart(lastChild, offset);
      range.collapse(true);
      sel.removeAllRanges();
      sel.addRange(range);
    }
  };
  const setCaretToEnd5 = () => {
    const range = document.createRange();
    const sel = window.getSelection();
    const lastChild = contentEditableRef5.current.lastChild;
    if (lastChild && lastChild.nodeType === Node.TEXT_NODE) {
      const textLength = lastChild.textContent.length;
      const offset = Math.min(caretPosition5, textLength);
      range.setStart(lastChild, offset);
      range.collapse(true);
      sel.removeAllRanges();
      sel.addRange(range);
    }
  };
  const Statsnumremove1 = () => {
    setStatsnumremove1(true)
  };
  const Statsnumremove2 = () => {
    setStatsnumremove2(true)
  };
  const Statsnumremove3 = () => {
    setStatsnumremove3(true)
  };

  const handelAddstats = () => {
    setStatsnumremove1(false);
    setStatsnumremove2(false);
    setStatsnumremove3(false);
    setStatsnumber1('')
    setStatsnumber2('')
    setStatsnumber3('')
    setStatsnumber4('')
    setStatsnumber5('')
    setStatsnumber6('')


  }
    ;


  const handleAddNewlegacy = () => {
    console.log('newlegacy', newlegacy)
    setNewlgacy(prevMenu => [...prevMenu, newlegacy]);
    setNewlgacy([...newlegacy, { value: '', label: '' ,prefix:'',suffix:''}])

  };



  const handleChange = (index, newValue) => {
    const updatedLegacy = [...newlegacy];
    updatedLegacy[index].label = newValue;
    setNewlgacy(updatedLegacy);
  };



  const handleRemoveDivs = (indexToRemove) => {
    setNewlgacy(prevMenu => {
      const updatedMenu = [...prevMenu];
      updatedMenu.splice(indexToRemove, 1);
      return updatedMenu;

    });

  };
  console.log('Divs', newlegacy, newlegacy.length, statsnumremove1, statsnumremove2, statsnumremove3);

  const handelNumberLabel = (index, value) => {
    const Newdata = [...newlegacy];
    Newdata[index].label = value;
    setNewlgacy(Newdata);
  };

  const handelNumberValue = (index, value) => {
    const Newdata = [...newlegacy];
    Newdata[index].value = value;
    setNewlgacy(Newdata);
  };
  const handelNumberPrefix = (index, value) => {
    const Newdata = [...newlegacy];
    Newdata[index].prefix = value;
    setNewlgacy(Newdata);
  };
  const handelNumberSuffix = (index, value) => {
    const Newdata = [...newlegacy];
    Newdata[index].suffix = value;
    setNewlgacy(Newdata);
  };

  const HandelstatsCaption=(Caption)=>{
     setStatsCaption(Caption.target.value)
  }; 

  const handlePrefixChange = (event) => {
    const newPrefix = event.target.value;
    setStatsOne(prevState => ({
      ...prevState,
      prefix: newPrefix
    }));
  };

  const handleSuffixChange = (event) => {
    const newSuffix = event.target.value;
    setStatsOne(prevState => ({
      ...prevState,
      suffix: newSuffix
    }));
  };
  const handlePrefixChangetwo = (event) => {
    const newPrefix = event.target.value;
    setStatstwo(prevState => ({
      ...prevState,
      prefix: newPrefix
    }));
  };

  const handleSuffixChangetwo = (event) => {
    const newSuffix = event.target.value;
    setStatstwo(prevState => ({
      ...prevState,
      suffix: newSuffix
    }));
  };
  const handlePrefixChangethree = (event) => {
    const newPrefix = event.target.value;
    setStatsthree(prevState => ({
      ...prevState,
      prefix: newPrefix
    }));
  };

  const handleSuffixChangethree = (event) => {
    const newSuffix = event.target.value;
    setStatsthree(prevState => ({
      ...prevState,
      suffix: newSuffix
    }));
  };

  return (
    <div>


      <div className='container-fluid d-flex flex-column' style={{ height: '100vh', overflow: 'auto' }}>
        <div>
          <MainNavBar />
        </div>
        <div className='flex-grow-1 d-flex flex-row mt-1' style={{ overflow: 'hidden' }}>
          <div style={{ width: '400px' }} >
            {mainnavbarbutton == false ?
              <div className='editing_wrapper__Wijfx h-100  overflow-auto'>
                <ContentDesign headerText="Stats" />

                {contentdesing == false ?
                  <div >
                    <div className="editing_content__RXzxk">
                      <div className="form_form__Sku4X" >
                        <div className="form_form__Sku4X">
                          <label class="form_label__27psn">Header</label>
                          <div className="form_group__kDXNb">
                            <div className="form_form__Sku4X">
                              <div class="form_more__6E9XQ" type="button" id="radix-:rer:" aria-haspopup="menu" aria-expanded="false" data-state="closed"><svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.625 2.5C8.625 3.12132 8.12132 3.625 7.5 3.625C6.87868 3.625 6.375 3.12132 6.375 2.5C6.375 1.87868 6.87868 1.375 7.5 1.375C8.12132 1.375 8.625 1.87868 8.625 2.5ZM8.625 7.5C8.625 8.12132 8.12132 8.625 7.5 8.625C6.87868 8.625 6.375 8.12132 6.375 7.5C6.375 6.87868 6.87868 6.375 7.5 6.375C8.12132 6.375 8.625 6.87868 8.625 7.5ZM7.5 13.625C8.12132 13.625 8.625 13.1213 8.625 12.5C8.625 11.8787 8.12132 11.375 7.5 11.375C6.87868 11.375 6.375 11.8787 6.375 12.5C6.375 13.1213 6.87868 13.625 7.5 13.625Z" fill="#8c8c8c" fill-rule="evenodd" clip-rule="evenodd"></path></svg></div>
                              <div class="form_row__2+BJ3">
                                <div class="form_field__d31bq form_size--2x2__J8UQE"><div class="picker_preview__QSKw4"><svg xmlns="http://www.w3.org/2000/svg" height="22" viewBox="0 0 24 24" width="22" fill="#bbb"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm-1-4h2v2h-2zm1.61-9.96c-2.06-.3-3.88.97-4.43 2.79-.18.58.26 1.17.87 1.17h.2c.41 0 .74-.29.88-.67.32-.89 1.27-1.5 2.3-1.28.95.2 1.65 1.13 1.57 2.1-.1 1.34-1.62 1.63-2.45 2.88 0 .01-.01.01-.01.02-.01.02-.02.03-.03.05-.09.15-.18.32-.25.5-.01.03-.03.05-.04.08-.01.02-.01.04-.02.07-.12.34-.2.75-.2 1.25h2c0-.42.11-.77.28-1.07.02-.03.03-.06.05-.09.08-.14.18-.27.28-.39.01-.01.02-.03.03-.04.1-.12.21-.23.33-.34.96-.91 2.26-1.65 1.99-3.56-.24-1.74-1.61-3.21-3.35-3.47z"></path></svg></div></div>
                                <div className="form_group__kDXNb">
                                  <div className="form_form__Sku4X" style={{ maxWidth: "89%" }}>
                                    <div class="form_field__d31bq" >
                                      <textarea placeholder="Caption" style={{ height: "32px" }} onChange={(e)=>HandelstatsCaption(e)} value={statscaption}></textarea></div>
                                    <div class="form_field__d31bq"><div class=""><div contenteditable="true" translate="no" class="tiptap ProseMirror form_value__oIwpW form_tiptap__s0JN1" tabindex="0" ref={contentEditableRef1} onInput={handleleagacy}>{legacy}</div></div></div>
                                    <div class="form_field__d31bq"><div class=""><div contenteditable="true" translate="no" class="tiptap ProseMirror form_value__oIwpW form_tiptap__s0JN1" tabindex="0" ref={contentEditableRef2} onInput={handleintro}>{statsintro}</div></div></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="form_group__kDXNb">
                          <div className="form_form__Sku4X">
                            <label class="form_label__27psn">Items</label>






                            {statsnumremove1 == false ?
                              <div className="form_sortable__FLtVI">
                                <div className="form_listItem__puOqW">
                                  <div class="form_headerHandle__33dpI" tabindex="0" role="button" aria-describedby="rbd-hidden-text-0-hidden-text-0" data-rbd-drag-handle-draggable-id="draggable-0" data-rbd-drag-handle-context-id="0" draggable="false"></div>
                                  <div class="form_headerRemove__mJ7no" onClick={() => Statsnumremove1()}><svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 0 24 24" width="14" fill="#8f8f8f"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"></path></svg></div>
                                  <div className="form_form__Sku4X">
                                    <div className="d-flex ">
                                      <div class="form_field__d31bq" style={{ width: '30%' }} ><textarea placeholder="Prefix" style={{ height: '32px' }} value={stateOne.prefix} onChange={(e)=>handlePrefixChange(e)}></textarea></div>
                                      <div class="form_field__d31bq" style={{ width: '30%' }} ><input type="text" class="" placeholder="Number" value={statsnumber2} onChange={(e) => handelnum2(e)} style={{ textAlign: 'left' }} /></div>
                                      <div class="form_field__d31bq" style={{ width: '30%' }} ><textarea placeholder="Suffix" style={{ height: '32px' }} value={stateOne.suffix} onChange={(e)=>handleSuffixChange(e)}></textarea></div>
                                    </div>
                                    <div class="form_field__d31bq form_active__EDXVH"><div class="form_active__EDXVH"><div contenteditable="true" translate="no" class="tiptap ProseMirror form_value__oIwpW form_tiptap__s0JN1" tabindex="0" ref={contentEditableRef3} onInput={handlenum1}>{statsnumber1}</div></div></div>                            </div>
                                </div>
                              </div> : null}



                            {statsnumremove2 == false ?
                              <div className="form_sortable__FLtVI">
                                <div className="form_listItem__puOqW">
                                  <div class="form_headerHandle__33dpI" tabindex="0" role="button" aria-describedby="rbd-hidden-text-0-hidden-text-0" data-rbd-drag-handle-draggable-id="draggable-0" data-rbd-drag-handle-context-id="0" draggable="false"></div>
                                  <div class="form_headerRemove__mJ7no" onClick={() => Statsnumremove2()}><svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 0 24 24" width="14" fill="#8f8f8f"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"></path></svg></div>
                                  <div className="form_form__Sku4X">
                                    <div className="d-flex ">
                                      <div class="form_field__d31bq" style={{ width: '30%' }} ><textarea placeholder="Prefix" style={{ height: '32px' }} value={statetwo.prefix} onChange={(e)=>handlePrefixChangetwo(e)}></textarea></div>
                                      <div class="form_field__d31bq" style={{ width: '30%' }} ><input type="text" onChange={(e) => handelnum4(e)} class="" placeholder="Number" value={statsnumber4} style={{ textAlign: 'left' }} /></div>
                                      <div class="form_field__d31bq" style={{ width: '30%' }} ><textarea placeholder="Suffix" style={{ height: '32px' }} value={statetwo.suffix} onChange={(e)=>handleSuffixChangetwo(e)} ></textarea></div>
                                    </div>
                                    <div class="form_field__d31bq"><div class=""><div contenteditable="true" translate="no" class="tiptap ProseMirror form_value__oIwpW form_tiptap__s0JN1" tabindex="0" ref={contentEditableRef4} onInput={handlenum3} >{statsnumber3}</div></div></div>
                                  </div>
                                </div>
                              </div> : null}


                            {statsnumremove3 == false ?
                              <div className="form_sortable__FLtVI">
                                <div className="form_listItem__puOqW">
                                  <div class="form_headerHandle__33dpI" tabindex="0" role="button" aria-describedby="rbd-hidden-text-0-hidden-text-0" data-rbd-drag-handle-draggable-id="draggable-0" data-rbd-drag-handle-context-id="0" draggable="false"></div>
                                  <div class="form_headerRemove__mJ7no" onClick={() => Statsnumremove3()}><svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 0 24 24" width="14" fill="#8f8f8f"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"></path></svg></div>
                                  <div className="form_form__Sku4X">
                                    <div className="d-flex ">
                                      <div class="form_field__d31bq" style={{ width: '30%' }} ><textarea placeholder="Prefix" style={{ height: '32px' }} value={statethree.prefix} onChange={(e)=>handlePrefixChangethree(e)}></textarea></div>
                                      <div class="form_field__d31bq" style={{ width: '30%' }} ><input type="text" onChange={(e) => handelnum6(e)} class="" placeholder="Number" value={statsnumber6} style={{ textAlign: 'left' }} /></div>
                                      <div class="form_field__d31bq" style={{ width: '30%' }} ><textarea placeholder="Suffix" style={{ height: '32px' }} value={statethree.suffix} onChange={(e)=>handleSuffixChangethree(e)}></textarea></div>
                                    </div>
                                    <div class="form_field__d31bq"><div class=""><div contenteditable="true" translate="no" class="tiptap ProseMirror form_value__oIwpW form_tiptap__s0JN1" tabindex="0" ref={contentEditableRef5} onInput={handlenum5} >{statsnumber5}</div></div></div>
                                  </div>
                                </div>

                              </div> : null}

                            {newlegacy.map((item, index) => {
                              console.log('item', item)
                              return <div className="form_sortable__FLtVI" key={index}>
                                <div className="form_listItem__puOqW">
                                  <div class="form_headerHandle__33dpI" tabindex="0" role="button" aria-describedby="rbd-hidden-text-0-hidden-text-0" data-rbd-drag-handle-draggable-id="draggable-0" data-rbd-drag-handle-context-id="0" draggable="false"></div>
                                  <div class="form_headerRemove__mJ7no" onClick={() => handleRemoveDivs()}><svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 0 24 24" width="14" fill="#8f8f8f"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"></path></svg></div>
                                  <div className="form_form__Sku4X">
                                    <div className="d-flex ">
                                      <div class="form_field__d31bq" style={{ width: '30%' }} ><textarea placeholder="Prefix" style={{ height: '32px' }} value={item.prefix} onChange={(e)=>handelNumberPrefix(index,e.target.value)}></textarea></div>
                                      <div class="form_field__d31bq" style={{ width: '30%' }} ><input type="text" class="" placeholder="Number" style={{ textAlign: 'left' }} value={item.label} onChange={(e) => handelNumberLabel(index, e.target.value)} /></div>
                                      <div class="form_field__d31bq" style={{ width: '30%' }} ><textarea placeholder="Suffix" style={{ height: '32px' }} value={item.suffix} onChange={(e)=>handelNumberSuffix(index,e.target.value)}></textarea></div>
                                    </div>
                                    <div class="form_field__d31bq"><div class="">
                                      {/* <div contenteditable="true" translate="no" class="tiptap ProseMirror form_value__oIwpW form_tiptap__s0JN1" tabindex="0"> */}
                                      <textarea contenteditable="true" translate="no" type="text" class="tiptap ProseMirror form_value__oIwpW form_tiptap__s0JN1" placeholder="Number" style={{ height: 20, width: '100%', textAlign: 'left', background: 'transperant', resize: 'none', overflow: 'hidden', borderStyle: "none" }} value={item.value} onChange={(e) => handelNumberValue(index, e.target.value)} ></textarea>
                                      {/* </div> */}
                                    </div></div>
                                  </div>
                                </div>

                              </div>
                            })}



                            {((statsnumremove1 == true && statsnumremove2 == true) && (statsnumremove3 == true) && (newlegacy.length == 0)) ?
                              <div className='form_emptyList__KNV3N'>
                                <div>Your list is empty</div>
                                <button class="button1 button--small" onClick={() => handleAddNewlegacy()} >Add Item</button>
                              </div> :
                              <button class="button1 button--small " style={{ alignSelf: 'flex-end', marginTop: '15px' }} onClick={() => handleAddNewlegacy()}>Add Item</button>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> : <LeagacyDesing />}



              </div> : <div className='editing_wrapper__Wijfx h-100  overflow-auto'><Theme/></div>}
          </div>
          <div className=' h-100 overflow-auto editing_wrapper__Wijfxnew' style={{ width: 'calc(100% - 400px)' }}>
            <WebPages />
          </div>
        </div>
      </div>


    </div>
  )
};

export default LegacyEdit;