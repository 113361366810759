import React, { useContext, useEffect,} from 'react'
import Navbar from './navbar'
import Hero from './hero'
import Services from './service'
import Feature from './feature'
import FAQs from './FAQs'
import Footer from './footer'
import EditHero from './Webpages/Hero'
import TrustedBy from './trustedBy'
import FeatureEditi from './Webpages/FeatureEditi'
import Downloadsourcecode from './Download'
import MainNavBar from './MainNavBar'
import MyContext from '../context/MyContext'
import UrlNav from './UrlNav'


function WebPages(props) {
    const { visibleComponents, setVisibleComponents, section7, section6, section5, section4, section3, section2, section1,  checked, setChecked, } = useContext(MyContext);
    
  
    
    
    useEffect(() => {
        if (section1 === true) {
            window.location.href = '#navbar1'
        };
        if (section2 === true) {
            window.location.href = '#hero1'
        }
        if (section3 === true) {
            window.location.href = '#trust1'
        }
        if (section4 === true) {
            window.location.href = '#feature1'
        }
        if (section5 === true) {
            window.location.href = '#service1'
        }
        if (section6 === true) {
            window.location.href = '#faq1'
        }
        if (section7 === true) {
            window.location.href = '#footer1'
        }

    }, [section1, section7, section6, section5, section4, section3, section2])
    return (

        <div >
                <UrlNav/>
            <div id='navbar1' className={checked ? 'sticky-top' : ''} ><Navbar  /></div>
            
            <div id='hero1' style={{position:'relative',top:"-2px"}}> <EditHero /></div>
            <div id='trust1'>  <TrustedBy /></div>
            <div id='feature1'> <FeatureEditi /></div>
            <div id='service1'>  <Services /></div>
            <div id='faq1'>   <FAQs /></div>
            <div id='footer1' > <Footer /></div>
        </div>
    )
};

export default WebPages