import React, { useState } from "react";
import umso_img from "../assets/images/umso.png";
import MyContext from "../context/MyContext";
import { useContext } from "react";

const Footer = () => {
    const { footeryear, Section7,
        footertext, footercolor,
        footerfeature, setfooterfeature,
        footercompany, setfootercompany,
        footerlegal, setfooterlegal,
        removeSocialIcon1, setRemoveSocialIcon1,
        removeSocialIcon2, setRemoveSocialIcon2,
        removeSocialIcon3, setRemoveSocialIcon3,
        removeSocialIcon4, setRemoveSocialIcon4, newfootersocialIcon,
        footerlegalhead, setFooterleagalhead,
        footerCompanyhead, setFooterCompanyhead,
        footerfeaturehead, setFooterfeaturehead,
        footerLinkgroups, newfooterhead, selectedFooter, setSelectedFooter,
        footerbodytextfont, footerMargintop, footerMarginBottom, contenttextheadingcolor, contenttextinfocolor,
        randomId,
    } = useContext(MyContext)
    console.log('footercolor', footercolor)
    console.log('footertext', footertext)
    const footerfeaturelinkhead = footerfeature[0];

    const Handleopenpixel=()=>{
        window.open('https://www.pexels.com/')
    }
    
    return (
        <div style={{ backgroundColor: `${footercolor}`, minWidth:"100%", padding:"50px",paddingTop:'0px'}} class="container ">
            {selectedFooter === null &&
                (
                    <div class="container " style={{ backgroundColor: `${footercolor}`, }} ref={Section7}>
                        <div class="row " style={{ marginTop: `${footerMargintop}px`, marginBottom: `${footerMarginBottom}px` }}>
                            <div class="col-sm-6 d-flex align-items-start justify-content-start flex-column">
                                <span style={{ color: `${footertext}`, fontFamily: `${footerbodytextfont}`, color: `${contenttextinfocolor}` }}>{footeryear}</span>
                                <div class="media mt-2">
                                    <a href="" style={{ display: removeSocialIcon1 ? 'none' : "" }}><i class="fa-brands fa-facebook" style={{ color: `${contenttextinfocolor}` }}></i></a>
                                    <a href="" class="ms-2" style={{ display: removeSocialIcon2 ? 'none' : "" }}><i class="fa-brands fa-instagram" style={{ color: `${contenttextinfocolor}` }}></i></a>
                                    <a href="" class="ms-2" style={{ display: removeSocialIcon3 ? 'none' : "" }}><i class="fa-brands fa-twitter" style={{ color: `${contenttextinfocolor}` }}></i></a>
                                    <a href="" class="ms-2" style={{ display: removeSocialIcon4 ? 'none' : "" }}><i class="fa-brands fa-youtube" style={{ color: `${contenttextinfocolor}` }}></i></a>
                                    {newfootersocialIcon.map((item) => (
                                        <a href="#" class="ms-2" ><i class="fa-brands ">#</i>{item}</a>

                                    ))}
                                </div>
                            </div>
                             
                            <div class="col-sm-6">
                                <div class="row">
                                    <div class="col-md-4 col-sm-5 footer-links">
                                        <span style={{ display: footerfeature.length == 0 ? 'none' : "", fontFamily: `${footerbodytextfont}`, color: `${contenttextinfocolor}` }}>{footerfeaturehead}</span>
                                        {footerfeature.map((item) => (
                                            <li style={{ fontSize: 13.5, color: `${contenttextinfocolor}`, listStyle: 'none', marginTop: 5, fontFamily: `${footerbodytextfont}` }}>
                                                {item}
                                            </li>

                                        ))}
                                    </div>
                                    <div class="col-md-4  col-sm-5 footer-links">
                                        <span style={{ display: footercompany.length == 0 ? 'none' : '', fontFamily: `${footerbodytextfont}`, color: `${contenttextinfocolor}` }}>{footerCompanyhead}</span>
                                        {footercompany.map((item) => (
                                            <li style={{ fontSize: 13.5, color: `${contenttextinfocolor}`, listStyle: 'none', marginTop: 5, fontFamily: `${footerbodytextfont}` }}>
                                                {item}
                                            </li>

                                        ))}
                                    </div>
                                    <div class="col-md-4  col-sm-5 footer-links">
                                        <span style={{ display: footerlegal.length == 0 ? 'none' : '', fontFamily: `${footerbodytextfont}`, color: `${contenttextinfocolor}` }}>{footerlegalhead}</span>
                                        {footerlegal.map((item) => (
                                            <li style={{ fontSize: 13.5, color: `${contenttextinfocolor}`, listStyle: 'none', marginTop: 5, fontFamily: `${footerbodytextfont}` }}>
                                                {item}
                                            </li>

                                        ))}
                                    </div>

                                </div>
                            </div>
                            <div>
                                <span onClick={Handleopenpixel} style={{cursor:"pointer",display:randomId?"none":"black",fontSize: 13.5, color: `${contenttextinfocolor}`, listStyle: 'none', marginTop: 5, fontFamily: `${footerbodytextfont}`}}>Photos provided by Pexels.</span>
                            </div>
                        </div>
                    </div>
                )}
            {selectedFooter === 0 && (
                <div class="container " style={{ backgroundColor: `${footercolor}`, }} ref={Section7}>
                    <div class="row " style={{ marginTop: `${footerMargintop}px`, marginBottom: `${footerMarginBottom}px` }}>
                        <div class="col-sm-6 d-flex align-items-start justify-content-start flex-column">
                            <span style={{ color: `${footertext}`, fontFamily: `${footerbodytextfont}`, color: `${contenttextinfocolor}` }}>{footeryear}</span>
                            <div class="media mt-2">
                                <a href="" style={{ display: removeSocialIcon1 ? 'none' : "" }}><i class="fa-brands fa-facebook"></i></a>
                                <a href="" class="ms-2" style={{ display: removeSocialIcon2 ? 'none' : "" }}><i class="fa-brands fa-instagram"></i></a>
                                <a href="" class="ms-2" style={{ display: removeSocialIcon3 ? 'none' : "" }}><i class="fa-brands fa-twitter"></i></a>
                                <a href="" class="ms-2" style={{ display: removeSocialIcon4 ? 'none' : "" }}><i class="fa-brands fa-youtube"></i></a>
                                {newfootersocialIcon.map((item) => (
                                    <a href="#" class="ms-2" ><i class="fa-brands ">#</i>{item}</a>

                                ))}
                            </div>
                        </div>

                        <div class="col-sm-6">
                            <div class="row">
                                <div class="col-md-4 col-sm-5 footer-links">
                                    <span style={{ display: footerfeature.length == 0 ? 'none' : "", fontFamily: `${footerbodytextfont}`, color: `${contenttextinfocolor}` }}>{footerfeaturehead}</span>
                                    {footerfeature.map((item) => (
                                        <li style={{ fontSize: 13.5, color: `${contenttextinfocolor}`, listStyle: 'none', marginTop: 5, fontFamily: `${footerbodytextfont}` }}>
                                            {item}
                                        </li>

                                    ))}
                                </div>
                                <div class="col-md-4  col-sm-5 footer-links">
                                    <span style={{ display: footercompany.length == 0 ? 'none' : '', fontFamily: `${footerbodytextfont}`, color: `${contenttextinfocolor}` }}>{footerCompanyhead}</span>
                                    {footercompany.map((item) => (
                                        <li style={{ fontSize: 13.5, color: `${contenttextinfocolor}`, listStyle: 'none', marginTop: 5, fontFamily: `${footerbodytextfont}` }}>
                                            {item}
                                        </li>

                                    ))}
                                </div>
                                <div class="col-md-4  col-sm-5 footer-links">
                                    <span style={{ display: footerlegal.length == 0 ? 'none' : '', fontFamily: `${footerbodytextfont}`, color: `${contenttextinfocolor}` }}>{footerlegalhead}</span>
                                    {footerlegal.map((item) => (
                                        <li style={{ fontSize: 13.5, color: `${contenttextinfocolor}`, listStyle: 'none', marginTop: 5, fontFamily: `${footerbodytextfont}` }}>
                                            {item}
                                        </li>

                                    ))}
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Footer;