import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquare } from '@fortawesome/free-solid-svg-icons';
import Navbar from './navbar';
import EditHero from './Webpages/Hero';
import TrustedBy from './trustedBy';
import FeatureEditi from './Webpages/FeatureEditi';
import Services from './service';
import FAQs from './FAQs';
import Footer from './footer';
import MainNavBar from './MainNavBar';
import MyContext from '../context/MyContext';
import Heroedit from './SaveandEdit/Heroedit';

function Edit(){
     
   const {Section1,
    Section2,
    Section3,
    Section4,
    Section5,
    Section6,
    Section7,
    handelscroller,}=useContext(MyContext); 
    const [navbar,setnavbar]=useState(false);
    const handelclick=()=>{
        setnavbar(true);
    }
          
    return(
        <div> 
             <MainNavBar/>
              <div style={{display:'flex', width:'100%',   }}>
                
                 <div style={{width:'30%',scrollbarWidth: 'none', height:'500px',boxShadow:'0 0 0 1px #eaeaea, 0 0 5px rgba(0, 0, 0, .05)',overflowY:'scroll',position:'relative',borderRadius:'10px',background:'#fff',margin:'10px 15px 15px',top:0,bottom:0}}>

                   
                    
                        
                {navbar==false?<Link to=''  style={{textDecoration:'none'}}> <div  className='sidebaritem' style={{display:"flex"}} >
                 <FontAwesomeIcon icon={faSquare} className='squareicon' style={{position:'relative',top:5,marginRight:5}} onClick={()=>handelscroller(Section1)}/>    <h6 onClick={()=>handelclick()}>  Header  </h6> 
                    </div></Link>:<Heroedit/>} 
                    <Link to='' style={{textDecoration:'none'}} onClick={()=>handelscroller(Section2)}>
                    <div className='sidebaritem'style={{display:"flex"}} >
                    <FontAwesomeIcon icon={faSquare} className='squareicon' style={{position:'relative',top:5,marginRight:5}}/>  <h6 onClick={()=>handelscroller(Section2)}>  Hero  </h6>
                    </div>
                    </Link>
                    <Link to='' style={{textDecoration:'none'}}>  <div className='sidebaritem' style={{display:"flex"}}>
                    <FontAwesomeIcon icon={faSquare} className='squareicon' style={{position:'relative',top:5,marginRight:5}} />   <h6 onClick={()=>handelscroller(Section3)}> Logos  </h6>
                    </div></Link>
                   
                    {/* <div className='sidebaritem' >
                        <h6> <FontAwesomeIcon icon={faSquare} className='squareicon' /> Logos  </h6>
                    </div> */}
                     <Link to='' style={{textDecoration:'none'}}>
                    <div className='sidebaritem'style={{display:"flex"}} >
                    <FontAwesomeIcon icon={faSquare} className='squareicon' style={{position:'relative',top:5,marginRight:5}}/>  <h6 onClick={()=>handelscroller(Section4)}> Features </h6>
                    </div>
                    </Link>
                     <Link to='' style={{textDecoration:'none'}}>
                    <div className='sidebaritem' style={{display:"flex"}} >
                    <FontAwesomeIcon icon={faSquare} className='squareicon'  style={{position:'relative',top:5,marginRight:5}}/>  <h6 onClick={()=>handelscroller(Section5)}>  Stats </h6>
                    </div>
                    </Link>
                 
                   
                      <Link to='' style={{textDecoration:'none'}}>
                    <div className='sidebaritem'style={{display:"flex"}} >
                    <FontAwesomeIcon icon={faSquare} className='squareicon' style={{position:'relative',top:5,marginRight:5}} />  <h6 onClick={()=>handelscroller(Section6)}> FAQ   </h6>
                    </div>
                    </Link>
                     <Link to='' style={{textDecoration:'none'}} >
                    <div className='sidebaritem' style={{display:"flex"}} >
                    <FontAwesomeIcon icon={faSquare} className='squareicon' style={{position:'relative',top:5,marginRight:5}} />  <h6 onClick={()=>handelscroller(Section7)}>  Footer</h6>
                    </div>
                    </Link>
                   </div>
                   
                   <div style={{width:'70%',boxShadow:'0 0 0 1px #eaeaea, 0 0 5px rgba(0, 0, 0, .05)',overflow:'hidden',position:'relative',borderRadius:'10px',background:'#fff',margin:'10px 15px 15px'}}>

                    {/* <WebPages/>  */}
                    <div ref={Section1}><Navbar/></div>
                    <div ref={Section2}><EditHero/></div>
                    <div ref={Section3}><TrustedBy/></div>
                    <div ref={Section4}><FeatureEditi/></div>
                    <div ref={Section5}><Services/></div>
                    <div ref={Section6}><FAQs/></div>
                    <div ref={Section7}><Footer/></div>

                   </div>
              </div>
        </div>
    )
};

export default Edit;