import React, { useContext, useState } from "react";
import MyContext from "../context/MyContext";

import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';

const FAQs = () => {

    const { Section6, faq, faqcolor, faqtext, faqA1, setfaqA1,
        faqA2, setfaqA2,
        faqA3, setfaqA3,
        faqA4, setfaqA4,
        faqQ1, setFaqQ1,
        faqQ2, setFaqQ2,
        faqQ3, setFaqQ3,
        faqQ4, setFaqQ4,
        removeFaq1, setRemovefaq1,
        removeFaq2, setRemovefaq2,
        removeFaq3, setRemovefaq3,
        removeFaq4, setRemovefaq4,
        faqtitle, setFaqtitle,
        faqIntro, setFaqIntro,
        newFaq, faqCaption, captioncolor,
        faqtitletextfont, setFaqtitletextfont,
        faqbodytextfont, setFaqbodytextfont,
         contenttextinfocolor,
        faqMargintop, faqMarginBottom, 
        contenttextheadingcolor, selectedFaq,
         setSelectedFaq,
    } = useContext(MyContext);
    const [openIndex, setOpenIndex] = useState(null);

    const toggleAccordion = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    }
    console.log('faq4', faqQ4);

    const [isOpen1, setIsOpen1] = useState(false);
    const [isOpen2, setIsOpen2] = useState(false);
    const [isOpen3, setIsOpen3] = useState(false);
    const [isOpen4, setIsopen4] = useState(false)
    const [isOpen, setIsopen] = useState(false);
    const toggleAccordionNew = () => {
        setIsopen(!isOpen);
        setIsOpen1(false);
        setIsOpen2(false);
        setIsOpen3(false);
        setIsopen4(false);
    }
    const toggleAccordion1 = () => {
        setIsOpen1(!isOpen1);
        setIsOpen2(false);
        setIsOpen3(false);
        setIsopen(false);
        setIsopen4(false)
    };

    const toggleAccordion2 = () => {
        setIsOpen1(false);
        setIsOpen2(!isOpen2);
        setIsOpen3(false);
        setIsopen(false);
        setIsopen4(false)
    };

    const toggleAccordion3 = () => {
        setIsOpen1(false);
        setIsOpen2(false);
        setIsOpen3(!isOpen3);
        setIsopen(false);
        setIsopen4(false)
    };

    const toggleAccordion4 = () => {
        setIsOpen1(false);
        setIsOpen2(false);
        setIsOpen3(false);
        setIsopen(false);
        setIsopen4(!isOpen4);
    }
    console.log('faq', selectedFaq)
    return (
        <div style={{ backgroundColor: `${faqcolor}`, minWidth:'100%',padding:'50px',paddingTop:'0px'}} class="container ">


            {selectedFaq === null && (
                <>
                    <div style={{ backgroundColor: `${faqcolor}`, minWidth:'100%'}} ref={Section6}>
                        <div style={{ marginTop: `${faqMargintop}px`, marginBottom: `${faqMarginBottom}px` }}>
                            <p style={{ color: `${captioncolor}` }}>{faqCaption}</p>
                            <h1 class=" heading fs-4" style={{ fontFamily: `${faqtitletextfont}`, color: `${contenttextheadingcolor}` }}>{faqtitle}</h1>
                            <p style={{ fontSize: 17, fontFamily: `${faqbodytextfont}`, color: `${contenttextinfocolor}` }}>
                                {faqIntro}
                            </p>


                         

                        </div>


                    </div>
                    <div>

                        
                        <Accordion style={{display:removeFaq1?'none':"block"}}>
                            <AccordionSummary 
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                                style={{ padding: '1rem 0', fontSize: 13, backgroundColor: `${faqcolor}`, fontFamily: `${faqbodytextfont}` }}
                            >
                                {faqQ1}
                            </AccordionSummary>
                            <AccordionDetails style={{ backgroundColor: `${faqcolor}`, fontFamily: `${faqbodytextfont}`, color: `${contenttextheadingcolor}`,}}>
                                {faqA1}
                            </AccordionDetails>
                        </Accordion>
                        

                        <Accordion style={{display:removeFaq2?'none':"block"}}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                                style={{ padding: '1rem 0', fontSize: 13, backgroundColor: `${faqcolor}`, fontFamily: `${faqbodytextfont}` }}
                            >
                                {faqQ2}
                            </AccordionSummary>
                            <AccordionDetails style={{ backgroundColor: `${faqcolor}`, fontFamily: `${faqbodytextfont}`, color: `${contenttextheadingcolor}`,}}>
                                {faqA2}
                            </AccordionDetails>
                        </Accordion>

                        <Accordion style={{display:removeFaq3?'none':"block"}}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                                style={{ padding: '1rem 0', fontSize: 13, backgroundColor: `${faqcolor}`, fontFamily: `${faqbodytextfont}` }}
                            >
                                {faqQ3}
                            </AccordionSummary>
                            <AccordionDetails style={{ backgroundColor: `${faqcolor}`, fontFamily: `${faqbodytextfont}`, color: `${contenttextheadingcolor}`,}}>
                                {faqA3}
                            </AccordionDetails>
                        </Accordion>

                        <Accordion style={{display:removeFaq4?'none':"block"}}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                                style={{ padding: '1rem 0', fontSize: 13, backgroundColor: `${faqcolor}`, fontFamily: `${faqbodytextfont}` }}
                            >
                                {faqQ4}
                            </AccordionSummary>
                            <AccordionDetails style={{ backgroundColor: `${faqcolor}`, fontFamily: `${faqbodytextfont}`, color: `${contenttextheadingcolor}`,}}>
                                {faqA4}
                            </AccordionDetails>
                        </Accordion>

                        {newFaq.map((item, index) => (
                                <Accordion style={{display:removeFaq4?'none':"block"}} key={index}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                    style={{ padding: '1rem 0', fontSize: 13, backgroundColor: `${faqcolor}`, fontFamily: `${faqbodytextfont}` }}
                                >
                                    {item.Question}
                                </AccordionSummary>
                                <AccordionDetails style={{ backgroundColor: `${faqcolor}`, fontFamily: `${faqbodytextfont}`, color: `${contenttextheadingcolor}`,}}>
                                    {item.Answer}
                                </AccordionDetails>
                            </Accordion>

                            ))}
                        
                    </div>

                </>
            )}


            {selectedFaq === 0 && (
                <div class="container" style={{ marginTop: `${faqMargintop}px`, marginBottom: `${faqMarginBottom}px` }} ref={Section6}>
                    <p style={{ color: `${captioncolor}` }}>{faqCaption}</p>
                    <h1 class=" heading fs-4" style={{ fontFamily: `${faqtitletextfont}`, color: `${contenttextheadingcolor}` }}>{faqtitle}</h1>
                    <p style={{ fontSize: 17, fontFamily: `${faqbodytextfont}`, color: `${contenttextinfocolor}` }}>
                        {faqIntro}
                    </p>

                    {/* <div className="accordion " style={{ display: removeFaq1 ? "none" : '' }} >
                        <div className="accordion-item" style={{ borderRadius: '0', borderTopRightRadius: '8px', borderTopLeftRadius: '8px', background: "linear-gradient(45deg, rgba(0, 0, 0, 0.78) 0%, rgb(0, 0, 0) 100%) ", padding: '1rem' }}>
                            <div className="accordion-title" onClick={toggleAccordion1}>
                                <div style={{  fontFamily: `${faqbodytextfont}`, color:  contenttextheadingcolor?`white`:`${contenttextheadingcolor}` }}>{faqQ1}</div>
                            </div>
                            {isOpen1 && (
                                <div className="accordion-content">
                                    <div style={{ color: contenttextinfocolor?`${contenttextinfocolor}`:'white', fontFamily: `${faqbodytextfont}`, }}>{faqA1}</div>
                                </div>
                            )}
                        </div>
                    </div> */}
                    <Accordion style={{display:removeFaq1?'none':"block"}}>
                            <AccordionSummary 
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                                style={{ borderRadius: '0', borderTop: ' 1px solid rgb(231, 231, 231)', background: "linear-gradient(45deg, rgba(0, 0, 0, 0.78) 0%, rgb(0, 0, 0) 100%) ", padding: '1rem',  }}
                            >
                               <div style={{ color: contenttextinfocolor?`${contenttextinfocolor}`:'white', fontFamily: `${faqbodytextfont}`,}}>{faqQ1}</div>
                            </AccordionSummary>
                            <AccordionDetails style={{ borderRadius: '0', borderTop: ' 1px solid rgb(231, 231, 231)', background: "linear-gradient(45deg, rgba(0, 0, 0, 0.78) 0%, rgb(0, 0, 0) 100%) ", padding: '1rem' }} >
                            <div style={{ color: contenttextinfocolor?`${contenttextinfocolor}`:'white', fontFamily: `${faqbodytextfont}`, }}>{faqA1}</div>
                            </AccordionDetails>
                        </Accordion>


                        <Accordion style={{display:removeFaq2?'none':"block"}}>
                            <AccordionSummary 
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                                style={{ borderRadius: '0', borderTop: ' 1px solid rgb(231, 231, 231)', background: "linear-gradient(45deg, rgba(0, 0, 0, 0.78) 0%, rgb(0, 0, 0) 100%) ", padding: '1rem', }}
                            >
                               <div style={{  color: contenttextinfocolor?`${contenttextinfocolor}`:'white', fontFamily: `${faqbodytextfont}`, }}>{faqQ2}</div>
                            </AccordionSummary>
                            <AccordionDetails style={{ borderRadius: '0', borderTop: ' 1px solid rgb(231, 231, 231)', background: "linear-gradient(45deg, rgba(0, 0, 0, 0.78) 0%, rgb(0, 0, 0) 100%) ", padding: '1rem' }} >
                            <div style={{ color: contenttextinfocolor?`${contenttextinfocolor}`:'white', fontFamily: `${faqbodytextfont}`, }}>{faqA2}</div>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion style={{display:removeFaq2?'none':"block"}}>
                            <AccordionSummary 
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                                style={{ borderRadius: '0', borderTop: ' 1px solid rgb(231, 231, 231)', background: "linear-gradient(45deg, rgba(0, 0, 0, 0.78) 0%, rgb(0, 0, 0) 100%) ", padding: '1rem', }}
                            >
                               <div style={{  color: contenttextinfocolor?`${contenttextinfocolor}`:'white', fontFamily: `${faqbodytextfont}`,}}>{faqQ3}</div>
                            </AccordionSummary>
                            <AccordionDetails style={{ borderRadius: '0', borderTop: ' 1px solid rgb(231, 231, 231)', background: "linear-gradient(45deg, rgba(0, 0, 0, 0.78) 0%, rgb(0, 0, 0) 100%) ", padding: '1rem' }} >
                            <div style={{ color: contenttextinfocolor?`${contenttextinfocolor}`:'white', fontFamily: `${faqbodytextfont}`, }}>{faqA3}</div>
                            </AccordionDetails>
                        </Accordion>


                        <Accordion style={{display:removeFaq2?'none':"block"}}>
                            <AccordionSummary 
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                                style={{ borderRadius: '0', borderTop: ' 1px solid rgb(231, 231, 231)', background: "linear-gradient(45deg, rgba(0, 0, 0, 0.78) 0%, rgb(0, 0, 0) 100%) ", padding: '1rem',  }}
                            >
                               <div style={{ color: contenttextinfocolor?`${contenttextinfocolor}`:'white', fontFamily: `${faqbodytextfont}`, }}>{faqQ4}</div>
                            </AccordionSummary>
                            <AccordionDetails style={{ borderRadius: '0', borderTop: ' 1px solid rgb(231, 231, 231)', background: "linear-gradient(45deg, rgba(0, 0, 0, 0.78) 0%, rgb(0, 0, 0) 100%) ", padding: '1rem' }} >
                            <div style={{ color: contenttextinfocolor?`${contenttextinfocolor}`:'white', fontFamily: `${faqbodytextfont}`, }}>{faqA4}</div>
                            </AccordionDetails>
                        </Accordion>

                
                    

                    {newFaq.map((item, index) => (
                        <div className="accordion " key={index}>
                            <div className="accordion-item" style={{ borderRadius: '0',  borderBottomRightRadius: "12px", background: "linear-gradient(45deg, rgba(0, 0, 0, 0.78) 0%, rgb(0, 0, 0) 100%) ", padding: '1rem' }}>
                                <div className="accordion-title" onClick={() => toggleAccordionNew(index)}>
                                    <div style={{ color: 'white', fontFamily: `${faqbodytextfont}`,color:  contenttextheadingcolor?`white`:`${contenttextheadingcolor}` }}>{item.Question}</div>
                                </div>
                                {isOpen && (
                                    <div className="accordion-content">
                                        <div style={{ color: contenttextinfocolor?`${contenttextinfocolor}`:'white', fontFamily: `${faqbodytextfont}` }}>{item.Answer}</div>
                                    </div>
                                )}
                            </div>
                        </div>

                    ))}
                </div>
            )}



            {selectedFaq === 1 && (
                <section class="container " style={{ marginTop: `${faqMargintop}px`, marginBottom: `${faqMarginBottom}px`,minWidth:"100%" }}>
                    <div className="row">
                        <div className="col-md-4">
                            <p style={{ color: `${captioncolor}` }}>{faqCaption}</p>

                            <h1 class=" _c53b3b37" style={{ fontFamily: `${faqtitletextfont}`, color: `${contenttextheadingcolor}` }}>{faqtitle}</h1>
                            <p style={{ fontSize: 17, color: `${faqtext}`, fontFamily: `${faqbodytextfont}`, color: `${contenttextinfocolor}` }}>
                                {faqIntro}
                            </p>
                        </div>
                        <div className="col-md-8">
                            <div class="_9a49c734" style={{ display: removeFaq1 ? "none" : '' }}>
                                <h4 class="_d1646f8c" style={{ color: `${contenttextheadingcolor}`, fontFamily: `${faqbodytextfont}` }}>{faqQ1}</h4>
                                <div class="_3840d56d" style={{ color: `${contenttextinfocolor}`, fontFamily: `${faqbodytextfont}` }} >{faqA1}
                                </div>
                            </div>
                            <div class="_9a49c734" style={{ display: removeFaq2 ? "none" : '' }}><h4 class="_d1646f8c" style={{ color: `${contenttextheadingcolor}`, fontFamily: `${faqbodytextfont}` }}>{faqQ2}</h4><div class="_3840d56d" style={{ color: `${contenttextinfocolor}`, fontFamily: `${faqbodytextfont}` }}>{faqA2}</div></div>
                            <div class="_9a49c734" style={{ display: removeFaq3 ? "none" : '' }}><h4 class="_d1646f8c" style={{ color: `${contenttextheadingcolor}`, fontFamily: `${faqbodytextfont}` }}>{faqQ3}</h4><div class="_3840d56d" style={{ color: `${contenttextinfocolor}`, fontFamily: `${faqbodytextfont}` }}>{faqA3}</div></div>
                            <div class="_9a49c734" style={{ display: removeFaq4 ? "none" : '' }}><h4 class="_d1646f8c" style={{ color: `${contenttextheadingcolor}`, fontFamily: `${faqbodytextfont}` }}>{faqQ4}</h4><div class="_3840d56d" style={{ color: `${contenttextinfocolor}`, fontFamily: `${faqbodytextfont}` }}>{faqA4}</div></div>
                            {newFaq.map((item, index) => (
                                <div class="_9a49c734" key={index}>
                                    <h4 class="_d1646f8c" style={{ color: `${contenttextheadingcolor}`, fontFamily: `${faqtitletextfont}` }}>{item.Question}</h4>
                                    <div class="_3840d56d">{item.Answer}</div>
                                </div>

                            ))}
                        </div>
                    </div>
                </section>
            )}


            {selectedFaq === 2 && (

                <div class="container" style={{ marginTop: `${faqMargintop}px`, marginBottom: `${faqMarginBottom}px`, backgroundColor: `${faqcolor}`,minWidth:"100%" }} ref={Section6}>
                    <p style={{ color: `${captioncolor}` }}>{faqCaption}</p>

                    <h1 class=" _c53b3b37" style={{ fontFamily: `${faqtitletextfont}`, color: `${contenttextheadingcolor}` }}>{faqtitle}</h1>
                    <p style={{ fontSize: 17, color: `${faqtext}`, fontFamily: `${faqbodytextfont}`, color: `${contenttextinfocolor}` }}>
                        {faqIntro}
                    </p>
                    <div className="accordion " style={{ display: removeFaq1 ? "none" : '' }}>
                        <div className="accordion-item" style={{ borderRadius: '0', borderTopRightRadius: '8px', borderTopLeftRadius: '8px', padding: '1rem', backgroundColor: `${faqcolor}` }}>
                            <div className="accordion-title" onClick={toggleAccordion1}>
                                <div style={{ color: `${contenttextheadingcolor}`, fontFamily: `${faqbodytextfont}` }}>{faqQ1}</div>
                            </div>
                            {isOpen1 && (
                                <div className="accordion-content">
                                    <div style={{ color: `${contenttextinfocolor}`, fontFamily: `${faqbodytextfont}` }} >{faqA1}</div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="accordion " style={{ display: removeFaq2 ? "none" : '' }} >
                        <div className="accordion-item" style={{ borderRadius: '0', padding: '1rem', backgroundColor: `${faqcolor}` }}>
                            <div className="accordion-title" onClick={toggleAccordion2}>
                                <div style={{ color: `${contenttextheadingcolor}`, fontFamily: `${faqbodytextfont}` }}>{faqQ2}</div>
                            </div>
                            {isOpen2 && (
                                <div className="accordion-content">
                                    <div style={{ color: `${contenttextinfocolor}`, fontFamily: `${faqbodytextfont}` }} >{faqA2}</div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="accordion " style={{ display: removeFaq3 ? "none" : '' }} >
                        <div className="accordion-item" style={{ borderRadius: '0', borderBottomLeftRadius: "12px", borderBottomRightRadius: "12px", padding: '1rem', backgroundColor: `${faqcolor}` }}>
                            <div className="accordion-title" onClick={toggleAccordion3}>
                                <div style={{ color: `${contenttextheadingcolor}`, fontFamily: `${faqbodytextfont}` }}>{faqQ3}</div>
                            </div>
                            {isOpen3 && (
                                <div className="accordion-content">
                                    <div style={{ color: `${contenttextinfocolor}`, fontFamily: `${faqbodytextfont}` }}>{faqA3}</div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="accordion " style={{ display: removeFaq4 ? "none" : '' }} >
                        <div className="accordion-item" style={{ borderRadius: '0', borderBottomLeftRadius: "12px", borderBottomRightRadius: "12px", padding: '1rem', backgroundColor: `${faqcolor}` }}>
                            <div className="accordion-title" onClick={toggleAccordion4}>
                                <div style={{ color: `${contenttextheadingcolor}`, fontFamily: `${faqbodytextfont}` }} >{faqQ4}</div>
                            </div>
                            {isOpen4 && (
                                <div className="accordion-content">
                                    <div style={{ color: `${contenttextinfocolor}`, fontFamily: `${faqbodytextfont}` }}>{faqA4}</div>
                                </div>
                            )}
                        </div>
                    </div>
                    {newFaq.map((item, index) => (
                        <div className="accordion " key={index} >
                            <div className="accordion-item" style={{ borderRadius: '0', borderBottomLeftRadius: "12px", borderBottomRightRadius: "12px", padding: '1rem', backgroundColor: `${faqcolor}` }}>
                                <div className="accordion-title" onClick={toggleAccordionNew}>
                                    <div style={{ color: `${contenttextheadingcolor}`, fontFamily: `${faqbodytextfont}` }}>{item.Question}</div>
                                </div>
                                {isOpen && (
                                    <div className="accordion-content">
                                        <div style={{ color: `${contenttextinfocolor}`, fontFamily: `${faqbodytextfont}` }}>{item.Answer}</div>
                                    </div>
                                )}
                            </div>
                        </div>

                    ))}
                </div>
            )}


            {selectedFaq === 3 && (
                <div class="container" style={{ marginTop: `${faqMargintop}px`, marginBottom: `${faqMarginBottom}px`,minWidth:'100%' }} ref={Section6}>
                    <header class="_e02e8965">
                        <p style={{ color: `${captioncolor}` }}>{faqCaption}</p>

                        <h1 class=" _c53b3b37" style={{ fontFamily: `${faqtitletextfont}`, color: `${contenttextheadingcolor}` }}>{faqtitle}</h1>
                        <p style={{ fontSize: 17, color: `${faqtext}`, fontFamily: `${faqbodytextfont}`, color: `${contenttextinfocolor}` }}>
                            {faqIntro}
                        </p>
                    </header>
                    <div className="row">
                        <div className="col-md-6 col-sm-12">
                            <div class="_157660be"><h6 class="_790354ef" style={{ color: `${contenttextheadingcolor}`, fontFamily: `${faqbodytextfont}` }}>{faqQ1}</h6><div class="_7d377080" style={{ color: `${contenttextinfocolor}`, fontFamily: `${faqbodytextfont}` }}>{faqA1}</div></div>
                            <div class="_157660be"><h6 class="_790354ef" style={{ color: `${contenttextheadingcolor}`, fontFamily: `${faqbodytextfont}` }}>{faqQ2}</h6><div class="_7d377080" style={{ color: `${contenttextinfocolor}`, fontFamily: `${faqbodytextfont}` }}>{faqA2}</div></div>
                        </div>

                        <div className="col-md-6 col-sm-12">
                            <div class="_157660be"><h6 class="_790354ef" style={{ color: `${contenttextheadingcolor}`, fontFamily: `${faqbodytextfont}` }}>{faqQ3}</h6>
                                <div class="_7d377080" style={{ color: `${contenttextheadingcolor}`, fontFamily: `${faqbodytextfont}` }}>{faqA3}</div></div>
                            <div class="_157660be"><h6 class="_790354ef" style={{ color: `${contenttextheadingcolor}`, fontFamily: `${faqbodytextfont}` }}>{faqQ4}</h6><div class="_7d377080" style={{ color: `${contenttextinfocolor}`, fontFamily: `${faqbodytextfont}` }}>{faqA4}</div></div>

                        </div>
                        {newFaq.map((item) => (
                            <div className="col-md-6 col-sm-12">
                                <div class="_157660be"><h6 class="_790354ef" style={{ color: `${contenttextheadingcolor}`, fontFamily: `${faqbodytextfont}` }}>{item.Question}</h6>
                                    <div class="_7d377080" style={{ color: `${contenttextheadingcolor}`, fontFamily: `${faqbodytextfont}` }}>{item.Answer}</div></div>

                            </div>
                        ))}
                    </div>
                </div>
            )}

        </div>
    )
};

export default FAQs;