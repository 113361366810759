import React, { useEffect, useState } from 'react'
import { useContext } from 'react';
import MyContext from '../context/MyContext';

const DesignSpacing = ({ closeSpacingPopup }) => {
    const [isDragging, setIsDragging] = useState(false);
    const [offsetX, setOffsetX] = useState(0);
    const [offsetY, setOffsetY] = useState(0);
    const { intromargintop, setIntromargintop,
        intromarginbottom, setIntromarginbottom,
        marginTop, setMarginTop,
        buttonvarticalpadding, setButtonvarticalpadding,
        buttonhorizantlepadding, setButtonhorizantlepadding,
        marginBottom, setmarginBottom,
        section1, section2, section3,
        section4, section5, section6,
        mainnavbarbutton, section7,
        trustmargintop, setTrustmargintop,
        trustmarginbottom, setTrustmarginBottom,
        serviceMarginBottom, setServiceMarginBottom,
        serviceMarginTop, setServiceMarginTop,
        statsMarginBottom, setStatsMarginBottom,
        statsMarginTop, setStatsMarginTop,
        faqMargintop, setFaqMarginTop,
        faqMarginBottom, setFaqMarginBottom,
        footerMargintop, setFooterMarginTop,
        footerMarginBottom, setFooterMarginBottom,
    } = useContext(MyContext)

    const handleMouseDown = (e) => {
        setIsDragging(true);
        setOffsetX(e.clientX - e.target.getBoundingClientRect().left);
        setOffsetY(e.clientY - e.target.getBoundingClientRect().top);
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    const handleMouseMove = (e) => {
        if (isDragging) {
            e.preventDefault();
            const popup = document.querySelector('.popup');
            popup.style.left = e.clientX - offsetX + 'px';
            popup.style.top = e.clientY - offsetY + 'px';
        }
    };
    const handelIntromargintop = (e) => {
        const Margintop = e.target.value;
        setIntromargintop(Margintop);

    };
    const handelIntromarginbottom = (e) => {
        const MarginBottom = e.target.value;
        setIntromarginbottom(MarginBottom)
    };

    const handelTrustmargintop = (e) => {
        const Margintop = e.target.value;
        setTrustmargintop(Margintop);

    };
    const handelTrustmarginBottom = (e) => {
        const MarginBottom = e.target.value;
        setTrustmarginBottom(MarginBottom)
    };
    const handelServicemargintop = (e) => {
        const Margintop = e.target.value;
        setServiceMarginTop(Margintop);

    };
    const handelServicemarginBottom = (e) => {
        const MarginBottom = e.target.value;
        setServiceMarginBottom(MarginBottom)
    };
    const handelStatsmargintop = (e) => {
        const Margintop = e.target.value;
        setStatsMarginTop(Margintop);

    };
    const handelStatsmarginBottom = (e) => {
        const MarginBottom = e.target.value;
        setStatsMarginBottom(MarginBottom)
    };

    const handelFaqmargintop = (e) => {
        const Margintop = e.target.value;
        setFaqMarginTop(Margintop);

    };
    const handelFaqmarginBottom = (e) => {
        const MarginBottom = e.target.value;
        setFaqMarginBottom(MarginBottom)
    };
    const handelFootermargintop = (e) => {
        const Margintop = e.target.value;
        setFooterMarginTop(Margintop);

    };
    const handelFootermarginBottom = (e) => {
        const MarginBottom = e.target.value;
        setFooterMarginBottom(MarginBottom)
    };
    const Handelbuttonhorizantlepadding = (e) => {

        setButtonhorizantlepadding(e.target.value)
    };

    const HandelButtonvarticalpadding = (e) => {

        setButtonvarticalpadding(e.target.value)
    }

    const HandelMarginTop = (e) => {
        if (section2 == true) {
            setMarginTop(e.target.value)
            handelIntromargintop(e);
        };
        if (section2 == false) {
            setMarginTop(0)
        };
        if (section3 == true) {
            setMarginTop(e.target.value)

            handelTrustmargintop(e);
        };
        if (section4 == true) {
            setMarginTop(e.target.value)

            handelServicemargintop(e);
        };
        if (section5 == true) {
            setMarginTop(e.target.value)

            handelStatsmargintop(e)
        };
        if (section6 == true) {
            setMarginTop(e.target.value)

            handelFaqmargintop(e)
        };
        if (section7 == true) {
            setMarginTop(e.target.value)

            handelFootermargintop(e)
        };
        if(mainnavbarbutton==true){
            setMarginTop(e.target.value);
            HandelButtonvarticalpadding(e);
        }
    };



    const HandelMarginBottom = (e) => {
        if (section2 == true) {

            setmarginBottom(e.target.value);
            handelIntromarginbottom(e);

        };
        if (section2 == false) {
            setmarginBottom(0);
        }
        if (section3 == true) {
            setmarginBottom(e.target.value);

            handelTrustmarginBottom(e);

        };
        if (section4 == true) {
            setmarginBottom(e.target.value);

            handelServicemarginBottom(e);

        };
        if (section5 == true) {
            setmarginBottom(e.target.value);

            handelStatsmarginBottom(e);

        };
        if (section6 == true) {
            setmarginBottom(e.target.value);

            handelFaqmarginBottom(e);

        };
        if (section7 == true) {
            setmarginBottom(e.target.value);

            handelFootermarginBottom(e);

        };
    }
    useEffect(() => {
        setMarginTop(0);
        setmarginBottom(0);
    }, [section1, section2, section3, section4, section5, section6, section7])
    console.log(marginTop, marginBottom, 'Section', section1, section2, section3, section4, section5, section6, section7)
    console.log('Margin', intromargintop, intromarginbottom, trustmargintop, trustmarginbottom, serviceMarginTop, serviceMarginBottom, faqMarginBottom, faqMargintop, statsMarginBottom, statsMarginTop, footerMarginBottom, footerMargintop)
    return (
        <>
            <div className="popup" onMouseUp={handleMouseUp}
                onMouseMove={handleMouseMove}
                style={{ position: 'fixed', left: '40%', top: '50%', transform: 'translate(-50%, -50%)', zIndex: 999, background: '#fff', borderRadius: '7px', boxShadow: '0 4px 30px #0000001a, 0 4px 10px #00000012', cursor: isDragging ? 'grabbing' : 'context-menu', width: '250px' }}
            >
                <header class="overlayHeader" onMouseDown={handleMouseDown} style={{ cursor: 'drag' }}>
                    <span class="">Spacing</span>
                    <button onClick={closeSpacingPopup}
                        class="button button--small button--clear overlay_overlayClose__GxQ0k">
                        <svg xmlns="http://www.w3.org/2000/svg" height="16" viewBox="0 0 24 24" width="16" fill="currentColor"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"></path>
                        </svg>
                    </button>
                </header>
                <div className='spacing_container__DHmIC'>
                    <div className="spacing_item__EstaE">
                        <label>top</label>
                        <div className="slider_container__29ImU">
                            {/* <span className="slider_SliderRoot__vjUOH" style={{ '--radix-slider-thumb-transform': 'translateX(-50%)' }}>
                                <span className="slider_SliderTrack__2UXOU">
                                    <span className="slider_SliderRange__CooOM" style={{ left: '0%', right: '100%' }}></span>
                                </span>
                                <span style={{ transform: 'var(--radix-slider-thumb-transform)', position: 'absolute', left: 'calc(0% + 8px)' }}>
                                    <span className="slider_SliderThumb__pa1re" style={{ left: '0%', right: '79%' }}></span>
                                </span>
                            </span> */}
                            <input type='range' style={{ width: '100%' }} min='0' max='100' value={marginTop} onChange={(e) => HandelMarginTop(e)}></input>
                            <input type="text" className="slider_customValue__PCqPu" value={marginTop / 10} onChange={(e) => HandelMarginTop(e)} />
                        </div>
                    </div>
                    <div className="spacing_item__EstaE">
                        <label>Bottom</label>
                        <div className="slider_container__29ImU">
                            {/* <span dir="ltr" data-orientation="horizontal" aria-disabled="false" className="slider_SliderRoot__vjUOH" style={{ '--radix-slider-thumb-transform': 'translateX(-50%)' }}>
                                <span data-orientation="horizontal" className="slider_SliderTrack__2UXOU">
                                    <span data-orientation="horizontal" className="slider_SliderRange__CooOM" style={{ left: '0%', right: '100%' }}></span>
                                </span>
                                <span style={{ transform: 'var(--radix-slider-thumb-transform)', position: 'absolute', left: 'calc(0% + 8px)' }}>
                                    <span className="slider_SliderThumb__pa1re" style={{ left: '0%', right: '79%' }}></span>
                                </span>
                            </span> */}
                            <input type='range' style={{ width: '100%' }} min='0' max='100' value={marginBottom} onChange={(e) => HandelMarginBottom(e)}></input>
                            <input type="text" className="slider_customValue__PCqPu" value={marginBottom / 10} onChange={(e) => HandelMarginBottom(e)} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DesignSpacing