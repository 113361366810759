import React, { useState } from 'react'

const DesignOtherFooter = (props) => {

    const [isDragging, setIsDragging] = useState(false);
    const [offsetX, setOffsetX] = useState(0);
    const [offsetY, setOffsetY] = useState(0);

    const handleMouseDown = (e) => {
        setIsDragging(true);
        setOffsetX(e.clientX - e.target.getBoundingClientRect().left);
        setOffsetY(e.clientY - e.target.getBoundingClientRect().top);
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    const handleMouseMove = (e) => {
        if (isDragging) {
            e.preventDefault();
            const popup = document.querySelector('.popup');
            popup.style.left = e.clientX - offsetX + 'px';
            popup.style.top = e.clientY - offsetY + 'px';
        }
    };


    return (
        <>
            <div className="popup" onMouseUp={handleMouseUp} onMouseMove={handleMouseMove}
                style={{ position: 'fixed', left: '40%', top: '50%', transform: 'translate(-50%, -50%)', zIndex: 999, background: '#fff', borderRadius: '7px', boxShadow: '0 4px 30px #0000001a, 0 4px 10px #00000012', cursor: isDragging ? 'grabbing' : 'context-menu', width: '200px' }} >

                <header class="overlayHeader" onMouseDown={handleMouseDown} style={{ cursor: 'drag' }}>
                    <span class="">Other Settings</span>
                    <button onClick={props.closeOtherPopup} class="button button--small button--clear overlay_overlayClose__GxQ0k " >
                        <svg xmlns="http://www.w3.org/2000/svg" height="16" viewBox="0 0 24 24" width="16" fill="currentColor"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"></path>
                        </svg>
                    </button>
                </header>
                <div className='misc_container__F8COs'>
                    <div className='misc_item__5-JsP'>
                        <label>Max. Content Width</label>
                        <input type="number" ></input>
                    </div>
                    <div class="misc_row__F7Lv-">
                        <div className='misc_item__5-JsP'>
                            <label>Accent Color</label>
                            <div class="swatch_swatch__7xFNG"><div class="swatch_swatchColor__ynsJn"></div></div>
                        </div>
                        <div className='misc_item__5-JsP'>
                            <label style={{ textAlign: 'right' }}>Width</label>
                            <input type="number" ></input>
                        </div>
                    </div>

                </div>
            </div>


        </>
    )
}

export default DesignOtherFooter