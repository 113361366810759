import React, { useContext } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import MyContext from "../../context/MyContext";



function Authantication() {
    const {memecoin}=useContext(MyContext);
    const Navigate=useNavigate();
    return (
       <Outlet/>
    );
};

export default Authantication;