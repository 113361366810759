import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

function Accordion({ items }) {
  const [activeIndex, setActiveIndex] = useState(null);

  const onItemClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <div className="accordion-- mt-5">
      {items.map((item, index) => (
        <div key={index} className="accordion--item ">
          <div
            className={`accordion--header ${index === activeIndex ? 'active' : ''}`}
            onClick={() => onItemClick(index)}
          >
            <div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
            {item.title}
            <FontAwesomeIcon icon={index === activeIndex ? faChevronUp : faChevronDown} />
            </div>
          </div>
          <div className={`accordion--content ${index === activeIndex ? 'show' : ''}`}>
            {item.content}
          </div>
        </div>
      ))}
    </div>
  );
}

export default Accordion;
