import React, { useContext, useEffect } from 'react';
import {BrowserRouter as Router, Routes,Route,createBrowserRouter,
    createRoutesFromElements,
    RouterProvider,} from 'react-router-dom'
import Saveandedit from '../components/SaveandEdit/Save&edit';
import Main from '../components/Main';
import Heroedit from '../components/SaveandEdit/Heroedit';
import HeroDesign from '../components/SaveandEdit/HeroDesign';
import LandingPage from '../components/landingPage';
import FooterEdit from '../components/SaveandEdit/Footer/Footeredit';
import FooterDesing from '../components/SaveandEdit/Footer/Footerdesing';
import IntroEdit from '../components/SaveandEdit/Intro/IntroEdit';
import IntroDesing from '../components/SaveandEdit/Intro/Indrtodesing';
import Leagacyedit from '../components/SaveandEdit/Legacy/Legacyedit';
import LeagacyDesing from '../components/SaveandEdit/Legacy/Legacydesing';
import FeatureEdit from '../components/SaveandEdit/Feature/FeatureEdit';
import FeatureDessing from '../components/SaveandEdit/Feature/featureDesing';
import FaqEdit from '../components/SaveandEdit/FAQ/Faqedit';
import FaqDesing from '../components/SaveandEdit/FAQ/Faqdesing';
import Trustedit from '../components/SaveandEdit/Trustedby/Trustedit';
import TrustDesign from '../components/SaveandEdit/Trustedby/trustedesing';
import Saveandeditt from '../components/SaveandEdit/Save&edit';
import Edit from '../components/Edit';
import Memecoin from '../components/MemeCoin/Memecoin';
import MyContext from '../context/MyContext';
import MemeNavbarEdit from '../components/MemeCoin/Save&edit/MemeNavbarEdit';
import MemeHomeEdit from '../components/MemeCoin/Save&edit/MemeMainEdit';
import MemecoinAboutEdit from '../components/MemeCoin/Save&edit/MemecoinAbout';
import MemeTokenamics from '../components/MemeCoin/Save&edit/MemeTOkenamics';
import MemecoinSteps from '../components/MemeCoin/Save&edit/MemecoinSteps';
import Dashborad from '../components/Dashborad';
import Memefooteredit from '../components/MemeCoin/Save&edit/Memefooteredit';
import Dashboardsite from '../components/Dashboradsite';
import SubscriptionsMeme from '../components/Subscriptions';
import DNSDoc from '../components/DNSDoc';
import NewLandingPage from '../components/NewlandingPage';
import Authantication from '../components/Authantication/Authantication';

function Routing({ onGenerateWebsite }){
   const { showSites} = useContext(MyContext);


  
    const router=createBrowserRouter(
        
        createRoutesFromElements(

            <>

              <Route path='' element={<Authantication/>}>
              <Route path=''element={<NewLandingPage   onGenerateWebsite={onGenerateWebsite}/>}></Route>

                <Route path='/' element={<Memecoin/> } />

                <Route path='/main' element={<Main />} />
                <Route path='/save&edit' element={<Saveandedit />} />
                <Route path='/heroedit' element={<Heroedit />} />
                <Route path='/heroedit/desing' element={<HeroDesign />} />
                <Route path='/FooterEdit' element={<FooterEdit />} />
                <Route path='/FooterDesing' element={<FooterDesing />} />
                <Route path='/IntroEdit' element={<IntroEdit />} />
                <Route path='/IntroDesing' element={<IntroDesing />} />
                <Route path='/LegacyEdit' element={<Leagacyedit />} />
                <Route path='/LegacyDesing' element={<LeagacyDesing />} />
                <Route path='/featureEdit' element={<FeatureEdit />} />
                <Route path='/featurDesing' element={<FeatureDessing />} />
                <Route path='/faqEdit' element={<FaqEdit />} />
                <Route path='/faqDesing' element={<FaqDesing />} />
                <Route path='/Trustedit' element={<Trustedit />} />
                <Route path='/Trustdesing' element={<TrustDesign />} />
                <Route path='/memecoinNav' element={<MemeNavbarEdit/>}/>
                <Route path='/memecoinhome' element={<MemeHomeEdit/>}/>
                <Route path='/memecoinabout' element={<MemecoinAboutEdit/>}/>
                <Route path='/memecoinTokenomics' element={<MemeTokenamics/>} />
                <Route path='/memecoinSteps' element={<MemecoinSteps/>}/>
                <Route path='/Dashboard' element={<Dashborad/>}/>
                <Route path='/Memefooter' element={<Memefooteredit/>}></Route>
                <Route path={showSites==true?'/DashboardSite':'/DashboardSite'} element={<Dashboardsite/>}></Route>
                <Route path='/subscriptions' element={<SubscriptionsMeme/>}></Route>
                <Route path='/dns' element={<DNSDoc/>}></Route>
                <Route path='' element={<NewLandingPage/>}/>
                </Route>
            </>
        )
    )
    
    
   
 
    return(
        <div>
            {/* <Router>
                 <Routes>
           
                    <>
                    <Route path='/memecoin' element={<Memecoin/> } />
                     
                        <Route path='/' element={<Main />} />
                        <Route path='/save&edit' element={<Saveandedit />} />
                        <Route path='/heroedit' element={<Heroedit />} />
                        <Route path='/heroedit/desing' element={<HeroDesign />} />
                        <Route path='/FooterEdit' element={<FooterEdit />} />
                        <Route path='/FooterDesing' element={<FooterDesing />} />
                        <Route path='/IntroEdit' element={<IntroEdit />} />
                        <Route path='/IntroDesing' element={<IntroDesing />} />
                        <Route path='/LegacyEdit' element={<Leagacyedit />} />
                        <Route path='/LegacyDesing' element={<LeagacyDesing />} />
                        <Route path='/featureEdit' element={<FeatureEdit />} />
                        <Route path='/featurDesing' element={<FeatureDessing />} />
                        <Route path='/faqEdit' element={<FaqEdit />} />
                        <Route path='/faqDesing' element={<FaqDesing />} />
                        <Route path='/Trustedit' element={<Trustedit />} />
                        <Route path='/Trustdesing' element={<TrustDesign />} />
                        <Route path='/memecoinNav' element={<MemeNavbarEdit/>}/>
                        <Route path='/memecoinhome' element={<MemeHomeEdit/>}/>
                        <Route path='/memecoinabout' element={<MemecoinAboutEdit/>}/>
                        <Route path='/memecoinTokenomics' element={<MemeTokenamics/>} />
                        <Route path='/memecoinSteps' element={<MemecoinSteps/>}/>
                        <Route path='/Dashboard' element={<Dashborad/>}/>
                        <Route path='/Memefooter' element={<Memefooteredit/>}></Route>
                        <Route path='/DashboardSite' element={<Dashboardsite/>}></Route>
                        <Route path='/subscriptions' element={<SubscriptionsMeme/>}></Route>
                        <Route path='/dns' element={<DNSDoc/>}></Route>
                    </>
                </Routes>
    </Router> */}
     <RouterProvider router={router} />
        </div>
    )
};

export default Routing