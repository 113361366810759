


import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faCircleQuestion, faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import WebPages from '../../Webpages';
import MyContext from '../../../context/MyContext';
import MainNavBar from '../../MainNavBar';
import TrustDesign from './trustedesing';
import Img1 from '../../../assets/images/img1.svg'
import Img2 from '../../../assets/images/img2.svg'
import Img3 from '../../../assets/images/img3.svg'
import Img4 from '../../../assets/images/img4.svg'
import Img5 from '../../../assets/images/img5.svg'
import Img6 from '../../../assets/images/img6.svg'
import Img7 from '../../../assets/images/img7.svg'
import Img8 from '../../../assets/images/img8.svg'
import Img9 from '../../../assets/images/img9.svg'
import Img10 from '../../../assets/images/img10.svg'
import ContentDesign from '../../ContentDesign';
import CaptionTitle from '../../CaptionTitle';
import Theme from '../../Theme';
import MemeHomeEdit from '../../MemeCoin/Save&edit/MemeMainEdit';
const Trustedit = () => {
  const { contentdesing,
    removelogos, setRemovelogos,
    mainnavbarbutton, trustcolor,
    settrustcolor, trsuttitle,
    settrusttile, trust, settrust,
    selectedImage, setSelectedImage,memecoin,
    trustLogo, settTrustlogos, newtrustlogo, setNewtrustlogo,trustCaption,setTrustCaption,section3 } = useContext(MyContext)
  const contentEditableRef = useRef(null);
  const [caretPosition, setCaretPosition] = useState(0);
  const contentEditableRef1 = useRef(null);
  const [caretPosition1, setCaretPosition1] = useState(0);

  const handleTrust = () => {
    const content = contentEditableRef.current.textContent;
    settrust(content);
    setCaretPosition(getCaretPosition());
  };

  const handleTrusttitle = () => {
    const content = contentEditableRef1.current.textContent;
    settrusttile(content);
    setCaretPosition1(getCaretPosition1());
  };







  useEffect(() => {
    setCaretToEnd();
  }, [trust]);

  useEffect(() => {
    setCaretToEnd1();
  }, [trsuttitle]);





  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Backspace') {
        setCaretPosition(getCaretPosition());
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Backspace') {
        setCaretPosition1(getCaretPosition1());
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);




  const getCaretPosition = () => {
    const selection = window.getSelection();
    if (selection && selection.rangeCount > 0) {
      return selection.getRangeAt(0).startOffset;
    }
    return 0;
  };
  const getCaretPosition1 = () => {
    const selection = window.getSelection();
    if (selection && selection.rangeCount > 0) {
      return selection.getRangeAt(0).startOffset;
    }
    return 0;
  };

  const setCaretToEnd = () => {
    const range = document.createRange();
    const sel = window.getSelection();
    const lastChild = contentEditableRef.current.lastChild;
    if (lastChild && lastChild.nodeType === Node.TEXT_NODE) {
      const textLength = lastChild.textContent.length;
      const offset = Math.min(caretPosition, textLength);
      range.setStart(lastChild, offset);
      range.collapse(true);
      sel.removeAllRanges();
      sel.addRange(range);
    }
  };

  const setCaretToEnd1 = () => {
    const range = document.createRange();
    const sel = window.getSelection();
    const lastChild = contentEditableRef1.current.lastChild;
    if (lastChild && lastChild.nodeType === Node.TEXT_NODE) {
      const textLength = lastChild.textContent.length;
      const offset = Math.min(caretPosition1, textLength);
      range.setStart(lastChild, offset);
      range.collapse(true);
      sel.removeAllRanges();
      sel.addRange(range);
    }
  };
  const Removeelogos = () => {
    setRemovelogos(true)
  };

  const handleRemovetrustlogo = (indexToRemove) => {
    settTrustlogos(prevMenu => {
      const updatedMenu = [...prevMenu];
      updatedMenu.splice(indexToRemove, 1);
      return updatedMenu;

    });

  };

  const handleRemoveNewtrustlogo = (indexToRemove) => {
    setNewtrustlogo(prevMenu => {
      const updatedMenu = [...prevMenu];
      updatedMenu.splice(indexToRemove, 1);
      return updatedMenu;

    });

  };


  const handleAddTrustLogos = (newItem) => {
    setNewtrustlogo(prevMenu => [...prevMenu, newItem]);
  };
  const handleSvgClick = (index) => {
    // Open file picker to select an image
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = (e) => {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const updatedTrustLogo = [...newtrustlogo];
        updatedTrustLogo[index] = event.target.result;
        setNewtrustlogo(updatedTrustLogo);
      };
      reader.readAsDataURL(file);
    };
    input.click();
  };

  console.log('newtrustlogo', newtrustlogo,newtrustlogo.length);

  const HandeltrustCaption=(Caption)=>{
     setTrustCaption(Caption.target.value)
  };
  console.log('Section3',section3)
  return (
    <div>
     {memecoin=='true'?<MemeHomeEdit/>:
    <div className='container-fluid d-flex flex-column' style={{ height: '100vh', overflow: 'auto' }}>
      <div>
        <MainNavBar />
      </div>
      <div className='flex-grow-1 d-flex flex-row mt-1' style={{ overflow: 'hidden' }}>
        <div style={{ width: '400px' }} >
          {mainnavbarbutton == false ?
            <div className='editing_wrapper__Wijfx h-100  overflow-auto'>
              <ContentDesign headerText="Logos" />
              {contentdesing == false ?
                <div >
                  <div className="editing_content__RXzxk">
                    <div className="form_form__Sku4X" >
                      <div className="form_form__Sku4X">
                        <label class="form_label__27psn">Header</label>
                        <div className="form_group__kDXNb">
                          <div className="form_form__Sku4X">
                            <div class="form_more__6E9XQ" type="button" id="radix-:rer:" aria-haspopup="menu" aria-expanded="false" data-state="closed"><svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.625 2.5C8.625 3.12132 8.12132 3.625 7.5 3.625C6.87868 3.625 6.375 3.12132 6.375 2.5C6.375 1.87868 6.87868 1.375 7.5 1.375C8.12132 1.375 8.625 1.87868 8.625 2.5ZM8.625 7.5C8.625 8.12132 8.12132 8.625 7.5 8.625C6.87868 8.625 6.375 8.12132 6.375 7.5C6.375 6.87868 6.87868 6.375 7.5 6.375C8.12132 6.375 8.625 6.87868 8.625 7.5ZM7.5 13.625C8.12132 13.625 8.625 13.1213 8.625 12.5C8.625 11.8787 8.12132 11.375 7.5 11.375C6.87868 11.375 6.375 11.8787 6.375 12.5C6.375 13.1213 6.87868 13.625 7.5 13.625Z" fill="#8c8c8c" fill-rule="evenodd" clip-rule="evenodd"></path></svg></div>
                            <div class="form_row__2+BJ3">
                              <div class="form_field__d31bq form_size--2x2__J8UQE">
                                <div class="picker_preview__QSKw4"><svg xmlns="http://www.w3.org/2000/svg" height="22" viewBox="0 0 24 24" width="22" fill="#bbb"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm-1-4h2v2h-2zm1.61-9.96c-2.06-.3-3.88.97-4.43 2.79-.18.58.26 1.17.87 1.17h.2c.41 0 .74-.29.88-.67.32-.89 1.27-1.5 2.3-1.28.95.2 1.65 1.13 1.57 2.1-.1 1.34-1.62 1.63-2.45 2.88 0 .01-.01.01-.01.02-.01.02-.02.03-.03.05-.09.15-.18.32-.25.5-.01.03-.03.05-.04.08-.01.02-.01.04-.02.07-.12.34-.2.75-.2 1.25h2c0-.42.11-.77.28-1.07.02-.03.03-.06.05-.09.08-.14.18-.27.28-.39.01-.01.02-.03.03-.04.1-.12.21-.23.33-.34.96-.91 2.26-1.65 1.99-3.56-.24-1.74-1.61-3.21-3.35-3.47z"></path></svg>
                                </div></div>
                              <div className="form_group__kDXNb">
                                <div className="form_form__Sku4X" style={{ maxWidth: "89%" }}>
                                  <div class="form_field__d31bq" >
                                    <textarea placeholder="Caption" style={{ height: "32px" }} value={trustCaption} onChange={(e)=>HandeltrustCaption(e)}>
                                      </textarea>
                                      </div>
                                  <div class="form_field__d31bq"><div class=""><div contenteditable="true" translate="no" class="tiptap ProseMirror form_value__oIwpW form_tiptap__s0JN1" tabindex="0" ref={contentEditableRef} onInput={handleTrust}>{trust}</div></div></div>
                                  <div class="form_field__d31bq"><div class=""><div contenteditable="true" translate="no" class="tiptap ProseMirror form_value__oIwpW form_tiptap__s0JN1" tabindex="0" ref={contentEditableRef1} onInput={handleTrusttitle}>{trsuttitle}</div></div></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <label class="form_label__27psn">Items</label>
                    {trustLogo.map((logos, index) => (
                      <div className='form_sortable__FLtVI' key={index}  >
                        <div className='form_listItem__puOqW'>
                          <div class="form_headerHandle__33dpI" tabindex="0" role="button" aria-describedby="rbd-hidden-text-4-hidden-text-17" data-rbd-drag-handle-draggable-id="draggable-0" data-rbd-drag-handle-context-id="4" draggable="false"></div>
                          <div className='form_headerRemove__mJ7no' onClick={() => handleRemovetrustlogo(index)}>
                            <svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 0 24 24" width="14" fill="currentColor"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"></path></svg>
                          </div>
                          <div className="form_form__Sku4X">
                            <div className='form_row__2\+BJ3  d-flex'>
                              <div className='form_field__d31bq form_size--2x2__J8UQE'>
                                <div className='media_preview__okAyH'>
                                  <label></label>
                                  <div class="media_value__+4KKG"><img src={logos} alt="Library File" class="media_image__kN9DM" /></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>))}
                    {newtrustlogo.map((item, index) => (
                     

                      <div className='form_sortable__FLtVI' key={index}  >
                        <div className='form_listItem__puOqW'>
                          <div class="form_headerHandle__33dpI" tabindex="0" role="button" aria-describedby="rbd-hidden-text-4-hidden-text-17" data-rbd-drag-handle-draggable-id="draggable-0" data-rbd-drag-handle-context-id="4" draggable="false"></div>
                          <div className='form_headerRemove__mJ7no' onClick={() => handleRemoveNewtrustlogo()}>
                            <svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 0 24 24" width="14" fill="currentColor"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"></path></svg>
                          </div>
                          <div className="form_form__Sku4X">
                            <div className='form_row__2\+BJ3  d-flex'>
                              <div className='form_field__d31bq form_size--2x2__J8UQE'>
                                <div className='media_preview__okAyH'>

                                  <div className="picker_preview__QSKw4" onClick={() => handleSvgClick(index)}>
                                   
                                  {newtrustlogo.length==0?
                                
                                  <svg xmlns="http://www.w3.org/2000/svg" height="22" viewBox="0 0 24 24" width="22" fill="#bbb">
                                  <path d="M0 0h24v24H0V0z" fill="none"></path>
                                  <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm-1-4h2v2h-2zm1.61-9.96c-2.06-.3-3.88.97-4.43 2.79-.18.58.26 1.17.87 1.17h.2c.41 0 .74-.29.88-.67.32-.89 1.27-1.5 2.3-1.28.95.2 1.65 1.13 1.57 2.1-.1 1.34-1.62 1.63-2.45 2.88 0 .01-.01.01-.01.02-.01.02-.02.03-.03.05-.09.15-.18.32-.25.5-.01.03-.03.05-.04.08-.01.02-.01.04-.02.07-.12.34-.2.75-.2 1.25h2c0-.42.11-.77.28-1.07.02-.03.03-.06.05-.09.08-.14.18-.27.28-.39.01-.01.02-.03.03-.04.1-.12.21-.23.33-.34.96-.91 2.26-1.65 1.99-3.56-.24-1.74-1.61-3.21-3.35-3.47z"></path>
                                </svg>
                               : 
                                       
                                    <img src={item} alt="Selected" style={{ height: 22, width: 22 }} />
                                    
                                    
                                  } </div> 

                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>


                    ))}

                    {((trustLogo.length == 0) && (newtrustlogo.length == 0)) ?
                      <div className='form_emptyList__KNV3N'>
                        <div>Your list is empty</div>
                        <button class="button1 button--small" onClick={() => handleAddTrustLogos()}>Add Item</button>
                      </div> : <button class="button1 button--small " style={{ alignSelf: 'flex-end', marginTop: '15px', position: 'relative', left: 280 }} onClick={(e) => handleAddTrustLogos(e)}>Add Item</button>}

                  </div>



                </div> : <TrustDesign />}

            </div> : <div className='editing_wrapper__Wijfx h-100  overflow-auto'><Theme/></div>}
        </div>
        <div className=' h-100 overflow-auto editing_wrapper__Wijfxnew' style={{ width: 'calc(100% - 400px)' }}>
          <WebPages />
        </div>
      </div>
    </div>
}



  </div>
  )
};

export default Trustedit;

