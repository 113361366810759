import React, { useContext, useEffect, useState } from "react";
import tea_img from "../../assets/images/tea.jpeg";
import pointer from "../../assets/images/arrow.png"
import MyContext from "../../context/MyContext";
import { IconPickerItem } from "react-icons-picker";

const FeatureEditi = () => {

    const { serviceHeading1, setServiceHeading1,
        serviceinfo1, setServiceinfo1,
        serviceHeading2, setServiceHeading2,
        serviceinfo2, setServiceinfo2,
        serviceHeading3, setServiceHeading3,
        serviceinfo3, setServiceinfo3,
        serviceHeading4, setServiceHeading4,
        serviceinfo4, setServiceinfo4,
        servicesIntro1, setServicesIntro1,
        servicesIntro, setservicesIntro,
        images, services, featurecolor,
        featuretext, serviceheader,
        serviceremove1, settServiceremove1,
        serviceremove2, settServiceremove2,
        serviceremove3, settServiceremove3,
        serviceremove4, settServiceremove4,
        newservicesheading, newservicesinfo,
        newservicesImage, servicecaption, featureimages,
        serviceBodytextfont, servicetitletextfont,
        serviceMarginBottom, serviceMarginTop, captioncolor,featureIcon1,featureIcon2,featureIcon3,featureIcon4,
        contenttextheadingcolor, mainnavbarbutton, contenttextinfocolor, selectedFeature, imagePosition, alignMedia,

    } = useContext(MyContext)
    const [randomIndex, setRandomIndex] = useState()

    useEffect(() => {
        const randomIndex = Math.floor((Math.random() * 5) + 5);
        setRandomIndex(randomIndex);
    }, [images]);



    return (
        <div class="container shadow" style={{ backgroundColor: `${featurecolor}`, position:'relative',margin:'-2px',padding:'50px',paddingTop:'0px'}}>
            {selectedFeature === null && (<div class="row" style={{ marginBottom: `${serviceMarginBottom}px`, marginTop: `${serviceMarginTop}px` }}>
                <div class="col-md-6 d-flex  flex-column justify-content-start">
                    <p class='mt-5' style={{ color: `${captioncolor}` }}>  {servicecaption}   </p>
                    <h1 class=" heading" style={{ fontSize: 33, color: `${contenttextheadingcolor}`, fontFamily: `${servicetitletextfont}` }}>{serviceheader}</h1>
                    <p class="fs-6 lh-sm" style={{ color: `${contenttextinfocolor}`, fontFamily: `${serviceBodytextfont}` }} >
                        {servicesIntro1}

                    </p>

                    <div class="d-flex"  >
                        <div style={{ display: serviceremove1 ? 'none' : 'flex' }}>
                            <div class="flex-shrink-0">
                                {/* <img src={featureIcon1} alt="..." /> */}
                                <IconPickerItem value={featureIcon1}></IconPickerItem>

                            </div>
                            <div class="flex-grow-1 ms-3" >
                                <h6 class="m-0 p-0 fs-6 " style={{ color: `${contenttextheadingcolor}`, fontFamily: `${serviceBodytextfont}` }}>{serviceHeading1}</h6>
                                <span style={{ marginTop: 2, fontSize: 13, color: `${contenttextinfocolor}`, fontFamily: `${serviceBodytextfont}` }}>{serviceinfo1}</span>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex"  >
                        <div style={{ display: serviceremove2 ? 'none' : 'flex' }} >
                            <div class="flex-shrink-0">
                                {/* <img src={featureIcon2} alt="..." /> */}
                                <IconPickerItem value={featureIcon2}></IconPickerItem>

                            </div>
                            <div class="flex-grow-1 ms-3">
                                <h6 class="m-0 p-0 fs-6 " style={{ color: `${contenttextheadingcolor}`, fontFamily: `${serviceBodytextfont}` }}>{serviceHeading2}</h6>
                                <span style={{ marginTop: 2, fontSize: 13, color: `${contenttextinfocolor}`, fontFamily: `${serviceBodytextfont}` }}>{serviceinfo2}</span>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex" >
                        <div style={{ display: serviceremove3 ? 'none' : 'flex' }}>
                            <div class="flex-shrink-0">
                                {/* <img src={featureIcon3} alt="..." /> */}
                                <IconPickerItem value={featureIcon3}></IconPickerItem>

                            </div>
                            <div class="flex-grow-1 ms-3">
                                <h6 class="m-0 p-0 fs-6 " style={{ color: `${contenttextheadingcolor}`, fontFamily: `${serviceBodytextfont}` }}>{serviceHeading3}</h6>
                                <span style={{ marginTop: 2, fontSize: 13, color: `${contenttextinfocolor}`, fontFamily: `${serviceBodytextfont}` }}>{serviceinfo3}</span>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex" >
                        <div style={{ display: serviceremove4 ? 'none' : 'flex' }}>
                            <div class="flex-shrink-0">
                                {/* <img src={featureIcon4} alt="..." /> */}
                                <IconPickerItem value={featureIcon4}></IconPickerItem>

                            </div>
                            <div class="flex-grow-1 ms-3">
                                <h6 class="m-0 p-0 fs-6 " style={{ color: `${contenttextheadingcolor}`, fontFamily: `${serviceBodytextfont}` }}>{serviceHeading4}</h6>
                                <span style={{ marginTop: 2, fontSize: 13, color: `${contenttextinfocolor}`, fontFamily: `${serviceBodytextfont}` }}>{serviceinfo4}</span>
                            </div>
                        </div>
                    </div>

                    {newservicesheading.map((heading, index) => (
                        <div class="d-flex" key={index}>
                            <div>
                                <div class="flex-shrink-0">
                                    <img src={pointer} alt="..." />

                                </div>
                                <div class="flex-grow-1 ms-3">
                                    <h6 class="m-0 p-0 fs-6 " style={{ color: `${contenttextheadingcolor}`, fontFamily: `${serviceBodytextfont}` }} key={index}>{heading.heading}</h6>

                                    <span style={{ marginTop: 2, fontSize: 13, color: `${contenttextinfocolor}`, fontFamily: `${serviceBodytextfont}` }} key={index}>{heading.info}</span>


                                </div>
                            </div>
                        </div>

                    ))}


                    <div style={{ width: "100%" }}>
                        <a href="#"><button class="mt-5  buttoncolor">More info</button></a>
                    </div>
                    {/* <div class="flex-grow-1 ms-3" >
                        <h6 class="m-0 p-0 fs-6 " style={{ color: `${contenttextheadingcolor}`, fontFamily: `${serviceBodytextfont}` }}>{serviceHeading1}</h6>
                        <span style={{ marginTop: 2, fontSize: 13, color: `${contenttextinfocolor}`, fontFamily: `${serviceBodytextfont}` }}>{serviceinfo1}</span>
                    </div> */}
                </div>
                <div class="col-md-6  p-6" >
                    {newservicesImage.length > 0 ?
                        <img src={newservicesImage} class="img-fluid shadow rounded p-6" alt="" style={{ marginTop: 100, height: 350 }} /> :

                        <img src={featureimages} class="img-fluid shadow rounded p-6" alt="" style={{ marginTop: 100, height: 350 }} />
                    }
                </div>
            </div>)}
            {selectedFeature === 0 && (<div class="container " style={{ backgroundColor: `${featurecolor}`, }}>
                <div class="row" style={{ marginBottom: `${serviceMarginBottom}px`, marginTop: `${serviceMarginTop}px` }}>
                    <div class="_c33e3ab2" style={{
                        flexDirection: imagePosition ? 'row-reverse' : 'row',
                    }}>
                        <div class="_5a4edc19" style={{ justifyContent: alignMedia === 'start' ? 'flex-start' : alignMedia === 'end' ? 'flex-end' : alignMedia === 'center' ? 'center' : 'flex-start' }}>
                            <div class="_c6ab3272">
                                <header class="_e519f50a">
                                    <p class='' style={{ color: `${captioncolor}` }}>  {servicecaption}   </p>
                                    <h1 class=" heading" style={{ fontSize: 33, color: `${contenttextheadingcolor}`, fontFamily: `${servicetitletextfont}` }}>{serviceheader}</h1>
                                    <p class="fs-6 lh-sm" style={{ color: `${contenttextinfocolor}`, fontFamily: `${serviceBodytextfont}` }} >
                                        {servicesIntro1}

                                    </p>  </header>
                                <button id="btn_gzt74ocmkq" class="_974be402 btn">
                                    <span>Read More</span>
                                </button>
                            </div>
                            {/* <div class="flex-grow-1 ms-3" >
                                <h6 class="m-0 p-0 fs-6 " style={{ color: `${contenttextheadingcolor}`, fontFamily: `${serviceBodytextfont}` }}>{serviceHeading1}</h6>
                                <span style={{ marginTop: 2, fontSize: 13, color: `${contenttextinfocolor}`, fontFamily: `${serviceBodytextfont}` }}>{serviceinfo1}</span>
                            </div>
                            <div class="flex-grow-1 ms-3" >
                                <h6 class="m-0 p-0 fs-6 " style={{ color: `${contenttextheadingcolor}`, fontFamily: `${serviceBodytextfont}` }}>{serviceHeading2}</h6>
                                <span style={{ marginTop: 2, fontSize: 13, color: `${contenttextinfocolor}`, fontFamily: `${serviceBodytextfont}` }}>{serviceinfo2}</span>
                            </div>
                            <div class="flex-grow-1 ms-3" >
                                <h6 class="m-0 p-0 fs-6 " style={{ color: `${contenttextheadingcolor}`, fontFamily: `${serviceBodytextfont}` }}>{serviceHeading3}</h6>
                                <span style={{ marginTop: 2, fontSize: 13, color: `${contenttextinfocolor}`, fontFamily: `${serviceBodytextfont}` }}>{serviceinfo3}</span>
                            </div>
                            <div class="flex-grow-1 ms-3" >
                                <h6 class="m-0 p-0 fs-6 " style={{ color: `${contenttextheadingcolor}`, fontFamily: `${serviceBodytextfont}` }}>{serviceHeading4}</h6>
                                <span style={{ marginTop: 2, fontSize: 13, color: `${contenttextinfocolor}`, fontFamily: `${serviceBodytextfont}` }}>{serviceinfo4}</span>
                            </div> */}

                            {/* <div class="_39a5731d">
                                <div class="_7e96d1a2" style={{ scale: '1' }}>
                                    <img src="https://files.umso.co/lib_RlIveqaGCTRDIbSf/cftx5x40cssur9er.svg?w=150&amp;h=30&amp;dpr=2" class="_9c827e5c" alt="carassava.svg" />
                                </div>
                                <span class="_063037f9">"AutoFix Hub got my car back on the road in no time - exceptional service!" -J. Smith</span>
                                <div class="_72288a42">
                                    <img src="https://files.umso.co/lib_wfKJyoksALKsfAWv/n2qivwn73qp5m1d5.png?w=50&amp;h=50&amp;fit=crop&amp;dpr=2" class="_6928058c" alt="character builder _ wave, waving, welcome, greeting, hi, hello, woman.png" />
                                    <div class="_c8ae6a52">
                                        <span class="_750519bb">You Customer</span>
                                        <span class="_2320f902">Technician</span>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                        <div class="_c8e71cbb">
                            {newservicesImage.length > 0 ?
                                <img src={newservicesImage} class="img-fluid shadow rounded p-6" alt="" style={{ marginTop: 100, height: 350 }} /> :

                                <img src={featureimages} class="img-fluid shadow rounded p-6" alt="" style={{ marginTop: 100, height: 350 }} />
                            }
                        </div>
                    </div>
                </div>
            </div>)}


            {selectedFeature === 1 && (<div class="container " style={{ backgroundColor: `${featurecolor}`, }}>
                <div class="row" style={{ marginBottom: `${serviceMarginBottom}px`, marginTop: `${serviceMarginTop}px`, margin: "20px" }}>
                    <div class="_c33e3ab2 _c33e3ab3" style={{
                        flexDirection: imagePosition ? 'row-reverse' : 'row',
                    }}>
                        <div class="_5a4edc19" style={{ justifyContent: alignMedia === 'start' ? 'flex-start' : alignMedia === 'end' ? 'flex-end' : alignMedia === 'center' ? 'center' : 'flex-start' }}>
                            <div class="_c6ab3272 ">
                                <header class="_e519f50a">
                                    <p class='' style={{ color: `${captioncolor}` }}>  {servicecaption}   </p>
                                    <h1 class=" heading" style={{ fontSize: 33, color: `${contenttextheadingcolor}`, fontFamily: `${servicetitletextfont}` }}>{serviceheader}</h1>
                                    <p class="fs-6 lh-sm" style={{ color: `${contenttextinfocolor}`, fontFamily: `${serviceBodytextfont}` }} >
                                        {servicesIntro1}

                                    </p> </header>
                                <button id="btn_gzt74ocmkq" class="_974be402 btn">
                                    <span>Read More</span>
                                </button>
                                {/* <div class="flex-grow-1 ms-3" >
                                    <h6 class="m-0 p-0 fs-6 " style={{ color: `${contenttextheadingcolor}`, fontFamily: `${serviceBodytextfont}` }}>{serviceHeading1}</h6>
                                    <span style={{ marginTop: 2, fontSize: 13, color: `${contenttextinfocolor}`, fontFamily: `${serviceBodytextfont}` }}>{serviceinfo1}</span>
                                </div>
                                <div class="flex-grow-1 ms-3" >
                                    <h6 class="m-0 p-0 fs-6 " style={{ color: `${contenttextheadingcolor}`, fontFamily: `${serviceBodytextfont}` }}>{serviceHeading2}</h6>
                                    <span style={{ marginTop: 2, fontSize: 13, color: `${contenttextinfocolor}`, fontFamily: `${serviceBodytextfont}` }}>{serviceinfo2}</span>
                                </div>
                                <div class="flex-grow-1 ms-3" >
                                    <h6 class="m-0 p-0 fs-6 " style={{ color: `${contenttextheadingcolor}`, fontFamily: `${serviceBodytextfont}` }}>{serviceHeading3}</h6>
                                    <span style={{ marginTop: 2, fontSize: 13, color: `${contenttextinfocolor}`, fontFamily: `${serviceBodytextfont}` }}>{serviceinfo3}</span>
                                </div>
                                <div class="flex-grow-1 ms-3" >
                                    <h6 class="m-0 p-0 fs-6 " style={{ color: `${contenttextheadingcolor}`, fontFamily: `${serviceBodytextfont}` }}>{serviceHeading4}</h6>
                                    <span style={{ marginTop: 2, fontSize: 13, color: `${contenttextinfocolor}`, fontFamily: `${serviceBodytextfont}` }}>{serviceinfo4}</span>
                                </div> */}
                            </div>
                            {/* <div class="_39a5731d">
                                <div class="_7e96d1a2" style={{ scale: '1' }}>
                                    <img src="https://files.umso.co/lib_RlIveqaGCTRDIbSf/cftx5x40cssur9er.svg?w=150&amp;h=30&amp;dpr=2" class="_9c827e5c" alt="carassava.svg" />
                                </div>
                                <span class="_063037f9">"AutoFix Hub got my car back on the road in no time - exceptional service!" -J. Smith</span>
                                <div class="_72288a42">
                                    <img src="https://files.umso.co/lib_wfKJyoksALKsfAWv/n2qivwn73qp5m1d5.png?w=50&amp;h=50&amp;fit=crop&amp;dpr=2" class="_6928058c" alt="character builder _ wave, waving, welcome, greeting, hi, hello, woman.png" />
                                    <div class="_c8ae6a52">
                                        <span class="_750519bb">You Customer</span>
                                        <span class="_2320f902">Technician</span>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                        <div class="_c8e71cbb">
                            {newservicesImage.length > 0 ?
                                <img src={newservicesImage} class="img-fluid shadow rounded p-6" alt="" style={{ marginTop: 100, height: 350 }} /> :

                                <img src={featureimages} class="img-fluid shadow rounded p-6" alt="" style={{ marginTop: 100, height: 350 }} />
                            }
                        </div>
                    </div>
                </div>
            </div>)}

            {selectedFeature === 2 && (<div class="container " style={{ backgroundColor: `${featurecolor}`, }}>
                <div class="row" style={{ marginBottom: `${serviceMarginBottom}px`, marginTop: `${serviceMarginTop}px` }}>
                    <div class="_c33e3ab2" style={{
                        flexDirection: imagePosition ? 'row-reverse' : 'row',
                    }}>
                        <div class="_5a4edc19" style={{ justifyContent: alignMedia === 'start' ? 'flex-start' : alignMedia === 'end' ? 'flex-end' : alignMedia === 'center' ? 'center' : 'flex-start' }}>
                            <div class="_c6ab3272">
                                <header class="_e519f50a">
                                    <p class='' style={{ color: `${captioncolor}` }}>  {servicecaption}   </p>
                                    <h1 class=" heading" style={{ fontSize: 33, color: `${contenttextheadingcolor}`, fontFamily: `${servicetitletextfont}` }}>{serviceheader}</h1>
                                    <p class="fs-6 lh-sm" style={{ color: `${contenttextinfocolor}`, fontFamily: `${serviceBodytextfont}` }} >
                                        {servicesIntro1}

                                    </p>  </header>
                                <button id="btn_gzt74ocmkq" class="_974be402 btn">
                                    <span>Read More</span>
                                </button>

                                <div class="flex-grow-1 ms-3" >
                                    <h6 class="m-0 p-0 fs-6 " style={{ color: `${contenttextheadingcolor}`, fontFamily: `${serviceBodytextfont}` }}>{serviceHeading1}</h6>
                                    <span style={{ marginTop: 2, fontSize: 13, color: `${contenttextinfocolor}`, fontFamily: `${serviceBodytextfont}` }}>{serviceinfo1}</span>
                                </div>
                                <div class="flex-grow-1 ms-3" >
                                    <h6 class="m-0 p-0 fs-6 " style={{ color: `${contenttextheadingcolor}`, fontFamily: `${serviceBodytextfont}` }}>{serviceHeading2}</h6>
                                    <span style={{ marginTop: 2, fontSize: 13, color: `${contenttextinfocolor}`, fontFamily: `${serviceBodytextfont}` }}>{serviceinfo2}</span>
                                </div>
                                <div class="flex-grow-1 ms-3" >
                                    <h6 class="m-0 p-0 fs-6 " style={{ color: `${contenttextheadingcolor}`, fontFamily: `${serviceBodytextfont}` }}>{serviceHeading3}</h6>
                                    <span style={{ marginTop: 2, fontSize: 13, color: `${contenttextinfocolor}`, fontFamily: `${serviceBodytextfont}` }}>{serviceinfo3}</span>
                                </div>
                                <div class="flex-grow-1 ms-3" >
                                    <h6 class="m-0 p-0 fs-6 " style={{ color: `${contenttextheadingcolor}`, fontFamily: `${serviceBodytextfont}` }}>{serviceHeading4}</h6>
                                    <span style={{ marginTop: 2, fontSize: 13, color: `${contenttextinfocolor}`, fontFamily: `${serviceBodytextfont}` }}>{serviceinfo4}</span>
                                </div>
                            </div>

                        </div>
                        <div class="_c8e71cbb">
                            {newservicesImage.length > 0 ?
                                <img src={newservicesImage} class="img-fluid shadow rounded p-6" alt="" style={{ marginTop: 100, height: 350 }} /> :

                                <img src={featureimages} class="img-fluid shadow rounded p-6" alt="" style={{ marginTop: 100, height: 350 }} />
                            }
                        </div>
                    </div>
                </div>
            </div>)}

            {selectedFeature === 3 && (<div class="container " style={{ backgroundColor: `${featurecolor}`, }}>
                <div class="row" style={{ marginBottom: `${serviceMarginBottom}px`, marginTop: `${serviceMarginTop}px`, margin: "20px" }}>
                    <div class="_c33e3ab2 _c33e3ab3" style={{
                        flexDirection: imagePosition ? 'row-reverse' : 'row',
                    }}>
                        <div class="_5a4edc19" style={{ justifyContent: alignMedia === 'start' ? 'flex-start' : alignMedia === 'end' ? 'flex-end' : alignMedia === 'center' ? 'center' : 'flex-start' }}>
                            <div class="_c6ab3272 ">
                                <header class="_e519f50a">
                                    <p class='' style={{ color: `${captioncolor}` }}>  {servicecaption}   </p>
                                    <h1 class=" heading" style={{ fontSize: 33, color: `${contenttextheadingcolor}`, fontFamily: `${servicetitletextfont}` }}>{serviceheader}</h1>
                                    <p class="fs-6 lh-sm" style={{ color: `${contenttextinfocolor}`, fontFamily: `${serviceBodytextfont}` }} >
                                        {servicesIntro1}

                                    </p>  </header>
                                <button id="btn_gzt74ocmkq" class="_974be402 btn">
                                    <span>Read More</span>
                                </button>
                                <div class="flex-grow-1 ms-3" >
                                    <h6 class="m-0 p-0 fs-6 " style={{ color: `${contenttextheadingcolor}`, fontFamily: `${serviceBodytextfont}` }}>{serviceHeading1}</h6>
                                    <span style={{ marginTop: 2, fontSize: 13, color: `${contenttextinfocolor}`, fontFamily: `${serviceBodytextfont}` }}>{serviceinfo1}</span>
                                </div>
                                <div class="flex-grow-1 ms-3" >
                                    <h6 class="m-0 p-0 fs-6 " style={{ color: `${contenttextheadingcolor}`, fontFamily: `${serviceBodytextfont}` }}>{serviceHeading2}</h6>
                                    <span style={{ marginTop: 2, fontSize: 13, color: `${contenttextinfocolor}`, fontFamily: `${serviceBodytextfont}` }}>{serviceinfo2}</span>
                                </div>
                                <div class="flex-grow-1 ms-3" >
                                    <h6 class="m-0 p-0 fs-6 " style={{ color: `${contenttextheadingcolor}`, fontFamily: `${serviceBodytextfont}` }}>{serviceHeading3}</h6>
                                    <span style={{ marginTop: 2, fontSize: 13, color: `${contenttextinfocolor}`, fontFamily: `${serviceBodytextfont}` }}>{serviceinfo3}</span>
                                </div>
                                <div class="flex-grow-1 ms-3" >
                                    <h6 class="m-0 p-0 fs-6 " style={{ color: `${contenttextheadingcolor}`, fontFamily: `${serviceBodytextfont}` }}>{serviceHeading4}</h6>
                                    <span style={{ marginTop: 2, fontSize: 13, color: `${contenttextinfocolor}`, fontFamily: `${serviceBodytextfont}` }}>{serviceinfo4}</span>
                                </div>
                            </div>

                        </div>
                        <div class="_c8e71cbb">
                            {newservicesImage.length > 0 ?
                                <img src={newservicesImage} class="img-fluid shadow rounded p-6" alt="" style={{ marginTop: 100, height: 350 }} /> :

                                <img src={featureimages} class="img-fluid shadow rounded p-6" alt="" style={{ marginTop: 100, height: 350 }} />
                            }  </div>
                    </div>
                </div>
            </div>)}
        </div>
    );
};

export default FeatureEditi;