import Web3 from "web3";
import { abiUSD } from "./ABI/USD.js";
// import { detectCurrentProvider, AdminPaymentAddress, TokenPayAmount } from "./ContractDependency.js";
import { detectCurrentProvider, AdminPaymentAddress, BasicTokenPayAmount, GoldTokenPayAmount, PlatinumTokenPayAmount } from "./ContractDependency.js";
import { useContext } from "react";
import MyContext from "../context/MyContext.js";
// import  MyContext from "../context/MyContext";


const chainAddresses = require('./ChainAddresses.json');

var abiUSDToken = abiUSD;


export const getCurrentChainId = async () => {
    console.log("getCurrentChainId")
    try {
        const provider = detectCurrentProvider();
        if (!provider) {
            throw new Error("No Ethereum provider found. Please install MetaMask or Trust Wallet.");
        }
        const web3 = new Web3(provider);
        const CurrentChainId = Number(await web3.eth.net.getId());
        console.log("CurrentChainId", CurrentChainId)
        return CurrentChainId;

    } catch (error) {
        console.log("getCurrentChainIdERROR", error);
    }
}
getCurrentChainId()

export const getCurrentChainIdAllDetails = async () => {
    try {
        let chainId = await getCurrentChainId();
        const chain = chainAddresses.find(chain => chain.hasOwnProperty(chainId));
        if (chain) {
            const allTokens = chain[chainId].map(token => ({
                stableCoinAddress: token.StableCoinAddress,
                stableCoinName: token.StableCoinName,
                name: token.name,
                routerAddress: token.Routeraddress,
                dexLink: token.Dexlink,
                explorerLink: token.explorerlink,
            }));
            console.log("getCurrentChainIdAllDetails:", allTokens);
            return allTokens;
        } else {
            console.log(`Chain ID ${chainId} not found`);
            return [];
        }
    } catch (error) {
        console.log("Error_getCurrentChainIdAllDetails:", error);
        return [];
    }
};
getCurrentChainIdAllDetails();

export const getCurrentChainIdStableCoinAddress = async (chainId) => {
    console.log("getCurrentChainIdStableCoinAddress");
    const chainDetails = chainAddresses.find(chain => chain.hasOwnProperty(chainId));
    console.log("chainDetails", chainDetails)
    if (chainDetails) {
        const stableCoinAddress = chainDetails[chainId].find(token => token.StableCoinAddress !== undefined);
        if (stableCoinAddress) {
            console.log(`getCurrentChainIdStableCoinAddress on ${chainId}: `, stableCoinAddress.StableCoinAddress);
            return stableCoinAddress.StableCoinAddress;
        } else {
            console.log(`No stable coin address found for chain ID ${chainId}`);
            return null;
        }
    } else {
        console.log(`Chain ID ${chainId} not found`);
        return null;
    }
};

export const getTokenBalance = async (tokenContractAddress) => {
    console.log("getTokenBalance");
    try {
        const provider = detectCurrentProvider();
        if (!provider) {
            throw new Error("No Ethereum provider found. Please install MetaMask or Trust Wallet.");
        }
        const web3 = new Web3(provider);
        const accounts = await provider.request({
            method: "eth_accounts"
        });
        const account = accounts[0];
        const contracts = new web3.eth.Contract(abiUSDToken, tokenContractAddress);
        console.log("contracts_methods", contracts.methods);
        console.log("account", account);
        const response = await contracts.methods.balanceOf(account).call();
        // const balance = Number(web3.utils.fromWei(response, 'ether'));
        const balance = Number(response) / 10 ** 6;
        console.log(`TokenBalance of ${tokenContractAddress}`, balance);
        return balance;

    } catch (error) {
        console.log("balance_error", error);
    }
};

export const getTokenSymbol = async (tokenContractAddress) => {
    try {
        const provider = detectCurrentProvider();
        if (!provider) {
            throw new Error("No Ethereum provider found. Please install MetaMask or Trust Wallet.");
        }
        const web3 = new Web3(provider);
        const contract = new web3.eth.Contract(abiUSDToken, tokenContractAddress);
        const tokenSymbol = await contract.methods.symbol().call();
        console.log(`Token Symbol of address ${tokenContractAddress} is: ${tokenSymbol}`);
        return tokenSymbol;

    } catch (error) {
        console.log(`Error fetching token symbol for address ${tokenContractAddress}:`, error);
    }
};

export const getCurrentTxExplorer = async () => {
    const currentChainId = await getCurrentChainId();
    console.log("getCurrentTxExplorerCurrentChainId", currentChainId);
    const currentChainIdInHex = Web3.utils.toHex(currentChainId);
    const networkExplorers = [{
            key: 1,
            networkExplorer: "https://etherscan.io/tx/",
            chainId: '0x1'
        },
        {
            key: 2,
            networkExplorer: "https://bscscan.com/tx/",
            chainId: '0x56'
        },
        {
            key: 3,
            networkExplorer: "https://polygonscan.com/tx/",
            chainId: '0x137'
        },
        {
            key: 4,
            networkExplorer: "https://snowscan.xyz/tx/",
            chainId: '0x43114'
        },
        {
            key: 5,
            networkExplorer: "https://arbiscan.io/tx/",
            chainId: '0x42161'
        },
        {
            key: 6,
            networkExplorer: "https://optimistic.etherscan.io/tx/",
            chainId: '0x10'
        },
        {
            key: 7,
            networkExplorer: "https://basescan.org/tx/",
            chainId: '0x8453'
        },
        {
            key: 8,
            networkExplorer: "https://explorer.celo.org/mainnet/tx/",
            chainId: '0x42220'
        },



        {
            key: 9,
            networkExplorer: "https://sepolia.etherscan.io/tx/",
            chainId: '0xaa36a7'
        },
        {
            key: 10,
            networkExplorer: "https://testnet.bscscan.com/tx/",
            chainId: '0x61'
        },
        {
            key: 11,
            networkExplorer: "https://amoy.polygonscan.com/tx/",
            chainId: '0x13881'
        },
        {
            key: 12,
            networkExplorer: "https://testnet.snowtrace.io/tx/",
            chainId: '0xa869'
        },
        {
            key: 13,
            networkExplorer: "https://goerli.basescan.org/tx/",
            chainId: '0x2105'
        },

        {
            key: 14,
            networkExplorer: "https://goerli-optimism.etherscan.io/tx/",
            chainId: '0xa'
        },
        {
            key: 15,
            networkExplorer: "https://alfajores.celoscan.io/tx/",
            chainId: '0xa4ec'
        },
        {
            key: 16,
            networkExplorer: "https://sepolia.basescan.org/tx/",
            chainId: '0x14a34'
        },
        {
            key: 17,
            networkExplorer: "https://ftmscan.com/tx/",
            chainId: '0xfa'
        },
    ];
    const currentNetworkExplorer = networkExplorers.find(
        explorer => explorer.chainId.toLowerCase() === currentChainIdInHex.toLowerCase()
    );
    console.log("currentNetworkTXNExplorer", currentNetworkExplorer.networkExplorer);
    return currentNetworkExplorer ? currentNetworkExplorer.networkExplorer : null;
};
getCurrentTxExplorer()

export const getImportedAddressExplorer = async () => {
    const currentChainId = await getCurrentChainId();
    console.log("getCurrentTxExplorerCurrentChainId", currentChainId);
    const currentChainIdInHex = Web3.utils.toHex(currentChainId);
    const networkExplorers = [{
            key: 1,
            networkExplorer: "https://etherscan.io/address/",
            chainId: '0x1'
        },
        {
            key: 2,
            networkExplorer: "https://bscscan.com/address/",
            chainId: '0x56'
        },
        {
            key: 3,
            networkExplorer: "https://polygonscan.com/address/",
            chainId: '0x137'
        },
        {
            key: 4,
            networkExplorer: "https://snowscan.xyz/address/",
            chainId: '0x43114'
        },
        {
            key: 5,
            networkExplorer: "https://arbiscan.io/address/",
            chainId: '0x42161'
        },
        {
            key: 6,
            networkExplorer: "https://optimistic.etherscan.io/address/",
            chainId: '0x10'
        },
        {
            key: 7,
            networkExplorer: "https://basescan.org/address/",
            chainId: '0x8453'
        },
        {
            key: 8,
            networkExplorer: "https://explorer.celo.org/mainnet/address/",
            chainId: '0x42220'
        },



        {
            key: 9,
            networkExplorer: "https://sepolia.etherscan.io/address/",
            chainId: '0xaa36a7'
        },
        {
            key: 10,
            networkExplorer: "https://testnet.bscscan.com/address/",
            chainId: '0x61'
        },
        {
            key: 11,
            networkExplorer: "https://amoy.polygonscan.com/address/",
            chainId: '0x13881'
        },
        {
            key: 12,
            networkExplorer: "https://testnet.snowtrace.io/address/",
            chainId: '0xa869'
        },
        {
            key: 13,
            networkExplorer: "https://goerli.basescan.org/address/",
            chainId: '0x2105'
        },

        {
            key: 14,
            networkExplorer: "https://goerli-optimism.etherscan.io/address/",
            chainId: '0xa'
        },
        {
            key: 15,
            networkExplorer: "https://alfajores.celoscan.io/address/",
            chainId: '0xa4ec'
        },
        {
            key: 16,
            networkExplorer: "https://sepolia.basescan.org/address/",
            chainId: '0x14a34'
        },
        {
            key: 17,
            networkExplorer: "https://ftmscan.com/address/",
            chainId: '0xfa'
        },
    ];
    const currentNetworkExplorer = networkExplorers.find(
        explorer => explorer.chainId.toLowerCase() === currentChainIdInHex.toLowerCase()
    );
    console.log("currentNetworkADDRExplorer", currentNetworkExplorer.networkExplorer);
    return currentNetworkExplorer ? currentNetworkExplorer.networkExplorer : null;
};
getImportedAddressExplorer()

/////////////////

const savesubscription = (account,plantitle,transactionhash,rid) => {
    //console.log("contentdesigndd",randomId); 
        fetch('https://trendifyweb.ai/pyapi/usersubscriptions', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              address: account,
              plan: plantitle,
              hash: transactionhash,
              randomid: rid
           })
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Failed to save and edit');
            }
            // Redirect or handle success as needed
        })
        .catch(error => {
            console.error('Error:', error);
            // Handle error
        });
    };    

////////////////

export const transferBasicPayment = async (rid) => {
    
    try {
        
        const provider = detectCurrentProvider();
        if (!provider) {
            console.error("Noproviderdetected");
        }
        console.log("Providerdetected:", provider);
        const web3 = new Web3(provider);
        await provider.request({
            method: "eth_requestAccounts"
        });
        const accounts = await provider.request({
            method: "eth_accounts"
        });
        const account = accounts[0];
        console.log("Accountfound:", account);
        const currentChainId = await getCurrentChainId();
        if (!currentChainId) {
            console.error("UnableToRetrieveCurrentChainID");
        }
        console.log("CurrentChainID:", currentChainId);
        const paymentTokenAddress = await getCurrentChainIdStableCoinAddress(currentChainId);
        if (!paymentTokenAddress) {
            console.error("UnableToRetrieveStablecoinAddress_chainID");
        }
        console.log("PaymentTokenAddress:", paymentTokenAddress);
        const contracts = new web3.eth.Contract(abiUSDToken, paymentTokenAddress);
        console.log("contracts", contracts)
        const tokenPayDecimals = await contracts.methods.decimals().call();
        console.log("tokenPayDecimals", tokenPayDecimals);
        const tokenPayAmount = (Number(BasicTokenPayAmount) * 10 ** Number(tokenPayDecimals)).toString();
        console.log("tokenPayAmount", tokenPayAmount);
        const resTransferPayment = await contracts.methods.transfer(AdminPaymentAddress, tokenPayAmount).send({
            from: account
        });
        console.log("TransferSuccessful:", resTransferPayment);
        const txExplorer = await getCurrentTxExplorer();
        const txHashResponse = {
            txHash: txExplorer + resTransferPayment.transactionHash,
            status: resTransferPayment.status
        };
        console.log("txHashResponse", txHashResponse);
        console.log('txHashResponsestatus',txHashResponse.status)
        console.log('txHashResponsestatustype',typeof txHashResponse)
          // Check if transaction is successful
        if (txHashResponse.status === 1n) {
            // Call the function to store transaction in the database
            // await storeTransactionInDatabase(txHashResponse);
            
            savesubscription(account,'basic',txHashResponse.txHash ,rid);
            console.log("33333333333333333", txHashResponse.status);
            // window.location.assign('/save&edit');
            window.history.back();
            
        }
        return txHashResponse;
    } catch (error) {
        console.error("Error_TransferPayment:", error);
    
    }
};


export const transferGoldPayment = async (rid) => {
    try {
        const provider = detectCurrentProvider();
        if (!provider) {
            console.error("Noproviderdetected");
        }
        console.log("Providerdetected:", provider);
        const web3 = new Web3(provider);
        await provider.request({
            method: "eth_requestAccounts"
        });
        const accounts = await provider.request({
            method: "eth_accounts"
        });
        const account = accounts[0];
        console.log("Accountfound:", account);
        const currentChainId = await getCurrentChainId();
        if (!currentChainId) {
            console.error("UnableToRetrieveCurrentChainID");
        }
        console.log("CurrentChainID:", currentChainId);
        const paymentTokenAddress = await getCurrentChainIdStableCoinAddress(currentChainId);
        if (!paymentTokenAddress) {
            console.error("UnableToRetrieveStablecoinAddress_chainID");
        }
        console.log("PaymentTokenAddress:", paymentTokenAddress);
        const contracts = new web3.eth.Contract(abiUSDToken, paymentTokenAddress);
        console.log("contracts", contracts)
        const tokenPayDecimals = await contracts.methods.decimals().call();
        console.log("tokenPayDecimals", tokenPayDecimals);
        const tokenPayAmount = (Number(GoldTokenPayAmount) * 10 ** Number(tokenPayDecimals)).toString();
        console.log("tokenPayAmount", tokenPayAmount);
        const resTransferPayment = await contracts.methods.transfer(AdminPaymentAddress, tokenPayAmount).send({
            from: account
        });
        console.log("TransferSuccessful:", resTransferPayment);
        const txExplorer = await getCurrentTxExplorer();
        const txHashResponse = {
            txHash: txExplorer + resTransferPayment.transactionHash,
            status: resTransferPayment.status
        };
        console.log("txHashResponse", txHashResponse);
        if (txHashResponse.status === 1n) {
            // Call the function to store transaction in the database
            // await storeTransactionInDatabase(txHashResponse);
            
            savesubscription(account,'gold',txHashResponse.txHash,rid);
            console.log("222222222222222", txHashResponse.status);
            window.history.back();
        }
        return txHashResponse;
    } catch (error) {
        console.error("Error_TransferPayment:", error);
    }
};

export const transferPlatinumPayment = async (rid) => {
    
    try {
        const provider = detectCurrentProvider();
        if (!provider) {
            console.error("Noproviderdetected");
        }
        console.log("Providerdetected:", provider);
        const web3 = new Web3(provider);
        await provider.request({
            method: "eth_requestAccounts"
        });
        const accounts = await provider.request({
            method: "eth_accounts"
        });
        const account = accounts[0];
        console.log("Accountfound:", account);
        const currentChainId = await getCurrentChainId();
        if (!currentChainId) {
            console.error("UnableToRetrieveCurrentChainID");
        }
        console.log("CurrentChainID:", currentChainId);
        const paymentTokenAddress = await getCurrentChainIdStableCoinAddress(currentChainId);
        if (!paymentTokenAddress) {
            console.error("UnableToRetrieveStablecoinAddress_chainID");
        }
        console.log("PaymentTokenAddress:", paymentTokenAddress);
        const contracts = new web3.eth.Contract(abiUSDToken, paymentTokenAddress);
        console.log("contracts", contracts)
        const tokenPayDecimals = await contracts.methods.decimals().call();
        console.log("tokenPayDecimals", tokenPayDecimals);
        const tokenPayAmount = (Number(PlatinumTokenPayAmount) * 10 ** Number(tokenPayDecimals)).toString();
        console.log("tokenPayAmount", tokenPayAmount);
        const resTransferPayment = await contracts.methods.transfer(AdminPaymentAddress, tokenPayAmount).send({
            from: account
        });
        console.log("TransferSuccessful:", resTransferPayment);
        const txExplorer = await getCurrentTxExplorer();
        const txHashResponse = {
            txHash: txExplorer + resTransferPayment.transactionHash,
            status: resTransferPayment.status
        };
        console.log("txHashResponse", txHashResponse);
        if (txHashResponse.status === 1n) {
            // Call the function to store transaction in the database
            // await storeTransactionInDatabase(txHashResponse);
           
            savesubscription(account,'platinum',txHashResponse.txHash, rid);
            console.log("111111111111111", txHashResponse.status);
            window.history.back();
        }
        return txHashResponse;
    } catch (error) {
        console.error("Error_TransferPayment:", error);
    }
};