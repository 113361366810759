import react, { useContext, useEffect, useRef, useState } from 'react'
import Memecoin from '../Memecoin';
import Theme from '../../Theme';
import IntroDesing from '../../SaveandEdit/Intro/Indrtodesing';
import MyContext from '../../../context/MyContext';
import { useReducer } from 'react';
import ContentDesign from '../../ContentDesign';
import MainNavBar from '../../MainNavBar';
import TrustDesign from '../../SaveandEdit/Trustedby/trustedesing';
import WebPages from '../../Webpages';
import heroImage from '../../../assets/images/BackgroundMemecoin1.png'
import cartoon from '../../../assets/images/leftflying.png'
import boomerright from '../../../assets/images/boomerright.png'
import boomerleft from '../../../assets/images/carttonleft.png'
import MemeMaindesing from './Mememaindesing';
import { SketchPicker } from 'react-color';
import CloudImage from '../../../assets/images/NewMaskImage.png'



const MemeHomeEdit = () => {
  const {
    memecoinname, SetMemecoinName,
    memecoinsymbol, setMemecoinSymbol,
    memecoinNavbar, setMemecoinNavbar,
    memecoinAbout, setMemecoinAbout, contentdesing,
    mainnavbarbutton,
    memeCaption, setMemecaption,
    memecoinValue, setMemecoinValue,
    memecoinaboutbgImage, setMemecoinaboutbgIamge,
    memecoinAminitionImage, setMemecoinAminationImage,
    memecoinaboutrightimage, setMemecoinaboutrightimage,
    memecoinaboutleftimage, setMemecoinaboutleftimage,
    memeherobutton, setmemeHerobutton,
    memeherobgcolor, setMemeherobgcolor, setMemeherobackground, selectMemeCoinTemplates,
    template1caption, setTemplate1caption,
    template1Info, setTemplate1Info,
    template2caption, setTemplate2Caption,
    template2memecaption, setTemplate2memecaption,
    template2info, setTemplate2info,
    template2tokenAddress, setTemplate2tokenAddress,
    template2coingecko, setTemplate2coingecko,
    template2cmc, setTemplate2cmc,
    template2uniswap, setTemplateuniswap,
    template3telegramlink, setTemplate3telegramlink,
    template3twitterlink, setTemplate3twitterlink,
    template3uniswaplink, setTemplate3uniswaplink,
    memecloudimage, setMemecloudImage,
    template2removebuttonuniswap, setTemplate2Remvebuttonuniswap,
    template2removebuttonunicmc, setTemplate2Remvebuttonunicmc,
    template2removebuttoncoingecko, setTemplate2Remvebuttoncoingecko,
    t4memeaddress,setT4memeadress,t4caption,setT4caption,
    t4mainbutton1,setT4mainbutton1,
      t4mainbutton2,setT4mainbutton2,setMemetwitterlink,
      memetwitterlink,memetelegramlink,
      setMemetelegramlink,
      memeinstagramlink,setMemeinstagramlink

  } = useContext(MyContext);


  const [isHeaderDesignText, setisHeaderDesignText] = useState(false);

  const HandelMemecoinName = (e) => {
    SetMemecoinName(e.target.value)
  };

  const HandelMemecoinSymbol = (e) => {
    setMemecoinSymbol(e.target.value)
  };
  const HandelMemecoincaption = (e) => {
    setMemecaption(e.target.value)
  };
  const HandelMemecoincaptionT1 = (e) => {
    setTemplate1caption(e.target.value)
  };
  const HandelMemecoinValue = (e) => {
    setMemecoinValue(e.target.value)
  };
  const HandelMemecoinT1Info = (e) => {
    setTemplate1Info(e.target.value)
  };

  const handleMainBackgroundImage = (index) => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = (e) => {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const updatedTrustLogo = [...memecoinaboutbgImage];
        updatedTrustLogo[index] = event.target.result;
        setMemecoinaboutbgIamge(updatedTrustLogo);
        console.log('updatedTrustLogo', updatedTrustLogo)
      };

      reader.readAsDataURL(file);
    };
    input.click();
  };
  const handleAminationImage = (index) => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = (e) => {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const updatedTrustLogo = [...memecoinAminitionImage];
        updatedTrustLogo[index] = event.target.result;
        setMemecoinAminationImage(updatedTrustLogo);
        console.log('updatedTrustLogo', updatedTrustLogo)
      };

      reader.readAsDataURL(file);
    };
    input.click();
  };

  const handleCloudImage = (index) => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = (e) => {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const updatedTrustLogo = [...memecloudimage];
        updatedTrustLogo[index] = event.target.result;
        setMemecloudImage(updatedTrustLogo);
        console.log('updatedTrustLogo', updatedTrustLogo)
      };

      reader.readAsDataURL(file);
    };
    input.click();
  };

  const handleLeftImage = (index) => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = (e) => {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const updatedTrustLogo = [...memecoinaboutleftimage];
        updatedTrustLogo[index] = event.target.result;
        setMemecoinaboutleftimage(updatedTrustLogo);
        console.log('updatedTrustLogo', updatedTrustLogo)
      };

      reader.readAsDataURL(file);
    };
    input.click();
  };

  const handleRightImage = (index) => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = (e) => {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const updatedTrustLogo = [...memecoinaboutrightimage];
        updatedTrustLogo[index] = event.target.result;
        setMemecoinaboutrightimage(updatedTrustLogo);
        console.log('updatedTrustLogo', updatedTrustLogo)
      };

      reader.readAsDataURL(file);
    };
    input.click();
  };

  const handelchangecolor = (titlecolor) => {
    setMemeherobgcolor(titlecolor.hex)
   
  };

  const handeltitle = () => {
    setisHeaderDesignText(!isHeaderDesignText)
  }
  useEffect(() => {
    function handleClickOutside(event) {
      const target = event.target;
      const divToExclude = document.getElementById('one');

      // Check if the click occurred outside of the specific div
      if (divToExclude && !divToExclude.contains(target)) {
        setisHeaderDesignText(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isHeaderDesignText]);
  return (
    <div>

      <div className='container-fluid d-flex flex-column' style={{ height: '100vh', overflow: 'auto' }}>
        <div>
          <MainNavBar />
        </div>
        <div className='flex-grow-1 d-flex flex-row mt-1' style={{ overflow: 'hidden' }}>
          <div style={{ width: '400px' }} >
            {mainnavbarbutton == false ?
              <div className='editing_wrapper__Wijfx h-100  overflow-auto'>
                <ContentDesign headerText="Hero" />
                {contentdesing == false ?
                  <div >
                    {selectMemeCoinTemplates == 1 ?
                      <div className="editing_content__RXzxk">
                        <div className="form_form__Sku4X" >
                          <div className="form_form__Sku4X">
                            <div className="form_group__kDXNb">
                              <div className="form_form__Sku4X">
                                <div class="form_row__2+BJ3">

                                  <div className="form_group__kDXNb">
                                    <div className="form_form__Sku4X" style={{ maxWidth: "89%" }}>
                                      <div class="form_field__d31bq" >
                                        <span>Caption</span>
                                        <textarea placeholder="Caption" style={{ height: "32px" }} value={memeCaption} onChange={(e) => HandelMemecoincaption(e)} >
                                        </textarea>
                                      </div>
                                      <div class="form_field__d31bq" >
                                        <span>Value</span>
                                        <textarea placeholder="Value" style={{ height: "32px" }} value={memecoinValue} onChange={(e) => HandelMemecoinValue(e)} >
                                        </textarea>
                                      </div>
                                      <div class="form_field__d31bq" >
                                        <span>Meme Coin Name</span>
                                        <textarea placeholder="Name" style={{ height: "32px" }} value={memecoinname} onChange={(e) => HandelMemecoinName(e)}>
                                        </textarea>
                                      </div>
                                      <div class="form_field__d31bq" >
                                        <span>Meme Coin Symbol</span>
                                        <textarea placeholder="Symbol" style={{ height: "32px" }} value={memecoinsymbol} onChange={(e) => HandelMemecoinSymbol(e)} >
                                        </textarea>
                                      </div>
                                      <div class="form_field__d31bq" >
                                        <span>Join US</span>
                                        <textarea placeholder="Symbol" style={{ height: "32px" }} value={memeherobutton} onChange={(e) => setmemeHerobutton(e.target.value)} >
                                        </textarea>
                                      </div>
                                      <div class='mt-3'>
                                        <label>Images</label>
                                        <div className="media_preview__okAyH">
                                          <label>Background Image</label>
                                          <div className="media_value__\+4KKG ">
                                            {memecoinaboutbgImage.length > 0 ?
                                              <div>
                                                {memecoinaboutbgImage.map((image, index) => (
                                                  <img
                                                    onClick={() => { handleMainBackgroundImage(0); setMemeherobackground(0) }}
                                                    key={index}
                                                    style={{ height: 30, width: 40 }}
                                                    src={image}
                                                    alt={`Image ${index}`}
                                                    className="media_image__kN9DM"
                                                  />
                                                ))}</div>
                                              :
                                              <img onClick={() => { handleMainBackgroundImage(0); setMemeherobackground(0) }} style={{ height: 30, width: 40 }} src={memecoinaboutbgImage.length > 0 ? memecoinaboutbgImage : heroImage} alt="" className="media_image__kN9DM" />
                                            }
                                          </div>
                                        </div>

                                        <span >Background color</span>

                                        <div className="media_preview__okAyH" onClick={() => { handeltitle(); setMemeherobackground(1) }}>
                                          <label>Color</label>
                                          <div className="media_value__\+4KKG ">

                                            <div style={{ height: 30, width: 40, backgroundColor: memeherobgcolor.length == 0 ? '' : memeherobgcolor, position: 'relative' }} className="media_image__kN9DM" ></div>

                                          </div>


                                        </div>
                                        {isHeaderDesignText == false ? null :
                                          <div id='one' style={{ position: 'relative', }}>
                                            <SketchPicker color={memeherobgcolor} onChange={handelchangecolor}></SketchPicker></div>
                                        }
                                        <br />
                                        {/* <div className="media_preview__okAyH">
                                          <label>Cloud Image</label>
                                          <div className="media_value__\+4KKG ">
                                            {memecloudimage.length > 0 ?
                                              <div>
                                                {memecloudimage.map((image, index) => (
                                                  <img
                                                    onClick={() => handleCloudImage(0)}
                                                    key={index}
                                                    style={{ height: 30, width: 40 }}
                                                    src={image}
                                                    alt={`Image ${index}`}
                                                    className="media_image__kN9DM"
                                                  />
                                                ))}</div>
                                              :
                                              <img onClick={() => handleCloudImage(0)} style={{ height: 30, width: 40 }} src={memecloudimage.length > 0 ? memecloudimage : CloudImage} alt="" className="media_image__kN9DM" />
                                            }
                                          </div>
                                        </div>
                                        <br/> */}
                                        <div className="media_preview__okAyH">
                                          <label>Aminition Image</label>
                                          <div className="media_value__\+4KKG ">
                                            {memecoinAminitionImage.length > 0 ?
                                              <div>
                                                {memecoinAminitionImage.map((image, index) => (
                                                  <img
                                                    onClick={() => handleAminationImage(0)}
                                                    key={index}
                                                    style={{ height: 30, width: 40 }}
                                                    src={image}
                                                    alt={`Image ${index}`}
                                                    className="media_image__kN9DM"
                                                  />
                                                ))}</div>
                                              :
                                              <img onClick={() => handleAminationImage(0)} style={{ height: 30, width: 40 }} src={memecoinAminitionImage.length > 0 ? memecoinAminitionImage : cartoon} alt="" className="media_image__kN9DM" />
                                            }
                                          </div>
                                        </div>
                                        <br />
                                        <div className="media_preview__okAyH">
                                          <label>Left Image</label>
                                          <div className="media_value__\+4KKG ">
                                            {memecoinaboutleftimage.length > 0 ?
                                              <div>
                                                {memecoinaboutleftimage.map((image, index) => (
                                                  <img
                                                    onClick={() => handleLeftImage(0)}
                                                    key={index}
                                                    style={{ height: 30, width: 40 }}
                                                    src={image}
                                                    alt={`Image ${index}`}
                                                    className="media_image__kN9DM"
                                                  />
                                                ))}</div>
                                              :
                                              <img onClick={() => handleLeftImage(0)} style={{ height: 30, width: 40 }} src={memecoinaboutleftimage.length > 0 ? memecoinaboutleftimage : boomerleft} alt="" className="media_image__kN9DM" />
                                            }
                                          </div>
                                        </div>
                                        <br />
                                        <div className="media_preview__okAyH">
                                          <label>Right Image</label>
                                          <div className="media_value__\+4KKG ">
                                            {memecoinaboutrightimage.length > 0 ?
                                              <div>
                                                {memecoinaboutrightimage.map((image, index) => (
                                                  <img
                                                    onClick={() => handleRightImage(0)}
                                                    key={index}
                                                    style={{ height: 30, width: 40 }}
                                                    src={image}
                                                    alt={`Image ${index}`}
                                                    className="media_image__kN9DM"
                                                  />
                                                ))}</div>
                                              :
                                              <img onClick={() => handleRightImage(0)} style={{ height: 30, width: 40 }} src={memecoinaboutrightimage.length > 0 ? memecoinaboutrightimage : boomerright} alt="" className="media_image__kN9DM" />
                                            }
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                  </div>


                                </div>
                              </div>
                            </div>
                          </div>
                        </div>




                      </div>
                      : null}

                    {selectMemeCoinTemplates == 2 ?
                      <div className="editing_content__RXzxk">
                        <div className="form_form__Sku4X" >
                          <div className="form_form__Sku4X">
                            <div className="form_group__kDXNb">
                              <div className="form_form__Sku4X">
                                <div class="form_row__2+BJ3">

                                  <div className="form_group__kDXNb">
                                    <div className="form_form__Sku4X" style={{ maxWidth: "89%" }}>
                                      <div class="form_field__d31bq" >
                                        <span>Caption</span>
                                        <textarea placeholder="Caption" style={{ height: "32px" }} value={template1caption} onChange={(e) => HandelMemecoincaptionT1(e)} >
                                        </textarea>
                                      </div>

                                      <div class="form_field__d31bq" >
                                        <span>Meme Coin Name</span>
                                        <textarea placeholder="Name" style={{ height: "32px" }} value={memecoinname} onChange={(e) => HandelMemecoinName(e)}>
                                        </textarea>
                                      </div>
                                      <div class="form_field__d31bq" >
                                        <span>Meme Coin Symbol</span>
                                        <textarea placeholder="Symbol" style={{ height: "32px" }} value={memecoinsymbol} onChange={(e) => HandelMemecoinSymbol(e)} >
                                        </textarea>
                                      </div>
                                      <div class="form_field__d31bq" >
                                        <span>Info</span>
                                        <textarea placeholder="Value" style={{ height: "32px" }} value={template1Info} onChange={(e) => HandelMemecoinT1Info(e)} >
                                        </textarea>
                                      </div>


                                      <div class='mt-3'>
                                        <label>Images</label>
                                        <div className="media_preview__okAyH">
                                          <label>Background Image</label>
                                          <div className="media_value__\+4KKG ">
                                            {memecoinaboutbgImage.length > 0 ?
                                              <div>
                                                {memecoinaboutbgImage.map((image, index) => (
                                                  <img
                                                    onClick={() => { handleMainBackgroundImage(0); setMemeherobackground(0) }}
                                                    key={index}
                                                    style={{ height: 30, width: 40 }}
                                                    src={image}
                                                    alt={`Image ${index}`}
                                                    className="media_image__kN9DM"
                                                  />
                                                ))}</div>
                                              :
                                              <img onClick={() => { handleMainBackgroundImage(0); setMemeherobackground(0) }} style={{ height: 30, width: 40 }} src={memecoinaboutbgImage.length > 0 ? memecoinaboutbgImage : ''} alt="" className="media_image__kN9DM" />
                                            }
                                          </div>
                                        </div>

                                        <span >Background color</span>

                                        <div className="media_preview__okAyH" onClick={() => { handeltitle(); setMemeherobackground(1) }}>
                                          <label>Color</label>
                                          <div className="media_value__\+4KKG ">

                                            <div style={{ height: 30, width: 40, backgroundColor: memeherobgcolor.length == 0 ? '' : memeherobgcolor, position: 'relative' }} className="media_image__kN9DM" ></div>

                                          </div>


                                        </div>
                                        {isHeaderDesignText == false ? null :
                                          <div id='one' style={{ position: 'relative', }}>
                                            <SketchPicker color={memeherobgcolor} onChange={handelchangecolor}></SketchPicker></div>
                                        }
                                        <br />

                                        <br />

                                        <br />
                                        <div className="media_preview__okAyH">
                                          <label> Image</label>
                                          <div className="media_value__\+4KKG ">
                                            {memecoinaboutrightimage.length > 0 ?
                                              <div>
                                                {memecoinaboutrightimage.map((image, index) => (
                                                  <img
                                                    onClick={() => handleRightImage(0)}
                                                    key={index}
                                                    style={{ height: 30, width: 40 }}
                                                    src={image}
                                                    alt={`Image ${index}`}
                                                    className="media_image__kN9DM"
                                                  />
                                                ))}</div>
                                              :
                                              <img onClick={() => handleRightImage(0)} style={{ height: 30, width: 40 }} src={memecoinaboutrightimage.length > 0 ? memecoinaboutrightimage : ''} alt="" className="media_image__kN9DM" />
                                            }
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                  </div>


                                </div>
                              </div>
                            </div>
                          </div>
                        </div>




                      </div>
                      : null}

                    {selectMemeCoinTemplates == 3 ?
                      <div className="editing_content__RXzxk">
                        <div className="form_form__Sku4X" >
                          <div className="form_form__Sku4X">
                            <div className="form_group__kDXNb">
                              <div className="form_form__Sku4X">
                                <div class="form_row__2+BJ3">

                                  <div className="form_group__kDXNb">
                                    <div className="form_form__Sku4X" style={{ maxWidth: "89%" }}>
                                      <div class="form_field__d31bq" >
                                        <span>Caption</span>
                                        <textarea placeholder="Caption" style={{ height: "62px" }} value={template2caption} onChange={(e) => setTemplate2Caption(e.target.value)} >
                                        </textarea>
                                      </div>

                                      {/* <div class="form_field__d31bq" >
                                      <span>Meme Coin Name</span>
                                      <textarea placeholder="Name" style={{ height: "32px" }} value={memecoinname} onChange={(e) => HandelMemecoinName(e)}>
                                      </textarea>
                                    </div> */}
                                      <div class="form_field__d31bq" >
                                        <span>Meme Coin Symbol</span>
                                        <textarea placeholder="Symbol" style={{ height: "32px" }} value={memecoinsymbol} onChange={(e) => HandelMemecoinSymbol(e)} >
                                        </textarea>
                                        <textarea placeholder="Symbol" style={{ height: "32px" }} value={template2memecaption} onChange={(e) => setTemplate2memecaption(e.target.value)} >
                                        </textarea>
                                      </div>
                                      <div class="form_field__d31bq" >
                                        <span>Info</span>
                                        <textarea placeholder="Value" style={{ height: "62px" }} value={template2info} onChange={(e) => setTemplate2info(e.target.value)} >
                                        </textarea>
                                      </div>
                                      <br />
                                      <div class="form_field__d31bq" >
                                        <span>Address</span>
                                        <textarea placeholder="Value" style={{ height: "52px" }} value={template2tokenAddress} onChange={(e) => setTemplate2tokenAddress(e.target.value)} >
                                        </textarea>
                                      </div>

                                      <br />
                                      <div class="form_field__d31bq" >

                                        <span>Uniswap</span>
                                        <textarea placeholder="Value" style={{ height: "52px" }} value={template2uniswap} onChange={(e) => setTemplateuniswap(e.target.value)} >
                                        </textarea>

                                      </div>


                                      <br />
                                      <div class="form_field__d31bq" >
                                        <span>CMC</span>
                                        <textarea placeholder="Value" style={{ height: "52px" }} value={template2cmc} onChange={(e) => setTemplate2cmc(e.target.value)} >
                                        </textarea>
                                      </div>

                                      <br />
                                      <div class="form_field__d31bq" >
                                        <span>CoinGecko</span>
                                        <textarea placeholder="Value" style={{ height: "52px" }} value={template2coingecko} onChange={(e) => setTemplate2coingecko(e.target.value)} >
                                        </textarea>
                                      </div>



                                      <div class='mt-3'>
                                        <label>Images</label>
                                        <div className="media_preview__okAyH">
                                          <label>Background Image</label>
                                          <div className="media_value__\+4KKG ">
                                            {memecoinaboutbgImage.length > 0 ?
                                              <div>
                                                {memecoinaboutbgImage.map((image, index) => (
                                                  <img
                                                    onClick={() => { handleMainBackgroundImage(0); setMemeherobackground(1) }}
                                                    key={index}
                                                    style={{ height: 30, width: 40 }}
                                                    src={image}
                                                    alt={`Image ${index}`}
                                                    className="media_image__kN9DM"
                                                  />
                                                ))}</div>
                                              :
                                              <img onClick={() => { handleMainBackgroundImage(0); setMemeherobackground(1) }} style={{ height: 30, width: 40 }} src={memecoinaboutbgImage.length > 0 ? memecoinaboutbgImage : ''} alt="" className="media_image__kN9DM" />
                                            }
                                          </div>
                                        </div>

                                        <span >Background color</span>

                                        <div className="media_preview__okAyH" onClick={() => { handeltitle(); setMemeherobackground(0) }}>
                                          <label>Color</label>
                                          <div className="media_value__\+4KKG ">

                                            {/* <div  style={{ height: 30, width: 40,  backgroundColor: memeherobgcolor.length==0 ? '' :  memeherobgcolor,position:'relative' }}  className="media_image__kN9DM" ></div> */}

                                          </div>


                                        </div>
                                        {isHeaderDesignText == false ? null :
                                          <div id='one' style={{ position: 'relative', }}>
                                            <SketchPicker color={memeherobgcolor} onChange={handelchangecolor}></SketchPicker></div>
                                        }
                                        <br />

                                        <br />


                                      </div>
                                    </div>

                                  </div>


                                </div>
                                <h5>Remove Button</h5>
                                <div className='form_listItem__puOqW'  >
                                  <div class="form_headerHandle__33dpI" tabindex="0" role="button" aria-describedby="rbd-hidden-text-4-hidden-text-17" data-rbd-drag-handle-draggable-id="draggable-0" data-rbd-drag-handle-context-id="4" draggable="false"></div>
                                  <div className='form_headerRemove__mJ7no' >
                                    <svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 0 24 24" width="14" fill="currentColor"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"></path></svg>
                                  </div>

                                  <div className="form_form__Sku4X">
                                    <div className='form_row__2\+BJ3 form_hasMore__JC1Yq d-flex'>
                                      <div className='form_field__d31bq'>
                                        <textarea placeholder="" style={{ height: 32, padding: 5, resize: "none", overflowY: 'hidden' }}  >Uniswap</textarea>
                                      </div>

                                      <div class="form_more__6E9XQ" type="button" id="radix-:rhl:" aria-haspopup="menu" aria-expanded="false" data-state="closed" onClick={() => setTemplate2Remvebuttonuniswap(true)}><svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => setTemplate2Remvebuttonuniswap(true)}><path d="M8.625 2.5C8.625 3.12132 8.12132 3.625 7.5 3.625C6.87868 3.625 6.375 3.12132 6.375 2.5C6.375 1.87868 6.87868 1.375 7.5 1.375C8.12132 1.375 8.625 1.87868 8.625 2.5ZM8.625 7.5C8.625 8.12132 8.12132 8.625 7.5 8.625C6.87868 8.625 6.375 8.12132 6.375 7.5C6.375 6.87868 6.87868 6.375 7.5 6.375C8.12132 6.375 8.625 6.87868 8.625 7.5ZM7.5 13.625C8.12132 13.625 8.625 13.1213 8.625 12.5C8.625 11.8787 8.12132 11.375 7.5 11.375C6.87868 11.375 6.375 11.8787 6.375 12.5C6.375 13.1213 6.87868 13.625 7.5 13.625Z" fill="#8c8c8c" fill-rule="evenodd" clip-rule="evenodd"></path></svg></div>
                                    </div>

                                  </div>
                                </div>
                                <br />
                                <div className='form_listItem__puOqW'  >
                                  <div class="form_headerHandle__33dpI" tabindex="0" role="button" aria-describedby="rbd-hidden-text-4-hidden-text-17" data-rbd-drag-handle-draggable-id="draggable-0" data-rbd-drag-handle-context-id="4" draggable="false"></div>
                                  <div className='form_headerRemove__mJ7no' >
                                    <svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 0 24 24" width="14" fill="currentColor"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"></path></svg>
                                  </div>

                                  <div className="form_form__Sku4X">
                                    <div className='form_row__2\+BJ3 form_hasMore__JC1Yq d-flex'>
                                      <div className='form_field__d31bq'>
                                        <textarea placeholder="" style={{ height: 32, padding: 5, resize: "none", overflowY: 'hidden' }}  >CMC</textarea>
                                      </div>

                                      <div class="form_more__6E9XQ" type="button" id="radix-:rhl:" aria-haspopup="menu" aria-expanded="false" data-state="closed" onClick={() => setTemplate2Remvebuttonunicmc(true)}><svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => setTemplate2Remvebuttonunicmc(true)}><path d="M8.625 2.5C8.625 3.12132 8.12132 3.625 7.5 3.625C6.87868 3.625 6.375 3.12132 6.375 2.5C6.375 1.87868 6.87868 1.375 7.5 1.375C8.12132 1.375 8.625 1.87868 8.625 2.5ZM8.625 7.5C8.625 8.12132 8.12132 8.625 7.5 8.625C6.87868 8.625 6.375 8.12132 6.375 7.5C6.375 6.87868 6.87868 6.375 7.5 6.375C8.12132 6.375 8.625 6.87868 8.625 7.5ZM7.5 13.625C8.12132 13.625 8.625 13.1213 8.625 12.5C8.625 11.8787 8.12132 11.375 7.5 11.375C6.87868 11.375 6.375 11.8787 6.375 12.5C6.375 13.1213 6.87868 13.625 7.5 13.625Z" fill="#8c8c8c" fill-rule="evenodd" clip-rule="evenodd"></path></svg></div>
                                    </div>

                                  </div>
                                </div>
                                <br />
                                <div className='form_listItem__puOqW'  >
                                  <div class="form_headerHandle__33dpI" tabindex="0" role="button" aria-describedby="rbd-hidden-text-4-hidden-text-17" data-rbd-drag-handle-draggable-id="draggable-0" data-rbd-drag-handle-context-id="4" draggable="false"></div>
                                  <div className='form_headerRemove__mJ7no' >
                                    <svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 0 24 24" width="14" fill="currentColor"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"></path></svg>
                                  </div>

                                  <div className="form_form__Sku4X">
                                    <div className='form_row__2\+BJ3 form_hasMore__JC1Yq d-flex'>
                                      <div className='form_field__d31bq'>
                                        <textarea placeholder="" style={{ height: 32, padding: 5, resize: "none", overflowY: 'hidden' }}  >CoinGecko</textarea>
                                      </div>

                                      <div class="form_more__6E9XQ" type="button" id="radix-:rhl:" aria-haspopup="menu" aria-expanded="false" data-state="closed" onClick={() => setTemplate2Remvebuttoncoingecko(true)}><svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => setTemplate2Remvebuttoncoingecko(true)}><path d="M8.625 2.5C8.625 3.12132 8.12132 3.625 7.5 3.625C6.87868 3.625 6.375 3.12132 6.375 2.5C6.375 1.87868 6.87868 1.375 7.5 1.375C8.12132 1.375 8.625 1.87868 8.625 2.5ZM8.625 7.5C8.625 8.12132 8.12132 8.625 7.5 8.625C6.87868 8.625 6.375 8.12132 6.375 7.5C6.375 6.87868 6.87868 6.375 7.5 6.375C8.12132 6.375 8.625 6.87868 8.625 7.5ZM7.5 13.625C8.12132 13.625 8.625 13.1213 8.625 12.5C8.625 11.8787 8.12132 11.375 7.5 11.375C6.87868 11.375 6.375 11.8787 6.375 12.5C6.375 13.1213 6.87868 13.625 7.5 13.625Z" fill="#8c8c8c" fill-rule="evenodd" clip-rule="evenodd"></path></svg></div>
                                    </div>

                                  </div>
                                </div>


                              </div>
                            </div>
                          </div>
                        </div>




                      </div>
                      : null}

                    {selectMemeCoinTemplates == 4 ?
                      <div className="editing_content__RXzxk">
                        <div className="form_form__Sku4X" >
                          <div className="form_form__Sku4X">
                            <div className="form_group__kDXNb">
                              <div className="form_form__Sku4X">
                                <div class="form_row__2+BJ3">

                                  <div className="form_group__kDXNb">
                                    <div className="form_form__Sku4X" style={{ maxWidth: "89%" }}>

                                      <div class="form_field__d31bq" >
                                        <span>Telegram</span>
                                        <textarea placeholder="Telegram" style={{ height: "32px" }} value={template3telegramlink} onChange={(e) => setTemplate3telegramlink(e.target.value)} >
                                        </textarea>
                                      </div>
                                      <br></br>
                                      <div class="form_field__d31bq" >
                                        <span>Twitter</span>
                                        <textarea placeholder="Twitter" style={{ height: "32px" }} value={template3twitterlink} onChange={(e) => setTemplate3twitterlink(e.target.value)}  >
                                        </textarea>
                                      </div>
                                      <br />
                                      <div class="form_field__d31bq" >
                                        <span>Uniswap</span>
                                        <textarea placeholder="Uniswap" style={{ height: "32px" }} value={template3uniswaplink} onChange={(e) => setTemplate3uniswaplink(e.target.value)}  >
                                        </textarea>
                                      </div>
                                      <div class='mt-3'>
                                        <label>Images</label>
                                        <div className="media_preview__okAyH">
                                          <label>Background Image</label>
                                          <div className="media_value__\+4KKG ">
                                            {memecoinaboutbgImage.length > 0 ?
                                              <div>
                                                {memecoinaboutbgImage.map((image, index) => (
                                                  <img
                                                    onClick={() => { handleMainBackgroundImage(0); setMemeherobackground(0) }}
                                                    key={index}
                                                    style={{ height: 30, width: 40 }}
                                                    src={image}
                                                    alt={`Image ${index}`}
                                                    className="media_image__kN9DM"
                                                  />
                                                ))}</div>
                                              :
                                              <img onClick={() => { handleMainBackgroundImage(0); setMemeherobackground(0) }} style={{ height: 30, width: 40 }} src={memecoinaboutbgImage.length > 0 ? memecoinaboutbgImage : ''} alt="" className="media_image__kN9DM" />
                                            }
                                          </div>
                                        </div>

                                        <span >Background color</span>

                                        <div className="media_preview__okAyH" onClick={() => { handeltitle(); setMemeherobackground(1) }}>
                                          <label>Color</label>
                                          <div className="media_value__\+4KKG ">

                                            <div style={{ height: 30, width: 40, backgroundColor: memeherobgcolor.length == 0 ? '' : memeherobgcolor, position: 'relative' }} className="media_image__kN9DM" ></div>

                                          </div>


                                        </div>
                                        {isHeaderDesignText == false ? null :
                                          <div id='one' style={{ position: 'relative', }}>
                                            <SketchPicker color={memeherobgcolor} onChange={handelchangecolor}></SketchPicker></div>
                                        }
                                        <br />
                                        <div className="media_preview__okAyH">
                                          <label> Image</label>
                                          <div className="media_value__\+4KKG ">
                                            {memecoinAminitionImage.length > 0 ?
                                              <div>
                                                {memecoinAminitionImage.map((image, index) => (
                                                  <img
                                                    onClick={() => handleAminationImage(0)}
                                                    key={index}
                                                    style={{ height: 30, width: 40 }}
                                                    src={image}
                                                    alt={`Image ${index}`}
                                                    className="media_image__kN9DM"
                                                  />
                                                ))}</div>
                                              :
                                              <img onClick={() => handleAminationImage(0)} style={{ height: 30, width: 40 }} src={memecoinAminitionImage.length > 0 ? memecoinAminitionImage : ''} alt="" className="media_image__kN9DM" />
                                            }
                                          </div>
                                        </div>

                                      </div>
                                    </div>

                                  </div>


                                </div>
                              </div>
                            </div>
                          </div>
                        </div>




                      </div>
                      : null}

                        

                    {selectMemeCoinTemplates == 6?
                      <div className="editing_content__RXzxk">
                        <div className="form_form__Sku4X" >
                          <div className="form_form__Sku4X">
                            <div className="form_group__kDXNb">
                              <div className="form_form__Sku4X">
                                <div class="form_row__2+BJ3">

                                  <div className="form_group__kDXNb">
                                    <div className="form_form__Sku4X" style={{ maxWidth: "89%" }}>
                                      <div class="form_field__d31bq" >
                                        <span>Caption</span>
                                        <textarea placeholder="Caption" style={{ height: "32px" }} value={t4caption} onChange={(e) => setT4caption(e.target.value)} >
                                        </textarea>
                                      </div>
                                      <div class="form_field__d31bq" >
                                        <span>Contract Address</span>
                                        <textarea placeholder="Value" style={{ height: "fit-content" }} value={t4memeaddress} onChange={(e) => setT4memeadress(e.target.value)} >
                                        </textarea>
                                      </div>

                                      <div class="form_field__d31bq" >
                                        <span>Buy</span>
                                        <textarea placeholder="Value" style={{ height: "fit-content" }} value={t4mainbutton1} onChange={(e) => setT4mainbutton1(e.target.value)} >
                                        </textarea>
                                        <textarea placeholder="Link" style={{ height: "fit-content" }} value={template2coingecko} onChange={(e) => setTemplate2coingecko(e.target.value)} >
                                        </textarea>
                                      </div>
                                      
                                      <div class="form_field__d31bq" >
                                        <span>X</span>
                                        <textarea placeholder="Value" style={{ height: "fit-content" }} value={t4mainbutton2} onChange={(e) => setT4mainbutton2(e.target.value)} >
                                        </textarea>
                                        <textarea placeholder="Link" style={{ height: "fit-content" }} value={memetwitterlink} onChange={(e) => setMemetwitterlink(e.target.value)} >
                                        </textarea>
                                      </div>
                                      
                                      <div class='mt-3'>
                                        <label>Images</label>
                                        <div className="media_preview__okAyH">
                                          <label>Background Image</label>
                                          <div className="media_value__\+4KKG ">
                                            {memecoinaboutbgImage.length > 0 ?
                                              <div>
                                                {memecoinaboutbgImage.map((image, index) => (
                                                  <img
                                                    onClick={() => { handleMainBackgroundImage(0); setMemeherobackground(0) }}
                                                    key={index}
                                                    style={{ height: 30, width: 40 }}
                                                    src={image}
                                                    alt={`Image ${index}`}
                                                    className="media_image__kN9DM"
                                                  />
                                                ))}</div>
                                              :
                                              <img onClick={() => { handleMainBackgroundImage(0); setMemeherobackground(0) }} style={{ height: 30, width: 40 }} src={memecoinaboutbgImage.length > 0 ? memecoinaboutbgImage : ''} alt="" className="media_image__kN9DM" />
                                            }
                                          </div>
                                        </div>

                                        <span >Background color</span>

                                        <div className="media_preview__okAyH" onClick={() => { handeltitle(); setMemeherobackground(1) }}>
                                          <label>Color</label>
                                          <div className="media_value__\+4KKG ">

                                            <div style={{ height: 30, width: 40, backgroundColor: memeherobgcolor.length == 0 ? '' : memeherobgcolor, position: 'relative' }} className="media_image__kN9DM" ></div>

                                          </div>


                                        </div>
                                        {isHeaderDesignText == false ? null :
                                          <div id='one' style={{ position: 'relative', }}>
                                            <SketchPicker color={memeherobgcolor} onChange={handelchangecolor}></SketchPicker></div>
                                        }
                                        <br />

                                        <div className="media_preview__okAyH">
                                          <label>Meme Image</label>
                                          <div className="media_value__\+4KKG ">
                                            {memecoinAminitionImage.length > 0 ?
                                              <div>
                                                {memecoinAminitionImage.map((image, index) => (
                                                  <img
                                                    onClick={() => handleAminationImage(0)}
                                                    key={index}
                                                    style={{ height: 30, width: 40 }}
                                                    src={image}
                                                    alt={`Image ${index}`}
                                                    className="media_image__kN9DM"
                                                  />
                                                ))}</div>
                                              :
                                              <img onClick={() => handleAminationImage(0)} style={{ height: 30, width: 40 }} src={memecoinAminitionImage.length > 0 ? memecoinAminitionImage : ''} alt="" className="media_image__kN9DM" />
                                            }
                                          </div>
                                        </div>
                                       
                                        
                                      </div>
                                    </div>

                                  </div>


                                </div>
                              </div>
                            </div>
                          </div>
                        </div>




                      </div>
                      : null}
                         

                         {selectMemeCoinTemplates == 5 ?
                      <div className="editing_content__RXzxk">
                        <div className="form_form__Sku4X" >
                          <div className="form_form__Sku4X">
                            <div className="form_group__kDXNb">
                              <div className="form_form__Sku4X">
                                <div class="form_row__2+BJ3">

                                  <div className="form_group__kDXNb">
                                    <div className="form_form__Sku4X" style={{ maxWidth: "89%" }}>
                                      <div class="form_field__d31bq" >
                                        <span>Caption</span>
                                        <textarea placeholder="Caption" style={{ height: "fit-content" }} value={memecoinAbout} onChange={(e) => setMemecoinAbout(e.target.value)} >
                                        </textarea>
                                      </div>
                                      <br/>
                                      <div class="form_field__d31bq" >
                                        <span>Twitter</span>
                                        <textarea placeholder="Caption" style={{ height: "fit-content" }} value={memetwitterlink} onChange={(e) => setMemetwitterlink(e.target.value)} >
                                        </textarea>
                                      </div>
                                      <br/>
                                      <div class="form_field__d31bq" >
                                        <span>Telegram</span>
                                        <textarea placeholder="Caption" style={{ height: "fit-content" }} value={memetelegramlink} onChange={(e) => setMemetelegramlink(e.target.value)} >
                                        </textarea>
                                      </div>
                                      <br/>
                                      <div class="form_field__d31bq" >
                                        <span>Instagram</span>
                                        <textarea placeholder="Caption" style={{ height: "fit-content" }} value={memeinstagramlink} onChange={(e) => setMemeinstagramlink(e.target.value)} >
                                        </textarea>
                                      </div>
                                      
                                      <div class='mt-3'>
                                        <label>Images</label>
                                        <div className="media_preview__okAyH">
                                          <label>Background Image</label>
                                          <div className="media_value__\+4KKG ">
                                            {memecoinaboutbgImage.length > 0 ?
                                              <div>
                                                {memecoinaboutbgImage.map((image, index) => (
                                                  <img
                                                    onClick={() => { handleMainBackgroundImage(0); setMemeherobackground(1) }}
                                                    key={index}
                                                    style={{ height: 30, width: 40 }}
                                                    src={image}
                                                    alt={`Image ${index}`}
                                                    className="media_image__kN9DM"
                                                  />
                                                ))}</div>
                                              :
                                              <img onClick={() => { handleMainBackgroundImage(0); setMemeherobackground(1) }} style={{ height: 30, width: 40 }} src={memecoinaboutbgImage.length > 0 ? memecoinaboutbgImage : ''} alt="" className="media_image__kN9DM" />
                                            }
                                          </div>
                                        </div>

                                        <span >Background color</span>

                                        <div className="media_preview__okAyH" onClick={() => { handeltitle(); setMemeherobackground(0) }}>
                                          <label>Color</label>
                                          <div className="media_value__\+4KKG ">

                                            <div style={{ height: 30, width: 40, backgroundColor: memeherobgcolor.length == 0 ? '' : memeherobgcolor, position: 'relative' }} className="media_image__kN9DM" ></div>

                                          </div>


                                        </div>
                                        {isHeaderDesignText == false ? null :
                                          <div id='one' style={{ position: 'relative', }}>
                                            <SketchPicker color={memeherobgcolor} onChange={handelchangecolor}></SketchPicker></div>
                                        }
                                        <br />
                                       
                                        <div className="media_preview__okAyH">
                                          <label>Meme Image</label>
                                          <div className="media_value__\+4KKG ">
                                            {memecoinAminitionImage.length > 0 ?
                                              <div>
                                                {memecoinAminitionImage.map((image, index) => (
                                                  <img
                                                    onClick={() => handleAminationImage(0)}
                                                    key={index}
                                                    style={{ height: 30, width: 40 }}
                                                    src={image}
                                                    alt={`Image ${index}`}
                                                    className="media_image__kN9DM"
                                                  />
                                                ))}</div>
                                              :
                                              <img onClick={() => handleAminationImage(0)} style={{ height: 30, width: 40 }} src={memecoinAminitionImage.length > 0 ? memecoinAminitionImage : ''} alt="" className="media_image__kN9DM" />
                                            }
                                          </div>
                                        </div>
                                        <br />
                                        
                                      </div>
                                    </div>

                                  </div>


                                </div>
                              </div>
                            </div>
                          </div>
                        </div>




                      </div>
                      : null}



                  </div>
                  : <MemeMaindesing />}

              </div> : <div className='editing_wrapper__Wijfx h-100  overflow-auto'><Theme /></div>}
          </div>
          <div className=' h-100 overflow-auto editing_wrapper__Wijfxnew' style={{ width: 'calc(100% - 400px)' }}>
            <Memecoin />
          </div>
        </div>
      </div>




    </div>
  )
};

export default MemeHomeEdit