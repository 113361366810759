
import MyContext from "../context/MyContext";
import heroImg from "../assets/images/cofee.jpeg";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Generatewebsite } from "../Redux/CreateSlice";
const Hero = (props) => {




    const { inttrobtn1, setIntrobtn1,
        introbtn2, setIntrobtn2,
        menu1, swtmenu1,
        Section2, images,
        title, intro,
        menu, titlecolor,
        welcometilte, introcolor,
        introtext, intromenutextcolor,
        introbtn1remove, setIntrobtnremove1,
        HandelIntro, newIntroImage,intromenubgcolor,
        introdiv, setIntrodiv, selectedOption1,
        introbtn2remove, setIntrobtnremove2, 
        addintrobutton, introbuttonapprovel ,introcaption, 
        introtextfont,introinfotextfont, button1font,
        intromargintop,intromarginbottom,buttonborder,buttonborderradius,buttonbordercolor,butttonshowdo,
      conatcmenuborder,setcontactmenuborder,buttonvarticalpadding,setButtonvarticalpadding,
      buttonhorizantlepadding,setButtonhorizantlepadding, introImages,
      contenttextinfocolor,  captioncolor,buttoncolor,
      contenttextheadingcolor,mainnavbarbutton, contactheadingcolor,selectedHero} = useContext(MyContext);
    const [randomIndex, setRandomIndex] = useState();


    useEffect(() => {
        const randomIndex = Math.floor(Math.random() * 5);
        setRandomIndex(randomIndex);
    }, [images]);
    // const randomIndex = Math.floor(Math.random() * 5);
    const Imageupdate = images;

    console.log('Imageupdate', Imageupdate)
    const handelupdate = () => {
        return Imageupdate
    }

    useEffect(() => {
        handelupdate()
    }, [])
    console.log('herot', selectedHero)
    return (
        <div class="container" style={{ backgroundColor: `${introcolor}`,minWidth:'100%' }} onClick={Section2}>
           {selectedHero === null && (
           <div class="container" ref={Section2} style={{minWidth:'100%'}}>
                <div class="row" style={{ marginTop: `${intromargintop}px`, marginBottom: `${intromarginbottom}px` }}>
                    <div class="col-md-6 d-flex align-items-center flex-column justify-content-center">
                        <div>
                            {selectedOption1 == 'A' ?
                                <button class="mt-5 btn  menu" style={{ fontSize: 12, color: `${intromenutextcolor}`, backgroundColor: `2${intromenubgcolor}`,border:`2px solid ${conatcmenuborder}`,borderRadius:30 ,position:'relative',top:'15%',left:'-16rem' }}>{menu}--<span style={{color:`${contactheadingcolor}`}}>{menu1}</span></button>
                                :
                                <div>
                                    <button class="mt-5 btn  menu" style={{ fontSize: 12, borderStyle: 'none', color: `${intromenutextcolor}`, backgroundColor: `${intromenubgcolor}`,left:'-100%'  }}>{menu}</button>
                                    <button class="mt-5 btn  menu" style={{ fontSize: 12, borderStyle: 'none',left:'-100%'  }}>{menu1}</button>
                                </div>

                            }
                        </div>
                        <p style={{ color: '#9c36b5', position: "relative", }}>{introcaption}</p>
                        <h1 style={{ fontSize: 48, fontWeight: '780', color: `${contenttextheadingcolor}`, fontFamily: `${introtextfont}` }}> {title}</h1>
                        <p class="fs-0" style={{ color: `${contenttextinfocolor}`, fontFamily: `${introinfotextfont}` }}>
                            {intro}
                        </p>

                    </div>
                    <div class="col-md-6 p-4">
                        {newIntroImage.length > 0 ?
                            <div>
                                {newIntroImage.map((image, index) => (
                                    // eslint-disable-next-line jsx-a11y/img-redundant-alt
                                    <img
                                        style={{ height: 390, borderRadius: 1, width: '100%', borderRadius: '10px' }}
                                        key={index}
                                        src={image}
                                        alt={`Image ${index}`}
                                        className="media_image__kN9DM"
                                    />
                                ))}</div>
                            :
                            <img style={{ height: 390, borderRadius: 1, width: '100%', borderRadius: '10px' }} src={introImages} className="img-fluid shadow" alt="" />

                        }

                    </div>
                    <div class="twobutton" style={{ width: "100%", color: `${introtext}`, display: 'flex',gap:"0px 10px" }}>
                        <a href="#"><button class=" buttoncolor" style={{ display: introbtn1remove ? 'none' : '',fontFamily: `${button1font}`,backgroundColor: `${buttoncolor}` ,border:`${buttonborder}px solid ${buttonbordercolor}`,borderRadius:`${buttonborderradius}px`,boxShadow:`0px 0px ${butttonshowdo}px 0px rgba(21, 21, 25, 0.21)`,width:'100%',paddingTop:`${buttonvarticalpadding}px`,paddingBottom:`${buttonvarticalpadding}px`,paddingLeft:`${buttonhorizantlepadding}px`,paddingRight:`${buttonhorizantlepadding}px` }}>{inttrobtn1}</button></a>
                        <a href="#"><button class="buttoncolor" style={{ display: introbtn2remove ? 'none' : '',fontFamily: `${button1font}`, backgroundColor: `${buttoncolor}` ,border:`${buttonborder}px solid ${buttonbordercolor}`,borderRadius:`${buttonborderradius}px`,boxShadow:`0px 0px ${butttonshowdo}px 0px rgba(21, 21, 25, 0.21)`,width:"100%", paddingTop:`${buttonvarticalpadding}px`,paddingBottom:`${buttonvarticalpadding}px`,paddingLeft:`${buttonhorizantlepadding}px`,paddingRight:`${buttonhorizantlepadding}px`}}>{introbtn2}</button></a>
                        {addintrobutton.map((button, index) => (
                            <a href="#" key={index} style={{ display: introbuttonapprovel == false ? 'none' : '', }}><button class="buttoncolor"style={{fontFamily:`${button1font}`,backgroundColor:`${buttoncolor}`,border:`${buttonborder}px solid ${buttonbordercolor}`,borderRadius:`${buttonborderradius}px`,boxShadow:`0px 0px ${butttonshowdo}px 0px rgba(21, 21, 25, 0.21)`,width:"100%",paddingTop:`${buttonvarticalpadding}px`,paddingBottom:`${buttonvarticalpadding}px`,paddingLeft:`${buttonhorizantlepadding}px`,paddingRight:`${buttonhorizantlepadding}px` }} >{button}</button></a>

                        ))}
                    </div>

                </div>
            </div>
            )}
            {selectedHero === 0 && (
            <div class="container" ref={Section2}>
                <div class="row" style={{ marginTop: `${intromargintop}px`, marginBottom: `${intromarginbottom}px` }}>
                    <div class="col-md-6 d-flex align-items-center flex-column justify-content-center">
                        <div style={{ width: "100%", }}>
                        {selectedOption1 == 'A' ?
                                <button class="mt-5 btn  menu" style={{ fontSize: 12, color: `${intromenutextcolor}`, backgroundColor: `2${intromenubgcolor}`,border:`2px solid ${conatcmenuborder}`,borderRadius:30 ,left:'-100%' }}>{menu}--<span style={{color:`${contactheadingcolor}`}}>{menu1}</span></button>
                                :
                                <div>
                                    <button class="mt-5 btn  menu" style={{ fontSize: 12, borderStyle: 'none', color: `${intromenutextcolor}`, backgroundColor: `${intromenubgcolor}`,left:'-100%'  }}>{menu}</button>
                                    <button class="mt-5 btn  menu" style={{ fontSize: 12, borderStyle: 'none',left:'-100%'  }}>{menu1}</button>
                                </div>

                            }
                        </div>
                        <p style={{ color: captioncolor?`${captioncolor}`:'#9c36b5', position: "relative", }}>{introcaption}</p>
                        <h1 style={{ fontSize: 48, fontWeight: '780', color: `${contenttextheadingcolor}`, fontFamily: `${introtextfont}` }}> {title}</h1>
                        <p class="fs-0" style={{ color: `${contenttextinfocolor}`, fontFamily: `${introinfotextfont}` }}>
                            {intro}
                        </p>
                        <div class="twobutton" style={{ width: "100%", color: `${introtext}`, display: 'flex',gap:"0px 10px" }}>
                            <a href="#"><button class=" buttoncolor" style={{ display: introbtn1remove ? 'none' : '' ,fontFamily: `${button1font}`, backgroundColor: `${buttoncolor}`,border:`${buttonborder}px solid ${buttonbordercolor}`,borderRadius:`${buttonborderradius}px`,boxShadow:`0px 0px ${butttonshowdo}px 0px rgba(21, 21, 25, 0.21)`,width:'100%',paddingTop:`${buttonvarticalpadding}px`,paddingBottom:`${buttonvarticalpadding}px`,paddingLeft:`${buttonhorizantlepadding}px`,paddingRight:`${buttonhorizantlepadding}px`}}>{inttrobtn1}</button></a>
                            <a href="#"><button class="buttoncolor" style={{ display: introbtn2remove ? 'none' : '',fontFamily: `${button1font}`,backgroundColor: `${buttoncolor}` ,border:`${buttonborder}px solid ${buttonbordercolor}`,borderRadius:`${buttonborderradius}px`,boxShadow:`0px 0px ${butttonshowdo}px 0px rgba(21, 21, 25, 0.21)`,width:"100%", paddingTop:`${buttonvarticalpadding}px`,paddingBottom:`${buttonvarticalpadding}px`,paddingLeft:`${buttonhorizantlepadding}px`,paddingRight:`${buttonhorizantlepadding}px`}}>{introbtn2}</button></a>
                            {addintrobutton.map((button, index) => (
                                <a href="#" key={index} style={{ display: introbuttonapprovel == false ? 'none' : '',}}><button class="buttoncolor" style={{fontFamily:`${button1font}`,backgroundColor:`${buttoncolor}`,border:`${buttonborder}px solid ${buttonbordercolor}`,borderRadius:`${buttonborderradius}px`,boxShadow:`0px 0px ${butttonshowdo}px 0px rgba(21, 21, 25, 0.21)`,width:"100%",paddingTop:`${buttonvarticalpadding}px`,paddingBottom:`${buttonvarticalpadding}px`,paddingLeft:`${buttonhorizantlepadding}px`,paddingRight:`${buttonhorizantlepadding}px` }} >{button}</button></a>

                            ))}
                        </div>
                    </div>
                    <div class="col-md-6 p-4">
                        <div className="_f497df412">
                            {newIntroImage.length > 0 ?
                                <div>
                                    {newIntroImage.map((image, index) => (
                                        // eslint-disable-next-line jsx-a11y/img-redundant-alt
                                        <img
                                            key={index}
                                            src={image}
                                            alt={`Image ${index}`}
                                            className="_e51b713c"
                                        />
                                    ))}</div>
                                :
                                <img style={{ height: 500, borderRadius: '5px' }} src={introImages} className="img-fluid " alt="" />

                            }
                        </div>
                    </div>


                </div>
            </div>
            )}


            {selectedHero === 1 && (
            <div class="container" ref={Section2}>
                <div class="row" style={{ marginTop: `${intromargintop}px`, marginBottom: `${intromarginbottom}px` }}>
                    <div class="col-md-12 d-flex align-items-center flex-column justify-content-center">
                        <div className="text-center" style={{ width: "100%", }}>
                        {selectedOption1 == 'A' ?
                                <button class="mt-5 btn  menu" style={{ fontSize: 12, color: `${intromenutextcolor}`, backgroundColor: `2${intromenubgcolor}`,border:`2px solid ${conatcmenuborder}`,borderRadius:30 ,left:'-100%' }}>{menu}--<span style={{color:`${contactheadingcolor}`}}>{menu1}</span></button>
                                :
                                <div>
                                    <button class="mt-5 btn  menu" style={{ fontSize: 12, borderStyle: 'none', color: `${intromenutextcolor}`, backgroundColor: `${intromenubgcolor}`,left:'-100%'  }}>{menu}</button>
                                    <button class="mt-5 btn  menu" style={{ fontSize: 12, borderStyle: 'none',left:'-100%'  }}>{menu1}</button>
                                </div>

                            }
                        </div>
                        <p style={{ color: captioncolor?`${captioncolor}`:'#9c36b5', position: "relative", }}>{introcaption}</p>
                        <h1 style={{ fontSize: 48, fontWeight: '780', color: `${contenttextheadingcolor}`, fontFamily: `${introtextfont}` }}> {title}</h1>
                        <p class="fs-0" style={{ color: `${contenttextinfocolor}`, fontFamily: `${introinfotextfont}` }}>
                            {intro}
                        </p>

                        <div class="twobutton d-flex align-items-center justify-content-center" style={{ width: "100%", color: `${introtext}`, display: 'flex' ,gap:"0px 10px"}}>
                            <a href="#"><button class=" buttoncolor" style={{ display: introbtn1remove ? 'none' : '' ,fontFamily: `${button1font}`,backgroundColor: `${buttoncolor}`,border:`${buttonborder}px solid ${buttonbordercolor}`,borderRadius:`${buttonborderradius}px`,boxShadow:`0px 0px ${butttonshowdo}px 0px rgba(21, 21, 25, 0.21)`,width:`100%`,paddingTop:`${buttonvarticalpadding}px`,paddingBottom:`${buttonvarticalpadding}px`,paddingLeft:`${buttonhorizantlepadding}px`,paddingRight:`${buttonhorizantlepadding}px`}}>{inttrobtn1}</button></a>
                            <a href="#"><button class="buttoncolor" style={{ display: introbtn2remove ? 'none' : '' ,fontFamily: `${button1font}`,backgroundColor: `${buttoncolor}`,border:`${buttonborder}px solid ${buttonbordercolor}`,borderRadius:`${buttonborderradius}px`,boxShadow:`0px 0px ${butttonshowdo}px 0px rgba(21, 21, 25, 0.21)`,width:'100%',paddingTop:`${buttonvarticalpadding}px`,paddingBottom:`${buttonvarticalpadding}px`,paddingLeft:`${buttonhorizantlepadding}px`,paddingRight:`${buttonhorizantlepadding}px`}}>{introbtn2}</button></a>
                            {addintrobutton.map((button, index) => (
                                <a href="#" key={index} style={{ display: introbuttonapprovel == false ? 'none' : '' ,}}><button class="buttoncolor"style={{fontFamily:`${button1font}`,backgroundColor:`${buttoncolor}`,border:`${buttonborder}px solid ${buttonbordercolor}`,borderRadius:`${buttonborderradius}px`,boxShadow:`0px 0px ${butttonshowdo}px 0px rgba(21, 21, 25, 0.21)`,width:"100%",paddingTop:`${buttonvarticalpadding}px`,paddingBottom:`${buttonvarticalpadding}px`,paddingLeft:`${buttonhorizantlepadding}px`,paddingRight:`${buttonhorizantlepadding}px` }} >{button}</button></a>

                            ))}
                        </div>

                    </div>
                    <div class="col-md-12 p-4">
                        {newIntroImage.length > 0 ?
                            <div>
                                {newIntroImage.map((image, index) => (
                                    <img
                                        style={{ height: 390, borderRadius: 1, width: '100%', borderRadius: '10px' }}
                                        key={index}
                                        src={image}
                                        alt={`Image ${index}`}
                                        className="media_image__kN9DM"
                                    />
                                ))}</div>
                            :
                            <img style={{ height: 390, borderRadius: 1, width: '100%', borderRadius: '10px' }} src={introImages} className="img-fluid shadow" alt="" />

                        }

                    </div>
                </div>
            </div>
            )}



            {selectedHero === 2 && (
            <div class="container" ref={Section2}>
                <div class="row" style={{ marginTop: `${intromargintop}px`, marginBottom: `${intromarginbottom}px` }}>
                    <div class="col-md-6 d-flex align-items-center flex-column justify-content-center">
                        <div style={{ width: "100%", }}>
                        {selectedOption1 == 'A' ?
                                <button class="mt-5 btn  menu" style={{ fontSize: 12, color: `${intromenutextcolor}`, backgroundColor: `2${intromenubgcolor}`,border:`2px solid ${conatcmenuborder}`,borderRadius:30 ,left:'-100%' }}>{menu}--<span style={{color:`${contactheadingcolor}`}}>{menu1}</span></button>
                                :
                                <div>
                                    <button class="mt-5 btn  menu" style={{ fontSize: 12, borderStyle: 'none', color: `${intromenutextcolor}`, backgroundColor: `${intromenubgcolor}`,left:'-100%'  }}>{menu}</button>
                                    <button class="mt-5 btn  menu" style={{ fontSize: 12, borderStyle: 'none',left:'-100%'  }}>{menu1}</button>
                                </div>

                            }
                        </div>
                        <p style={{ color: captioncolor?`${captioncolor}`:'#9c36b5', position: "relative", }}>{introcaption}</p>
                        <h1 style={{ fontSize: 48, fontWeight: '780', color: `${contenttextheadingcolor}`, fontFamily: `${introtextfont}` }}> {title}</h1>
                        <p class="fs-0" style={{ color: `${contenttextinfocolor}`, fontFamily: `${introinfotextfont}` }}>
                            {intro}
                        </p>
                        <div class="twobutton" style={{ width: "100%", color: `${introtext}`, display: 'flex',gap:"0px 10px" }}>
                            <a href="#"><button class=" buttoncolor" style={{ display: introbtn1remove ? 'none' : '',fontFamily: `${button1font}` ,backgroundColor: `${buttoncolor}`,border:`${buttonborder}px solid ${buttonbordercolor}`,borderRadius:`${buttonborderradius}px`,boxShadow:`0px 0px ${butttonshowdo}px 0px rgba(21, 21, 25, 0.21)`,width:'100%',paddingTop:`${buttonvarticalpadding}px`,paddingBottom:`${buttonvarticalpadding}px`,paddingLeft:`${buttonhorizantlepadding}px`,paddingRight:`${buttonhorizantlepadding}px` }}>{inttrobtn1}</button></a>
                            <a href="#"><button class="buttoncolor" style={{ display: introbtn2remove ? 'none' : '',fontFamily: `${button1font}` ,backgroundColor: `${buttoncolor}` ,border:`${buttonborder}px solid ${buttonbordercolor}`,borderRadius:`${buttonborderradius}px`,boxShadow:`0px 0px ${butttonshowdo}px 0px rgba(21, 21, 25, 0.21)`,width:"100%",paddingTop:`${buttonvarticalpadding}px`,paddingBottom:`${buttonvarticalpadding}px`,paddingLeft:`${buttonhorizantlepadding}px`,paddingRight:`${buttonhorizantlepadding}px` }}>{introbtn2}</button></a>
                            {addintrobutton.map((button, index) => (
                                <a href="#" key={index} style={{ display: introbuttonapprovel == false ? 'none' : '' ,}}><button class="buttoncolor" style={{fontFamily:`${button1font}`,backgroundColor:`${buttoncolor}`,border:`${buttonborder}px solid ${buttonbordercolor}`,borderRadius:`${buttonborderradius}px`,boxShadow:`0px 0px ${butttonshowdo}px 0px rgba(21, 21, 25, 0.21)`,width:"100%",paddingTop:`${buttonvarticalpadding}px`,paddingBottom:`${buttonvarticalpadding}px`,paddingLeft:`${buttonhorizantlepadding}px`,paddingRight:`${buttonhorizantlepadding}px` }} >{button}</button></a>

                            ))}
                        </div>
                    </div>
                    <div class="col-md-6 p-4">
                        <div className="_f497df413">
                            {newIntroImage.length > 0 ?
                                <div>
                                    {newIntroImage.map((image, index) => (
                                        // eslint-disable-next-line jsx-a11y/img-redundant-alt
                                        <img
                                            key={index}
                                            src={image}
                                            alt={`Image ${index}`}
                                            className="_e51b713c"
                                        />
                                    ))}</div>
                                :
                                <img style={{ height: 500 }} src={introImages} className="img-fluid " alt="" />

                            }
                        </div>
                    </div>


                </div>
            </div>
            )}
       
            {selectedHero === 3 && (
            <div class="container py-0" style={{ marginTop: "11px" }} ref={Section2}>
                <div class="row" style={{ marginTop: `${intromargintop}px`, marginBottom: `${intromarginbottom}px` }}>
                    <div class="col-md-6 d-flex align-items-center flex-column justify-content-center">
                        <div style={{ width: "100%", }}>
                        {selectedOption1 == 'A' ?
                                <button class="mt-5 btn  menu" style={{ fontSize: 12, color: `${intromenutextcolor}`, backgroundColor: `2${intromenubgcolor}`,border:`2px solid ${conatcmenuborder}`,borderRadius:30 ,left:'-100%' }}>{menu}--<span style={{color:`${contactheadingcolor}`}}>{menu1}</span></button>
                                :
                                <div>
                                    <button class="mt-5 btn  menu" style={{ fontSize: 12, borderStyle: 'none', color: `${intromenutextcolor}`, backgroundColor: `${intromenubgcolor}`,left:'-100%'  }}>{menu}</button>
                                    <button class="mt-5 btn  menu" style={{ fontSize: 12, borderStyle: 'none',left:'-100%'  }}>{menu1}</button>
                                </div>

                            }
                        </div>
                        <p style={{ color: captioncolor?`${captioncolor}`:'#9c36b5', position: "relative", }}>{introcaption}</p>
                        <h1 style={{ fontSize: 48, fontWeight: '780', color: `${contenttextheadingcolor}`, fontFamily: `${introtextfont}` }}> {title}</h1>
                        <p class="fs-0" style={{ color: `${contenttextinfocolor}`, fontFamily: `${introinfotextfont}` }}>
                            {intro}
                        </p>
                        <div class="twobutton" style={{ width: "100%", color: `${introtext}`, display: 'flex',gap:"0px 10px" }}>
                            <a href="#"><button class=" buttoncolor" style={{ display: introbtn1remove ? 'none' : '',fontFamily: `${button1font}`,backgroundColor: `${buttoncolor}`,border:`${buttonborder}px solid ${buttonbordercolor}`,borderRadius:`${buttonborderradius}px`,boxShadow:`0px 0px ${butttonshowdo}px 0px rgba(21, 21, 25, 0.21)`,width:'100%',paddingTop:`${buttonvarticalpadding}px`,paddingBottom:`${buttonvarticalpadding}px`,paddingLeft:`${buttonhorizantlepadding}px`,paddingRight:`${buttonhorizantlepadding}px`  }}>{inttrobtn1}</button></a>
                            <a href="#"><button class="buttoncolor" style={{ display: introbtn2remove ? 'none' : '' ,fontFamily: `${button1font}`,backgroundColor: `${buttoncolor}`,border:`${buttonborder}px solid ${buttonbordercolor}`,borderRadius:`${buttonborderradius}px`,boxShadow:`0px 0px ${butttonshowdo}px 0px rgba(21, 21, 25, 0.21)`,width:'100%',paddingTop:`${buttonvarticalpadding}px`,paddingBottom:`${buttonvarticalpadding}px`,paddingLeft:`${buttonhorizantlepadding}px`,paddingRight:`${buttonhorizantlepadding}px`}}>{introbtn2}</button></a>
                            {addintrobutton.map((button, index) => (
                                <a href="#" key={index} style={{ display: introbuttonapprovel == false ? 'none' : '' ,}}><button class="buttoncolor" style={{fontFamily:`${button1font}`,backgroundColor:`${buttoncolor}`,border:`${buttonborder}px solid ${buttonbordercolor}`,borderRadius:`${buttonborderradius}px`,boxShadow:`0px 0px ${butttonshowdo}px 0px rgba(21, 21, 25, 0.21)`,width:"100%" ,paddingTop:`${buttonvarticalpadding}px`,paddingBottom:`${buttonvarticalpadding}px`,paddingLeft:`${buttonhorizantlepadding}px`,paddingRight:`${buttonhorizantlepadding}px` }} >{button}</button></a>

                            ))}
                        </div>
                    </div>
                    <div class="col-md-6 ">
                        <div className="_f497df41">
                            {newIntroImage.length > 0 ?
                                <div>
                                    {newIntroImage.map((image, index) => (
                                        // eslint-disable-next-line jsx-a11y/img-redundant-alt
                                        <img
                                            class='_e51b713c '
                                            key={index}
                                            src={image}
                                            alt={`Image ${index}`}

                                        />
                                    ))}</div>
                                :
                                <img style={{ height: 400, borderRadius: 1, width: '100%' }} src={introImages} className="img-fluid " alt="" />

                            }
                        </div>

                    </div>


                </div>
            </div>
            )}

            {selectedHero === 4 && (
            <div class="container" ref={Section2}>
                <div class="row" style={{ marginTop: `${intromargintop}px`, marginBottom: `${intromarginbottom}px` }}>
                    <div class="col-md-6 d-flex align-items-center flex-column justify-content-center">
                        <div style={{ width: "100%", }}>
                        {selectedOption1 == 'A' ?
                                <button class="mt-5 btn  menu" style={{ fontSize: 12, color: `${intromenutextcolor}`, backgroundColor: `2${intromenubgcolor}`,border:`2px solid ${conatcmenuborder}`,borderRadius:30 ,left:'-100%' }}>{menu}--<span style={{color:`${contactheadingcolor}`}}>{menu1}</span></button>
                                :
                                <div>
                                    <button class="mt-5 btn  menu" style={{ fontSize: 12, borderStyle: 'none', color: `${intromenutextcolor}`, backgroundColor: `${intromenubgcolor}`,left:'-100%'  }}>{menu}</button>
                                    <button class="mt-5 btn  menu" style={{ fontSize: 12, borderStyle: 'none',left:'-100%'  }}>{menu1}</button>
                                </div>

                            }
                        </div>
                        <p style={{ color: captioncolor?`${captioncolor}`:'#9c36b5', position: "relative", }}>{introcaption}</p>
                        <h1 style={{ fontSize: 48, fontWeight: '780', color: `${contenttextheadingcolor}`, fontFamily: `${introtextfont}` }}> {title}</h1>
                        <p class="fs-0" style={{ color: `${contenttextinfocolor}`, fontFamily: `${introinfotextfont}` }}>
                            {intro}
                        </p>
                        <div class="twobutton" style={{ width: "100%", color: `${introtext}`, display: 'flex',gap:"0px 10px" }}>
                            <a href="#"><button class=" buttoncolor" style={{ display: introbtn1remove ? 'none' : '',fontFamily: `${button1font}`,backgroundColor: `${buttoncolor}` ,border:`${buttonborder}px solid ${buttonbordercolor}`,borderRadius:`${buttonborderradius}px`,boxShadow:`0px 0px ${butttonshowdo}px 0px rgba(21, 21, 25, 0.21)`,width:"100%" ,paddingTop:`${buttonvarticalpadding}px`,paddingBottom:`${buttonvarticalpadding}px`,paddingLeft:`${buttonhorizantlepadding}px`,paddingRight:`${buttonhorizantlepadding}px`}}>{inttrobtn1}</button></a>
                            <a href="#"><button class="buttoncolor" style={{ display: introbtn2remove ? 'none' : '' ,fontFamily: `${button1font}`,backgroundColor: `${buttoncolor}`,border:`${buttonborder}px solid ${buttonbordercolor}`,borderRadius:`${buttonborderradius}px`,boxShadow:`0px 0px ${butttonshowdo}px 0px rgba(21, 21, 25, 0.21)`,paddingTop:`${buttonvarticalpadding}px`,paddingBottom:`${buttonvarticalpadding}px`,paddingLeft:`${buttonhorizantlepadding}px`,paddingRight:`${buttonhorizantlepadding}px`}}>{introbtn2}</button></a>
                            {addintrobutton.map((button, index) => (
                                <a href="#" key={index} style={{ display: introbuttonapprovel == false ? 'none' : '',}}><button class="buttoncolor" style={{fontFamily:`${button1font}`,backgroundColor:`${buttoncolor}`,border:`${buttonborder}px solid ${buttonbordercolor}`,borderRadius:`${buttonborderradius}px`,boxShadow:`0px 0px ${butttonshowdo}px 0px rgba(21, 21, 25, 0.21)`,width:"100%",paddingTop:`${buttonvarticalpadding}px`,paddingBottom:`${buttonvarticalpadding}px`,paddingLeft:`${buttonhorizantlepadding}px`,paddingRight:`${buttonhorizantlepadding}px` }} >{button}</button></a>

                            ))}
                        </div>
                    </div>
                    <div class="col-md-6 ">
                        <div className="_f497df414">
                            {newIntroImage.length > 0 ?
                                <div>
                                    {newIntroImage.map((image, index) => (
                                        // eslint-disable-next-line jsx-a11y/img-redundant-alt
                                        <img
                                            key={index}
                                            src={image}
                                            alt={`Image ${index}`}
                                            className="_e51b713c"
                                        />
                                    ))}</div>
                                :
                                <img style={{ height: 500 }} src={introImages} className="img-fluid " alt="" />

                            }
                        </div>
                    </div>


                </div>
            </div>
            )}
        </div>
    );
};

export default Hero