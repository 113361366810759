import React, { useEffect, useState } from 'react'
import { useContext } from 'react';
import MyContext from '../context/MyContext';
import { SketchPicker } from 'react-color';

const DesignShadow = ({ closeShadowPopup }) => {
    const [showShadowValues, setShowShadowValues] = useState(false);
    const [isDragging, setIsDragging] = useState(false);
    const [offsetX, setOffsetX] = useState(0);
    const [offsetY, setOffsetY] = useState(0);
    const [isOpen,setIsopen]=useState(false);
      
      const {  showdowrange,setShowdorange, 
         showdovalue,setshowdovalue,
        butttonshowdo,setButtonshowdo,section1,mainnavbarbutton,
        buttonshowdowcolor,setButtonshowdocolor,
      buttonshowdospread,setButtonshowdospread,
      buttonshowdoleft,setButtonshowdoleft,
      buttonshowdoTop,setButtonshowdoTop,
      buttonshowdoblur,setButtonshowdoblur,
        }=useContext(MyContext)
    
    const handleMouseDown = (e) => {
        setIsDragging(true);
        setOffsetX(e.clientX - e.target.getBoundingClientRect().left);
        setOffsetY(e.clientY - e.target.getBoundingClientRect().top);
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    const handleMouseMove = (e) => {
        if (isDragging) {
            e.preventDefault();
            const popup = document.querySelector('.popup');
            popup.style.left = e.clientX - offsetX + 'px';
            popup.style.top = e.clientY - offsetY + 'px';
        }
    };
    const Handelshowdo=(e)=>{
       const  showdo=e.target.value;
         setShowdorange(showdo)

    };
    const Handelbuttonshowdo=(e)=>{
       const showdo=e.target.value;
        setButtonshowdo(showdo);
        };

    const HandelRange=(e)=>{

        if((section1==true)&&(mainnavbarbutton==false)){
            Handelshowdo(e);
            setshowdovalue(e.target.value);
    };

    if(mainnavbarbutton==true){
        Handelbuttonshowdo(e);
        setshowdovalue(e.target.value);
        
    };
}
   useEffect(() => {
      setshowdovalue(0) ;  
   },[]);
//    useEffect(() => {
//         setsection1(false)
//    },[]);
    console.log('range',showdowrange,butttonshowdo,section1,mainnavbarbutton);
  
   const Handelbuttonshowdotop=(e)=>{
       setButtonshowdoTop(e.target.value)
   };
   const HandelbuttonshowdoLeft=(e)=>{
    setButtonshowdoleft(e.target.value)
}; 
  const Handelbuttonshowdospread=(e)=>{
     setButtonshowdospread(e.target.value)
  };
  const Handelbuttonshowdoblur=(e)=>{
    setButtonshowdoblur(e.target.value)
  };
    useEffect(()=>{
        if(showdovalue<=8){
              setButtonshowdoTop(1);
              setButtonshowdoblur(15);
              setButtonshowdospread(0);
              setButtonshowdoleft(0);
              setButtonshowdocolor('rgba(21, 21, 25, 0.21)')
        };
      
    },[showdovalue]);
    const Handelbuttonshowdocolor=(showdocolor)=>{
        setButtonshowdocolor(showdocolor.hex)
    };

    return (
        <>
            <div className="popup" onMouseUp={handleMouseUp} onMouseMove={handleMouseMove}
                style={{ position: 'fixed', left: '40%', top: '50%', transform: 'translate(-50%, -50%)', zIndex: 999, background: '#fff', borderRadius: '7px', boxShadow: '0 4px 30px #0000001a, 0 4px 10px #00000012', cursor: isDragging ? 'grabbing' : 'context-menu', width: '320px' }} >
                <header class="overlayHeader" onMouseDown={handleMouseDown} style={{ cursor: 'drag' }}>
                    <span class="">Shadows</span>
                    <button class="overlay_clear__EdOFC">Clear</button>
                    <button onClick={closeShadowPopup} class="button button--small button--clear overlay_overlayClose__GxQ0k">
                        <svg xmlns="http://www.w3.org/2000/svg" height="16" viewBox="0 0 24 24" width="16" fill="currentColor"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"></path>
                        </svg>
                    </button>
                </header>
                <div className='shadow_container__AyT9K'>
                    <div className='shadow_slider__GEWDd'>
                        <label>Magic Shadows</label>
                        {/* <input type='range' min={0} max={10}></input>
                        <div className='slider_container__29ImU'>
                            <div className='slider_SliderRoot__vjUOH' >
                                <span class="slider_SliderRoot__vjUOH" >
                                    <span d class="slider_SliderTrack__2UXOU">
                                        <span d class="slider_SliderRange__CooOM" style={{ left: "0%", right: "100%" }}></span>
                                    </span>
                                    <span style={{ transform: "translateX(-50%)", position: "absolute", left: " calc(0% + 8px)" }}>
                                        <span class="slider_SliderThumb__pa1re"  ></span>
                                    </span>
                                </span>
                            </div>

                        </div> */}
                        <div className='slider_container__29ImU' style={{width:'100%'}}>
                            <div className='slider_SliderRoot__vjUOH' style={{width:'100%'}}>
                                {/* <span className="slider_SliderRoot__vjUOH">
                                    <span className="slider_SliderTrack__2UXOU">
                                        <span className="slider_SliderRange__CooOM" style={{ left: "0%", right: "100%" }}></span>
                                    </span>
                                    <span style={{ transform: "translateX(-50%)", position: "absolute", left: "calc(0% + 8px)" }}>
                                        <span className="slider_SliderThumb__pa1re"></span>
                                    </span>
                                </span> */}
                                <input  style={{width:'100%',height:60}}  value={showdovalue} type='range' className='slider_SliderInput__abcdef' min={0} max={8}  onChange={(e)=>HandelRange(e)}></input>
                            </div>
                        </div>

                    </div>
                    <button className='detail_openBtn__x6SYz' onClick={() => setShowShadowValues(!showShadowValues)}>
                        <span>Fine-Tune Shadows</span>
                        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24" fill="currentColor"><path d="M24 24H0V0h24v24z" fill="none" opacity=".87"></path><path d="M15.88 9.29L12 13.17 8.12 9.29c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41l4.59 4.59c.39.39 1.02.39 1.41 0l4.59-4.59c.39-.39.39-1.02 0-1.41-.39-.38-1.03-.39-1.42 0z"></path></svg>
                    </button>
                    {showShadowValues && (<div className='shadow_values__xp2Js'>
                        <ul className='shadow_shadows__59Hq5'>
                            <li class="shadow_header__MMkCy">
                                <span>Left</span>
                                <span>Top</span>
                                <span>Blur</span>
                                <span>Spread</span>
                                <span>Color</span>
                            </li>
                            <li class="shadow_shadow__1dKUp">
                                <input type="number" placeholder="0" value={buttonshowdoleft} onChange={(e)=>HandelbuttonshowdoLeft(e)} />
                                <input type="number" placeholder="0" value={buttonshowdoTop} onChange={(e)=>Handelbuttonshowdotop(e)}/>
                                <input type="number" placeholder="0" value={buttonshowdoblur} onChange={(e)=>Handelbuttonshowdoblur(e)}/>
                                <input type="number" placeholder="0" value={buttonshowdospread} onChange={(e)=>Handelbuttonshowdospread(e)} />
                                   {/* <div className='showdobutton'> */}
                                    {/* <SketchPicker></SketchPicker> */}
                                   {/* </div> */}
                                  {/* <div class="colors_swatch__gosbA tooltip">
                                    
                                        
                                        </div> */}
                                <input onClick={()=>setIsopen(!isOpen)} type="text"   style={{width:30,position:"relative",left:10,backgroundColor:`${buttonshowdowcolor}`}} />
                               {isOpen==true?<SketchPicker color={buttonshowdowcolor} onChange={Handelbuttonshowdocolor}/>:null} 

                                <button class="shadow_delete__-Poz0">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14" width="14" height="14"><path fill="currentColor" d="M13.06.832H9.485V.66C9.485.1,9.023,0,8.459,0H5.522C4.958,0,4.5.1,4.5.66V.832H.939a.608.608,0,0,0-.609.609V2.617a.609.609,0,0,0,.609.609H13.06a.609.609,0,0,0,.609-.609V1.441A.609.609,0,0,0,13.06.832Z"></path><path fill="currentColor" d="M11.782,4.844H2.217a.61.61,0,0,0-.6.685l.981,7.422A1.2,1.2,0,0,0,3.781,14h6.437a1.2,1.2,0,0,0,1.188-1.049l.98-7.422A.609.609,0,0,0,11.782,4.844Z"></path></svg>
                                </button>
                            </li>
                        </ul>
                    </div>)}
                </div>
            </div>


        </>
    )
}

export default DesignShadow