import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import MyContext from '../context/MyContext';
import { TbClipboardSmile } from 'react-icons/tb';

function Button() {
  const { faq, title, searchTerm, setSearchTerm, serachtearm1, setSearchteam, setRandomId, memecoinAbout, memecoin,randomId,setUniqueID,setMemecoinsection } = useContext(MyContext);
  const [progress, setProgress] = useState(0);
  const [progressmeme, setProgressmeme] = useState(0);


  useEffect(() => {
    const interval = setInterval(() => {
      // Simulate progress update
      setProgress(prevProgress => {
        if (prevProgress == 100) {

          // clearInterval(interval);
          return 100;
        }
        return prevProgress + 1;
      });
    }, 182.9);
    if ((faq != '') || (progress == 100)) {
      clearInterval(interval);

    }

    return () => clearInterval(interval);
  }, [faq]);

  useEffect(() => {
    const interval = setInterval(() => {
      // Simulate progress update
      setProgressmeme(prevProgressmeme => {
        if (prevProgressmeme == 100) {

          // clearInterval(interval);
          return 100;
        }
        return prevProgressmeme + 1;
      });
    }, 182.9);
    if ((memecoinAbout != '') || (progressmeme == 100)) {
      clearInterval(interval);

    }

    return () => clearInterval(interval);
  }, [memecoinAbout]);
  console.log(serachtearm1, 'serach');

  function generateRandomId() {
    const randid = Math.random().toString(36).substring(2);
    setRandomId(randid)
    console.log('ID',randid);
    setUniqueID(randid);

  }

    useEffect(()=>{
      setMemecoinsection(0)
    },[]);
    console.log('Buttun',)

  return (

    <div>
      <div className='d-flex align-items-center justify-content-between  container' style={{minWidth:"100%"}}>
        {memecoin == 'false' ?
          <div>

            {
              faq == '' && progress < 99 ?
                <div class="text-center" >
                  <span>{serachtearm1} {progress}% </span>

                  <div class="spinner-border border-1" role="status" style={{ height: 17, width: 16, marginLeft: 3 }}>
                  </div>
                </div> : <div style={{ display: 'none' }}></div>

            }
          </div> : <div>

            {
              memecoinAbout == '' && progress < 99 ?
                <div class="text-center" >
                  <span>{serachtearm1} {progressmeme}% </span>

                  <div class="spinner-border border-1" role="status" style={{ height: 17, width: 16, marginLeft: 3 }}>
                  </div>
                </div> : <div style={{ display: 'none' }}></div>

            }
          </div>}
        <div>
          
          {memecoin=='false'?
          <Link to='/save&edit' style={{ textDecorationLine: 'none' }}>
            {faq == '' ? <button disabled className="border-0" style={{ height: 40, textDecoration: 'none', padding: '0px 15px 0px 15px', color: 'white', background: 'rgba(94, 34, 190,0.3) ', borderRadius: 10, fontSize: 16 }}>Save & Edit  <FontAwesomeIcon icon={faArrowRight} />  </button>
              : <button className="border-0" style={{ height: 40, textDecoration: 'none', padding: '0px 15px 0px 15px', color: 'white', background: 'rgb(94, 34, 190) ', borderRadius: 10, fontSize: 16 }} onClick={()=>{generateRandomId();setMemecoinsection(7)}}>Save & Edit  <FontAwesomeIcon icon={faArrowRight} />  </button>}

          </Link>:<Link to='/save&edit' style={{ textDecorationLine: 'none' }}>
            {memecoinAbout == '' ? <button disabled className="border-0" style={{ height: 40, textDecoration: 'none', padding: '0px 15px 0px 15px', color: 'white', background: 'rgba(94, 34, 190,0.3) ', borderRadius: 10, fontSize: 16 }}>Save & Edit  <FontAwesomeIcon icon={faArrowRight} />  </button>
              : <button className="border-0" style={{ height: 40, textDecoration: 'none', padding: '0px 15px 0px 15px', color: 'white', background: 'rgb(94, 34, 190) ', borderRadius: 10, fontSize: 16 ,}} onClick={()=>{generateRandomId();setMemecoinsection(7)}}>Save & Edit  <FontAwesomeIcon icon={faArrowRight} />  </button>}

          </Link>}
        </div>
      </div>
    </div>
  )
};

export default Button