import React, { useContext } from "react";

import Img1 from '../assets/images/img1.svg'
import Img2 from '../assets/images/img2.svg'
import Img3 from '../assets/images/img3.svg'
import Img4 from '../assets/images/img4.svg'
import Img5 from '../assets/images/img5.svg'
import Img6 from '../assets/images/img6.svg'
import Img7 from '../assets/images/img7.svg'
import Img8 from '../assets/images/img8.svg'
import Img9 from '../assets/images/img9.svg'
import Img10 from '../assets/images/img10.svg'
import Img11 from '../assets/images/img11.svg'
import MyContext from "../context/MyContext";

const TrustedBy = () => {
    const { trusttext, settrusttextcolor,
        trustcolor, settrustcolor,
        trust, settrust, trustCaption,
        trsuttitle, settrusttile,
        removelogos, setRemovelogos,
        trustLogo, settTrustlogos,
        newtrustlogo, selectedImage,
        trusttitletextfont, trusttextfont,
        trustmargintop, trustmarginbottom,
        contenttextheadingcolor, mainnavbarbutton, contenttextinfocolor,
        captioncolor, selectedLogo, setSelectedLogo,
    } = useContext(MyContext);
    console.log('trsutt', trustmargintop, trustmarginbottom)
    return (
        <div class="container" style={{ backgroundColor: `${trustcolor}`, minWidth: '100%' ,padding:'50px',position:'relative',top:'-2px',paddingTop:'0'}}>

            {selectedLogo === null && (
            <div class="container" style={{ backgroundColor: `${trustcolor}`, }}>
                <div style={{ marginTop: `${trustmargintop}px`, marginBottom: `${trustmarginbottom}px` }}>
                    <p class="text-center" style={{ color: '#9c36b5' }}>{trustCaption}</p>
                    <h2 class="text-center heading" style={{ color: `${contenttextheadingcolor}`, fontFamily: `${trusttextfont}` }}>{trust}</h2>
                    <p class="text-center  " style={{ color: `${contenttextinfocolor}`, fontFamily: `${trusttitletextfont}` }}>
                        {trsuttitle}
                    </p>
                    <div class="scrolling-text" id="scrollingText">

                        {trustLogo.map((Logos, index) => (
                            <img src={Logos} alt="logos"></img>
                        ))}

                        {newtrustlogo.map((Logos, index) => (
                            <img src={Logos} alt="logos" style={{ width: 100, height: 40 }}></img>
                        ))}

                    </div>
                </div>
            </div>
            )}
            {selectedLogo === 0 && (
            <div class="container" style={{ backgroundColor: `${trustcolor}`, }}>
                <div style={{ marginTop: `${trustmargintop}px`, marginBottom: `${trustmarginbottom}px` }}>
                    <p class="text-center" style={{ color: '#9c36b5' }}>{trustCaption}</p>
                    <h2 class="text-center heading" style={{ color: `${contenttextheadingcolor}`, fontFamily: `${trusttextfont}` }}>{trust}</h2>
                    <p class="text-center  " style={{ color: `${contenttextinfocolor}`, fontFamily: `${trusttitletextfont}` }}>
                        {trsuttitle}
                    </p>
                    <div class="scrolling-text" id="scrollingText">

                        {trustLogo.map((Logos, index) => (
                            <img src={Logos} style={{ height: '24px', width: '150px' }} alt="logos"></img>
                        ))}

                        {newtrustlogo.map((Logos, index) => (
                            <img src={Logos} alt="logos" style={{ height: '24px', width: '150px' }}></img>
                        ))}

                    </div>
                </div>
            </div>
            )}


            {selectedLogo === 1 && (
            <div class="container" style={{ backgroundColor: `${trustcolor}`, }}>
                <div style={{ marginTop: `${trustmargintop}px`, marginBottom: `${trustmarginbottom}px` }}>
                    <p class="text-center" style={{ color: '#9c36b5' }}>{trustCaption}</p>
                    <h2 class="text-center heading" style={{ color: `${contenttextheadingcolor}`, fontFamily: `${trusttextfont}` }}>{trust}</h2>
                    <p class="text-center  " style={{ color: `${contenttextinfocolor}`, fontFamily: `${trusttitletextfont}` }}>
                        {trsuttitle}
                    </p>
                    <div className="text-center" id="scrollingText">

                        {trustLogo.map((Logos, index) => (
                            <img src={Logos} style={{ height: '24px', width: '150px' }} alt="logos"></img>
                        ))}

                        {newtrustlogo.map((Logos, index) => (
                            <img src={Logos} alt="logos" style={{ height: '24px', width: '150px' }}></img>
                        ))}

                    </div>
                </div>
            </div>
            )}
            {selectedLogo === 2 && (
            <div class="container" style={{ backgroundColor: `${trustcolor}`, }}>
                <div style={{ marginTop: `${trustmargintop}px`, marginBottom: `${trustmarginbottom}px` }}>
                    <p class="text-center" style={{ color: '#9c36b5' }}>{trustCaption}</p>
                    <h2 class="text-center heading" style={{ color: `${contenttextheadingcolor}`, fontFamily: `${trusttextfont}` }}>{trust}</h2>
                    <p class="text-center  " style={{ color: `${contenttextinfocolor}`, fontFamily: `${trusttitletextfont}` }}>
                        {trsuttitle}
                    </p>
                    <div class="scrolling-text" id="scrollingText">

                        {trustLogo.map((Logos, index) => (
                            <img src={Logos} style={{ height: '24px', width: '150px' }} alt="logos"></img>
                        ))}

                        {newtrustlogo.map((Logos, index) => (
                            <img src={Logos} alt="logos" style={{ height: '24px', width: '150px' }}></img>
                        ))}

                    </div>
                </div>
            </div>
            )}
        </div>
    );
};

export default TrustedBy;