import react, { useContext, useEffect, useState } from 'react';
import MyContext from '../context/MyContext';
import { SketchPicker } from 'react-color';



const Buttonborder = ({ closePopupbuttonborder }) => {
    const [showShadowValues, setShowShadowValues] = useState(false);
    const [isDragging, setIsDragging] = useState(false);
    const [offsetX, setOffsetX] = useState(0);
    const [offsetY, setOffsetY] = useState(0);
    const [isOpen, setIsopen] = useState(false);
    const {
        buttonborder, setbuttonborder,
        buttonborderradius, setButtonborderradios,
        buttonbordercolor, setbuttonbordercolor,
    } = useContext(MyContext);
    const handleMouseMove = (e) => {
        if (isDragging) {
            e.preventDefault();
            const popup = document.querySelector('.popup');
            popup.style.left = e.clientX - offsetX + 'px';
            popup.style.top = e.clientY - offsetY + 'px';
        }
    };
    const handleMouseDown = (e) => {
        setIsDragging(true);
        setOffsetX(e.clientX - e.target.getBoundingClientRect().left);
        setOffsetY(e.clientY - e.target.getBoundingClientRect().top);
    };
    const handleMouseUp = () => {
        setIsDragging(false);
    };
    const HandelButtonborderwith = (e) => {
        setbuttonborder(e.target.value)
    };
    const Handelbuttonborderradious = (e) => {
        setButtonborderradios(e.target.value)
    };

    const HandepOpen = () => {
        setIsopen(true)
    };

    const Handelbuttonbordercolor = (color) => {
        setbuttonbordercolor(color.hex)
    };

    useEffect(() => {
        function handleClickOutside(event) {
            const target = event.target;
            const divToExclude = document.getElementById("one");

            // Check if the click occurred outside of the specific div
            if (divToExclude && !divToExclude.contains(target)) {
                setIsopen(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen]);

    console.log('isopen',isOpen)
    return (
        <div className="popup" onMouseUp={handleMouseUp} onMouseMove={handleMouseMove}
            style={{ position: 'fixed', left: '40%', top: '50%', transform: 'translate(-50%, -50%)', zIndex: 999, background: '#fff', borderRadius: '7px', boxShadow: '0 4px 30px #0000001a, 0 4px 10px #00000012', cursor: isDragging ? 'grabbing' : 'context-menu', width: '320px' }} >
            <header class="overlayHeader" onMouseDown={handleMouseDown} style={{ cursor: 'drag' }}>
                <span class="">Border</span>
                <button onClick={closePopupbuttonborder} class="button button--small button--clear overlay_overlayClose__GxQ0k">
                    <svg xmlns="http://www.w3.org/2000/svg" height="16" viewBox="0 0 24 24" width="16" fill="currentColor"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"></path></svg>
                </button>
            </header>
            <div className='picker_container__vy82H ' style={{ width: "100%" }}>




                <div class="picker_setting__OsFeD"><label>Border Radius</label>
                    <input type="number" value={buttonborderradius} onChange={Handelbuttonborderradious} />


                </div>
                <div class="picker_setting__OsFeD">
                    <label>Border Width</label>
                    <input type="number" value={buttonborder} onChange={HandelButtonborderwith} />
                </div>


                <div class="picker_setting__OsFeD">
                    <label>Border color</label>
                    <div  class="picker_background__YKaIH" style={{ background: "rgb(255, 255, 255)" }} onClick={HandepOpen}>
                        {isOpen == false ? null : 
                        <div id='one'>
                        <SketchPicker  color={buttonbordercolor} onChangeComplete={Handelbuttonbordercolor} />
                       </div> }</div>
                </div>




            </div>
        </div>
    )
};
export default Buttonborder;