import React, { useContext, useEffect, useRef, useState } from "react";
import Boomer from '../../assets/images/logo2.png';
import cartoon from '../../assets/images/leftflying.png'
import boomerright from '../../assets/images/boomerright.png'
import boomerleft from '../../assets/images/carttonleft.png'
import arrow from '../../assets/images/arrowMeme.png'
import aboutright from '../../assets/images/aboutright.png'
import aboutleft from '../../assets/images/aboutleft.png'
import '../../assets/CSS/Memecoin.css'
import Backgroundimage from '../../assets/images/menubackground.png'
import heroImage from '../../assets/images/BackgroundMemecoin1.png'
import MyContext from "../../context/MyContext";
import twitter from '../../assets/images/twittermeme.png'
import telegram from '../../assets/images/Telegrammeme.png'
import tiktok from '../../assets/images/tiktok.png'
import youtube from '../../assets/images/Youtubememe.png'
import instagram from '../../assets/images/Instagram.png'
import warpcast from '../../assets/images/Wmeme.png'
import Contract from '../../assets/images/Contract.svg'
import Database from '../../assets/images/Database.svg'
import Circulating from '../../assets/images/Circulating.png'
import Burned from '../../assets/images/Burned.png'
import Liquidity from '../../assets/images/Liquidity.svg'
import Renounced from '../../assets/images/Renounced.svg'
import tokonmocismask from '../../assets/images/tokonomicsmask.png'
import Tokonemoicsbgimage from '../../assets/images/tokonmicsbg.svg'
import UrlNav from "../UrlNav";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import DownloadMemecoinTemplate from "../DownloadMemecoin";
import { Image2 } from "./Images";
import CloudImage from '../../assets/images/NewMaskImage.png'

import styles from '../../assets/CSS/Template1.module.css';
import stylesT2 from '../../assets/CSS/Template2.module.css';
import styleT3 from '../../assets/CSS/Template3.module.css'
import styleT4 from '../../assets/CSS/T4.module.css'
import stylesT5 from '../../assets/CSS/T5.module.css'

import Twitter from '../../assets/images/TwitterT1.png'
import MainImage from '../../assets/images/Template1MainImage.png'
import Tokenomic1 from '../../assets/images/TokenomicImage2T1.png'
import Tokenomics2 from '../../assets/images/TokenomicIamge1T1.png'
import Tokenomics3 from '../../assets/images/TokenomicImage3T1.png'
import ContractImage1 from '../../assets/images/ContractIamge1T1.png'
import ContractImage2 from '../../assets/images/ContractImage1T1.png'
import ContractImage3 from '../../assets/images/ContractImageIamge1T1.png'
import FooterIamge from '../../assets/images/Template1footerimage.png'
import BackgroundImage from '../../assets/images/SollybackgroungT1.png'
import Solly from '../../assets/images/Template1Solly.png'


import Template2Logo from '../../assets/images/Template2Logo.png'
import Template2telegram from '../../assets/images/Template2Telegram.png'
import Template2twitter from '../../assets/images/Template2twitter.png'
import Template2unicorn from '../../assets/images/Template2unicorn.png'
import Template2M from '../../assets/images/Template2M.png'
import Template2Forg from '../../assets/images/Template2forg.png'
import Template2sectionbg from '../../assets/images/Template2Sectionbg.png'
import Template2Section3bg from '../../assets/images/Template2Section3bg.png'
import Template2SectionMain from '../../assets/images/Template2SectionMainbg.png'


import Template3BG from '../../assets/images/BackgroundImageT3.jpg'
import Template3Container from '../../assets/images/ContainerT3.svg'
import Template3Sunppd from '../../assets/images/SunppdT3.png'
import Template3Telegram from '../../assets/images/TelegramT3.png'
import Template3Twitter from '../../assets/images/Template3x.png'
import Template3Chart from '../../assets/images/Template3Chart.png'
import Template3Svg from '../../assets/images/Template3Svg.png'
import Template3BrownContainer from '../../assets/images/Template3BrownContainer.png'
import Template3Vector from '../../assets/images/Template3Vector.png'
import Template3Maks from '../../assets/images/Template3Mask.png'
import Template3MaskSvg from '../../assets/images/Template3Makssvg.png'
import Template3footersvg from '../../assets/images/Template3Footersvg.png'
import Template3footerbg from '../../assets/images/Template3Footerbg.jpg'
import Template3Footercontainer1 from '../../assets/images/Template3Footercontainer1.png'
import Template3FooterContainer2 from '../../assets/images/Template3FooterContainer2.png'
import Template3Footertwitter from '../../assets/images/Template3Footertwitter.png'
import Template3FooterSunppd from '../../assets/images/Template3FooterSunppd.png'
import Template3FOoterEmail from '../../assets/images/Template3FooterEmail.svg'
import Template3SectionSnuppd from '../../assets/images/Template3Sectionsnuppd.png'
import Template3Section3svg from '../../assets/images/Template3Section3svg.png'
import Template3DogImage from '../../assets/images/Template3Dogimage.png'


import T4Navlogo from '../../assets/images/T4Navlogo.png'
import T4MainSectionImage from '../../assets/images/images/T4MainsectionBackground.png'
import T4Memelogo from '../../assets/images/T4MemeImage.png'
import T4MemeImage1 from '../../assets/images/T4MemeImage2.png'
import T4twitter from '../../assets/images/T4Twitter.png'
import T4telegram from '../../assets/images/T4telegram.png'
import T4Instagram from '../../assets/images/T4instagram.png'
import T4trend from '../../assets/images/T4trend.png'
import T4Section3bgImage from '../../assets/images/T4Section2Image.jpg'
import T4MemeImage2 from '../../assets/images/T4MemeLogo2.png'
import T4MemeImage3 from '../../assets/images/T4MemeImage3.png'
import T4footertelegram from '../../assets/images/T4Footertelegram.png'
import T4footerTwitter from '../../assets/images/T4FooterTwitter.png'
import T4footerInstagram from '../../assets/images/T4FooterInstagram.png'
import T4footertrend from '../../assets/images/T4FooterTrend.png'
import { HelmetProvider } from "react-helmet-async";


import T5image1 from '../../assets/images/T5Image1.png'
import T5image2 from '../../assets/images/T5image2.png'
import T5image3 from '../../assets/images/T5image3.png'
import T5image4 from '../../assets/images/T5image4.png'
import T5image5 from '../../assets/images/T5image5.png'
import T5image6 from '../../assets/images/T5image6.png'
import T5image7 from '../../assets/images/T5image7.png'
import T5image8 from '../../assets/images/T5image8.png'
import T5image9 from '../../assets/images/T5image9.png'
import T5image10 from '../../assets/images/T5image10.png'
import T5image11 from '../../assets/images/T5image11.png'
import T5image12 from '../../assets/images/T5image12.png'
import T5image13 from '../../assets/images/T5image13.png'
import T5image14 from '../../assets/images/T5image14.png'
import T5image15 from '../../assets/images/T5image15.png'
import T5image16 from '../../assets/images/T5image16.png'
import T5image17 from '../../assets/images/T5image17png.png'
import T5image18 from '../../assets/images/T5image18.png'
import T5image19 from '../../assets/images/T5image19.png'
import T5image20 from '../../assets/images/T5image20.png'
import T5image21 from '../../assets/images/T5image21.png'
import T5image22 from '../../assets/images/T5image22.png'
import T5image23 from '../../assets/images/T5image23.png'
import { Helmet } from "react-helmet";
const Memecoin = () => {
    const {
        memecoinname, SetMemecoinName,
        memecoinsymbol, setMemecoinSymbol,
        memecoinNavbar, setMemecoinNavbar,
        memecoinAbout, setMemecoinAbout,
        memeCaption, memecoinValue, newIntroImage,
        memecoincontractaddress, setMemecoincontractAddress,
        memecoinInitialsupply, setMemecoinInitialsupply,
        memecoinCirculatingSupply, setMemecoinCirculatingSupply,
        memecoinSupplyBurned, setMemecoinSupplyBurned,
        memecoinLiquidity, setMemecoinLiquidity,
        memecoinlogoImage, setMemecoinlogoImage,
        memecoinaboutbgImage, memecoinAminitionImage,
        memecoinaboutleftimage, memecoinaboutrightimage,
        memecoinavisionleftimage, memecoinavisionrightimage,
        memecointokenomicsimage, memecoinContractImage,
        memecoinDatabaseImage, memecoinCirculatingImage,
        memecoinBurnedImage, memecoinLiquidityImage,
        memecoinRenouncedImage, memenavbgcolor,
        memeaboutbgcolor, memecointokonomicsbgimage,
        memecoinanvfont, memenavtextcolor,
        memecoinabouttextcolor, memecoinaboutfont,
        memecoinsection, memecoinvisionfont,
        memecoinvisiontextcolor, memecointokenfont,
        memecointoketextcolor, memecoinstepfont,
        memecoinsteptextcolor, randomId, setRandomId,
        iSremove1, setIsremove1,
        iSremove2, setIsremove2,
        iSremove3, setIsremove3,
        iSremove4, setIsremove4,
        iSremove5, setIsremove5,
        iSremove6, setIsremove6,
        newaddtokest, newiconsImage,
        step1, setStep1,
        step2, setStep2,
        step3, setStep3,
        step4, setStep4,
        stepheading, setStepheading,
        memeherobutton,
        memefooterabout, setMemefooterabout,
        memetwitterlink, setMemetwitterlink,
        memetelegramlink, setMemetelegramlink,
        memeYoutubelink, setMemeYoutubelink,
        memetiktoklink, setMemetiktoklink,
        memeinstagramlink, setMemeinstagramlink,
        memewarpcastlink, setMemewarpcastlink,
        setMemecoinsection, memeaboutbgimage,
        memestepbgimage, memefooterbgimage,
        memenavbarbgcolor, memebackground,
        memeherobgcolor, memeherobackground,
        memeaboutbackground, memetokenomicsbgcolor,
        memetokenomicsbackground, memestepbgcolor,
        memestepbackground, memefooterbgcolor,
        memefootrbackground, setMemefootrbackground,
        tokenvalubgcolor, settokenvalubgcolor,
        memefootertextcolor, memefootertextfont, memenavfontsize,
        memeMainfontsize, memeaboutfontSize, memetokenfontSize,
        memestepfontSize, memefooterfontSize, selectMemeCoinTemplates,
        contractRenounced, showWebsite, modalShow,
        setMemeherobgcolor, setMemeherobackground,
        setMemenavbarbgcolor, setMemebackground,
        template1caption, template1Info, setMemetokenomicsbgcolor, setMemetokenomicsbackground,
        template1tokenimage2, template1tokenimage3,
        template1tokenomics, template1tokenomicssub1,
        template1tokenomicssub2, template1tokenomicssub3,
        template1tokenomisupply, template1tokenomicontract,
        template1tokenomiTax, template1footerabout,
        template1footerImage, statsbackgroundcolor,
        statsborder, statsbordercolor,
        statsborderradius, statsshowdowrange,
        setMemestepbgcolor, setMemestepbackground,
        template1stepAdd, template1stepcontract,
        template2footerlogo, template2footeringo,
        template2stepheading, template2step1,
        template2step2, template2step3,
        template2step4, setMemestepbgimage,
        setMemecointokonomicsbgimage,
        template2tokenheading, template2tokensupply,
        template2tokentatesupply, template2tokentax,
        template2tokenlp, template2tokenMeme,
        setmemeAboutbgimage, setMemeaboutbackground,
        template2telegramlink, template2twitterlink,
        template2caption, template2memecaption,
        template2info, template2tokenAddress,
        template2uniswap, template2cmc, template2coingecko,
        template3telegramlink, template3twitterlink,
        template3uniswaplink, template3heading,
        template3section1, template3Section2,
        template3Section3, template3Section4,
        template3stepimage, template3stepquestion,
        template3stepsection1, template3stepsection2,
        template3step3section2heading,
        template3stepsection2bg, template3stepsection2color,
        memestepbackgroundt3, template3footerheading,
        template3footerinfo, template3email, template3memeaddress,
        statsbackgroundcolorT3, memecloudimage,
        memetokencaption, memetokenbuy,
        template2removebuttonuniswap, template2removebuttonunicmc,
        template2removebuttoncoingecko, t4navbutton,
        t4memeaddress, t4caption,
        t4mainbutton1, t4mainbutton2,
        t4token, t4step1,
        t4step2, t4step3,
        t4step4, t4step5,
        t4footer1, t4footer2,
        t4footer3, t4footer4,

        t4stepimage,
        t5Nav1, setT5Nav1,
        t5nav2, sett5Nav2,
        t5about1, t5about2,
        t5Token1,
        t5Token3, t5Token4,
        t5Token5, t5Token6,
        t5step1, t5step2,
        t5step3, t5step4,

    } = useContext(MyContext);
    const [googletransalte, setGoogletransalte] = useState(false);


    const [shownewicon, setshownewicon] = useState(false)
    const memecoinRef = useRef(null);
    const [location, setLocations] = useState();


    const Navbar = memecoinNavbar.map((item) => {
        return item
    })



    if (memecoinNavbar.length < 4) {
        var Newmenubar = [...memecoinNavbar]
    }
    console.log('Memecoin', memeCaption.slice(13, 32))
    const Caption1 = memeCaption.slice(0, 13);
    const Caption2 = memeCaption.slice(13,);

    console.log('memeCount', memecoinsection);
    useEffect(() => {
        if (memecoinsection == 1) {
            window.location.href = '#navbar'
            window.location.href = '#Navbar'
            // window.location.href = '#Weburl'
        }
        if (memecoinsection == 2) {
            window.location.href = '#hero'
            window.location.href = '#Hero'

            // window.location.href = '#Weburl'


        }
        if (memecoinsection == 3) {
            window.location.href = '#vision'
            window.location.href = '#Vision'

            // window.location.href = '#Weburl'

        }
        if (memecoinsection == 4) {
            window.location.href = '#token'
            window.location.href = '#Token'

            // window.location.href = '#Weburl'

        }
        if (memecoinsection == 5) {
            window.location.href = '#howtobuy'
            window.location.href = '#HowTOBuy'

            // window.location.href = '#Weburl'

        }

        if (memecoinsection == 6) {
            window.location.href = '#footer'
            window.location.href = '#Footer'

            // window.location.href = '#Weburl'

        }
    }, [memecoinsection])



    // console.log('section', memecoinsection)

    function hexToRgb(hex) {
        let bigint = parseInt(hex.slice(1), 16);
        let r = (bigint >> 16) & 255;
        let g = (bigint >> 8) & 255;
        let b = bigint & 255;
        return [r, g, b];
    }

    function rgbToHsl(r, g, b) {
        r /= 255;
        g /= 255;
        b /= 255;
        let max = Math.max(r, g, b), min = Math.min(r, g, b);
        let h, s, l = (max + min) / 2;

        if (max == min) {
            h = s = 0; // achromatic
        } else {
            let d = max - min;
            s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
            switch (max) {
                case r: h = (g - b) / d + (g < b ? 6 : 0); break;
                case g: h = (b - r) / d + 2; break;
                case b: h = (r - g) / d + 4; break;
            }
            h /= 6;
        }

        return [h, s, l];
    }

    function hslToRgb(h, s, l) {
        let r, g, b;

        if (s == 0) {
            r = g = b = l; // achromatic
        } else {
            function hue2rgb(p, q, t) {
                if (t < 0) t += 1;
                if (t > 1) t -= 1;
                if (t < 1 / 6) return p + (q - p) * 6 * t;
                if (t < 1 / 2) return q;
                if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
                return p;
            }

            let q = l < 0.5 ? l * (1 + s) : l + s - l * s;
            let p = 2 * l - q;
            r = hue2rgb(p, q, h + 1 / 3);
            g = hue2rgb(p, q, h);
            b = hue2rgb(p, q, h - 1 / 3);
        }

        return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
    }

    function darkenHexColor(hex, percent) {
        let [r, g, b] = hexToRgb(hex);
        let [h, s, l] = rgbToHsl(r, g, b);
        l = l - l * (percent / 100);
        [r, g, b] = hslToRgb(h, s, l);
        return `rgb(${r},${g},${b})`;
    }

    var darkenedColor = darkenHexColor(`${memetokenomicsbgcolor}`, 30); // Darken by 20%
    console.log(darkenedColor); // Output: rgb(0, 33, 204)
    useEffect(() => {
        settokenvalubgcolor(darkenedColor)
    }, [memetokenomicsbgcolor])
    console.log('Locations', window.location.href)

    useEffect(() => {
        setLocations(window.location.href)
    }, [])
    console.log('Locations', location);
    console.log('modalShow', modalShow, selectMemeCoinTemplates, showWebsite);
    const Step3test1 = step3.slice(0, 11);
    const Step3test2 = step3.slice(12,)
    console.log('step3', memecoinsection);

    useEffect(() => {
        if (selectMemeCoinTemplates == 2) {
            // setMemeherobgcolor('#7939c8')
            // setMemeherobackground(1);
            // setMemenavbarbgcolor('#7939c8');
            // setMemebackground(1);
            // setMemetokenomicsbgcolor('#7939c8');
            // setMemetokenomicsbackground(1);
            // setMemestepbgcolor('#981F27');
            // setMemestepbackground(1);
        }
    }, [selectMemeCoinTemplates]);


    console.log('memeherobgcolor', memeherobgcolor);
    const test = template2footerlogo.split('');
    const test1 = test.splice(3, 0, `${memecoinsymbol}`);
    const test2 = test.join('');
    console.log('template2footerlogo', test2);

    //    console.log('template2step1',template2step1.split(''));
    for (var i = 0; i < template2step4.length; i++) {
        console.log('template2step1', template2step4[i], i)
    };
    const step1Test1 = template2step1.slice(0, 21);
    const step1Test2 = template2step1.slice(22, 41);
    const step1Test3 = template2step1.slice(42, 84);
    const step1Test4 = template2step1.slice(85, 127);
    const step1Test5 = template2step1.slice(128, 165);
    const Step1Test6 = template2step1.slice(166, 203);
    const Step1Test7 = template2step1.slice(204,);


    const Template2opentelegram = () => {
        window.open(`${template2telegramlink}`)
    };

    const Template2opentwitter = () => {
        window.open(`${template2twitterlink}`)
    };

    const Opentemplate2uniswap = () => {
        // window.open(`${template2uniswap}`)
        window.open(`${template2uniswap}`)

    };
    const OpenTemplate2CMC = () => {
        window.open(`${template2cmc}`)
    };
    const OpenTemplate2Coingecko = () => {
        window.open(`${template2coingecko}`)
    }
    console.log('memeherobackground', memestepbackground);

    const OpenTemplate3telegram = () => {
        window.open(`${template3telegramlink}`)
    };

    const OpenTemplate3twitter = () => {
        window.open(`${template3twitterlink}`)
    };

    const OpenTemplate3Uniswap = () => {
        window.open(`${template3uniswaplink}`)
    };
    const OpenTemplate3Email = () => {
        const url = `https://mail.google.com/mail/?view=cm&fs=1&to=${template3email}&su=Hello&body=How%20are%20you%3F`
        window.open(url, '_blank');
    }

    console.log('template3stepsection2color', template3stepsection2color);

    const insertLineBreaks = (text, maxChars) => {
        const lines = [];
        for (let i = 0; i < text.length; i += maxChars) {
            lines.push(text.substring(i, i + maxChars));
        }
        return lines.join('\n');
    };

    const formattedText = insertLineBreaks(memetokencaption, 20);
    const Template2caption = insertLineBreaks(template2caption, 20);
    const Template2memecaption = insertLineBreaks(template2memecaption, 25);
    const Template2info = insertLineBreaks(template2info, 15);
    const MemecoinAbout = insertLineBreaks(memecoinAbout, 20);

    console.log('t4step1', t4step2)

    return (
        <>
            <HelmetProvider>

                {selectMemeCoinTemplates == 1 ?
                    <>
                        <div class='body' >
                            {(memecoinsection >= 1 && memecoinsection <= 7) ? <UrlNav /> : null}


                            <div class=''>

                                <nav id="navbar" className="mememenu-memecoin" style={{ backgroundImage: memebackground == 0 ? `${memenavbgcolor.length > 0 ? `url(${memenavbgcolor})` : `url(${Backgroundimage})`}` : '', height: 'auto', width: "auto", objectFit: 'cover', backgroundSize: 'cover', backgroundColor: memebackground == 1 ? `${memenavbarbgcolor}` : '' }}>
                                    <div className="mememenu-inner" style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', flexDirection: "row", }}>
                                        <div style={{ width: "15%", display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: "row", overflow: 'hidden' }}>
                                            <span class="mememenu-item" aria-label="boomer-link" style={{ fontFamily: `${memecoinanvfont}`, color: `${memenavtextcolor}`, fontSize: `${memenavfontsize}px` }}>{Navbar[0]}</span>
                                        </div>
                                        <div style={{ width: "15%", display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: "row", overflow: 'hidden' }}>
                                            <span class="mememenu-item" aria-label="boomer-link" style={{ fontFamily: `${memecoinanvfont}`, color: `${memenavtextcolor}`, fontSize: `${memenavfontsize}px` }}>{Navbar[1]}</span>
                                        </div>
                                        <div class="mememenu-item logo">
                                            <picture>
                                                <img src={memecoinlogoImage.length > 0 ? memecoinlogoImage : Boomer} style={{ width: '360px', height: '200px' }} alt="boomer" />
                                            </picture>
                                        </div>
                                        <div style={{ width: "15%", display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: "row", overflow: 'hidden' }}>
                                            <span class="mememenu-item" aria-label="boomer-link" style={{ fontFamily: `${memecoinanvfont}`, color: `${memenavtextcolor}`, fontSize: `${memenavfontsize}px` }}>{Navbar[2]}</span>
                                        </div>
                                        <div style={{ width: "15%", display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: "row", overflow: 'hidden' }} >
                                            <span class="mememenu-item" aria-label="boomer-link" style={{ fontFamily: `${memecoinanvfont}`, color: `${memenavtextcolor}`, fontSize: `${memenavfontsize}px` }} >{Navbar[3]}</span>
                                        </div>


                                    </div>
                                </nav>
                            </div>
                            <div class='' className="section" id="hero" style={{ backgroundImage: memeherobackground == 0 ? memecoinaboutbgImage.length > 0 ? `url(${memecoinaboutbgImage})` : `url(${heroImage})` : "", backgroundColor: memeherobackground == 1 ? `${memeherobgcolor}` : "" }}>
                                <div className="section-inner">
                                    <div className="hero-animation">
                                        <div className="animation-wrapper">
                                            {memecoinAminitionImage.length > 0 ? <img src={memecoinAminitionImage} style={{ height: '400px', width: '350px', marginTop: 50, objectFit: 'cover', backgroundSize: 'cover' }}></img> : <img src={cartoon} style={{ height: '400px', width: '350px' }}></img>}
                                        </div>
                                    </div>
                                    <div class="header-container" style={{ backgroundImage: memecloudimage.length > 0 ? `url(${memecloudimage})` : `url(${CloudImage})` }} >
                                        <h2 style={{ color: `${memecoinabouttextcolor}`, fontFamily: `${memecoinaboutfont}`, fontSize: `${memeMainfontsize}px` }} >{Caption1}</h2>
                                        <h2 style={{ color: `${memecoinabouttextcolor}`, fontFamily: `${memecoinaboutfont}`, fontSize: `${memeMainfontsize}px` }} >{Caption2}</h2>


                                        <h1 style={{ color: `${memecoinabouttextcolor}`, fontFamily: `${memecoinaboutfont}`, fontSize: `${memeMainfontsize}px` }}>{memecoinValue}</h1>
                                        <h2 style={{ color: `${memecoinabouttextcolor}`, fontFamily: `${memecoinaboutfont}`, fontSize: `${memeMainfontsize}px` }}>{memecoinname}</h2>
                                    </div>
                                    <div class="boomer-cartoon boomer-cig">
                                        <img src={memecoinaboutrightimage.length > 0 ? memecoinaboutrightimage : boomerright} style={{ width: '350px', height: "400px", objectFit: 'cover', backgroundSize: 'cover' }} alt="" />
                                    </div>
                                    <div class="boomer-cartoon boomer-chair">
                                        <img src={memecoinaboutleftimage.length > 0 ? memecoinaboutleftimage : boomerleft} style={{ width: '350px', height: "400px", objectFit: 'cover', backgroundSize: 'cover' }} alt="" />
                                    </div>
                                    <div class="memebutton-container">
                                        <a aria-label="boomer-link" href="" target="_blank" ><div class="memebutton memebutton-primary" style={{ color: `${memecoinabouttextcolor}`, fontFamily: `${memecoinaboutfont}`, fontSize: `${memeMainfontsize}px` }}>Buy ${memecoinsymbol}</div></a>
                                        <a aria-label="boomer-link" href="" target="_blank" ><div class="memebutton memebutton-secondary" style={{ color: `${memecoinabouttextcolor}`, fontFamily: `${memecoinaboutfont}`, fontSize: `${memeMainfontsize}px` }}>{memeherobutton} <i class="fa-brands fa-telegram"></i></div></a>
                                    </div>
                                </div>
                                <div class="arrow-memecoin"><a href="#vision"><img width="100%" src={arrow} alt="continue to next section for more information" /></a></div>
                            </div>

                            <div class="section blue " id="vision" style={{ marginTop: '-0.5px', backgroundColor: memeaboutbackground == 0 ? memeaboutbgcolor.length == 0 ? '#0452ff' : memeaboutbgcolor : '', backgroundImage: memeaboutbackground == 1 ? memeaboutbgimage.length !== 0 ? `url(${memeaboutbgimage})` : '' : '', height: '100%', width: '100%', objectFit: 'cover', backgroundSize: 'cover' }} >

                                <div class="section-inner" >
                                    <div class="header-container">
                                        <h1 style={{ fontFamily: `${memecoinvisionfont}`, color: `${memecoinvisiontextcolor}`, fontSize: `${memeaboutfontSize}px` }}>ABOUT</h1>
                                        <p style={{ fontFamily: `${memecoinvisionfont}`, color: `${memecoinvisiontextcolor}`, fontSize: `${memeaboutfontSize}px` }}>{memecoinAbout}</p>

                                    </div>
                                    <div class="boomer-cartoon boomer-cig" style={{ top: "65%" }} >
                                        {memecoinavisionrightimage.length > 0 ? <img src={memecoinavisionrightimage} style={{ width: '350px', marginTop: 50, height: '350px', objectFit: 'cover', backgroundSize: 'cover' }}></img> :
                                            <img src={aboutright} style={{ width: '350px' }} alt="" />}
                                    </div>
                                    <div class="boomer-cartoon boomer-chair" style={{ top: "66%" }}>
                                        {memecoinavisionleftimage.length > 0 ? <img src={memecoinavisionleftimage} style={{ width: '350px', marginTop: 50, height: '350px', objectFit: 'cover', backgroundSize: 'cover' }}></img> : <img src={aboutleft} style={{ width: '380px', }} alt="" />
                                        }
                                    </div>


                                </div>

                            </div>

                            <div id='token' class="section white" style={{ marginBottom: '2rem', marginTop: '-60px' }} >
                                <span style={{ position: 'absolute', alignItems: "center", left: '50%', top: '' }}>
                                    <div class="header-container">
                                        <h1 style={{ fontFamily: `${memecointokenfont}`, color: `${memecointoketextcolor}`, fontSize: `${memetokenfontSize}px` }}>TOKENOMICS</h1>
                                    </div>
                                </span>

                                <div class="section-inner" >

                                    <div className="frame-container" style={{ backgroundImage: memetokenomicsbackground == 0 ? memecointokonomicsbgimage.length > 0 ? `url(${memecointokonomicsbgimage})` : `url(${Tokonemoicsbgimage})` : '', width: '100%', height: '100%', objectFit: 'cover', backgroundSize: 'cover', backgroundColor: memetokenomicsbackground == 1 ? `${memetokenomicsbgcolor}` : '', }} >

                                        <div class="token-grid">
                                            {newaddtokest == 1 ? (null) : (newaddtokest.map((item, index) => (
                                                <div key={index}>
                                                    <div style={{ display: "flex", flexDirection: 'column', alignItems: 'center' }}>
                                                        {newiconsImage.length > 0 ? <img src={newiconsImage} alt="" style={{ height: 95, width: 80 }} /> : <img src={Contract} alt="" />}
                                                        <div style={{ fontFamily: `${memecointokenfont}`, color: `${memecointoketextcolor}`, fontSize: `${memetokenfontSize}px` }}>{item.title}</div>
                                                    </div>
                                                    <div class="textarea" aria-label="wallet address container" readonly="" style={{ padding: "15px", fontFamily: `${memecointokenfont}`, color: `${memecointoketextcolor}`, backgroundColor: 'transparent', fontSize: `${memetokenfontSize}px` }} >{item.value}</div>
                                                </div>
                                            )))}

                                            {iSremove1 ? null : <div >
                                                <div style={{ display: "flex", flexDirection: 'column', alignItems: 'center' }}>
                                                    {memecoinContractImage.length > 0 ? <img src={memecoinContractImage} alt="" style={{ height: 95, width: 80 }} /> : <img src={Contract} alt="" />}
                                                    <div style={{ fontFamily: `${memecointokenfont}`, color: `${memecointoketextcolor}`, fontSize: `${memetokenfontSize}px` }}>{memecoincontractaddress.title}</div>
                                                </div>
                                                <div class="textarea" aria-label="wallet address container" readonly="" style={{ padding: "15px", fontFamily: `${memecointokenfont}`, color: `${memecointoketextcolor}`, backgroundColor: 'transparent', fontSize: `${memetokenfontSize}px` }} >{memecoincontractaddress.value}</div>
                                            </div>}


                                            {iSremove2 ? null : <div>
                                                <div style={{ display: "flex", flexDirection: 'column', alignItems: 'center' }}>
                                                    {memecoinDatabaseImage.length > 0 ? <img src={memecoinDatabaseImage} alt="" style={{ height: 95, width: 80 }} /> : <img src={Database} alt="" />}
                                                    <div style={{ fontFamily: `${memecointokenfont}`, color: `${memecointoketextcolor}`, fontSize: `${memetokenfontSize}px` }} >{memecoinInitialsupply.title}</div>
                                                </div>
                                                <span class="animate-number" style={{ textAlign: 'center', fontFamily: `${memecointokenfont}`, color: `${memecointoketextcolor}`, fontSize: `${memetokenfontSize}px` }}>{memecoinInitialsupply.value}</span>
                                            </div>}


                                            {iSremove3 ? null : <div>
                                                <div style={{ display: "flex", flexDirection: 'column', alignItems: 'center' }}>
                                                    {memecoinCirculatingImage.length > 0 ? <img src={memecoinCirculatingImage} alt="" style={{ height: 95, width: 80 }} /> : <img src={Circulating} alt="" />}
                                                    <div style={{ fontFamily: `${memecointokenfont}`, color: `${memecointoketextcolor}`, fontSize: `${memetokenfontSize}px` }}>{memecoinCirculatingSupply.title}</div>
                                                </div>

                                                <span class="animate-number" style={{ fontFamily: `${memecointokenfont}`, color: `${memecointoketextcolor}`, fontSize: `${memetokenfontSize}px` }}>{memecoinCirculatingSupply.value}</span>
                                            </div>}

                                            {iSremove4 ? null : <div>
                                                <div style={{ display: "flex", flexDirection: 'column', alignItems: 'center' }}>
                                                    {memecoinBurnedImage.length > 0 ? <img src={memecoinBurnedImage} alt="" style={{ height: 95, width: 80 }} /> : <img src={Burned} alt="" />}
                                                    <div style={{ fontFamily: `${memecointokenfont}`, color: `${memecointoketextcolor}`, fontSize: `${memetokenfontSize}px` }}>{memecoinSupplyBurned.title}</div>
                                                </div>
                                                <span class="animate-number" style={{ fontFamily: `${memecointokenfont}`, color: `${memecointoketextcolor}`, color: `${memecointoketextcolor}`, fontSize: `${memetokenfontSize}px` }}>{memecoinSupplyBurned.value}</span>
                                            </div>}

                                            {iSremove5 ? null : <div>
                                                <div style={{ display: "flex", flexDirection: 'column', alignItems: 'center' }}>
                                                    {memecoinLiquidityImage.length > 0 ? <img src={memecoinLiquidityImage} alt="" style={{ height: 95, width: 80 }} /> : <img src={Liquidity} alt="" />}
                                                    <div style={{ fontFamily: `${memecointokenfont}`, color: `${memecointoketextcolor}`, fontSize: `${memetokenfontSize}px` }}> {memecoinLiquidity.title}</div>
                                                </div>

                                                <span class="animate-number" style={{ fontFamily: `${memecointokenfont}`, color: `${memecointoketextcolor}`, fontSize: `${memetokenfontSize}px` }}>{memecoinLiquidity.value}</span>
                                                {/* <span class="animate-number" style={{ margin: '0px', padding: "0px!important", color: `${memecointoketextcolor}`,fontSize:`${memetokenfontSize}px` }}>%</span> */}
                                            </div>}

                                            {iSremove6 ? null : <div>
                                                <div style={{ display: "flex", flexDirection: 'column', alignItems: 'center' }}>
                                                    {memecoinRenouncedImage.length > 0 ? <img src={memecoinRenouncedImage} alt="" style={{ height: 95, width: 80 }} /> : <img src={Renounced} alt="" />}
                                                    <div style={{ fontFamily: `${memecointokenfont}`, color: `${memecointoketextcolor}`, fontSize: `${memetokenfontSize}px` }}>{contractRenounced.title}</div>
                                                    <div style={{ fontFamily: `${memecointokenfont}`, color: `${memecointoketextcolor}`, fontSize: `${memetokenfontSize}px` }}>{contractRenounced.value}</div>

                                                </div>

                                            </div>}



                                        </div>

                                        <div style={{ textAlign: "center" }}>
                                            <div class="animated-rotate" style={{ margin: "auto" }}>
                                                <img width="50%" src={memecointokenomicsimage.length > 0 ? memecointokenomicsimage : tokonmocismask} alt="illustration of boomer coin" />
                                                <p class="boomer-coin-text" style={{ fontFamily: `${memecointokenfont}`, color: `${memecointoketextcolor}`, fontSize: `${memetokenfontSize}px`, }} >{memecoinname} {formattedText}</p>
                                                <span href="#howtobuy" class="memebutton memebutton-secondary" style={{ fontFamily: `${memecointokenfont}`, color: `${memecointoketextcolor}`, color: memecointoketextcolor == 'white' ? 'blue' : `${memecointoketextcolor}`, fontSize: `${memetokenfontSize}px` }}>{memetokenbuy} {memecoinsymbol}</span>

                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div class="divider"></div>
                            </div>

                            <div className="frame-parent" id="howtobuy" style={{ backgroundImage: memestepbackground == 0 ? `url(${memestepbgimage})` : '', height: '100%', width: '100%', backgroundSize: 'cover', objectFit: 'cover', backgroundColor: memestepbackground == 1 ? `${memestepbgcolor}` : "" }}>

                                <h2 className="heading-3" style={{ fontFamily: `${memecoinstepfont}`, color: `${memecoinsteptextcolor}`, fontSize: `${memestepfontSize}px` }}>{stepheading}</h2>


                                <div className="parent">
                                    <div className="div"

                                    >
                                        <div className="group-parent">
                                            <div className="wrapper"

                                            >
                                                <div className="div1" style={{ fontFamily: `${memecoinstepfont}`, color: `${memecoinsteptextcolor}`, fontSize: `${memestepfontSize}px` }}>1</div>
                                            </div>
                                            <img className="frame-item" alt="" src="Line 2.svg" />
                                        </div>
                                        <div className="step-1-parent-memecoin" style={{ background: `linear-gradient(130deg, ${statsbackgroundcolor != 'white' ? statsbackgroundcolor : '#023cba'}, #516faf)`, border: `${statsborder}px solid ${statsbordercolor}`, borderRadius: `${statsborderradius}px`, boxShadow: statsshowdowrange ? `0px 0px ${statsshowdowrange}px 0px rgba(21, 21, 25, 0.21` : '0px 19px 30px 8px rgba(21, 21, 25, 0.21)' }}>

                                            <b className="step-5-optional-container" style={{ fontFamily: `${memecoinstepfont}`, color: `${memecoinsteptextcolor}`, fontSize: `${memestepfontSize}px` }}>Step 1</b>
                                            <b className="install-a-chrome" style={{ fontFamily: `${memecoinstepfont}`, color: `${memecoinsteptextcolor}`, fontSize: `${memestepfontSize}px` }}>
                                                {step1}
                                            </b>
                                        </div>
                                    </div>
                                    <div className="div">
                                        <div className="group-parent"

                                        >
                                            <div className="wrapper">
                                                <div className="div1" style={{ fontFamily: `${memecoinstepfont}`, color: `${memecoinsteptextcolor}`, fontSize: `${memestepfontSize}px` }}>2</div>
                                            </div>
                                        </div>
                                        <div className="step-1-parent-memecoin" style={{ background: `linear-gradient(130deg, ${statsbackgroundcolor != 'white' ? statsbackgroundcolor : '#023cba'}, #516faf)`, border: `${statsborder}px solid ${statsbordercolor}`, borderRadius: `${statsborderradius}px`, boxShadow: statsshowdowrange ? `0px 0px ${statsshowdowrange}px 0px rgba(21, 21, 25, 0.21` : '0px 19px 30px 8px rgba(21, 21, 25, 0.21)' }}>
                                            <b className="step-5-optional-container" style={{ fontFamily: `${memecoinstepfont}`, color: `${memecoinsteptextcolor}`, fontSize: `${memestepfontSize}px` }}>Step 2</b>
                                            <b className="install-a-chrome" style={{ fontFamily: `${memecoinstepfont}`, color: `${memecoinsteptextcolor}`, fontSize: `${memestepfontSize}px` }}>{step2}</b>
                                        </div>
                                    </div>
                                    <div className="div">
                                        <div className="group-parent">
                                            <div className="wrapper">
                                                <div className="div1" style={{ fontFamily: `${memecoinstepfont}`, color: `${memecoinsteptextcolor}`, fontSize: `${memestepfontSize}px` }}>3</div>
                                            </div>
                                        </div>
                                        <div className="step-1-parent-memecoin" style={{ background: `linear-gradient(130deg, ${statsbackgroundcolor != 'white' ? statsbackgroundcolor : '#023cba'}, #516faf)`, border: `${statsborder}px solid ${statsbordercolor}`, borderRadius: `${statsborderradius}px`, boxShadow: statsshowdowrange ? `0px 0px ${statsshowdowrange}px 0px rgba(21, 21, 25, 0.21` : '0px 19px 30px 8px rgba(21, 21, 25, 0.21)' }}>
                                            <b className="step-5-optional-container" style={{ fontFamily: `${memecoinstepfont}`, color: `${memecoinsteptextcolor}`, fontSize: `${memestepfontSize}px` }}>Step 3</b>
                                            <b className="install-a-chrome" style={{ fontFamily: `${memecoinstepfont}`, color: `${memecoinsteptextcolor}`, fontSize: `${memestepfontSize}px` }}>
                                                {Step3test1} {memecoinsymbol} {Step3test2}
                                            </b>
                                        </div>
                                    </div>
                                    <div className="div">
                                        <div className="group-parent">
                                            <div className="wrapper">
                                                <div className="div1" style={{ fontFamily: `${memecoinstepfont}`, color: `${memecoinsteptextcolor}`, fontSize: `${memestepfontSize}px` }}>4</div>
                                            </div>
                                        </div>
                                        <div className="step-1-parent-memecoin" style={{ background: `linear-gradient(130deg, ${statsbackgroundcolor != 'white' ? statsbackgroundcolor : '#023cba'}, #516faf)`, border: `${statsborder}px solid ${statsbordercolor}`, borderRadius: `${statsborderradius}px`, boxShadow: statsshowdowrange ? `0px 0px ${statsshowdowrange}px 0px rgba(21, 21, 25, 0.21` : '0px 19px 30px 8px rgba(21, 21, 25, 0.21)' }}>
                                            <b className="step-5-optional-container" style={{ fontFamily: `${memecoinstepfont}`, color: `${memecoinsteptextcolor}`, fontSize: `${memestepfontSize}px` }}>Step 4</b>
                                            <b className="mint-mlon-eusk-container">
                                                <span className="mint-mlon-eusk-container1">
                                                    <p className="install-a-chrome" style={{ fontFamily: `${memecoinstepfont}`, color: `${memecoinsteptextcolor}`, fontSize: `${memestepfontSize}px` }}>{step4} {memecoinname}</p>
                                                    <p className="install-a-chrome" style={{ fontFamily: `${memecoinstepfont}`, color: `${memecoinsteptextcolor}`, fontSize: `${memestepfontSize}px` }}>{memecoinsymbol}</p>
                                                </span>
                                            </b>
                                        </div>
                                    </div>

                                </div>
                            </div>



                            <div className="footermeme" id="footer" style={{ backgroundImage: memefootrbackground == 1 ? memefooterbgimage.length !== 0 ? `url(${memefooterbgimage})` : '' : '', objectFit: 'cover', backgroundSize: 'cover', backgroundColor: memefootrbackground == 0 ? memefooterbgcolor.length == 0 ? '#0452ff' : memefooterbgcolor : '', marginTop: '-6rem' }}>
                                <div className="container" >
                                    <span className={location != ('http://localhost:3000/') || ('https://trendifyweb.ai/') ? " Newwhite-logosvg-icon" : "white-logosvg-icon"} style={{ color: `${memefootertextcolor}`, fontSize: `${memefooterfontSize}px`, fontFamily: `${memefootertextfont}` }}>${memecoinsymbol}</span>
                                    <div className={location != ('http://localhost:3000/') && ('https://trendifyweb.ai/') ? 'Newmeme-was-made' : "meme-was-made"} style={{ color: `${memefootertextcolor}`, fontFamily: `${memefootertextfont}`, fontSize: `${memefooterfontSize}px` }}>{memecoinsymbol}{memefooterabout}</div>
                                </div>
                                <a href={memetwitterlink} target="_blank">
                                    <img className={location != ('http://localhost:3000/') && ('https://trendifyweb.ai/') ? "Newlink-boomer-on-base-social-m " : "link-boomer-on-base-social-m"} alt="" src={twitter} />
                                </a>
                                <a href={memetelegramlink} target="_blank" >
                                    <img className={location != ('http://localhost:3000/') && ('https://trendifyweb.ai/') ? "Newlink-boomer-on-base-social-m1" : "link-boomer-on-base-social-m1"} alt="" src={telegram} />
                                </a>
                                <a href={memeYoutubelink} target="_blank">
                                    <img className={location != ('http://localhost:3000/') && ('https://trendifyweb.ai/') ? "Newlink-boomer-on-base-social-m2" : "link-boomer-on-base-social-m2"} alt="" src={youtube} />
                                </a>
                                <a href={memetiktoklink} target="_blank">
                                    <img className={location != ('http://localhost:3000/') && ('https://trendifyweb.ai/') ? "Newlink-boomer-on-base-social-m3" : "link-boomer-on-base-social-m3"} alt="" src={tiktok} />
                                </a>
                                <a href={memeinstagramlink} target="_blank">
                                    <img className={location != ('http://localhost:3000/') && ('https://trendifyweb.ai/') ? "Newlink-boomer-on-base-social-m4 " : "link-boomer-on-base-social-m4"} alt="" src={instagram} />
                                </a>
                                <a href={memewarpcastlink} target="_blank">
                                    <img className={location != ('http://localhost:3000/') && ('https://trendifyweb.ai/') ? "Newlink-boomer-on-base-social-m5" : "link-boomer-on-base-social-m5"} alt="" src={warpcast} />
                                </a>
                            </div>
                        </div></> : null}

                {/* --------------------------------------T1-------------------------------------------- */}

                {selectMemeCoinTemplates == 2 ? (
                    <div className={styles.home_nav_soly} style={{ overflowX: 'hidden' }}>
                        {(memecoinsection >= 1 && memecoinsection <= 7) ? <UrlNav /> : null}


                        <div id='Navbar' className={styles.navBar} style={{ backgroundImage: memebackground == 0 ? `${memenavbgcolor.length > 0 ? `url(${memenavbgcolor})` : ``}` : '', height: 'auto', width: "auto", objectFit: 'cover', backgroundSize: 'cover', backgroundColor: memebackground == 1 ? `${memenavbarbgcolor}` : memenavbgcolor.length === 0 ? '#7939c8' : '', }}>
                            <div>
                                <li style={{ fontFamily: `${memecoinanvfont}`, color: `${memenavtextcolor}`, fontSize: `${memenavfontsize}px` }}>{Navbar[0]}</li>
                            </div>
                            <div>
                                <li style={{ fontFamily: `${memecoinanvfont}`, color: `${memenavtextcolor}`, fontSize: `${memenavfontsize}px` }}>{Navbar[1]}</li>
                            </div>
                            <div>
                                <li className={styles.Solly} style={{ fontFamily: `${memecoinanvfont}`, color: `${memenavtextcolor}`, fontSize: `${memenavfontsize}px` }}>{memecoinname}</li>
                            </div>
                            <div>
                                <li style={{ fontFamily: `${memecoinanvfont}`, color: `${memenavtextcolor}`, fontSize: `${memenavfontsize}px` }}>{Navbar[2]}</li>
                            </div>
                            <div>

                                <li style={{ fontFamily: `${memecoinanvfont}`, color: `${memenavtextcolor}`, fontSize: `${memenavfontsize}px` }}>{Navbar[3]}</li>
                            </div>
                        </div>


                        <div id='Hero' className={styles.Contract} style={{ backgroundImage: memeherobackground == 0 ? memecoinaboutbgImage.length > 0 ? `url(${memecoinaboutbgImage})` : `` : "", backgroundColor: memeherobackground == 1 ? `${memeherobgcolor}` : memecoinaboutbgImage.length > 0 ? "" : "#7939c8", backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}  >
                            <div className={styles.left_side}>
                                <div className={styles.heading2} style={{ color: `${memecoinabouttextcolor}`, fontFamily: `${memecoinaboutfont}`, fontSize: `${memeMainfontsize}px` }}>
                                    {template1caption} {memecoinsymbol}
                                </div>



                                <div className={styles.sollyIsThe} style={{ color: `${memecoinabouttextcolor}`, fontFamily: `${memecoinaboutfont}`, fontSize: `${memeMainfontsize}px` }}>
                                    {memecoinsymbol} {template1Info}
                                </div>
                                <br />
                                <br />
                                <div className={styles.tele}>
                                    <div className={styles.link4}>
                                        <div className={styles.telegram} style={{ color: `${memecoinabouttextcolor}`, fontFamily: `${memecoinaboutfont}`, fontSize: `${memeMainfontsize}px` }}>
                                            Telegram
                                        </div>
                                    </div>
                                    <div className={styles.link5}>
                                        <img
                                            className={styles.twitterpngIcon}
                                            alt=""
                                            src={Twitter}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={styles.right_side}>
                                <img
                                    className={styles.solly20render20x112webmIcon}
                                    alt=""

                                    src={memecoinaboutrightimage.length > 0 ? memecoinaboutrightimage : MainImage}
                                />
                            </div>
                        </div>

                        <div id='Token' className={styles.background1} style={{ backgroundImage: memetokenomicsbackground == 0 ? memecointokonomicsbgimage.length > 0 ? `url(${memecointokonomicsbgimage})` : `` : '', objectFit: 'cover', backgroundSize: 'cover', backgroundColor: memetokenomicsbackground == 1 ? `${memetokenomicsbgcolor}` : memecointokonomicsbgimage.length === 0 ? '#7939c8' : '', }}>
                            <div className={styles.tokenomics} style={{ fontFamily: `${memecointokenfont}`, color: `${memecointoketextcolor}` }}>{template1tokenomics}</div>
                            <div className={styles.neverForgetAbout} style={{ fontFamily: `${memecointokenfont}`, color: `${memecointoketextcolor}` }}>
                                {template1tokenomicssub1}
                            </div>
                            <img
                                className={styles.supplytokenomicspngIcon}
                                alt=""
                                // src={Tokenomic1}
                                src={memecointokenomicsimage.length > 0 ? memecointokenomicsimage : Tokenomic1}
                            />
                            <div className={styles.heading21} style={{ fontFamily: `${memecointokenfont}`, color: `${memecointoketextcolor}` }}>{template1tokenomisupply.label}</div>
                            <div className={styles.div} style={{ fontFamily: `${memecointokenfont}`, color: `${memecointoketextcolor}` }}>{template1tokenomisupply.value}</div>
                            <img
                                className={styles.revokedpngIcon}
                                alt=""
                                // src={Tokenomics3}
                                src={template1tokenimage2.length > 0 ? template1tokenimage2 : Tokenomics2}

                            />
                            <div className={styles.heading22} style={{ fontFamily: `${memecointokenfont}`, color: `${memecointoketextcolor}` }}>{template1tokenomicontract.label}</div>
                            <div className={styles.revoked} style={{ fontFamily: `${memecointokenfont}`, color: `${memecointoketextcolor}` }}>{template1tokenomicontract.value}</div>
                            <img
                                className={styles.taxpngIcon}
                                alt=""
                                src={template1tokenimage3.length > 0 ? template1tokenimage3 : Tokenomics3}
                            />
                            <div className={styles.heading23} style={{ fontFamily: `${memecointokenfont}`, color: `${memecointoketextcolor}` }}>{template1tokenomiTax.label}</div>
                            <div className={styles.buy0Sell} style={{ fontFamily: `${memecointokenfont}`, color: `${memecointoketextcolor}` }}>{template1tokenomiTax.value}</div>

                            <br />
                            <div className={styles.revoked1} style={{ fontFamily: `${memecointokenfont}`, color: `${memecointoketextcolor}` }}>{template1tokenomicssub2}</div>
                            <div className={styles.revoked2} style={{ fontFamily: `${memecointokenfont}`, color: `${memecointoketextcolor}` }}>{template1tokenomicssub3}</div>

                        </div>

                        <div id='HowTOBuy' className={styles.background2} style={{ backgroundImage: memestepbackground == 0 ? `url(${memestepbgimage})` : '', backgroundSize: 'cover', objectFit: 'cover', backgroundColor: memestepbackground == 1 ? `${memestepbgcolor}` : memestepbgimage.length === 0 ? "#981F27" : "" }}>
                            <div className={styles.threelogos}>
                                <div className={styles.link6}
                                    style={{ backgroundColor: `${statsbackgroundcolor}`, border: `${statsborder}px solid ${statsbordercolor}`, borderRadius: `${statsborderradius}px`, boxShadow: statsshowdowrange ? `0px 0px ${statsshowdowrange}px 0px rgba(21, 21, 25, 0.21` : '0px 19px 30px 8px rgba(21, 21, 25, 0.21)' }}

                                >
                                    <img
                                        className={styles.telegrampngIcon}
                                        alt=""
                                        src={ContractImage1}
                                    />
                                </div>
                                <div className={styles.link7}
                                    style={{ backgroundColor: `${statsbackgroundcolor}`, border: `${statsborder}px solid ${statsbordercolor}`, borderRadius: `${statsborderradius}px`, boxShadow: statsshowdowrange ? `0px 0px ${statsshowdowrange}px 0px rgba(21, 21, 25, 0.21` : '0px 19px 30px 8px rgba(21, 21, 25, 0.21)' }}

                                >
                                    <img
                                        className={styles.telegrampngIcon}
                                        alt=""
                                        src={ContractImage2}
                                    />
                                </div>
                                <div className={styles.link8}
                                    style={{ backgroundColor: `${statsbackgroundcolor}`, border: `${statsborder}px solid ${statsbordercolor}`, borderRadius: `${statsborderradius}px`, boxShadow: statsshowdowrange ? `0px 0px ${statsshowdowrange}px 0px rgba(21, 21, 25, 0.21` : '0px 19px 30px 8px rgba(21, 21, 25, 0.21)' }}

                                >
                                    <img
                                        className={styles.telegrampngIcon}
                                        alt=""
                                        src={ContractImage3}
                                    />
                                </div>
                            </div>
                            <div className={styles.contract} style={{ fontFamily: `${memecoinstepfont}`, color: `${memecoinsteptextcolor}`, fontSize: `${memestepfontSize}px` }}>{template1stepcontract}</div>
                            <div className={styles.backgroundshadow}>
                                <div
                                    className={
                                        styles.cegufsuu6xxphpxi62nkxoq438qn8o
                                    }
                                    style={{ fontFamily: `${memecoinstepfont}`, color: `${memecoinsteptextcolor}`, fontSize: `${memestepfontSize}px` }}
                                >
                                    {template1stepAdd}
                                </div>
                            </div>
                        </div>

                        <div id='Footer' className={styles.background3} style={{ backgroundImage: memefootrbackground == 1 ? memefooterbgimage.length !== 0 ? `url(${memefooterbgimage})` : '' : '', objectFit: 'cover', backgroundSize: 'cover', backgroundColor: memefootrbackground == 0 ? memefooterbgcolor.length == 0 ? '#5b2f90' : memefooterbgcolor : '', }}>
                            <div className={styles.heading24} style={{ color: `${memefootertextcolor}`, fontSize: `${memefooterfontSize}px`, fontFamily: `${memefootertextfont}` }}>
                                {memecoinsymbol}{template1footerabout}
                                <br />
                                {memecoinsymbol} © ALL RIGHTS RESERVED

                            </div>


                            <div className={styles.solly2024}></div>
                            <img
                                className={styles.clean20exportpngIcon}
                                alt=""
                                src={template1footerImage.length > 0 ? template1footerImage : FooterIamge}
                            />
                        </div>

                        <div id='Vision'
                            className={styles.background4}
                            style={{ backgroundColor: memeaboutbackground == 0 ? memeaboutbgcolor.length == 0 ? '#981F27' : memeaboutbgcolor : '', backgroundImage: memeaboutbackground == 1 ? memeaboutbgimage.length !== 0 ? `url(${memeaboutbgimage})` : '' : '', }}
                        >
                            <div className={styles.main}>
                                <div className={styles.left}>
                                    <div className={styles.sollyIsTheContainer}>
                                        <span
                                            className={styles.getThisMemeContainer1}
                                        >
                                            <p className={styles.solly2} style={{ fontFamily: `${memecoinvisionfont}`, color: `${memecoinvisiontextcolor}`, fontSize: `${memeaboutfontSize}px` }}>${memecoinsymbol}</p>
                                            <p className={styles.getThisMeme} style={{ fontFamily: `${memecoinvisionfont}`, color: `${memecoinvisiontextcolor}`, fontSize: `${memeaboutfontSize}px` }}>
                                                {memecoinAbout}
                                            </p>

                                        </span>
                                    </div>
                                </div>
                                <div className={styles.right}>
                                    {/* <img
                                    className={
                                        styles.animation20second20section2Icon
                                    }
                                    alt=""
                                    src={Solly}
                                /> */}
                                    {memecoinavisionrightimage.length > 0 ? <img src={memecoinavisionrightimage} style={{ width: '100%', marginTop: 50, height: '100%', objectFit: 'cover', backgroundSize: 'cover' }}></img> :
                                        <img src={Solly} className={
                                            styles.animation20second20section2Icon
                                        } alt="" />}
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null}

                {/* ---------------------------------------------T2---------------------------------------------- */}

                {selectMemeCoinTemplates == 3 ? (
                    <>
                        {(memecoinsection >= 1 && memecoinsection <= 7) ? <UrlNav /> : null}

                        <div className={stylesT2.topgDogvipByHtmltodesignParent} >

                            <div className={stylesT2.topgDogvipByHtmltodesign} >
                                <div
                                    className={stylesT2.section}

                                    style={{ backgroundColor: memeherobackground == 0 ? memeherobgcolor.length == 0 ? '#fe9c34' : memeherobgcolor : '', backgroundImage: memeherobackground == 1 ? memecoinaboutbgImage.length !== 0 ? `url(${memecoinaboutbgImage})` : '' : `url(${Template2sectionbg})`, objectFit: 'cover', backgroundSize: 'cover' }}


                                >


                                    <div className={stylesT2.navBar} >
                                        <div
                                            className={stylesT2.backgroundbordershadow}
                                            style={{ backgroundImage: memebackground == 0 ? `${memenavbgcolor.length > 0 ? `url(${memenavbgcolor})` : ``}` : '', objectFit: 'cover', backgroundSize: 'cover', backgroundColor: memebackground == 1 ? `${memenavbarbgcolor}` : '' }}
                                        >
                                            <div className={stylesT2.dogImage}>
                                                <img
                                                    className={stylesT2.logopngIcon}
                                                    alt=""
                                                    src={memecoinlogoImage.length > 0 ? memecoinlogoImage : Template2Logo}
                                                />
                                                <div className={stylesT2.heading1} style={{ fontFamily: `${memecoinanvfont}`, color: `${memenavtextcolor}`, fontSize: `${memenavfontsize}px` }}>
                                                    {memecoinname}
                                                </div>
                                            </div>

                                            <div className={stylesT2.List}>
                                                <div className={stylesT2.list}>
                                                    <div className={stylesT2.itemLink}>
                                                        <div
                                                            className={
                                                                stylesT2.homeLinkText
                                                            }
                                                            style={{ fontFamily: `${memecoinanvfont}`, color: `${memenavtextcolor}`, fontSize: `${memenavfontsize}px` }}
                                                        >
                                                            {Navbar[0]}
                                                        </div>
                                                    </div>
                                                    <div className={stylesT2.itemLink1}>
                                                        <div
                                                            className={
                                                                stylesT2.aboutLinkText
                                                            }
                                                            style={{ fontFamily: `${memecoinanvfont}`, color: `${memenavtextcolor}`, fontSize: `${memenavfontsize}px` }}
                                                        >
                                                            {Navbar[1]}
                                                        </div>
                                                    </div>
                                                    <div className={stylesT2.itemLink2}>
                                                        <div
                                                            className={
                                                                stylesT2.tokenomicsLinkText
                                                            }
                                                            style={{ fontFamily: `${memecoinanvfont}`, color: `${memenavtextcolor}`, fontSize: `${memenavfontsize}px` }}
                                                        >
                                                            {Navbar[2]}
                                                        </div>
                                                    </div>
                                                    <div className={stylesT2.itemLink3}>
                                                        <div
                                                            className={
                                                                stylesT2.howToBuy
                                                            }
                                                            style={{ fontFamily: `${memecoinanvfont}`, color: `${memenavtextcolor}`, fontSize: `${memenavfontsize}px` }}
                                                        >
                                                            {Navbar[3]}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={stylesT2.leftlogos} style={{ cursor: 'pointer' }}>

                                                    <img
                                                        className={stylesT2.linkIcon}
                                                        alt=""
                                                        src={Template2telegram}
                                                        onClick={Template2opentelegram}
                                                    />

                                                    <img
                                                        className={stylesT2.linkIcon1}
                                                        alt=""
                                                        src={Template2twitter}
                                                        onClick={Template2opentwitter}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={stylesT2.Section1}  >
                                        <div className={stylesT2.left}>
                                            <div className={stylesT2.heading11} style={{ color: `${memecoinabouttextcolor}`, fontFamily: `${memecoinaboutfont}`, fontSize: `${memeMainfontsize}px` }}>
                                                ${memecoinsymbol}

                                            </div>
                                            <div>
                                                <p className={stylesT2.heading6} style={{ color: `${memecoinabouttextcolor}`, fontFamily: `${memecoinaboutfont}`, fontSize: `${memeMainfontsize}px` }}>{Template2memecaption}</p>
                                            </div>
                                            {/* <div className={stylesT2.heading6} style={{ color: `${memecoinabouttextcolor}`, fontFamily: `${memecoinaboutfont}`,fontSize:`${memeMainfontsize}px` }}>
                                        {template2memecaption.slice(7,25)}
                                        
                                    </div> */}
                                            <img
                                                className={stylesT2.logopngIcon1}
                                                alt=""
                                                src={memecoinlogoImage.length > 0 ? memecoinlogoImage : Template2Logo}
                                            />
                                        </div>
                                        <div className={stylesT2.right}>
                                            <div className={stylesT2.welcomeText}>
                                                <span
                                                    className={
                                                        stylesT2.welcomeTextTxtContainer
                                                    }
                                                    style={{ color: `${memecoinabouttextcolor}`, fontFamily: `${memecoinaboutfont}`, fontSize: `${memeMainfontsize}px` }}
                                                >
                                                    <span>{Template2caption.slice(0, 28)} </span>
                                                    <span className={stylesT2.g}>
                                                        ${memecoinsymbol}
                                                    </span>
                                                </span>
                                                <br />
                                                <span className={stylesT2.g} style={{ color: `${memecoinabouttextcolor}`, fontFamily: `${memecoinaboutfont}`, fontSize: `${memeMainfontsize}px` }}>{Template2caption.slice(29,)}</span>

                                            </div>
                                            {/* <div
                                        className={
                                            stylesT2.andrewTatesDogContainer
                                        }
                                    >
                                        <span
                                            className={
                                                stylesT2.welcomeTextTxtContainer
                                            }
                                            style={{ color: `${memecoinabouttextcolor}`, fontFamily: `${memecoinaboutfont}`,fontSize:`${memeMainfontsize}px` }}
                                        >
                                            <span style={{ color: `${memecoinabouttextcolor}`, fontFamily: `${memecoinaboutfont}`,fontSize:`${memeMainfontsize}px` }}>{Template2caption.slice(27,47)}</span>
                                            <span className={stylesT2.on} style={{ color: `${memecoinabouttextcolor}`, fontFamily: `${memecoinaboutfont}`,fontSize:`${memeMainfontsize}px` }}>
                                                {template2caption.slice(48,50)}
                                                
                                            </span>
                                        </span>
                                    </div>
                                    <div
                                        className={stylesT2.ethereumDeclaration}
                                        style={{ color: `${memecoinabouttextcolor}`, fontFamily: `${memecoinaboutfont}`,fontSize:`${memeMainfontsize}px` }}
                                    >
                                       {template2caption.slice(51,60)}
                                        
                                    </div> */}


                                            <div
                                                className={
                                                    stylesT2.memeCoinIntroductionContainer
                                                }
                                            >
                                                <span
                                                    className={
                                                        stylesT2.welcomeTextTxtContainer
                                                    }
                                                >
                                                    <span style={{ color: `${memecoinabouttextcolor}`, fontFamily: `${memecoinaboutfont}`, fontSize: `${memeMainfontsize}px` }}>{Template2info.slice(0, 13)} </span>
                                                    <span className={stylesT2.g} style={{ color: `${memecoinabouttextcolor}`, fontFamily: `${memecoinaboutfont}`, fontSize: `${memeMainfontsize}px` }}>
                                                        {Template2info.slice(14, 15)}{memecoinsymbol}
                                                    </span>
                                                    <span style={{ color: `${memecoinabouttextcolor}`, fontFamily: `${memecoinaboutfont}`, fontSize: `${memeMainfontsize}px` }}> {Template2info.slice(16,)}</span>
                                                </span>
                                            </div>

                                            {/* <div className={stylesT2.marketsTailText} style={{ color: `${memecoinabouttextcolor}`, fontFamily: `${memecoinaboutfont}`,fontSize:`${memeMainfontsize}px` }}>
                                        {template2info.slice(33,)}
                                    </div> */}
                                            <div className={stylesT2.buyJoin}>
                                                <div className={stylesT2.link1}>
                                                    <div
                                                        className={stylesT2.buyLinkText}
                                                        style={{ color: `${memecoinabouttextcolor}`, fontFamily: `${memecoinaboutfont}`, fontSize: `${memeMainfontsize}px` }}
                                                    >
                                                        BUY ${memecoinsymbol}
                                                    </div>
                                                </div>
                                                <div className={stylesT2.link2}>
                                                    <div
                                                        className={
                                                            stylesT2.communityLinkText
                                                        }
                                                        style={{ color: `${memecoinabouttextcolor}`, fontFamily: `${memecoinaboutfont}`, fontSize: `${memeMainfontsize}px` }}
                                                    >
                                                        JOIN COMMUNITY
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={stylesT2.heading31}>
                                                <div className={stylesT2.contractLabel} style={{ color: `${memecoinabouttextcolor}`, fontFamily: `${memecoinaboutfont}`, fontSize: `${memeMainfontsize}px` }}>
                                                    CA :
                                                </div>
                                                <div
                                                    className={stylesT2.contractAddress}
                                                    style={{ color: `${memecoinabouttextcolor}`, fontFamily: `${memecoinaboutfont}`, fontSize: `${memeMainfontsize}px` }}
                                                >
                                                    {template2tokenAddress}
                                                </div>
                                            </div>
                                            <div className={stylesT2.lastlogos}>
                                                <div style={{ display: template2removebuttonuniswap == true ? 'none' : '', cursor: 'pointer' }} className={stylesT2.link3} onClick={Opentemplate2uniswap}>

                                                    <div
                                                        className={
                                                            stylesT2.uniswapLinkText
                                                        }

                                                    >
                                                        Uniswap
                                                    </div>
                                                    <img
                                                        className={stylesT2.unipngIcon}
                                                        alt=""
                                                        src={Template2unicorn}
                                                    />
                                                </div>
                                                <div style={{ display: template2removebuttonunicmc == true ? 'none' : '', cursor: 'pointer' }} className={stylesT2.link4} onClick={OpenTemplate2CMC}>
                                                    <div
                                                        className={stylesT2.cmcLinkText}

                                                    >
                                                        CMC
                                                    </div>
                                                    <img
                                                        className={stylesT2.mpngIcon}
                                                        alt=""
                                                        src={Template2M}

                                                    />
                                                </div>
                                                <div style={{ display: template2removebuttoncoingecko == true ? 'none' : '', cursor: 'pointer' }} className={stylesT2.link5} onClick={OpenTemplate2Coingecko}>
                                                    <div
                                                        className={
                                                            stylesT2.coingeckoLinkText
                                                        }

                                                    >
                                                        CoinGecko
                                                    </div>
                                                    <img
                                                        className={stylesT2.gecopngIcon}
                                                        alt=""
                                                        src={Template2Forg}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={stylesT2.container}></div>
                                </div>



                                <div
                                    className={stylesT2.section1}
                                    // style={{
                                    //     backgroundImage: `url(${Template2sectionbg})`
                                    // }}
                                    style={{ backgroundColor: memeaboutbackground == 0 ? memeaboutbgcolor.length == 0 ? '#fe9c34' : memeaboutbgcolor : '', backgroundImage: memeaboutbackground == 1 ? memeaboutbgimage.length !== 0 ? `url(${memeaboutbgimage})` : '' : `url(${Template2sectionbg})`, objectFit: 'cover', backgroundSize: 'cover' }}
                                >
                                    <div className={stylesT2.Section2}   >
                                        <div className={stylesT2.Left_side}>
                                            <div className={stylesT2.heading12} style={{ fontFamily: `${memecoinvisionfont}`, color: `${memecoinvisiontextcolor}`, fontSize: `${memeaboutfontSize}px` }}>
                                                ABOUT
                                            </div>
                                            <br />
                                            {/* <span     className={stylesT2.welcomeTextTxtContainer} style={{ fontFamily: `${memecoinvisionfont}`, color: `${memecoinvisiontextcolor}`,fontSize:`${memeaboutfontSize}px` }}>${memecoinsymbol}</span> */}

                                            <p className={stylesT2.memeIntroTextContainer} style={{ fontFamily: `${memecoinvisionfont}`, color: `${memecoinvisiontextcolor}`, fontSize: `${memeaboutfontSize}px` }}> {MemecoinAbout}</p>

                                            {/* <div
                                        className={
                                            stylesT2.memeIntroTextContainer
                                        }
                                    >
                                        <span
                                            className={
                                                stylesT2.welcomeTextTxtContainer
                                            }
                                        >
                                            <span style={{ fontFamily: `${memecoinvisionfont}`, color: `${memecoinvisiontextcolor}`,fontSize:`${memeaboutfontSize}px` }}>${memecoinsymbol}</span>
                                            <span className={stylesT2.on} style={{ fontFamily: `${memecoinvisionfont}`, color: `${memecoinvisiontextcolor}`,fontSize:`${memeaboutfontSize}px` }}>
                                              <p className={stylesT2.on} style={{ fontFamily: `${memecoinvisionfont}`, color: `${memecoinvisiontextcolor}`,fontSize:`${memeaboutfontSize}px` }}>{MemecoinAbout}</p>

                                                ${memecoinsymbol}!
                                            </span>
                                        </span>
                                    </div> */}
                                            <br />
                                            <div className={stylesT2.section2logo}>
                                                <div className={stylesT2.link6}>
                                                    <div
                                                        // className={stylesT2.buyLinkText}
                                                        style={{ fontFamily: `${memecoinvisionfont}`, color: `${memecoinvisiontextcolor}`, fontSize: `${memeaboutfontSize}px` }}
                                                    >
                                                        BUY ${memecoinsymbol}
                                                    </div>
                                                </div>
                                                <img
                                                    className={stylesT2.logopngIcon2}
                                                    alt=""
                                                    src={memecoinlogoImage.length > 0 ? memecoinlogoImage : Template2Logo}
                                                />
                                            </div>
                                        </div>
                                        <div className={stylesT2.Right_side}>
                                            <img
                                                className={stylesT2.logopngIcon3}
                                                alt=""
                                                src={memecoinlogoImage.length > 0 ? memecoinlogoImage : Template2Logo}
                                            />
                                        </div>
                                    </div>

                                    <div className={stylesT2.container1}>
                                        <div className={stylesT2.heading32}>
                                            <div className={stylesT2.memeCulture}></div>
                                            <div
                                                className={
                                                    stylesT2.tequilaHumorTextContainer
                                                }
                                            >
                                                <span
                                                    className={
                                                        stylesT2.welcomeTextTxtContainer
                                                    }
                                                ></span>
                                            </div>
                                            <div className={stylesT2.haveAGood}></div>
                                            <div className={stylesT2.partyText}></div>
                                            <div
                                                className={stylesT2.memeCultureText}
                                            ></div>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    className={stylesT2.section2}
                                    // style={{
                                    //     backgroundImage: `url(${Template2sectionbg})`,
                                    // backgroundColor:"#fe9c34",
                                    // }}
                                    // style={{ backgroundImage:memetokenomicsbackground==0? memecointokonomicsbgimage.length > 0 ? `url(${memecointokonomicsbgimage})` : `url(${Tokonemoicsbgimage})`:`url(${Template2sectionbg})`,objectFit: 'cover',backgroundSize: 'cover',backgroundColor:memetokenomicsbackground==1?`${memetokenomicsbgcolor}`:'', }}
                                    style={{ backgroundColor: memetokenomicsbackground == 0 ? memetokenomicsbgcolor.length == 0 ? '#fe9c34' : memetokenomicsbgcolor : '', backgroundImage: memetokenomicsbackground == 1 ? memecointokonomicsbgimage.length !== 0 ? `url(${memecointokonomicsbgimage})` : '' : `url(${Template2sectionbg})`, objectFit: 'cover', backgroundSize: 'cover' }}

                                >
                                    <div className={stylesT2.Section3}  >
                                        <div className={stylesT2.Head}>
                                            <div className={stylesT2.heading13} style={{ fontFamily: `${memecointokenfont}`, color: `${memecointoketextcolor}`, fontSize: `${memetokenfontSize}px` }}>
                                                {template2tokenheading}
                                            </div>
                                        </div>
                                        <div className={stylesT2.S3Main}>
                                            <div className={stylesT2.S3left}>
                                                <img
                                                    className={
                                                        stylesT2.logopngIconSect3
                                                    }
                                                    alt=""
                                                    src={memecoinlogoImage.length > 0 ? memecoinlogoImage : Template2Logo}
                                                />
                                            </div>
                                            <div className={stylesT2.S3right}>
                                                <div className={stylesT2.heading33} style={{ backgroundColor: memetokenomicsbgcolor ? '' : '#ea9a46' }}>
                                                    <div
                                                        className={stylesT2.tokenSupply}
                                                        style={{ fontFamily: `${memecointokenfont}`, color: `${memecointoketextcolor}`, fontSize: `${memetokenfontSize}px` }}
                                                    >
                                                        {template2tokensupply}
                                                    </div>
                                                    <div
                                                        className={
                                                            stylesT2.supplyStatement
                                                        }
                                                        style={{ fontFamily: `${memecointokenfont}`, color: `${memecointoketextcolor}`, fontSize: `${memetokenfontSize}px` }}
                                                    >
                                                        {template2tokentatesupply.slice(0, 5)}{memecoinsymbol} {template2tokentatesupply.slice(6, 28)}
                                                    </div>
                                                    <div className={stylesT2.heading34} style={{ backgroundColor: memetokenomicsbgcolor ? '' : '#ea9a46' }}>
                                                        <div
                                                            className={
                                                                stylesT2.taxPromo
                                                            }
                                                            style={{ fontFamily: `${memecointokenfont}`, color: `${memecointoketextcolor}`, fontSize: `${memetokenfontSize}px` }}
                                                        >
                                                            {template2tokentax}
                                                        </div>
                                                    </div>
                                                    <div className={stylesT2.heading35} style={{ backgroundColor: memetokenomicsbgcolor ? '' : '#ea9a46' }}>
                                                        <div
                                                            className={
                                                                stylesT2.burntLpPromo
                                                            }
                                                            style={{ fontFamily: `${memecointokenfont}`, color: `${memecointoketextcolor}`, fontSize: `${memetokenfontSize}px` }}
                                                        >
                                                            {template2tokenlp}
                                                        </div>
                                                    </div>
                                                    <div className={stylesT2.heading36} style={{ backgroundColor: memetokenomicsbgcolor ? '' : '#ea9a46' }}>
                                                        <div
                                                            className={
                                                                stylesT2.memesPromo
                                                            }
                                                            style={{ fontFamily: `${memecointokenfont}`, color: `${memecointoketextcolor}`, fontSize: `${memetokenfontSize}px` }}
                                                        >
                                                            {template2tokenMeme}
                                                        </div>
                                                    </div>
                                                    <div className={stylesT2.link7}>
                                                        <div
                                                            className={
                                                                stylesT2.joinLinkText
                                                            }
                                                            style={{ fontFamily: `${memecointokenfont}`, color: memecointoketextcolor != 'white' ? `${memecointoketextcolor}` : 'black', fontSize: `${memetokenfontSize}px` }}
                                                        >JOIN WITH ${memecoinsymbol}</div>
                                                        <img
                                                            className={
                                                                stylesT2.tgpngIcon
                                                            }
                                                            alt=""
                                                            src={Template2telegram}
                                                        />
                                                    </div>
                                                    <img
                                                        className={
                                                            stylesT2.logopngIcon6
                                                        }
                                                        alt=""
                                                        src={memecoinlogoImage.length > 0 ? memecoinlogoImage : Template2Logo}
                                                    />
                                                </div>
                                                {/* <img
                                            className={stylesT2.logopngIcon7}
                                            alt=""
                                            src={Template2Logo}
                                        /> */}
                                            </div>
                                        </div>
                                    </div>


                                </div>


                                <div
                                    className={stylesT2.section3}
                                    style={{ backgroundColor: memestepbackground == 0 ? memestepbgcolor.length == 0 ? '#fe9c34' : memestepbgcolor : '', backgroundImage: memestepbackground == 1 ? memestepbgimage.length !== 0 ? `url(${memestepbgimage})` : '' : `url(${Template2sectionbg})`, objectFit: 'cover', backgroundSize: 'cover' }}

                                >
                                    <div className={stylesT2.heading14} style={{ fontFamily: `${memecoinstepfont}`, color: `${memecoinsteptextcolor}`, fontSize: `${memestepfontSize}px` }}>
                                        {template2stepheading}{memecoinsymbol}
                                    </div>

                                    <div className={stylesT2.heading37} style={{ fontFamily: `${memecoinstepfont}`, color: `${memecoinsteptextcolor}`, fontSize: `${memestepfontSize}px` }}>
                                        <div
                                            className={
                                                stylesT2.step1InstructionContainer
                                            }
                                        >
                                            <span
                                                className={
                                                    stylesT2.welcomeTextTxtContainer
                                                }
                                            >
                                                <span style={{ fontFamily: `${memecoinstepfont}`, color: `${memecoinsteptextcolor}`, fontSize: `${memestepfontSize}px` }}>{step1Test1}</span>
                                                <span className={stylesT2.on} style={{ fontFamily: `${memecoinstepfont}`, color: `${memecoinsteptextcolor}`, fontSize: `${memestepfontSize}px` }}>
                                                    {step1Test2}

                                                </span>
                                            </span>
                                        </div>
                                        <div className={stylesT2.appDownloadMethods}>
                                            {step1Test3}
                                        </div>
                                        <div className={stylesT2.appStoreInstruction}>
                                            {step1Test4}
                                        </div>
                                        <div
                                            className={
                                                stylesT2.downloadExtensionInstruction
                                            }
                                        >
                                            {step1Test5}
                                        </div>
                                        <div
                                            className={
                                                stylesT2.extensionLinkInstruction
                                            }
                                        >
                                            {Step1Test6}


                                        </div>

                                        <div
                                            className={
                                                stylesT2.extensionLinkInstruction
                                            }
                                        >


                                            {Step1Test7}
                                        </div>

                                        <div
                                            className={
                                                stylesT2.step2InstructionContainer
                                            }
                                        >

                                            <span
                                                className={
                                                    stylesT2.welcomeTextTxtContainer
                                                }
                                            >
                                                <span style={{ fontFamily: `${memecoinstepfont}`, color: `${memecoinsteptextcolor}`, fontSize: `${memestepfontSize}px` }}>
                                                    {template2step2.slice(0, 34)}
                                                </span>

                                            </span>
                                        </div>
                                        <div
                                            className={stylesT2.ethRequiredInstruction}
                                        >
                                            {template2step2.slice(35, 58)}{memecoinsymbol} {template2step2.slice(65, 82)}
                                        </div>
                                        <div className={stylesT2.ethAcquisitionMethods}>
                                            {template2step2.slice(83, 124)}
                                        </div>
                                        <div
                                            className={
                                                stylesT2.transferMethodInstruction
                                            }
                                        >
                                            {template2step2.slice(125, 166)}
                                        </div>
                                        <div
                                            className={stylesT2.ethTransferInstruction}
                                        >
                                            {template2step2.slice(167, 207)}
                                        </div>
                                        <div className={stylesT2.walletText}>
                                            {template2step2.slice(208, 228)}
                                        </div>
                                        <div
                                            className={
                                                stylesT2.step3InstructionContainer
                                            }
                                        >
                                            <span
                                                className={
                                                    stylesT2.welcomeTextTxtContainer
                                                }
                                            >
                                                <span style={{ fontFamily: `${memecoinstepfont}`, color: `${memecoinsteptextcolor}`, fontSize: `${memestepfontSize}px` }}>
                                                    {template2step3.slice(0, 38)}

                                                </span>
                                                <span className={stylesT2.on} style={{ fontFamily: `${memecoinstepfont}`, color: `${memecoinsteptextcolor}`, fontSize: `${memestepfontSize}px` }}>
                                                    {template2step3.slice(39, 45)}

                                                </span>
                                            </span>
                                        </div>
                                        <div className={stylesT2.appLinkInstruction}>
                                            {template2step3.slice(46, 88)}
                                        </div>
                                        <div
                                            className={stylesT2.uniswapLinkInstruction}
                                        >
                                            {template2step3.slice(89, 129)}
                                        </div>
                                        <div
                                            className={
                                                stylesT2.walletConnectionInstruction
                                            }
                                        >
                                            {template2step3.slice(130, 152)}{memecoinsymbol}{template2step3.slice(159, 176)}
                                        </div>
                                        <div className={stylesT2.confirmationText}>
                                            {template2step3.slice(177, 210)}
                                        </div>
                                        <div
                                            className={
                                                stylesT2.step4InstructionContainer
                                            }
                                        >
                                            <span
                                                className={
                                                    stylesT2.welcomeTextTxtContainer
                                                }
                                            >
                                                <span style={{ fontFamily: `${memecoinstepfont}`, color: `${memecoinsteptextcolor}`, fontSize: `${memestepfontSize}px` }}>{template2step4.slice(0, 22)}{memecoinsymbol}</span>
                                                <span className={stylesT2.on} style={{ fontFamily: `${memecoinstepfont}`, color: `${memecoinsteptextcolor}`, fontSize: `${memestepfontSize}px` }}>
                                                    {template2step4.slice(24, 44)}

                                                </span>
                                            </span>
                                        </div>
                                        <div className={stylesT2.slippageInformation}>
                                            {template2step4.slice(46, 90)}
                                        </div>
                                        <div className={stylesT2.volatilityCaution}>
                                            {template2step4.slice(91, 136)}
                                        </div>
                                        <div className={stylesT2.warningText}>
                                            {template2step4.slice(137, 184)}

                                        </div>
                                    </div>
                                    <div className={stylesT2.link8}>
                                        <div className={stylesT2.buyLinkText} style={{ fontFamily: `${memecoinstepfont}`, color: `${memecoinsteptextcolor}`, fontSize: `${memestepfontSize}px` }}>
                                            BUY ${memecoinsymbol}
                                        </div>
                                    </div>
                                    <div className={stylesT2.link9}>
                                        <div className={stylesT2.viewChartLink} style={{ fontFamily: `${memecoinstepfont}`, color: `${memecoinsteptextcolor}`, fontSize: `${memestepfontSize}px` }}>
                                            View Chart
                                        </div>
                                    </div>
                                </div>



                                <div className={stylesT2.section4} style={{ backgroundImage: memefootrbackground == 1 ? memefooterbgimage.length !== 0 ? `url(${memefooterbgimage})` : '' : '', objectFit: 'cover', backgroundSize: 'cover', backgroundColor: memefootrbackground == 0 ? memefooterbgcolor.length == 0 ? '#fe9c34' : memefooterbgcolor : '', }}>
                                    <img
                                        className={stylesT2.logopngIcon8}
                                        alt=""
                                        src={memecoinlogoImage.length > 0 ? memecoinlogoImage : Template2Logo}
                                    />
                                    <div className={stylesT2.introductionText} style={{ color: `${memefootertextcolor}`, fontFamily: `${memefootertextfont}`, fontSize: `${memefooterfontSize}px` }}>
                                        {template2footeringo}
                                    </div>
                                    <div className={stylesT2.footer} style={{ color: `${memefootertextcolor}`, fontFamily: `${memefootertextfont}`, fontSize: `${memefooterfontSize}px` }}>{test2}</div>


                                </div>


                            </div>
                        </div>
                    </>
                ) : null}

                {/* --------------------------------------------t3-------------------------------------------------- */}

                {selectMemeCoinTemplates == 4 ? (
                    <div className={styleT3.spurdoErcxyzByHtmltodesi}>
                        {(memecoinsection >= 1 && memecoinsection <= 7) ? <UrlNav /> : null}

                        <div
                            className={styleT3.Section1}

                            style={{ backgroundImage: memeherobackground == 0 ? memecoinaboutbgImage.length > 0 ? `url(${memecoinaboutbgImage})` : `url(${Template3BG})` : "", backgroundColor: memeherobackground == 1 ? `${memeherobgcolor}` : "", backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}
                        >
                            <div className={styleT3.Head}>
                                <div className={styleT3.snuppdOnEth}>
                                    <p className={styleT3.p} style={{ fontFamily: memecoinanvfont.length > 0 ? `${memecoinanvfont}` : 'Finger Paint', color: `${memenavtextcolor}`, fontSize: `${memenavfontsize}px` }}>{Navbar[0]}</p>
                                    <img
                                        className={styleT3.containerIcon}
                                        alt=""
                                        src={Template3Container}
                                    />
                                </div>

                                <div className={styleT3.linkBuy}>
                                    <p className={styleT3.lp} style={{ fontFamily: memecoinanvfont.length > 0 ? `${memecoinanvfont}` : 'Finger Paint', color: `${memenavtextcolor}`, fontSize: `${memenavfontsize}px` }}>{Navbar[1]}</p>
                                    <img
                                        className={styleT3.containerIcon1}
                                        alt=""
                                        src={Template3Container}
                                    />
                                </div>
                            </div>
                            <div className={styleT3.middle}>
                                <div className={styleT3.imagCont}>
                                    <img
                                        className={styleT3.containerIcon2}
                                        alt="Template3Sunppd"
                                        src={memecoinAminitionImage.length > 0 ? memecoinAminitionImage : Template3Sunppd}
                                    />
                                </div>
                            </div>
                            <div className={styleT3.bottom}>
                                <div className={styleT3.Iconsbottom}

                                >
                                    <img
                                        className={styleT3.linkIcon}
                                        alt="Template3Telegram"
                                        src={Template3Telegram}
                                        onClick={OpenTemplate3telegram}
                                        style={{ cursor: 'pointer' }}

                                    />
                                    <img
                                        className={styleT3.linkIcon1}
                                        alt="Template3Twitter"
                                        src={Template3Twitter}
                                        onClick={OpenTemplate3twitter}
                                        style={{ cursor: 'pointer' }}

                                    />
                                    <img
                                        className={styleT3.linkIcon2}
                                        alt="Template3Chart"
                                        src={Template3Chart}
                                        onClick={OpenTemplate3Uniswap}
                                        style={{ cursor: 'pointer' }}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className={styleT3.Section2} style={{ backgroundColor: memeaboutbackground == 0 ? memeaboutbgcolor.length == 0 ? ' #000' : memeaboutbgcolor : '', backgroundImage: memeaboutbackground == 1 ? memeaboutbgimage.length !== 0 ? `url(${memeaboutbgimage})` : '' : '', objectFit: 'cover', backgroundSize: 'cover' }}>
                            <div className={styleT3.Sect2Head} style={{ backgroundColor: `${statsbackgroundcolorT3}`, border: `${statsborder.length > 0 ? statsborder : '10'}px solid ${statsbordercolor.length > 0 ? statsbordercolor : 'white'}`, borderRadius: `${statsborderradius.length > 0 ? statsborderradius : '30'}px`, boxShadow: statsshowdowrange ? `0px 0px ${statsshowdowrange}px 0px rgba(21, 21, 25, 0.21` : '0px 19px 30px 8px rgba(21, 21, 25, 0.21)' }}>
                                <p className={styleT3.welgomeDoDa} style={{ fontFamily: memecoinvisionfont.length > 0 ? `${memecoinvisionfont}` : 'Finger Paint', color: `${memecoinvisiontextcolor}`, fontSize: `${memeaboutfontSize}px` }}>
                                    {template3heading.slice(0, 22)} <br />
                                    <p className={styleT3.s3p} style={{ fontFamily: memecoinvisionfont.length > 0 ? `${memecoinvisionfont}` : 'Finger Paint', color: `${memecoinvisiontextcolor}`, fontSize: `${memeaboutfontSize}px` }}>

                                        {template3heading.slice(23, 30)} {memecoinname} {template3heading.slice(31, 43)}
                                    </p>
                                </p>
                            </div>
                            <div className={styleT3.Sect3Middle}>
                                <div className={styleT3.gedDdUrContainer2}>
                                    <span
                                        className={
                                            styleT3.dedicatedCommunityTxtContainer
                                        }
                                    >
                                        <p className={styleT3.welgomeDoDa} style={{ fontFamily: memecoinvisionfont.length > 0 ? `${memecoinvisionfont}` : 'Finger Paint', color: `${memecoinvisiontextcolor}`, fontSize: `${memeaboutfontSize}px` }}>

                                            {memecoinAbout}
                                        </p>
                                    </span>
                                </div>

                            </div>
                            <div className={styleT3.extra}>
                                <div className={styleT3.Sect3bottom}>
                                    <div className={styleT3.Sect3Front}>
                                        <img
                                            className={styleT3.containerIcon3}
                                            alt=""
                                            src={memecoinavisionleftimage.length > 0 ? memecoinavisionleftimage : Template3Maks}
                                        />
                                    </div>
                                    <div className={styleT3.Imgbottom}>
                                        <img
                                            className={styleT3.maskGroupIcon}
                                            alt=""
                                            src={memecoinavisionrightimage.length > 0 ? memecoinavisionrightimage : Template3Section3svg}
                                        />
                                    </div>
                                </div>
                            </div>

                        </div>


                        <div className={styleT3.Section3} style={{ backgroundImage: memetokenomicsbackground == 0 ? memecointokonomicsbgimage.length > 0 ? `url(${memecointokonomicsbgimage})` : `` : '', width: '100%', height: '100%', objectFit: 'cover', backgroundSize: 'cover', backgroundColor: memetokenomicsbackground == 1 ? `${memetokenomicsbgcolor}` : memecointokonomicsbgimage.length == 0 ? '#000' : '', }}>
                            <div className={styleT3.Svg}>
                                <div className={styleT3.SvgInner}>
                                    <div className={styleT3.whyGhooseSburdo} style={{ fontFamily: memecointokenfont.length > 0 ? `${memecointokenfont}` : 'Finger Paint', color: `${memecointoketextcolor}`, fontSize: `${memetokenfontSize}px` }}>
                                        Why ghoose {memecoinname} ?
                                    </div>
                                </div>
                            </div>

                            <div className={styleT3.Card} >
                                <div className={styleT3.cards} style={{ fontFamily: memecointokenfont.length > 0 ? `${memecointokenfont}` : 'Finger Paint', color: `${memecointoketextcolor}`, fontSize: `${memetokenfontSize}px`, backgroundColor: `${statsbackgroundcolorT3}`, border: `${statsborder.length > 0 ? statsborder : '10'}px solid ${statsbordercolor.length > 0 ? statsbordercolor : 'white'}`, borderRadius: `${statsborderradius.length > 0 ? statsborderradius : '20'}px`, boxShadow: statsshowdowrange ? `0px 0px ${statsshowdowrange}px 0px rgba(21, 21, 25, 0.21` : '0px 19px 30px 8px rgba(21, 21, 25, 0.21)' }}

                                >
                                    <p >{template3section1.slice(0, 13)}</p>
                                    <p className={styleT3.welgomeDoDa}>

                                        {template3section1.slice(14, 36)}
                                    </p>
                                    <p className={styleT3.welgomeDoDa}>

                                        {template3section1.slice(37, 62)}
                                    </p>
                                    <p className={styleT3.welgomeDoDa}>
                                        {template3section1.slice(63, 94)}
                                    </p>
                                    <p className={styleT3.welgomeDoDa}>
                                        {template3section1.slice(95, 124)}
                                    </p>
                                    <p className={styleT3.welgomeDoDa}>
                                        {template3section1.slice(127, 137)} {memecoinsymbol}
                                    </p>
                                </div>
                                <div className={styleT3.cards}
                                    style={{ fontFamily: memecointokenfont.length > 0 ? `${memecointokenfont}` : 'Finger Paint', color: `${memecointoketextcolor}`, fontSize: `${memetokenfontSize}px`, backgroundColor: `${statsbackgroundcolorT3}`, border: `${statsborder.length > 0 ? statsborder : '10'}px solid ${statsbordercolor.length > 0 ? statsbordercolor : 'white'}`, borderRadius: `${statsborderradius.length > 0 ? statsborderradius : '20'}px`, boxShadow: statsshowdowrange ? `0px 0px ${statsshowdowrange}px 0px rgba(21, 21, 25, 0.21` : '0px 19px 30px 8px rgba(21, 21, 25, 0.21)' }}>
                                    <p>{template3Section2.slice(0, 12)}</p>
                                    <p className={styleT3.welgomeDoDa}>
                                        {template3Section2.slice(12, 39)}
                                    </p>
                                    <p className={styleT3.welgomeDoDa}>
                                        {template3Section2.slice(40, 62)}
                                    </p>
                                    <p className={styleT3.welgomeDoDa}>
                                        {template3Section2.slice(62, 86)}
                                    </p>
                                    <p
                                        className={styleT3.welgomeDoDa}
                                    >{template3Section2.slice(86, 110)}</p>
                                </div>
                                <div className={styleT3.cards}
                                    style={{ fontFamily: memecointokenfont.length > 0 ? `${memecointokenfont}` : 'Finger Paint', color: `${memecointoketextcolor}`, fontSize: `${memetokenfontSize}px`, backgroundColor: `${statsbackgroundcolorT3}`, border: `${statsborder.length > 0 ? statsborder : '10'}px solid ${statsbordercolor.length > 0 ? statsbordercolor : 'white'}`, borderRadius: `${statsborderradius.length > 0 ? statsborderradius : '20'}px`, boxShadow: statsshowdowrange ? `0px 0px ${statsshowdowrange}px 0px rgba(21, 21, 25, 0.21` : '0px 19px 30px 8px rgba(21, 21, 25, 0.21)' }}>
                                    <p>{template3Section3.slice(0, 25)}</p>
                                    <p className={styleT3.welgomeDoDa}>
                                        {memecoinname} {template3Section3.slice(25, 48)}
                                    </p>
                                    <p className={styleT3.welgomeDoDa}>
                                        {template3Section3.slice(48, 78)}
                                    </p>
                                    <p className={styleT3.welgomeDoDa}>
                                        {template3Section3.slice(78, 105)}
                                    </p>
                                    <p
                                        className={styleT3.welgomeDoDa}
                                    >{template3Section3.slice(106, 130)}</p>
                                </div>
                                <div className={styleT3.cards}
                                    style={{ fontFamily: memecointokenfont.length > 0 ? `${memecointokenfont}` : 'Finger Paint', color: `${memecointoketextcolor}`, fontSize: `${memetokenfontSize}px`, backgroundColor: `${statsbackgroundcolorT3}`, border: `${statsborder.length > 0 ? statsborder : '10'}px solid ${statsbordercolor.length > 0 ? statsbordercolor : 'white'}`, borderRadius: `${statsborderradius.length > 0 ? statsborderradius : '20'}px`, boxShadow: statsshowdowrange ? `0px 0px ${statsshowdowrange}px 0px rgba(21, 21, 25, 0.21` : '0px 19px 30px 8px rgba(21, 21, 25, 0.21)' }}>
                                    <p>{template3Section4.slice(0, 19)}</p>
                                    <p className={styleT3.welgomeDoDa}>
                                        {template3Section4.slice(19, 42)}
                                    </p>
                                    <p className={styleT3.welgomeDoDa}>
                                        {template3Section4.slice(42, 71)}
                                    </p>
                                    <p className={styleT3.welgomeDoDa}>
                                        {template3Section4.slice(71, 91)} {memecoinname}
                                    </p>
                                    <p className={styleT3.welgomeDoDa}>
                                        {template3Section4.slice(92, 120)}
                                    </p>
                                    <p className={styleT3.welgomeDoDa}>
                                        {template3Section4.slice(120, 150)}
                                    </p>
                                </div>
                            </div>
                        </div>


                        <div className={styleT3.Section4}
                            style={{ backgroundImage: memestepbackground == 0 ? `url(${memestepbgimage})` : '', height: '100%', width: '100%', backgroundSize: 'cover', objectFit: 'cover', backgroundColor: memestepbackground == 1 ? `${memestepbgcolor}` : memestepbgimage.length === 0 ? "#834d3b" : '' }}
                        >
                            <div className={styleT3.Sect4Main}>

                                <div className={styleT3.Back}>

                                </div>
                                <div className={styleT3.Front}>
                                    <img
                                        className={styleT3.containerIcon7}
                                        alt="6"
                                        src={memecoinlogoImage.length > 0 ? memecoinlogoImage : Template3SectionSnuppd}
                                    />
                                </div>
                            </div>
                            <div className={styleT3.Sect4right} style={{ fontFamily: memecoinstepfont.length > 0 ? `${memecoinstepfont}` : 'Finger Paint', color: `${memecoinsteptextcolor}`, fontSize: `${memestepfontSize}px` }}>
                                <div className={styleT3.whatIsSnuppd} >
                                    {template3stepquestion} <br /> {memecoinsymbol}?
                                </div>
                                <span
                                    className={
                                        styleT3.dedicatedCommunityTxtContainer
                                    }
                                >
                                    <p className={styleT3.welgomeDoDa1}>
                                        {memecoinname} {template3stepsection1.slice(0, 31)}
                                    </p>
                                    <p className={styleT3.welgomeDoDa1}>
                                        {template3stepsection1.slice(32, 75)}
                                    </p>
                                    <p className={styleT3.welgomeDoDa1}>
                                        {template3stepsection1.slice(76, 106)}
                                    </p>
                                    <p className={styleT3.welgomeDoDa1}>
                                        {template3stepsection1.slice(107, 144)}
                                    </p>
                                    <p className={styleT3.welgomeDoDa1}>
                                        {template3stepsection1.slice(145, 160)} {memecoinname} {template3stepsection1.slice(161, 172)}
                                    </p>
                                    <p className={styleT3.welgomeDoDa1}>
                                        {template3stepsection1.slice(172, 212)}
                                    </p>
                                    <p className={styleT3.welgomeDoDa1}>
                                        {template3stepsection1.slice(212, 252)}
                                    </p>
                                </span>
                            </div>
                        </div>


                        <div className={styleT3.Section5}
                            style={{ backgroundImage: memestepbackgroundt3 == 0 ? `url(${template3stepsection2bg})` : '', height: '100%', width: '100%', backgroundSize: 'cover', objectFit: 'cover', backgroundColor: memestepbackgroundt3 == 1 ? `${template3stepsection2color}` : template3stepsection2bg.length === 0 ? "#834d3b" : '' }}

                        >
                            <div className={styleT3.Sect5Left} style={{ fontFamily: memecoinstepfont.length > 0 ? `${memecoinstepfont}` : 'Finger Paint', color: `${memecoinsteptextcolor}`, fontSize: `${memestepfontSize}px` }}>
                                <div className={styleT3.ourMission}>
                                    {template3step3section2heading}
                                </div>
                                <span
                                    className={
                                        styleT3.dedicatedCommunityTxtContainer
                                    }
                                >
                                    <p className={styleT3.welgomeDoDa5}>
                                        {template3stepsection2.slice(0, 4)} {memecoinname} {template3stepsection2.slice(5, 24)}
                                    </p>
                                    <p className={styleT3.welgomeDoDa5}>
                                        {template3stepsection2.slice(25, 54)}
                                    </p>
                                    <p className={styleT3.welgomeDoDa5}>
                                        {template3stepsection2.slice(55, 87)}
                                    </p>
                                    <p className={styleT3.welgomeDoDa5}>
                                        {template3stepsection2.slice(87, 115)}
                                    </p>
                                    <p className={styleT3.welgomeDoDa5}>
                                        {template3stepsection2.slice(115, 150)}
                                    </p>
                                </span>
                            </div>
                            <div className={styleT3.Sect5Right}>
                                {/* <img className={styleT3.containerIcon9} alt="" src="Background.png"  /> */}

                                <div className={styleT3.front}>

                                </div>
                                <div className={styleT3.back}>
                                    <img
                                        className={styleT3.containerIcon10}
                                        alt="3"
                                        src={template3stepimage.length > 0 ? template3stepimage : Template3DogImage}
                                    />
                                </div>
                            </div>
                        </div>




                        <div
                            className={styleT3.Section6}


                            style={{ backgroundImage: memefootrbackground == 1 ? memefooterbgimage.length !== 0 ? `url(${memefooterbgimage})` : '' : memefooterbgcolor.length === 0 ? `url(${Template3BG})` : '', backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundColor: memefootrbackground == 0 ? memefooterbgcolor.length == 0 ? '' : memefooterbgcolor : '', }}
                        >
                            <div className={styleT3.Joinus} style={{ backgroundColor: `${statsbackgroundcolorT3}`, border: `${statsborder.length > 0 ? statsborder : '8'}px solid ${statsbordercolor != 'white' ? statsbordercolor : 'black'}`, borderRadius: `${statsborderradius.length > 0 ? statsborderradius : '10'}px`, boxShadow: statsshowdowrange ? `0px 0px ${statsshowdowrange}px 0px rgba(21, 21, 25, 0.21` : '0px 19px 30px 8px rgba(21, 21, 25, 0.21)' }}>
                                <div className={styleT3.joinUs} style={{ color: `${memefootertextcolor}`, fontFamily: `${memefootertextfont}`, fontSize: `${memefooterfontSize}px` }}>{template3footerheading}</div>
                            </div>
                            <div className={styleT3.Sect6content} style={{ backgroundColor: `${statsbackgroundcolorT3}`, border: `${statsborder.length > 0 ? statsborder : '10'}px solid ${statsbordercolor != 'white' ? statsbordercolor : 'black'}`, borderRadius: `${statsborderradius.length > 0 ? statsborderradius : '10'}px`, boxShadow: statsshowdowrange ? `0px 0px ${statsshowdowrange}px 0px rgba(21, 21, 25, 0.21` : '0px 19px 30px 8px rgba(21, 21, 25, 0.21)' }}>
                                <p className={styleT3.welgomeDoDa6} style={{ color: `${memefootertextcolor}`, fontFamily: memefootertextfont.length > 0 ? `${memefootertextfont}` : 'Finger Paint', fontSize: `${memefooterfontSize}px` }}>
                                    {template3footerinfo.slice(0, 48)}
                                    {memecoinname}
                                    {template3footerinfo.slice(48, 150)}
                                </p>
                            </div>
                            <div className={styleT3.Sect6Button}>
                                <div className={styleT3.wrap} style={{ cursor: 'pointer' }} onClick={OpenTemplate3twitter}>
                                    <img
                                        className={styleT3.containerIcon15}
                                        alt="6"
                                        src={Template3Footertwitter}
                                    />
                                    <div className={styleT3.linkTwitter} style={{ color: `${memefootertextcolor}`, fontFamily: memefootertextfont.length > 0 ? `${memefootertextfont}` : 'Finger Paint', fontSize: `${memefooterfontSize}px` }} >
                                        Twitter
                                    </div>
                                </div>
                                <div className={styleT3.wrap} style={{ cursor: 'pointer' }} onClick={OpenTemplate3telegram}>
                                    <img
                                        className={styleT3.containerIcon15}
                                        alt="6"
                                        src={Template3Footertwitter}
                                    />
                                    <div className={styleT3.linkTelegram} style={{ color: `${memefootertextcolor}`, fontFamily: memefootertextfont.length > 0 ? `${memefootertextfont}` : 'Finger Paint', fontSize: `${memefooterfontSize}px` }}>
                                        Telegram
                                    </div>
                                </div>
                                <div className={styleT3.wrap} style={{ cursor: 'pointer' }} onClick={OpenTemplate3Uniswap}>
                                    <img
                                        className={styleT3.containerIcon15}
                                        alt="6"
                                        src={Template3Footertwitter}
                                    />
                                    <div className={styleT3.linkDextools} style={{ color: `${memefootertextcolor}`, fontFamily: memefootertextfont.length > 0 ? `${memefootertextfont}` : 'Finger Paint', fontSize: `${memefooterfontSize}px` }}>
                                        Dextools
                                    </div>
                                </div>
                            </div>

                            <div
                                className={styleT3.Section7}
                            // style={{
                            //     backgroundImage: `url(${Template3BG})`,
                            //     backgroundSize: 'cover',
                            //     backgroundPosition: 'center',
                            //     backgroundRepeat: 'no-repeat'
                            // }}
                            >
                                <div className={styleT3.mainbanner}>
                                    <div className={styleT3.innerMain}>
                                        <img
                                            className={styleT3.containerIcon12}
                                            alt="3"
                                            src={template1footerImage.length > 0 ? template1footerImage : Template3FooterSunppd}
                                        />
                                    </div>
                                </div>
                                <div className={styleT3.Footer}>
                                    <div className={styleT3.Sect7Code} style={{ backgroundColor: `${statsbackgroundcolorT3}`, border: `${statsborder.length > 0 ? statsborder : '10'}px solid ${statsbordercolor != 'white' ? statsbordercolor : 'black'}`, borderRadius: `${statsborderradius.length > 0 ? statsborderradius : '10'}px`, boxShadow: statsshowdowrange ? `0px 0px ${statsshowdowrange}px 0px rgba(21, 21, 25, 0.21` : '0px 19px 30px 8px rgba(21, 21, 25, 0.21)' }}>
                                        <div
                                            className={
                                                styleT3.ca0x3927fb89f34bbee63351a63
                                            }
                                            style={{ color: `${memefootertextcolor}`, fontFamily: memefootertextfont.length > 0 ? `${memefootertextfont}` : 'Finger Paint', fontSize: `${memefooterfontSize}px` }}

                                        >
                                            {template3memeaddress}
                                        </div>
                                    </div>
                                    <div className={styleT3.Sect7last} onClick={OpenTemplate3Email} >
                                        <img
                                            class
                                            Name={styleT3.containerIcon11}
                                            alt="2"
                                            src={Template3FOoterEmail}
                                        />

                                        <h4 style={{ cursor: 'pointer', color: `${memefootertextcolor}`, fontFamily: memefootertextfont.length > 0 ? `${memefootertextfont}` : 'Finger Paint', fontSize: `${memefooterfontSize}px` }}>{template3email}</h4>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                ) : null}


                {/* -----------------------------------------------t4----------------------------------------------------- */}
                {selectMemeCoinTemplates == 6 ? <div style={{ position: 'relative', height: "100%", width: "100%", }}>
                    {(memecoinsection >= 1 && memecoinsection <= 7) ? <UrlNav /> : null}

                    <div style={{
                        position: 'relative', height: "108px", width: "100%", display: "flex", alignItems: "center",
                        backgroundImage: memebackground == 0 ? `${memenavbgcolor.length > 0 ? `url(${memenavbgcolor})` : ``}` : '', objectFit: 'cover', backgroundSize: 'cover', backgroundColor: memebackground == 1 ? `${memenavbarbgcolor}` : '',

                    }}>

                        <div style={{ position: "relative", width: '30%', display: "flex", alignItems: "center", padding: "10px", paddingLeft: "35px" }} >
                            <img
                                src={memecoinlogoImage.length > 0 ? memecoinlogoImage : T4Navlogo}
                                alt='logo' height={'70px'}></img>

                        </div>
                        <div style={{ position: "relative", width: '70%', display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                            <span className={styleT4.NavLabel} style={{ fontFamily: `${memecoinanvfont}`, color: `${memenavtextcolor}`, fontSize: `${memenavfontsize}px` }}>{Navbar[0]}</span>
                            <span className={styleT4.NavLabel} style={{ fontFamily: `${memecoinanvfont}`, color: `${memenavtextcolor}`, fontSize: `${memenavfontsize}px` }}>{Navbar[1]}</span>
                            <span className={styleT4.NavLabel} style={{ fontFamily: `${memecoinanvfont}`, color: `${memenavtextcolor}`, fontSize: `${memenavfontsize}px` }}>{Navbar[2]}</span>
                            <span className={styleT4.NavLabel} style={{ fontFamily: `${memecoinanvfont}`, color: `${memenavtextcolor}`, fontSize: `${memenavfontsize}px` }}>{Navbar[3]}</span>
                            <button className={styleT4.buyPremt} style={{ fontFamily: `${memecoinanvfont}`, color: `${memenavtextcolor}`, fontSize: `${memenavfontsize}px`,cursor:'pointer' }} onClick={()=>window.open(`${template2coingecko}`)}>{t4navbutton}</button>


                        </div>

                    </div>


                    <div
                        style={{
                            position: "relative",
                            height: "100%",
                            width: "100%",

                            backgroundImage: memeherobackground == 0 ? memecoinaboutbgImage.length > 0 ? `url(${memecoinaboutbgImage})` : `url(${T4MainSectionImage})` : "",

                            backgroundPositionY: "center",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                        }}
                    >
                        <div style={{
                            backgroundColor: "#254a9a", opacity: '0.9', position: "relative",
                            backgroundColor: memeherobackground == 1 ? `${memeherobgcolor}` : "#254a9a",
                            height: "100%",
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }}>

                            <div style={{ color: "white", width: '50%', display: "flex", flexDirection: "column", alignItems: "start", justifyContent: "center", marginLeft: "50px", overflow: 'hidden' }}>
                                <div className={styleT4.mekMemesGretContainer} style={{ color: `${memecoinabouttextcolor}`, fontFamily: `${memecoinaboutfont}`, fontSize: `${memeMainfontsize}px` }}>

                                    {t4caption}
                                </div>
                                <div style={{ color: `${memecoinabouttextcolor}`, fontFamily: `${memecoinaboutfont}`, fontSize: `${memeMainfontsize}px`, lineBreak: 'anywhere' }}>
                                    {t4memeaddress}
                                </div>
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: '20px', marginTop: "30px" }}>
                                    <button
                                        style={{
                                            width: '150px',
                                            position: 'relative',
                                            borderRadius: '6.4px',
                                            backgroundColor: '#d93731',
                                            height: '69.2px',
                                            textAling: 'center',
                                            fontSize: '16px',
                                            color: memecoinabouttextcolor.length > 0 ? `${memecoinabouttextcolor}` : '#fff',
                                            fontFamily: 'Inter',
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            color: `${memecoinabouttextcolor}`, fontFamily: `${memecoinaboutfont}`, fontSize: `${memeMainfontsize}px`,
                                            cursor:'pointer',
                                        }}
                                        onClick={()=>window.open(`${template2coingecko}`)}
                                        >{t4mainbutton1}</button>
                                    <button
                                        style={{
                                            width: '150px',
                                            position: 'relative',
                                            borderRadius: '6.4px',
                                            backgroundColor: 'blue',
                                            height: '69.2px',
                                            textAling: 'center',
                                            fontSize: '16px',
                                            color: memecoinabouttextcolor.length > 0 ? `${memecoinabouttextcolor}` : '#fff',
                                            fontFamily: 'Inter',
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            opacity: '1',
                                            fontFamily: `${memecoinaboutfont}`, fontSize: `${memeMainfontsize}px`,
                                            cursor:'pointer',
                                        }}
                                         onClick={()=>window.open(`${memetwitterlink}`)}
                                        >{t4mainbutton2}</button>
                                </div>
                            </div>
                            <div style={{ color: "white", width: '50%', display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <img src={memecoinAminitionImage.length > 0 ? memecoinAminitionImage : T4Memelogo} alt="logo" height={'100%'}></img>
                            </div>
                        </div>

                    </div>

                    <div
                        style={{
                            position: "relative",
                            height: "100%",
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",

                            padding: "50px",
                            backgroundColor: memeaboutbackground == 0 ? memeaboutbgcolor.length == 0 ? 'white' : memeaboutbgcolor : '', backgroundImage: memeaboutbackground == 1 ? memeaboutbgimage.length !== 0 ? `url(${memeaboutbgimage})` : '' : '', objectFit: 'cover', backgroundSize: 'cover'
                        }}
                    >
                        <div
                            style={{
                                position: "relative",
                                height: "100%",
                                width: "50%",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "start",
                                gap: "20px"
                            }}
                        >
                            <div
                                style={{

                                    textTransform: 'uppercase',
                                    fontWeight: '800',
                                    fontFamily: 'Inter',
                                    color: memecoinvisiontextcolor.length > 0 ? `${memecoinvisiontextcolor}` : '#254a9a',
                                    textAling: 'left',
                                    display: 'flex',
                                    alignItem: 'center',
                                    height: 'fit-content',
                                    overflow: 'hidden',
                                    lineBreak: 'anywhere',
                                    fontFamily: `${memecoinvisionfont}`, fontSize: `${memeaboutfontSize}px`,
                                    width: "70%"

                                }}
                            >{memecoinname}</div>
                            <div
                                style={{
                                    width: '95%',
                                    position: 'relative',
                                    fontSize: '14.5px',
                                    letterSpacing: '-0.19px',
                                    lineHeight: '29.18px',
                                    fontFamily: 'Inter',
                                    color: memecoinvisiontextcolor.length > 0 ? `${memecoinvisiontextcolor}` : '#254a9a',
                                    textALing: 'left',
                                    display: 'flex',
                                    alignItems: 'center',
                                    height: 'fit-content',
                                    marginTop: '10px',
                                    overflow: 'hidden',
                                    fontFamily: `${memecoinvisionfont}`, fontSize: `${memeaboutfontSize}px`,
                                    lineBreak: 'anywhere'
                                }}
                            >
                                {memecoinAbout}
                            </div>
                            <div
                                style={{
                                    width: '179.7px',
                                    position: 'relative',
                                    fontSize: '19.5px',
                                    letterSpacing: '-0.19px',
                                    lineHeight: '29.18px',
                                    fontFamily: 'Inter',
                                    color: memecoinvisiontextcolor.length > 0 ? `${memecoinvisiontextcolor}` : '#254a9a',
                                    textAling: 'left',
                                    display: 'flex',
                                    alignItems: 'center',
                                    height: 'fit-content',
                                    overflow: 'hidden',
                                    fontFamily: `${memecoinvisionfont}`, fontSize: `${memeaboutfontSize}px`,
                                    lineBreak: 'anywhere',
                                }}
                            >${memecoinsymbol}</div>
                            <div>
                                <img src={T4twitter} alt='log' height={'46px'} style={{ cursor: 'pointer' }}  onClick={()=>window.open(`${memetwitterlink}`)}></img>
                                <img src={T4telegram} alt='log' height={'46px'} style={{ cursor: 'pointer' }} onClick={()=>window.open(`${memetelegramlink}`)}></img>
                                <img src={T4Instagram} alt='log' height={'46px'} style={{ cursor: 'pointer' }} onClick={()=>window.open(`${memeinstagramlink}`)}></img>
                                <img src={T4trend} alt='log' height={'46px'} style={{ cursor: 'pointer' }} onClick={()=>window.open(`${template2coingecko}`)}></img>

                            </div>

                        </div>
                        <div
                            style={{
                                position: "relative",
                                height: "100%",
                                width: "50%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <img src={memecoinavisionleftimage.length > 0 ? memecoinavisionleftimage : T4MemeImage1} alt="logo" height={'100%'} width={'100%'}></img>
                        </div>

                    </div>

                    <div
                        style={{


                            backgroundImage: memestepbackground == 0 ? `url(${memestepbgimage})` : 'url(${T4Section3bgImage})',
                            position: "relative",
                            width: '100%',
                            height: '100%',
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',

                        }}
                    >
                        <div
                            style={{
                                position: 'relative',
                                height: '100%',
                                width: '100%',

                                backgroundColor: memestepbackground == 1 ? `${memestepbgcolor}` : "#254a9a",
                                opacity: "0.9",
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',

                                padding: '50px',

                            }}

                        >
                            <div
                                style={{
                                    color: 'white',
                                    position: 'relative',
                                    height: "100%",
                                    width: "50%",
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',

                                }}
                            >
                                <span
                                    style={{
                                        position: 'relative',


                                        textTransform: 'uppercase',
                                        fontWeight: '800',


                                        textAling: 'left',
                                        display: 'flex',
                                        alignItems: 'center',
                                        height: 'fit-content',
                                        width: "90%",
                                        lineBreak: 'anywhere',
                                        fontFamily: memecoinstepfont.length > 0 ? `${memecoinstepfont}` : "Inter", color: memecoinsteptextcolor.length > 0 ? `${memecoinsteptextcolor}` : "white", fontSize: '81.93px',
                                    }}
                                >{t4step1}</span>
                                <img src={t4stepimage.length > 0 ? t4stepimage : T4MemeImage2} alt="logo" height={'100%'}></img>
                            </div>
                            <div
                                style={{
                                    color: 'white',
                                    position: 'relative',
                                    height: "100%",
                                    width: "50%",
                                    display: 'flex',
                                    alignItems: 'start',
                                    justifyContent: 'center',
                                    flexDirection: "column",
                                    marginTop: "30px",


                                }}

                            >
                                <span
                                    style={{
                                        textTransformation: 'uppercase',
                                        fontWeight: '800',

                                        width: "167.6px",
                                        height: "29px",
                                        fontFamily: memecoinstepfont.length > 0 ? `${memecoinstepfont}` : "Inter", color: memecoinsteptextcolor.length > 0 ? `${memecoinsteptextcolor}` : "white", fontSize: memestepfontSize != 0 ? `${memestepfontSize}px` : '17.1px',


                                    }}
                                >CREATE A WALLET</span>
                                <span
                                    style={{
                                        fontSize: "14.3px",
                                        width: '70%',
                                        fontFamily: "Inter",
                                        color: 'white',
                                        overflow: 'hidden',
                                        height: "fit-content",
                                        lineBreak: 'anywhere',
                                        fontFamily: memecoinstepfont.length > 0 ? `${memecoinstepfont}` : "Inter", color: memecoinsteptextcolor.length > 0 ? `${memecoinsteptextcolor}` : "white", fontSize: memestepfontSize != 0 ? `${memestepfontSize}px` : '14.3px',


                                    }}
                                >
                                    {t4step2}
                                </span>
                                <span
                                    style={{
                                        textTransformation: 'uppercase',
                                        fontWeight: '800',

                                        width: "157.6px",
                                        height: "29px",
                                        fontFamily: memecoinstepfont.length > 0 ? `${memecoinstepfont}` : "Inter", color: memecoinsteptextcolor.length > 0 ? `${memecoinsteptextcolor}` : "white", fontSize: memestepfontSize != 0 ? `${memestepfontSize}px` : '17.1px',

                                    }}
                                >Get some sol</span>
                                <span
                                    style={{
                                        width: '70%',

                                        overflow: 'hidden',
                                        height: "fit-content",
                                        lineBreak: 'anywhere',
                                        fontFamily: memecoinstepfont.length > 0 ? `${memecoinstepfont}` : "Inter", color: memecoinsteptextcolor.length > 0 ? `${memecoinsteptextcolor}` : "white", fontSize: memestepfontSize != 0 ? `${memestepfontSize}px` : '14.3px',

                                    }}
                                >{t4step3}</span>
                                <span
                                    style={{
                                        textTransformation: 'uppercase',
                                        fontWeight: '800',

                                        width: "157.6px",
                                        height: "29px",
                                        fontFamily: memecoinstepfont.length > 0 ? `${memecoinstepfont}` : "Inter", color: memecoinsteptextcolor.length > 0 ? `${memecoinsteptextcolor}` : "white", fontSize: memestepfontSize != 0 ? `${memestepfontSize}px` : '17.1px',

                                    }}
                                >
                                    GO TO JUPITER</span>
                                <span
                                    style={{

                                        width: '70%',

                                        overflow: 'hidden',
                                        height: "fit-content",
                                        lineBreak: 'anywhere',
                                        fontFamily: memecoinstepfont.length > 0 ? `${memecoinstepfont}` : "Inter", color: memecoinsteptextcolor.length > 0 ? `${memecoinsteptextcolor}` : "white", fontSize: memestepfontSize != 0 ? `${memestepfontSize}px` : '14.3px',

                                    }}
                                >{t4step4}</span>
                                <span
                                    style={{
                                        textTransformation: 'uppercase',
                                        fontWeight: '800',

                                        width: "157.6px",
                                        height: "29px",
                                        fontFamily: memecoinstepfont.length > 0 ? `${memecoinstepfont}` : "Inter", color: memecoinsteptextcolor.length > 0 ? `${memecoinsteptextcolor}` : "white", fontSize: memestepfontSize != 0 ? `${memestepfontSize}px` : '17.1px',

                                    }}
                                >SWAP FOR PREMT</span>
                                <span
                                    style={{

                                        width: '70%',

                                        overflow: 'hidden',
                                        height: "fit-content",
                                        lineBreak: 'anywhere',
                                        fontFamily: memecoinstepfont.length > 0 ? `${memecoinstepfont}` : "Inter", color: memecoinsteptextcolor.length > 0 ? `${memecoinsteptextcolor}` : "white", fontSize: memestepfontSize != 0 ? `${memestepfontSize}px` : '14.3px',

                                    }}
                                >{t4step5}</span>

                            </div>
                        </div>
                    </div>

                    <div
                        style={{
                            position: "relative",
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: memetokenomicsbackground == 1 ? `${memetokenomicsbgcolor}` : 'white',
                            padding: "40px",
                            backgroundImage: memetokenomicsbackground == 0 ? memecointokonomicsbgimage.length > 0 ? `url(${memecointokonomicsbgimage})` : `` : '', objectFit: 'cover', backgroundSize: 'cover',
                        }}

                    >
                        <span
                            style={{
                                fontWeight: '800',
                                fontFamily: 'Inter',
                                color: memecointoketextcolor != 'white' ? memecointoketextcolor : '#0B0F3A',

                                width: "281.8px",
                                height: "71.6px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                overflow: "hidden",
                                fontFamily: `${memecointokenfont}`, fontSize: memetokenfontSize != 0 ? `${memetokenfontSize}px` : "49.26px",
                            }}
                        >{t4token}</span>
                        <span
                            style={{

                                fontFamily: 'Inter',
                                color: '#d93731',
                                fontSize: "14.3px",
                                width: "116.8px",
                                height: "21.4px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                fontFamily: `${memecointokenfont}`, fontSize: memetokenfontSize != 0 ? `${memetokenfontSize}px` : "49.26px",
                                color: memecointoketextcolor != 'white' ? memecointoketextcolor : '#0B0F3A',

                            }}
                        >See more on X.</span>
                        <img alt="logo" src={memecointokenomicsimage.length > 0 ? memecointokenomicsimage : T4MemeImage3} height={"60%"} width={"80%"}></img>
                    </div>


                    <div
                        style={{
                            position: "relative",
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",

                            gap: "40px",
                            paddingTop: "46px",
                            backgroundImage: memefootrbackground == 1 ? memefooterbgimage.length !== 0 ? `url(${memefooterbgimage})` : '' : '', objectFit: 'cover', backgroundSize: 'cover', backgroundColor: memefootrbackground == 0 ? memefooterbgcolor.length == 0 ? '#0b0f3a' : memefooterbgcolor : '',

                        }}
                    >
                        <div style={{

                            textTransformation: 'uppercase',
                            fontWeight: '800',


                            height: '71.6px',
                            width: '315.3px',
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            color: memefootertextcolor.length > 0 ? `${memefootertextcolor}` : "white", fontSize: `${memefooterfontSize}px`, fontFamily: memefootertextfont.length > 0 ? `${memefootertextfont}` : 'Inter',

                        }}>{t4footer4}</div>
                        <div style={{
                            color: "white",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-evenly",
                            width: '70%',
                            gap: "40px",
                        }}>
                            <span
                                style={{



                                    width: '274px',
                                    height: "fit-content",
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    overflow: 'hidden',
                                    lineBreak: 'anywhere',
                                    color: memefootertextcolor.length > 0 ? `${memefootertextcolor}` : "white", fontSize: memefooterfontSize != 0 ? `${memefooterfontSize}px` : '16px', fontFamily: memefootertextfont.length > 0 ? `${memefootertextfont}` : 'Inter',

                                }}
                            >{t4footer1}

                            </span>
                            <span
                                style={{


                                    width: '274px',
                                    height: "fit-content",
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    overflow: 'hidden',
                                    lineBreak: 'anywhere',
                                    color: memefootertextcolor.length > 0 ? `${memefootertextcolor}` : "white", fontSize: memefooterfontSize != 0 ? `${memefooterfontSize}px` : '16px', fontFamily: memefootertextfont.length > 0 ? `${memefootertextfont}` : 'Inter',

                                }}
                            >{t4footer2}</span>
                            <span
                                style={{


                                    width: '274px',
                                    height: "fit-content",
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    overflow: 'hidden',
                                    lineBreak: 'anywhere',
                                    color: memefootertextcolor.length > 0 ? `${memefootertextcolor}` : "white", fontSize: memefooterfontSize != 0 ? `${memefooterfontSize}px` : '16px', fontFamily: memefootertextfont.length > 0 ? `${memefootertextfont}` : 'Inter',

                                }}
                            >{t4footer3}</span>

                        </div>
                    </div>

                    <div
                        style={{
                            position: "relative",
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "#254a9a",

                        }}
                    >
                        <div
                            style={{
                                position: "relative",
                                width: "100%",
                                height: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                paddingTop: "46px"
                            }}
                        >
                            <div
                                style={{
                                    position: "relative",
                                    width: "50%",
                                    height: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    paddingLeft: '90px',

                                }}
                            >
                                <img src={memecoinlogoImage.length > 0 ? memecoinlogoImage : T4Navlogo} height={'119.4px'}></img>
                            </div>
                            <div
                                style={{
                                    position: "relative",
                                    width: "50%",
                                    height: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    gap: "30px"
                                }}
                            >
                                <div>
                                    <img src={T4footertelegram} height={'89.3px'} style={{cursor:'pointer'}}  onClick={()=>window.open(`${memetelegramlink}`)}></img>
                                    <img src={T4footerTwitter} height={'89.3px'} style={{cursor:'pointer'}}  onClick={()=>window.open(`${memetwitterlink}`)}></img>
                                    <img src={T4footerInstagram} height={'89.3px'} style={{cursor:'pointer'}}  onClick={()=>window.open(`${memeinstagramlink}`)}></img>
                                    <img src={T4footertrend} height={'89.3px'} style={{cursor:'pointer'}} onClick={()=>window.open(`${template2coingecko}`)}></img>
                                </div>
                                <span
                                    style={{

                                        color: memefootertextcolor.length > 0 ? `${memefootertextcolor}` : "white", fontSize: memefooterfontSize != 0 ? `${memefooterfontSize}px` : '14.3px', fontFamily: memefootertextfont.length > 0 ? `${memefootertextfont}` : 'Inter',

                                    }}
                                >Media contact:  realdolandPremt@proton.me</span>
                            </div>
                        </div>
                        <div style={{
                            position: "relative",
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",


                        }}>
                            <hr
                                style={{
                                    position: 'relative',
                                    color: 'white',
                                    height: '2px',
                                    overflow: ' hidden',
                                    width: '90%',
                                    lineHeight: '1px',
                                }}>

                            </hr>
                            <span style={{ color: memefootertextcolor.length > 0 ? `${memefootertextcolor}` : "white", fontSize: memefooterfontSize != 0 ? `${memefooterfontSize}px` : '10px', fontFamily: memefootertextfont.length > 0 ? `${memefootertextfont}` : 'Inter', }}>${memecoinsymbol} has no association with Donald Trump. This token is simply a meme coin with no
                                intrinsic value or expectation of financial return.</span>
                            <span style={{ color: memefootertextcolor.length > 0 ? `${memefootertextcolor}` : "white", fontSize: memefooterfontSize != 0 ? `${memefooterfontSize}px` : '10px', fontFamily: memefootertextfont.length > 0 ? `${memefootertextfont}` : 'Inter', }}>© 2024 by doland Premt $PREMT. All rights reserved.</span>

                        </div>
                    </div>

                </div > : null}


                {/* --------------------------------------------------------t5------------------------------------------------ */}

                {selectMemeCoinTemplates == 5 ?
                    <div className={stylesT5.wrapper}>
                        {/* Header Section */}
                        {(memecoinsection >= 1 && memecoinsection <= 7) ? <UrlNav /> : null}

                        <section className={stylesT5.header}

                            style={{ backgroundColor: memebackground == 0 ? memenavbarbgcolor.length == 0 ? '#f6b400' : memenavbarbgcolor : '', backgroundImage: memebackground == 1 ? memenavbgcolor.length !== 0 ? `url(${memenavbgcolor})` : '' : '', height: '100%', width: '100%', objectFit: 'cover', backgroundSize: 'cover' }}>
                           
                            <img src={memecoinlogoImage.length > 0 ? memecoinlogoImage : T5image1} alt="Ponke Logo" className={stylesT5.logo} />

                            <h1 className={stylesT5.poweredBySolana} style={{ color: memecoinabouttextcolor.length > 0 ? `${memecoinabouttextcolor}` : "#000", fontFamily: memecoinaboutfont.length > 0 ? `${memecoinaboutfont}` : "Helvetica Neue", }} >{t5Nav1}</h1>
                            <button className={stylesT5.button} style={{ color: memecoinabouttextcolor.length > 0 ? `${memecoinabouttextcolor}` : "white", fontFamily: memecoinaboutfont.length > 0 ? `${memecoinaboutfont}` : "Helvetica Neue", }} >{t5nav2} {memecoinname}</button>
                        </section>


                        <div
                            style={{
                                position: "relative", width: "100%", height: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",

                                backgroundColor: memeherobackground == 0 ? memeherobgcolor.length == 0 ? '#f6b400' : memeherobgcolor : '',
                                backgroundImage: memeherobackground == 1 ? memecoinaboutbgImage.length !== 0 ? `url(${memecoinaboutbgImage})` : '' : '',
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                            }}
                        >
                            <div style={{ position: "relative", width: "50%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <img src={memecoinAminitionImage.length > 0 ? memecoinAminitionImage : T5image2} alt="Ponke Logo" height={"100%"} width={"90%"}></img>
                            </div>
                            <div style={{ position: "relative", width: "50%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: 'column' }}>
                                <span
                                    style={{
                                        width: '90%',
                                        height: "fit-content",
                                        position: "relative",
                                        fontSize: '28.8px',
                                        color: memecoinabouttextcolor.length > 0 ? `${memecoinabouttextcolor}` : '#000',
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        overflow: "hidden",
                                        fontFamily: `${memecoinaboutfont}`,
                                        lineBreak: 'anywhere',
                                    }}
                                >
                                    {memecoinname} {memecoinAbout}
                                </span>
                                <div style={{ position: "relative", width: "70%", height: "100%", display: "flex", justifyContent: "space-around", alignItems: "center", }}>
                                    <img src={T5image3} alt="Ponke Logo" height={'50px'}></img>
                                    <img src={T5image4} alt="Ponke Logo" height={'50px'} style={{ cursor: 'pointer' }} onClick={() => window.open(`${memetwitterlink}`)}></img>
                                    <img src={T5image5} alt="Ponke Logo" height={'50px'} style={{ cursor: 'pointer' }} onClick={() => window.open(`${memetelegramlink}`)}></img>
                                    <img src={T5image6} alt="Ponke Logo" height={'50px'} style={{ backgroundColor: "#000", borderRadius: '50%', padding: '8px', cursor: 'pointer' }} onClick={() => window.open(`${memeinstagramlink}`)}></img>
                                </div>
                            </div>
                        </div>

                        {/* Exchanges Section */}
                        {/* <section className={stylesT5.exchanges}>
                            <h2
                                style={{
                                    width: '649.9px',
                                    position: 'relative',
                                    fontSize: '90px',

                                    fontFamily: 'Helvetica Neue',
                                    color: '#f6b400',
                                    textAlign: 'center',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height: '135px',
                                    overflow: 'hidden',
                                    lineBreak: 'anywhere',
                                }}
                            >EXCHANGES</h2>
                            <span
                                style={{
                                    width: '613.9px',
                                    position: 'relative',
                                    fontSize: '15px',

                                    fontFamily: 'Helvetica Neue',
                                    color: '#fff',
                                    textAlign: 'center',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height: '32px',
                                    overflow: 'hidden',
                                    lineBreak: 'anywhere',
                                }}
                            >{memecoinname} is available for trading on
                                major exchanges.</span>
                            <div className={stylesT5.exchangesGrid}>
                                <img src={T5image7} alt="Exchange 1" className={stylesT5.exchangeIcon} />
                                <img src={T5image8} alt="Exchange 1" className={stylesT5.exchangeIcon} />
                                <img src={T5image9} alt="Exchange 1" className={stylesT5.exchangeIcon} />
                                <img src={T5image10} alt="Exchange 1" className={stylesT5.exchangeIcon} />
                                <img src={T5image11} alt="Exchange 1" className={stylesT5.exchangeIcon} />
                                <img src={T5image12} alt="Exchange 1" className={stylesT5.exchangeIcon} />
                                <img src={T5image13} alt="Exchange 1" className={stylesT5.exchangeIcon} />
                                <img src={T5image14} alt="Exchange 1" className={stylesT5.exchangeIcon} />
                                <img src={T5image15} alt="Exchange 1" className={stylesT5.exchangeIcon} />
                                <img src={T5image16} alt="Exchange 1" className={stylesT5.exchangeIcon} />
                                <img src={T5image17} alt="Exchange 1" className={stylesT5.exchangeIcon} />

                                
                            </div>
                        </section> */}



                        {/* Helmet Section */}
                        <section className={stylesT5.helmet}
                            style={{
                                backgroundColor: memeaboutbackground == 0 ? memeaboutbgcolor.length == 0 ? '#f6b400' : memeaboutbgcolor : '',
                                backgroundImage: memeaboutbackground == 1 ? memeaboutbgimage.length !== 0 ? `url(${memeaboutbgimage})` : '' : '',
                                height: '100%', width: '100%', objectFit: 'cover', backgroundSize: 'cover'
                            }}
                        >
                            <div>
                                <h2
                                    style={{
                                        width: '100%',
                                        position: 'relative',
                                        fontSize: '90px',



                                        textAlign: 'center',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: 'fit-content',
                                        overflow: 'hidden',
                                        lineBreak: 'anywhere',
                                        fontFamily: memecoinvisionfont.length > 0 ? `${memecoinvisionfont}` : "Helvetica Neue", color: memecoinvisiontextcolor.length > 0 ? `${memecoinvisiontextcolor}` : '#000',
                                    }}
                                >
                                    {t5about1}
                                </h2>
                            </div>
                            <div style={{ width: '100%', height: '100%', position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <div style={{ width: '50%', height: '100%', position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: "5%" }}>
                                    <img src={memecoinavisionrightimage.length > 0 ? memecoinavisionrightimage : T5image18} alt="T5image18" height={'80%'} width={'350px'}></img>
                                </div>
                                <div style={{ width: '50%', height: '100%', position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: "column", paddingLeft: "-5%" }}>
                                    <h3
                                        style={{
                                            width: '80%',
                                            position: 'relative',
                                            fontSize: '30px',



                                            textAlign: 'center',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            height: 'fit-content',
                                            overflow: 'hidden',
                                            lineBreak: 'anywhere',
                                            fontFamily: memecoinvisionfont.length > 0 ? `${memecoinvisionfont}` : "Helvetica Neue", color: memecoinvisiontextcolor.length > 0 ? `${memecoinvisiontextcolor}` : '#000',

                                        }}
                                    >{t5about2}</h3>
                                    <img src={T5image19} alt="T5image19" width={'300px'} height={'100px'}></img>
                                </div>

                            </div>
                        </section>



                        {/* Tokenomics Section */}
                        <section className={stylesT5.tokenomics}
                            style={{
                                backgroundImage: memetokenomicsbackground == 1 ? memecointokonomicsbgimage.length > 0 ? `url(${memecointokonomicsbgimage})` : `` : '',
                                width: '100%', height: '100%', objectFit: 'cover', backgroundSize: 'cover',
                                backgroundColor: memetokenomicsbackground == 0 ? memetokenomicsbgcolor.length == 0 ? "#121212" : `${memetokenomicsbgcolor}` : '',
                            }}
                        >

                            <div style={{ width: '100%', height: '100%', position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: "column" }}>
                                <h3
                                    style={{

                                        fontSize: "90px",
                                        width: '90%',
                                        height: "fit-content",
                                        lineBreak: 'anywhere',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',

                                        overflow: 'hidden',
                                        textAlign: 'center',
                                        position: 'relative',
                                        fontFamily: memecointokenfont.length > 0 ? `${memecointokenfont}` : "Helvetica Neue", color: memecointoketextcolor.length > 0 ? `${memecointoketextcolor}` : "#f6b400",
                                    }}
                                >{t5Token1}</h3>
                                <p
                                    style={{
                                        fontSize: '30px',
                                        width: '90%',
                                        height: "fit-content",
                                        lineBreak: 'anywhere',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        overflow: 'hidden',
                                        textAlign: 'center',
                                        position: 'relative',
                                        fontFamily: memecointokenfont.length > 0 ? `${memecointokenfont}` : "Helvetica Neue", color: memecointoketextcolor.length > 0 ? `${memecointoketextcolor}` : "#FFFFFF",

                                    }}
                                >{t5Token3}</p>
                                <p
                                    style={{

                                        fontSize: '30px',
                                        width: '90%',
                                        height: "fit-content",
                                        lineBreak: 'anywhere',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',

                                        overflow: 'hidden',
                                        textAlign: 'center',
                                        position: 'relative',
                                        fontFamily: memecointokenfont.length > 0 ? `${memecointokenfont}` : "Helvetica Neue", color: memecointoketextcolor.length > 0 ? `${memecointoketextcolor}` : "#FFFFFF",

                                    }}
                                >{t5Token4}</p>
                            </div>
                            <div
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <div
                                    style={{
                                        width: '50%',
                                        height: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        padding: "5%",
                                        gap: "10px"
                                    }}
                                >
                                    <img src={memecointokenomicsimage.length > 0 ? memecointokenomicsimage : T5image20} height={'90%'} width={"90%"}></img>

                                    <img src={T5image3} height={'70px'} width={'50%'}></img>
                                </div>
                                <div
                                    style={{
                                        width: '50%',
                                        height: '100%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        flexDirection: 'column',

                                        gap: "10px",
                                    }}
                                >
                                    <img src={newiconsImage.length > 0 ? newiconsImage : T5image22} width={'40%'} height={'20%'}></img>
                                    <div
                                        style={{
                                            backgroundColor: "#f6b400",
                                            width: "90%",
                                            border: '4px solid #000',
                                            height: '136px',
                                            lineBreak: 'anywhere',
                                            overflow: 'hidden',


                                            display: 'flex',
                                            alignItems: 'center',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            fontSize: '30px',
                                            textAlign: 'center',
                                            position: 'relative',
                                            fontFamily: memecointokenfont.length > 0 ? `${memecointokenfont}` : "Helvetica Neue", color: memecointoketextcolor.length > 0 ? `${memecointoketextcolor}` : "#000",


                                        }}
                                    >
                                        <p>TOTAL SUPPLY</p>
                                        <p style={{ fontSize: "20px" }}>{t5Token5}</p>
                                    </div>
                                    <div
                                        style={{
                                            backgroundColor: "#f6b400",
                                            width: "90%",
                                            border: '4px solid #000',
                                            height: '136px',
                                            lineBreak: 'anywhere',
                                            overflow: 'hidden',

                                            display: 'flex',
                                            alignItems: 'center',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            fontSize: '30px',
                                            textAlign: 'center',
                                            position: 'relative',
                                            fontFamily: memecointokenfont.length > 0 ? `${memecointokenfont}` : "Helvetica Neue", color: memecointoketextcolor.length > 0 ? `${memecointoketextcolor}` : "#000",

                                        }}
                                    >
                                        <p>TOKEN ADDRESS</p>
                                        <p style={{ fontSize: "20px" }}>{t5Token6}</p>
                                    </div>

                                </div>


                            </div>


                        </section>


                        {/* How to Buy Section */}
                        <section className={stylesT5.howToBuy}
                            style={{
                                backgroundImage: memestepbackground == 1 ? `url(${memestepbgimage})` : '',
                                height: '100%', width: '100%', backgroundSize: 'cover', objectFit: 'cover',
                                backgroundColor: memestepbackground == 0 ? memestepbgcolor.length == 0 ? '#f6b400' : `${memestepbgcolor}` : ""
                            }}
                        >
                            <h2
                                style={{
                                    width: '70%',
                                    position: 'relative',
                                    fontSize: '86px',


                                    textAlign: 'center',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height: 'fit-content',
                                    overflow: 'hidden',
                                    lineBreak: 'anywhere',
                                    wordBreak: 'break-word',
                                    fontFamily: memecoinstepfont.length > 0 ? `${memecoinstepfont}` : "Helvetica Neue", color: memecoinsteptextcolor.length > 0 ? `${memecoinsteptextcolor}` : '#000',
                                }}
                            >{stepheading}</h2>
                            <span
                                style={{
                                    width: '75%',
                                    position: 'relative',
                                    fontSize: '30px',

                                    fontFamily: 'Helvetica Neue',
                                    color: '#000',
                                    textAlign: 'center',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height: 'fit-content',
                                    overflow: 'hidden',
                                    lineBreak: 'anywhere',
                                    wordBreak: 'break-word',
                                    fontFamily: memecoinstepfont.length > 0 ? `${memecoinstepfont}` : "Helvetica Neue", color: memecoinsteptextcolor.length > 0 ? `${memecoinsteptextcolor}` : '#000',

                                }}
                            >
                                Follow these easy steps to become a
                                {memecoinname} holder.</span>
                            <div className={stylesT5.buySteps}>

                                <div className={stylesT5.buyStep}>
                                    <h3
                                        style={{
                                            width: '100%',
                                            position: 'relative',
                                            fontSize: '25px',


                                            textAlign: 'center',
                                            display: 'flex',
                                            alignItems: 'start',
                                            justifyContent: 'center',
                                            height: '72px',
                                            overflow: 'hidden',
                                            lineBreak: 'anywhere',
                                            fontFamily: memecoinstepfont.length > 0 ? `${memecoinstepfont}` : "Helvetica Neue", color: memecoinsteptextcolor.length > 0 ? `${memecoinsteptextcolor}` : '#fff',

                                        }}
                                    >1. Create a Wallet</h3>
                                    <p
                                        style={{
                                            width: '100%',
                                            position: 'relative',
                                            fontSize: '14px',


                                            textAlign: 'center',
                                            display: 'flex',
                                            alignItems: 'start',
                                            justifyContent: 'center',
                                            height: '54px',
                                            overflow: 'hidden',
                                            lineBreak: 'anywhere',
                                            padding: '10px',
                                            fontFamily: memecoinstepfont.length > 0 ? `${memecoinstepfont}` : "Helvetica Neue", color: memecoinsteptextcolor.length > 0 ? `${memecoinsteptextcolor}` : 'gba(255, 255, 255, 0.8)',

                                        }}
                                    >{t5step1}</p>
                                </div>

                                <div className={stylesT5.buyStep}>
                                    <h3
                                        style={{
                                            width: '100%',
                                            position: 'relative',
                                            fontSize: '25px',


                                            textAlign: 'center',
                                            display: 'flex',
                                            alignItems: 'start',
                                            justifyContent: 'center',
                                            height: '72px',
                                            overflow: 'hidden',
                                            lineBreak: 'anywhere',
                                            fontFamily: memecoinstepfont.length > 0 ? `${memecoinstepfont}` : "Helvetica Neue", color: memecoinsteptextcolor.length > 0 ? `${memecoinsteptextcolor}` : '#ffff',

                                        }}
                                    >2. Get some SOL</h3>
                                    <p
                                        style={{
                                            width: '100%',
                                            position: 'relative',
                                            fontSize: '14px',



                                            textAlign: 'center',
                                            display: 'flex',
                                            alignItems: 'start',
                                            justifyContent: 'center',
                                            height: '54px',
                                            padding: '10px',
                                            overflow: 'hidden',
                                            lineBreak: 'anywhere',
                                            fontFamily: memecoinstepfont.length > 0 ? `${memecoinstepfont}` : "Helvetica Neue", color: memecoinsteptextcolor.length > 0 ? `${memecoinsteptextcolor}` : 'gba(255, 255, 255, 0.8)',

                                        }}
                                    >{t5step2}</p>
                                </div>
                                <div className={stylesT5.buyStep}>
                                    <h3
                                        style={{
                                            width: '100%',
                                            position: 'relative',
                                            fontSize: '25px',


                                            textAlign: 'center',
                                            display: 'flex',
                                            alignItems: 'start',
                                            justifyContent: 'center',
                                            height: '72px',
                                            overflow: 'hidden',
                                            lineBreak: 'anywhere',
                                            fontFamily: memecoinstepfont.length > 0 ? `${memecoinstepfont}` : "Helvetica Neue", color: memecoinsteptextcolor.length > 0 ? `${memecoinsteptextcolor}` : '#fff',


                                        }}
                                    >3. Swap $SOL for ${memecoinname}</h3>
                                    <p
                                        style={{
                                            width: '100%',
                                            position: 'relative',
                                            fontSize: '14px',


                                            textAlign: 'center',
                                            display: 'flex',
                                            alignItems: 'start',
                                            justifyContent: 'center',
                                            height: '54px',
                                            padding: '10px',
                                            overflow: 'hidden',
                                            lineBreak: 'anywhere',
                                            fontFamily: memecoinstepfont.length > 0 ? `${memecoinstepfont}` : "Helvetica Neue", color: memecoinsteptextcolor.length > 0 ? `${memecoinsteptextcolor}` : 'gba(255, 255, 255, 0.8)',

                                        }}
                                    >{t5step3}</p>
                                </div>
                                <div className={stylesT5.buyStep}>
                                    <h3
                                        style={{
                                            width: '100%',
                                            position: 'relative',
                                            fontSize: '25px',


                                            textAlign: 'center',
                                            display: 'flex',
                                            alignItems: 'start',
                                            justifyContent: 'center',
                                            height: '72px',
                                            overflow: 'hidden',
                                            lineBreak: 'anywhere',
                                            fontFamily: memecoinstepfont.length > 0 ? `${memecoinstepfont}` : "Helvetica Neue", color: memecoinsteptextcolor.length > 0 ? `${memecoinsteptextcolor}` : '#fff',

                                        }}
                                    >4. You are now a {memecoinname} Holder!</h3>
                                    <p
                                        style={{
                                            width: '100%',
                                            position: 'relative',
                                            fontSize: '14px',


                                            textAlign: 'center',
                                            display: 'flex',
                                            alignItems: 'start',
                                            justifyContent: 'center',
                                            height: '54px',
                                            padding: '10px',
                                            overflow: 'hidden',
                                            lineBreak: 'anywhere',
                                            fontFamily: memecoinstepfont.length > 0 ? `${memecoinstepfont}` : "Helvetica Neue", color: memecoinsteptextcolor.length > 0 ? `${memecoinsteptextcolor}` : 'gba(255, 255, 255, 0.8)',

                                        }}
                                    >{t5step4}</p>
                                </div>
                            </div>
                        </section>
                        {/* Socials Section */}
                        <section className={stylesT5.socials}
                            style={{ backgroundImage: memefootrbackground == 1 ? memefooterbgimage.length !== 0 ? `url(${memefooterbgimage})` : '' : '', objectFit: 'cover', backgroundSize: 'cover', backgroundColor: memefootrbackground == 0 ? memefooterbgcolor.length == 0 ? '#f6b400' : memefooterbgcolor : '', }}
                        >
                            <div style={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                                <h3
                                    style={{
                                        width: "50%",

                                        fontSize: "90px",
                                        height: "fit-content",
                                        lineBreak: 'anywhere',
                                        overflow: 'hidden',
                                        color: memefootertextcolor.length > 0 ? `${memefootertextcolor}` : '#000', fontFamily: memefootertextfont.length > 0 ? `${memefootertextfont}` : "Helvetica Neue",
                                    }}
                                >SOCIALS</h3>
                                <p
                                    style={{
                                        width: "50%",

                                        fontSize: "30px",
                                        height: "fit-content",
                                        lineBreak: 'anywhere',
                                        overflow: 'hidden',
                                        color: memefootertextcolor.length > 0 ? `${memefootertextcolor}` : '#000', fontFamily: memefootertextfont.length > 0 ? `${memefootertextfont}` : "Helvetica Neue",

                                    }}
                                >Join the ${memecoinname} community</p>
                            </div>
                            <div style={{ width: "30%", height: "100%", display: "flex", alignItems: "center", justifyContent: "space-evenly", }}>
                                <img src={T5image4} alt="Ponke Logo" height={'50px'} style={{ cursor: 'pointer' }} onClick={() => window.open(`${memetwitterlink}`)}></img>
                                <img src={T5image5} alt="Ponke Logo" height={'50px'} style={{ cursor: 'pointer' }} onClick={() => window.open(`${memetelegramlink}`)}></img>
                                <img src={T5image6} alt="Ponke Logo" height={'50px'} style={{ backgroundColor: "#000", borderRadius: '50%', padding: '8px', cursor: 'pointer' }} onClick={() => window.open(`${memeinstagramlink}`)}></img>
                            </div>
                            <div style={{ display: "flex", width: '100%', alignItems: "center", justifyContent: "space-around", gap: "30px", padding: "2%" }}>
                                <div style={{ display: "flex", width: '50%', alignItems: "center", justifyContent: "center", height: "100%" }}>
                                    <img src={memecoinAminitionImage.length > 0 ? memecoinAminitionImage : T5image2} height={'90%'} width={'70%'}></img>
                                </div>
                                <div style={{ display: "flex", width: '50%', alignItems: "center", justifyContent: "center", height: "100%", flexDirection: "column" }}>
                                    <img src={T5image3} height={'20%'} width={'40%'}></img>
                                </div>


                            </div>

                        </section>
                    </div>
                    : null}
            </HelmetProvider >
        </>
    )
};

export default Memecoin 