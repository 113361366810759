
import React, { useContext, useEffect, useState } from 'react';
import MyContext from '../context/MyContext';
import '../assets/CSS/Donwloadtemple.css'






import { usePublish } from '../context/publishSiteContext';


const Downloadsourcecode = () => {

  const { publishWebsite } = usePublish();
  const {randomId} = useContext(MyContext);

  const downloadTemplate = async () => {

    const publishWebsitecontent = await publishWebsite();




    const blob = new Blob([publishWebsitecontent], { type: 'text/html' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'template.html';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const validatedownload = async () => {

    ////////////
        const savedValue = localStorage.getItem('wagmi.store');
        // console.log('downloadaddressValue', address)
        // console.log('savedaddressValue  wg', typeof savedValue)
        const savedValuejson = JSON.parse(savedValue)
        const storeaddvalue = savedValuejson.state.connections.value
    
        let useradderess = null;
        if (storeaddvalue.length > 0) {
          useradderess = storeaddvalue[0][1]?.accounts[0]
            // alert(`storeaddvalue is greater than 0 ${useradderess}`)
        } else {
                alert('Please Connect Your Wallet')
            }
        console.log('2222222222222', useradderess)
    /////////// https://trendifyweb.ai/pyapi/cangenerate
    
        const cangenerate = await fetch(
          'https://trendifyweb.ai/pyapi/cangenerate',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              address: useradderess,
              randomid: randomId,
              usecase:'download',
            }),
          },
        )
        const cangeneratestatus = await cangenerate.json();
        console.log('cangeneratestatus', cangeneratestatus.can)
        if (!useradderess) {
          alert('Please connect your wallet');
        }
        else if (cangeneratestatus.can === true) {
          downloadTemplate();
        }else{
          alert("Please Upgrade your subscription")
        }
        
      };

  return (
    <div className='d-flex justify-content-end'>
      <button className='buttoncolor' style={{ padding: "8px 16px" }} onClick={validatedownload}>Download</button>
    </div>
  );
}

export default Downloadsourcecode;

