import React, { useContext } from 'react'
import MyContext from '../../../context/MyContext'

export const Introbutton = () => {
    const {introtext}=useContext(MyContext)
  return (
    <div>
         <div class="twobutton" style={{ width: "100%", color: `${introtext}` }}>
                        <a href="#"><button class="buttoncolor"></button></a>
                    </div>
    </div>
  )
}
