import React, { useState, useContext, useEffect } from 'react';
import './assets/CSS/template.css';
import Routing from './Routing/Routing';
import MyContext from './context/MyContext';
import NewLandingPage from './components/NewlandingPage';
import Memecoin from './components/MemeCoin/Memecoin';
import { useNavigate } from 'react-router-dom';

function App() {


  const { serviceHeading1, setServiceHeading1,
    serviceinfo1, setServiceinfo1,
    serviceHeading2, setServiceHeading2,
    serviceinfo2, setServiceinfo2,
    serviceHeading3, setServiceHeading3,
    serviceinfo3, setServiceinfo3,
    serviceHeading4, setServiceHeading4,
    serviceinfo4, setServiceinfo4,
    faqA1, setfaqA1, setnavbarmenu,
    faqA2, setfaqA2, setNewnavbarmenu,
    faqA3, setfaqA3,
    faqA4, setfaqA4,
    faqQ1, setFaqQ1,
    faqQ2, setFaqQ2,
    faqQ3, setFaqQ3, setFeatureImages,
    faqQ4, setFaqQ4, setIntroIamge,
    statsintro, setStatsinto,
    statsnumber1, setStatsnumber1,
    statsnumber2, setStatsnumber2,
    statsnumber3, setStatsnumber3,
    statsnumber4, setStatsnumber4, searchTerm,
    statsnumber5, setStatsnumber5, memecoin, setMemecoin,
    statsnumber6, setStatsnumber6,
    servicesIntro1, setServicesIntro1,
    servicesIntro, setservicesIntro,
    navbartitle, setNavbartitle, title,
    setImages, setTitle, setIntro,
    setAbout, setServices, setPortfolio,
    setReviews, setContact, setFaq,
    setByTheNumber, loading,
    setLoading, showWebsite,
    SetMemecoinName,setMemecoinSymbol,
    setMemecoinNavbar,setMemecoinAbout,
    setShowWebsite, showSites,setModalShow} = useContext(MyContext)
    const [navigation,setnavigations]=useState(false);
    const [randomnumber,setrandumnumber]=useState(Math.floor(Math.random() * 10) + 1);
    const [randomnumber2,setrandumnumber2]=useState(Math.floor(Math.random() * 10) + 1);
    // const Navigate=useNavigate();


    


  const handleGenerateWebsite = async (userInput) => {

    const MemedomainResponce = await fetch(
      'https://trendifyweb.ai/pyapi/websiteIdentifier',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_prompt: userInput,
        }),


      }
    )
    const MemecoinResponce = await MemedomainResponce.json();
    setMemecoin(MemecoinResponce.isMemeCoin,)

    setLoading(true);
    setShowWebsite(true);

    
     if(MemecoinResponce.isMemeCoin=='false')
      {
      // alert('website')

    const domainResponse = await fetch(
      'https://trendifyweb.ai/pyapi/domain-identifier',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          website_domain: userInput,
        }),
      },
    )
    const domainresponseData = await domainResponse.json();
    console.log("domainresponseData", domainresponseData.domain);



    console.log('domainresponseData', MemecoinResponce.isMemeCoin, typeof memecoin)


    {
      const titleResponse = await fetch(
        'https://trendifyweb.ai/pyapi/title',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            website_domain: domainresponseData.domain,
          }),
        },
      )

      const titleresponseData = await titleResponse.json();
      setTitle(` Welcome To  ${titleresponseData.title}`);
      setNavbartitle(titleresponseData.title);

      const apiUrl = `https://api.pexels.com/v1/search?query=${domainresponseData.domain}`;
      const authToken = 'g1RFtwOeN3pZAuIQuQNdmXvGLsURWXLK087YJkxtggHfWBkNiBIBxj68';
      

      fetch(apiUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': authToken
        }
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {

          console.log('API Response:', data);
          console.log('Images:', data.photos);
          
          console.log('src:', data.photos[0].src.landscape)
          // Generate a random integer between 1 and 10 (inclusive)
          

          setImages(data.photos);
          setIntroIamge(data.photos[randomnumber].src.landscape);
          setFeatureImages(data.photos[randomnumber2].src.landscape)

           console.log('randomnumber',randomnumber)
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });

      const navbardata = await fetch(
        'https://trendifyweb.ai/pyapi/navbar',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            website_domain: domainresponseData.domain,
            website_title: titleresponseData.title
          }),
        },
      )
      const navbarrrData = await navbardata.json();

      setNewnavbarmenu(navbarrrData.navbar_list);
      console.log('navbardata', navbarrrData, typeof navbarrrData,);


      const response = await fetch(
        'https://trendifyweb.ai/pyapi/intro',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            website_domain: domainresponseData.domain,
            website_title: titleresponseData.title
          }),
        },
      )

      if (!response.ok) {
        throw new Error('Request failed');
      }

      console.log("responseee", response)

      const reader = response.body.getReader();
      let chunks = '';
      while (true) {
        const { done, value } = await reader.read();
        if (done) {
          break;
        }
        chunks += new TextDecoder().decode(value);
        setIntro(chunks)
      }

      const servicesResponse = await fetch(
        'https://trendifyweb.ai/pyapi/services',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            website_domain: domainresponseData.domain,
            website_title: titleresponseData.title
          }),
        },
      )

      const responseData = await servicesResponse.json();

      // console.log("services_response", responseData.services_section.intro)
      // console.log('services_response',responseData.services_section)
      setServicesIntro1(responseData.services_section_intro.intro)
      setServiceHeading1(responseData.services_section_head_1.heading)
      setServiceinfo1(responseData.services_section_head_1.info)

      setServiceHeading2(responseData.services_section_head_2.heading)
      setServiceHeading3(responseData.services_section_head_3.heading)
      setServiceHeading4(responseData.services_section_head_4.heading)
      setServiceinfo2(responseData.services_section_head_2.info)
      setServiceinfo3(responseData.services_section_head_3.info)
      setServiceinfo4(responseData.services_section_head_4.info)
      console.log('serviceinfo', responseData)




      setServices(responseData)
      // setservicesIntro(responseData.services_section.intro)
      console.log(responseData, 'Service')
      const byTheNumberResponse = await fetch(
        'https://trendifyweb.ai/pyapi/byTheNumbers',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            website_domain: domainresponseData.domain,
            website_title: titleresponseData.title
          }),
        },
      )

      const byTheNumberData = await byTheNumberResponse.json();

      console.log("byTheNumberData", byTheNumberData)

      setByTheNumber(byTheNumberData?.numberArray)
      setStatsinto(byTheNumberData.numberArray_1.Intro)
      setStatsnumber1(byTheNumberData.numberArray_2.label);
      setStatsnumber2(byTheNumberData.numberArray_2.value);
      setStatsnumber3(byTheNumberData.numberArray_3.label);
      setStatsnumber4(byTheNumberData.numberArray_3.value);
      setStatsnumber5(byTheNumberData.numberArray_4.label);
      setStatsnumber6(byTheNumberData.numberArray_4.value);


      const faqResponse = await fetch(
        'https://trendifyweb.ai/pyapi/faq',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            website_domain: domainresponseData.domain,
            website_title: titleresponseData.title
          }),
        },
      )

      const faqData = await faqResponse.json();

      console.log("faqData", faqData)

      setFaq(faqData)
      setFaqQ1(faqData.faq_section_1.Question)
      setFaqQ2(faqData.faq_section_2.Question)
      setFaqQ3(faqData.faq_section_3.Question)
      setFaqQ4(faqData.faq_section_4.Question)
      setfaqA1(faqData.faq_section_1.Answer)
      setfaqA2(faqData.faq_section_2.Answer)
      setfaqA3(faqData.faq_section_3.Answer)
      setfaqA4(faqData.faq_section_4.Answer)
      setnavigations(true);


      console.log('question', faqQ1)
    }
  }
    //  ---------------------------------Memecoin---------------------------//
    if(MemecoinResponce.isMemeCoin=='true'){
      // alert('memecoin')
      setModalShow(true);
       
    const MemecoinType = await fetch('https://trendifyweb.ai/pyapi/meme_coin_identifier',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          user_prompt: userInput
        })

      }
    );
    const MemeCoinTypevalue = await MemecoinType.json();
    console.log('domainresponseData', MemeCoinTypevalue);


    const MemecoinName = await fetch('https://trendifyweb.ai/pyapi/meme_coin_name', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        user_prompt: MemeCoinTypevalue
      })

    });
    const MemeCoinTypevalue1 = await MemecoinName.json();
    console.log("domainresponseData", MemeCoinTypevalue1.meme_coin_name);
     SetMemecoinName(MemeCoinTypevalue1.meme_coin_name)

    const MemecoinSymbol = await fetch('https://trendifyweb.ai/pyapi/meme_coin_symbol',
      {
        method: "POST",
        headers: {
          'Content-type': 'application/json'
        },
        body: JSON.stringify({
          meme_coin_name: MemeCoinTypevalue1,
        })
      }
    );
    const MemecoinSymbol1 = await MemecoinSymbol.json();
    console.log('domainresponseData', MemecoinSymbol1.tickerSymbol);
    setMemecoinSymbol(MemecoinSymbol1.tickerSymbol);

    const MemecoinNavbar = await fetch('https://trendifyweb.ai/pyapi/meme_coin_navbar', {
      method: 'POST',
      headers: {
        'Content-type': 'application/json'
      },
      body: JSON.stringify({
        meme_coin_name: MemeCoinTypevalue
      })
    });
    const MemecoinNavbar1 = await MemecoinNavbar.json();
    console.log('domainresponseData', MemecoinNavbar1.navbarItems);
    setMemecoinNavbar(MemecoinNavbar1.navbarItems);
    setnavigations(true);

    const MemecoinAbout = await fetch('https://trendifyweb.ai/pyapi/meme_coin_about', {
      method: "POST",
      headers: {
        'Content-type': 'application/json'
      },
      body: JSON.stringify({
        meme_coin_name: MemeCoinTypevalue1.meme_coin_name,
        ticker_symbol: MemecoinSymbol1.tickerSymbol
      })
    });
    const MemecoinAbout1 = await MemecoinAbout.json();
    console.log('domainresponseData', MemecoinAbout1.about_section);
    setMemecoinAbout(MemecoinAbout1.about_section);
    console.log('Memecoin', memecoin, typeof memecoin)
  }

  };

  
  console.log('randomInteger',randomnumber);
  

  useEffect(()=>{
  console.log('Locations',navigation);
  

  },[])
   
  useEffect(() => {
        const handleBeforeUnload = (event) => {
      const customMessage = 'Are you sure you want to leave? Any unsaved changes will be lost.';
      event.preventDefault();
      event.returnValue = customMessage;
      return customMessage; // This line is necessary for some browsers
      
    };
    
  
    window.addEventListener('beforeunload', handleBeforeUnload);
   
   
    
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
     
    };
   
    
   
  },
  
   []);
  
 
  useEffect(() => {

    const handleBeforeUnload = (event) => {
      
      const confirmationMessage = 'Are you sure you want to leave?';
      event.returnValue = confirmationMessage; 
      return confirmationMessage; 
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
   
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
    
    
  },
 
   []);
  
  

    useEffect(()=>{
      if((!showWebsite)&&(window.location.pathname!='/')){
          window.location.assign('/')
      }
       
    },[])
    console.log('Window',showWebsite,showSites)  
    useEffect(()=>{
      console.log('website',window.location.href.length);
      
            
        },[window.location.href]);

        // useEffect(()=>{
        //   setTimeout(()=>{
        //     if(showSites==true){
        //       window.location.href='/MySites'
        //     }
        //   },2000)
          
        // },[showSites]);

        console.log('showWebsite',showWebsite,showSites)

  return (
    
    <div>

      {/* {showWebsite ? (
        <>
          <Routing />

        </>) : <NewLandingPage onGenerateWebsite={handleGenerateWebsite} />} */}
        <Routing onGenerateWebsite={handleGenerateWebsite}/>
    </div>
  );
};

export default App;

