// context/MyProvider.js
import React, { useState, createContext, useContext, useRef } from 'react';
import MyContext from './MyContext';
import { Introbutton } from '../components/SaveandEdit/Intro/Introbutton';
import { LeagacyButton } from '../components/SaveandEdit/Legacy/LeagacyButton';
import Img1 from '../assets/images/img1.png'
import Img2 from '../assets/images/img2.png'
import Img3 from '../assets/images/img3.png'
import Img4 from '../assets/images/img4.png'
import Img5 from '../assets/images/img5.png'
import Img6 from '../assets/images/img6.png'
import Img7 from '../assets/images/img7.png'
import Img8 from '../assets/images/img8.png'
import Img9 from '../assets/images/img9.png'
import Img10 from '../assets/images/img10.png'
import Image from '../assets/images/menubackground.png'

import { HeaderNavFontFamily } from '../components/TextFont/Font';

const MyProvider = ({ children }) => {
  const [newlogo, setNewlogo] = useState([]);
  const [showWebsite, setShowWebsite] = useState(false);
  const [loading, setLoading] = useState(false);
  const [images, setImages] = useState([]);
  const [newIntroImage, setNewIntroImage] = useState([])
  const [location, setLocation] = useState("");
  const [title, setTitle] = useState("",);
  const [welcometilte, setwelcometitle] = useState('Welcome To ')
  const [titlecolor, settitlecolor] = useState("white")
  const [navbartextcolor, setnavbartextcolor] = useState('black')
  const [intro, setIntro] = useState("");
  const [about, setAbout] = useState("");
  const [services, setServices] = useState([]);
  const [portfolio, setPortfolio] = useState("");
  const [reviews, setReviews] = useState("");
  const [contact, setContact] = useState("");
  const [faq, setFaq] = useState([]);
  const [menu, setmenu] = useState('Menu');
  const [menu1, setmenu1] = useState('Contact')
  const [byTheNumber, setByTheNumber] = useState([]);
  const [footercolor, setfootercolor] = useState('white');
  const [footertext, setfootertext] = useState('black');
  const [introcolor, setintrocolor] = useState('white');
  const [introtext, setintrotext] = useState('black');
  const [legacycolor, setlegacycolor] = useState('white');
  const [legacytext, setlegacytext] = useState('black');
  const [featurecolor, setfeaturecolor] = useState('white');
  const [featuretext, setfeaturetext] = useState('black');
  const [faqcolor, setfaqcolor] = useState('white');
  const [faqtext, setfaqtext] = useState('black');
  const [footerfeatures, setfooterfeatures] = useState('Features')
  // const [footerfeaturemenu,setfeaturremenu]=useState(['Something Great','Another Thing','So many Features','Its Amazing'])
  const [navbarmenu, setnavbarmenu] = useState(['']);
  const [serviceheader, setserviceheader] = useState('Services');
  const [servicesIntro, setservicesIntro] = useState('');
  const [legacy, setleagacy] = useState('Our Legacy');
  const [trustcolor, settrustcolor] = useState('white');
  const [trusttext, settrusttextcolor] = useState('black');
  const [trust, settrust] = useState('Trusted by Many');
  const [introo, setIntroo] = useState([])
  const [serv_list_obj, Setserv_list_obj] = useState([]);
  const [footerfeature, setfooterfeature] = useState(['Another Thing', 'So many Features', 'It s Amazing'])
  const [trsuttitle, settrusttile] = useState("Over the years, we've earned trust from residential and commercial clients alike, whose endorsements  speak volumes about our commitment to excellence.");
  const [footercompany, setfootercompany] = useState(['Blog', 'About Us', 'Contact', 'Jobs']);
  const [footerlegal, setfooterlegal] = useState(['Privacy Policy', 'Terms of USe', 'Cookie Policy']);
  const [visibleComponents, setVisibleComponents] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [serachtearm1, setSearchteam] = useState();
  const [contentdesing, setContentdesing] = useState(false);
  const [mainnavbarbutton, setMainnavbarbutton] = useState(false);
  const [section1, setsection1] = useState(false);
  const [section2, setsection2] = useState(false);
  const [section3, setsection3] = useState(false);
  const [section4, setsection4] = useState(false);
  const [section5, setsection5] = useState(false);
  const [section6, setsection6] = useState(false);
  const [section7, setsection7] = useState(false);
  const [headerdiv, setHeaderdiv] = useState(false);
  const [headerdivbgcolor, setHeaderdivbgcolor] = useState('#ffffff');
  const [navbartitle, setNavbartitle] = useState('');
  const [headerdivtext, setHeaderDivText] = useState('')
  const [headerdivtextcolor, setHeaderDivTextColor] = useState('#ffffff');
  const [servicesIntro1, setServicesIntro1] = useState([]);
  const [serviceHeading1, setServiceHeading1] = useState('');
  const [serviceinfo1, setServiceinfo1] = useState('');
  const [serviceHeading2, setServiceHeading2] = useState('');
  const [serviceinfo2, setServiceinfo2] = useState('');
  const [serviceHeading3, setServiceHeading3] = useState('');
  const [serviceinfo3, setServiceinfo3] = useState('');
  const [serviceHeading4, setServiceHeading4] = useState('');
  const [serviceinfo4, setServiceinfo4] = useState('');
  const [faqQ1, setFaqQ1] = useState('');
  const [faqQ2, setFaqQ2] = useState('');
  const [faqQ3, setFaqQ3] = useState('');
  const [faqQ4, setFaqQ4] = useState('');
  const [faqA1, setfaqA1] = useState('');
  const [faqA2, setfaqA2] = useState('');
  const [faqA3, setfaqA3] = useState('');
  const [faqA4, setfaqA4] = useState('');
  const [statsintro, setStatsinto] = useState('')
  const [statsnumber1, setStatsnumber1] = useState('');
  const [statsnumber2, setStatsnumber2] = useState('');
  const [statsnumber3, setStatsnumber3] = useState('');
  const [statsnumber4, setStatsnumber4] = useState('');
  const [statsnumber5, setStatsnumber5] = useState('');
  const [statsnumber6, setStatsnumber6] = useState('');
  const [navbtn1, setNavbtn1] = useState('');
  const [navbtn2, setNavbtn2] = useState('');
  const [inttrobtn1, setIntrobtn1] = useState('learn more');
  const [introbtn2, setIntrobtn2] = useState('join us');
  const [navremovebtn1, setNavremovebtn1] = useState(false);
  const [navremovebtn2, setNavremovebtn2] = useState(false);
  const [navbuttonincraese, setNavbuttonincrease] = useState(0);
  const [navbarremove, setNavbarremove] = useState(false);
  const [introbtn1remove, setIntrobtnremove1] = useState(false);
  const [introbtn2remove, setIntrobtnremove2] = useState(false);
  const [serviceremove1, settServiceremove1] = useState(false);
  const [serviceremove2, settServiceremove2] = useState(false);
  const [serviceremove3, settServiceremove3] = useState(false);
  const [serviceremove4, settServiceremove4] = useState(false);
  const [statsnumremove1, setStatsnumremove1] = useState(false);
  const [statsnumremove2, setStatsnumremove2] = useState(false);
  const [statsnumremove3, setStatsnumremove3] = useState(false);
  const [removeFaq1, setRemovefaq1] = useState(false);
  const [removeFaq2, setRemovefaq2] = useState(false);
  const [removeFaq3, setRemovefaq3] = useState(false);
  const [removeFaq4, setRemovefaq4] = useState(false);
  const [removelogos, setRemovelogos] = useState(false);
  const [navadddiv, setNavnavadddiv] = useState(false);
  const [addnavmenu, setAddnavmenu] = useState([])
  const [leagacydiv, setLeagacydiv] = useState([]);
  const [faqtitle, setFaqtitle] = useState('FAQ Corner');
  const [faqIntro, setFaqIntro] = useState('Got questions? We re here to help! Check out our FAQs for quick answers.')
  const [footeryear, setFooteryear] = useState('© 2024 Your Company');
  const [removeSocialIcon1, setRemoveSocialIcon1] = useState(false);
  const [removeSocialIcon2, setRemoveSocialIcon2] = useState(false);
  const [removeSocialIcon3, setRemoveSocialIcon3] = useState(false);
  const [removeSocialIcon4, setRemoveSocialIcon4] = useState(false);
  const [navAddbutton, setNavaddbutton] = useState([]);
  const [addbuttonapprovel, setAddbuttonapprovel] = useState(false)
  const [addintrobutton, setAddintrobutton] = useState([]);
  const [introbuttonapprovel, setIntrobuttonapprovel] = useState(false);
  const [newservicesheading, setNewservicesheading] = useState([]);
  const [newservicesheading1, setNewservicesheading1] = useState([]);
  const [newservicesinfo, setNewservicesinfo] = useState([]);
  const [trustLogo, settTrustlogos] = useState([Img1, Img2, Img3, Img4, Img5, Img6, Img7, Img8, Img9, Img10]);
  const [newtrustlogo, setNewtrustlogo] = useState([])
  const [removelegacynum, setremoveleagacynum] = useState(false);
  const [divs, setDivs] = useState([]);
  const [removelegacy, setRemovelegacy] = useState(false);
  const [newlegacy, setNewlgacy] = useState([]);
  const [newlegacy1, setNewlgacy1] = useState([]);
  const [newlegacy2, setNewlgacy2] = useState([]);
  const [newFaq, setNewFaq] = useState([]);
  const [newfootersocialIcon, setNewfootersocialIcon] = useState([]);
  const [headernotice, setheadernotice] = useState(false);
  const [newservicesImage, setNewserviceImage] = useState([]);
  const [colorheaderDiv, setcolorheaderdiv] = useState(false);
  const [newBackgroundcolor, setNewbackgroundcolor] = useState(false);
  const [intromenubgcolor, setIntromenubgcolor] = useState('white');
  const [intromenutextcolor, setIntromenutextcolor] = useState('black');
  const [selectedOption1, setSelectedOption1] = useState('A');
  const [headertextfont, setHeadertextfont] = useState('');
  const [footerfeaturehead, setFooterfeaturehead] = useState('Feature');
  const [footerCompanyhead, setFooterCompanyhead] = useState('Company');
  const [footerlegalhead, setFooterleagalhead] = useState('Legal');
  const [introcaption, setIntrocaption] = useState('');
  const [trustCaption, setTrustCaption] = useState('');
  const [servicecaption, setServicesCaption] = useState('');
  const [statscaption, setStatsCaption] = useState('');
  const [faqCaption, setFaqCaption] = useState('');
  const [footerLinkgroups, setfooterLinkgroups] = useState([]);
  const [newfooteritem, setNewfooteritem] = useState([]);
  const [newfooterhead, setNewfooterhead] = useState([]);
  const [introtextfont, setIntrotextfont] = useState('');
  const [introinfotextfont, setintroinfotextfont] = useState('');
  const [trusttextfont, setTrusttextfont] = useState('');
  const [trusttitletextfont, setTrusttitletextfont] = useState('');
  const [servicetitletextfont, setServicestitletextfont] = useState('');
  const [serviceBodytextfont, setserviceBodytextfont] = useState('');
  const [statstitletextfont, setStatstitletextfont] = useState('');
  const [statsbodytextfont, setStatsbodytextfont] = useState('');
  const [faqtitletextfont, setFaqtitletextfont] = useState('');
  const [faqbodytextfont, setFaqbodytextfont] = useState('');
  const [footerbodytextfont, setFooterbodytextfont] = useState('');
  const [showdowrange, setShowdorange] = useState(0);
  const [intromargintop, setIntromargintop] = useState(0);
  const [intromarginbottom, setIntromarginbottom] = useState(0);
  const [marginTop, setMarginTop] = useState(0);
  const [marginBottom, setmarginBottom] = useState(0);
  const [trustmargintop, setTrustmargintop] = useState(0);
  const [trustmarginbottom, setTrustmarginBottom] = useState(0);
  const [serviceMarginTop, setServiceMarginTop] = useState(0);
  const [serviceMarginBottom, setServiceMarginBottom] = useState(0);
  const [statsMarginTop, setStatsMarginTop] = useState(0);
  const [statsMarginBottom, setStatsMarginBottom] = useState(0);
  const [faqMargintop, setFaqMarginTop] = useState();
  const [faqMarginBottom, setFaqMarginBottom] = useState();
  const [footerMargintop, setFooterMarginTop] = useState(0);
  const [footerMarginBottom, setFooterMarginBottom] = useState(0);
  const [stateOne, setStatsOne] = useState({ prefix: '', suffix: '' });
  const [statetwo, setStatstwo] = useState({ prefix: '', suffix: '' });
  const [statethree, setStatsthree] = useState({ prefix: '', suffix: '' });
  const [contenttextheadingcolor, setContenttextheadingcolor] = useState('black');
  const [contenttextinfocolor, setContenttextinfocolor] = useState('');
  const [contactheadingcolor, setContactheadingcolor] = useState('black');
  const [conatcmenuborder, setcontactmenuborder] = useState('white');
  const [selectedLi, setSelectedLi,] = useState(null);
  const [checked, setChecked] = useState(false);
  const [captioncolor, setCaptioncolor] = useState('#9c36b5');
  const [statevaluecolor, setStatsvaluecolor] = useState('');
  const [statslabelcolor, setStatslabelcolor] = useState('');
  const [statsbackgroundcolor, setStatsbackgroundcolor] = useState('white');
  const [statsborder, setStatsborder] = useState(0);
  const [statsborderradius, setStatsborderradius] = useState(0);
  const [statsshowdowrange, setStatsshowdowrange] = useState(0);
  const [statsbordercolor, setStatsbordercolor] = useState('white');
  const [selectedHero, setSelectedHero] = useState(null);
  const [buttontext, setbuttontext] = useState(null);
  const [button1font, setButton1font] = useState('');
  const [buttoncolor, setButtoncolor] = useState('rgb(236, 235, 235)');
  const [buttonborder, setbuttonborder] = useState(0);
  const [buttonborderradius, setButtonborderradios] = useState(8);
  const [buttonbordercolor, setbuttonbordercolor] = useState('rgb(220, 213, 213)');
  const [butttonshowdo, setButtonshowdo] = useState(0);
  const [showdovalue, setshowdovalue] = useState(0);
  const [selectedFaq, setSelectedFaq] = useState(null);
  const [selectedFooter, setSelectedFooter] = useState(null);
  const [selectedLogo, setSelectedLogo] = useState(null);
  const [Animated, setAnimated] = useState(false);
  const [selectedStats, setSelectedStats] = useState(null);
  const [buttonvarticalpadding, setButtonvarticalpadding] = useState(0);
  const [buttonhorizantlepadding, setButtonhorizantlepadding] = useState(0);
  const [selectedFeature, setSelectedFeature] = useState(null);
  const [imagePosition, setImagePosition] = useState(false);
  const [alignMedia, setAlignMedia] = useState('start');
  const [newnavbarmenu, setNewnavbarmenu] = useState([]);
  const [memecoin, setMemecoin] = useState();
  const [featureIcon1, setfeatureIcon1] = useState('AiFillCaretRight');
  const [featureIcon2, setfeatureIcon2] = useState('AiFillCaretRight');
  const [featureIcon3, setfeatureIcon3] = useState('AiFillCaretRight');
  const [featureIcon4, setfeatureIcon4] = useState('AiFillCaretRight');
  const [buttonshowdoleft, setButtonshowdoleft] = useState(0);
  const [buttonshowdoTop, setButtonshowdoTop] = useState(1);
  const [buttonshowdospread, setButtonshowdospread] = useState(0);
  const [buttonshowdowcolor, setButtonshowdocolor] = useState('rgba(21, 21, 25, 0.21)');
  const [buttonshowdoblur, setButtonshowdoblur] = useState(15);
  const [introImages, setIntroIamge] = useState();
  const [featureimages, setFeatureImages] = useState();
  const [domainupdate, setDomianupdate] = useState(1);
  const [memecustmonapi, setMemecustmonapi] = useState();



  // ---------------------------memecoinstates--------------------------------//
  const [modalShow, setModalShow] =useState(false);
  const [memecloudimage,setMemecloudImage]=useState([])
  const [publishsite,setPublishsite]=useState()
  const [memecoinname, SetMemecoinName] = useState('');
  const [memecoinsymbol, setMemecoinSymbol] = useState('');
  const [memecoinNavbar, setMemecoinNavbar] = useState([]);
  const [memecoinAbout, setMemecoinAbout] = useState('');
  const [memeCaption, setMemecaption] = useState('ON A MISSION TO ONBOARD THE NEXT');
  const [memecoinValue, setMemecoinValue] = useState('10,000,000');
  const [memecoincontractaddress, setMemecoincontractAddress] = useState({ title: 'Contact Address', value: "0xf3995F5a2fAa01D909afd7E17EB94c2Bd9ee8d50" })
  const [memecoinInitialsupply, setMemecoinInitialsupply] = useState({ title: 'Initialsupply', value: '1,000,000,000' });
  const [memecoinCirculatingSupply, setMemecoinCirculatingSupply] = useState({ title: 'Circulating Supply', value: '926,930,545' });
  const [memecoinSupplyBurned, setMemecoinSupplyBurned] = useState({ title: 'Supply Burned', value: '7.3%' });
  const [memecoinLiquidity, setMemecoinLiquidity] = useState({ title: 'Liquidity Burned', value: "100%" });
  const [contractRenounced,setContractRenounced]=useState({title:'Contract Renounced',value:''})
  const [memecoinstep1, setMemecoinStep1] = useState('Install a Chrome Extension Wallet.');
  const [memecoinstep2, setMemecoinStep2] = useState('Set Up Your Wallet & Fund it with Bitcoin.');
  const [memecoinstep3, setMemecoinStep3] = useState(`Locate ${memecoinname} on a Marketplace.`);
  const [memecoinstep4, setMemecoinStep4] = useState('Mint  Runes  -RUNES');
  const [memecoinlogoImage, setMemecoinlogoImage] = useState([]);
  const [randomId, setRandomId] = useState('');
  const [memecoinaboutbgImage, setMemecoinaboutbgIamge] = useState([]);
  const [memecoinAminitionImage, setMemecoinAminationImage] = useState([]);
  const [memecoinaboutrightimage, setMemecoinaboutrightimage] = useState([]);
  const [memecoinaboutleftimage, setMemecoinaboutleftimage] = useState([]);
  const [memecoinavisionleftimage, setMemecoinavisionleftimage] = useState([]);
  const [memecoinavisionrightimage, setMemecoinavisionrightimage] = useState([]);
  const [memecointokenomicsimage, setMemecointokenomicsimage] = useState([]);
  const [memecoinContractImage, setMemecoinContractImage] = useState([]);
  const [memecoinDatabaseImage, setMemecoinDatabaseImage] = useState([]);
  const [memecoinCirculatingImage, setMemecoinCirculatingImage] = useState([]);
  const [memecoinBurnedImage, setMemecoinBurnedImage] = useState([]);
  const [memecoinLiquidityImage, setMemecoinLiquidityImage] = useState([]);
  const [memecoinRenouncedImage, setMemecoinRenouncedImage] = useState([]);
  const [memenavbgcolor, setMemenavbgcolor] = useState([]);
  const [memeaboutbgcolor, setMemeaboutbgcolor] = useState([]);
  const [memecointokonomicsbgimage, setMemecointokonomicsbgimage] = useState([]);
  const [memecoinanvfont, setMemecoinanvfont] = useState('');
  const [memenavtextcolor, setMemenavtextcolor] = useState('');
  const [memecoinaboutfont, setMemecoinaboutfont] = useState('');
  const [memecoinabouttextcolor, setMemecoinabouttextcolor] = useState('');
  const [memecoinsection, setMemecoinsection] = useState(0);
  const [memecoinvisionfont, setMemecoinvisionfont] = useState('');
  const [memecoinvisiontextcolor, setMemecoinvisiontextcolor] = useState('');
  const [memecointokenfont, setMemecointokenfont] = useState('');
  const [memecointoketextcolor, setMemecointoketextcolor] = useState('white');
  const [memecoinstepfont, setMemecoinstepfont] = useState('');
  const [memecoinsteptextcolor, setMemecoinsteptextcolor] = useState('');
  const [removelogotokan, setRemovelogotoken] = useState(false);
  const [newaddtokest, setNewaddtokesst] = useState([]);
  const  [newiconsImage,setNewiconImage]=useState([]);
  const [iSremove1, setIsremove1] = useState(false);
  const [iSremove2, setIsremove2] = useState(false);
  const [iSremove3, setIsremove3] = useState(false);
  const [iSremove4, setIsremove4] = useState(false);
  const [iSremove5, setIsremove5] = useState(false);
  const [iSremove6, setIsremove6] = useState(false);
  const [iSremove, setIsremove] = useState(false);
  const [stepheading,setStepheading]=useState('HOW TO BUY');
  const [step1,setStep1]=useState('Install a Chrome Extension Wallet.');
  const [step2,setStep2]=useState('Set Up Your Wallet & Fund it with Bitcoin.');
  const [step3,setStep3]=useState(`Locate your on a Marketplace.`);
  const [step4,setStep4]=useState(`Mint `);
  const [memeherobutton,setmemeHerobutton]=useState('Join us');
  const [memeaboutbgimage,setmemeAboutbgimage]=useState([]);
  const [memestepbgimage,setMemestepbgimage]=useState([]);
  const [memenavbarbgcolor,setMemenavbarbgcolor]=useState([]);
  const [memebackground,setMemebackground]=useState(0);
  const [memeherobackground,setMemeherobackground]=useState(0);
  const [memeaboutbackground,setMemeaboutbackground]=useState(0);
  const [memetokenomicsbgcolor,setMemetokenomicsbgcolor]=useState([]);
  const [memetokenomicsbackground,setMemetokenomicsbackground]=useState(0);
  const [memestepbackground,setMemestepbackground]=useState(0);

  const [memestepbgcolor,setMemestepbgcolor]=useState([]);
  const [memefooterbgcolor,setMemefooterbgcolor]=useState('');
  const [memefootrbackground,setMemefootrbackground]=useState(0);
  const [tokenvalubgcolor,settokenvalubgcolor]=useState('');
  const [memefootertextcolor,setMemefootertextcolore]=useState('');
  const [memefootertextfont,setMemefootertextfont]=useState('');
  const [memenavfontsize,setMemenavfontsize]=useState();
  const [memeMainfontsize,setMemeMainfontsize]=useState();
  const [memeaboutfontSize,setMemeaboutfontsize]=useState();
  const [memetokenfontSize,setMemetokenfontSize]=useState();
  const [memestepfontSize,setMemestepfontSize]=useState();
  const [memefooterfontSize,setMemefooterfontSize]=useState();
  const [uniqueID,setUniqueID]=useState('');
  const [websiteName,setWebsiteName]=useState('UnTitled');
  const [memebuttoncolor,setMemebuttoncolor]=useState('');

  const [selectMemeCoinTemplates,setSelectMemeCoinTemplates]=useState(0);
  const [memetokencaption,setMemetokenCaption]=useState('is a cryptocurrency on the Base network with its contract now renounced for greater decentralization and a tax rate of 0%, ensuring no additional fees on transactions. The initial liquidity has been burned, enhancing the security and potential value of boomer tokens.');
  const [memetokenbuy,setMemetokenBuy]=useState('BUY SOME');

  





  const [memeherobgcolor,setMemeherobgcolor]=useState([]);
// MediaLink
const [memefooterabout, setMemefooterabout] = useState(`-MEME WAS MADE FOR A FAN AND HAS NO VALUE. DONT RISK MONEY YOU ARE AFRAID OF LOSING. THE PRICE MAY GO UP OR IT MAY GO DOWN. WE ARE NOT RESPONSIBLE FOR THE PRICE OF THE TOKEN.`);
const [memetwitterlink,setMemetwitterlink]=useState('https://twitter.com/?lang=en');
const [memetelegramlink,setMemetelegramlink]=useState('https://telegram.org/');
const [memeYoutubelink,setMemeYoutubelink]=useState('https://www.youtube.com/');
const [memetiktoklink,setMemetiktoklink]=useState('https://www.tiktok.com/en/');
const [memeinstagramlink,setMemeinstagramlink]=useState('https://www.instagram.com/');
const [memewarpcastlink,setMemewarpcastlink]=useState('https://warpcast.com/');
const [template3uniswaplink,setTemplate3uniswaplink]=useState('https://uniswap.org/');
const [template2cmc,setTemplate2cmc]=useState('https://coinmarketcap.com/');
const [template2coingecko,setTemplate2coingecko]=useState('https://www.coingecko.com/');




  //  ---------------------------------landingpage-------------------------------------------------------//
  const [purchaseData, setPurchaseData] = useState('');
  const [expiredate, setExpiredate] = useState('');
  const [subdomain, setSubdomain] = useState('https://');
  const [subdomain1, setSubdomain1] = useState('.trendifyweb.ai');
  const [newdomain, setNewdomain] = useState('');
  const [custmondomian,setcustmondomian]=useState('')
  const [memefooterbgimage, setMemefooterbgimage] = useState([]);


  // ------------------------------------------Subscription--------------------------------------------------------//
  const [subscriptionStatus, setSubscriptionStatus] = useState();
  const[purchaseplane,setPurchaseplane]=useState([]);


  // ----------------------------------------------Sites---------------------------------------------------------//
  const [showSites,setShowSites]=useState(false);


  // -------------------------------------------------T1-------------------------------------------------------//
  const [template1caption,setTemplate1caption]=useState('OFFICIALLY');
  const [template1Info,setTemplate1Info]=useState(' is the legendary character inspired by MattFurie’s Boys club comic.');
  const [template1tokenimage2,setTemplate1tokenimage2]=useState([]);
  const [template1tokenimage3,setTemplate1tokenimage3]=useState([]);
  const [template1tokenomics,setTemplate1Tokenomics]=useState('TOKENOMICS');
  const [template1tokenomicssub1,setTemplate1tokenomicssub1]=useState('NEVER FORGET ABOUT CONTRACT DETAILS!');
  const [template1tokenomicssub2,setTemplate1tokenomicssub2]=useState('Socials & Links');
  const [template1tokenomicssub3,setTemplate1tokenomicssub3]=useState('Check our socials & LINKS here!');
  const [template1tokenomisupply,setTemplate1tokenomisupply]=useState({label:'Supply',value:'999,999,999'});
  const [template1tokenomicontract,setTemplate1tokenomicontract]=useState({label:"Contract",value:"Revoked"});
  const [template1tokenomiTax,setTemplate1tokenomiTax]=useState({label:'TAX',value:'0% Buy 0% Sell'});
  const [template1footerabout,setTemplate1footerAbout]=useState(' OFFICIAL SITE GET this MEME! It " is shaking things up big time INSOLANA, right? WE ARE GOING HIGH AND NOT GOING BACK. The information provided in this disclaimer does not constitute investment advice, financial advice, trading advice, usage or playing advice or any other sort of advice.');
  const [template1footerImage,setTemplate1FooterImage]=useState([]);
  const [template1stepcontract,setTemplatestepcontract]=useState('CONTRACT:');
  const [template1stepAdd,setTemplate1Add]=useState('0xf3995F5a2fAa01D909afd7E17EB94c2Bd9ee8d50')

// -------------------------------------------------------------T2-------------------------------------------------------//
  const [template2footerlogo,setTemplate2footerlogo]=useState(`@ $ 2024`);
  const [template2footeringo,setTemplate2footerinfo]=useState('Ready to ride the meme wave with G Tndrew Aate s dog? Follow us on social media, join our Telegram group, and get your paws on GATD. It s time to let the dog out and shake up the crypto world, one meme at a time !');
  const [template2stepheading,setTemplate2stepheading]=useState('HOW TO BUY $');
  const [template2step1,setTemplate2step1]=useState('STEP 1: CREATE WALLET - Download Metamask,or your self-custody wallet of choice from theapp store or google play store for free. Desktopusers can download the google chrome extensionby going to Metamask’s official website ,');
  const [template2step2,setTemplate2step2]=useState('STEP 2: GET ETH ON ERC20 NETWORK - acquire some ETH to buy $MEOW. If you don’t haveany ETH, you can buy directly on metamask,transfer from another wallet, or buy on anexchange and send it to your self-custodywallet.');
  const [template2step3,setTemplate2step3]=useState('STEP 3: Connect your Wallet to Uniswap - Go to app.uniswap.org in google chrome or on thebrowser inside your Metamask app. Connectyour wallet. Paste the $MEOW token address intoUniswap, select Papi, and confirm.');
  const [template2step4,setTemplate2step4]=useState('STEP 4: Swap ETH for $G - We have ZERO taxes, so no need to worry about slippage. Please beweary of slippage attacks and note that higherslippage may be required during high volatility.');
  const [template2tokenheading,setTemplate2tokenheading]=useState('Tokenomics');
  const [template2tokensupply,setTemplate2tokensupply]=useState(' Supply: 420,690,000,000');
  const [template2tokentatesupply,setTemplate2tokentatesupply]=useState('50%  Supply to Andrew Tate');
  const [template2tokentax,setTemplate2tokentax]=useState('0% TAX');
  const [template2tokenlp,setTemplate2tokenlp]=useState('LP BRUNT');
  const [template2tokenMeme,setTemplate2tokenMeme]=useState('PURE MEMES')
  const [template2telegramlink,setTemplate2telegramlink]=useState('https://web.telegram.org/');
  const [template2twitterlink,setTemplate2twitterlink]=useState('https://twitter.com/?lang=en');
  const[template2caption,setTemplate2Caption]=useState('Welcome to the wild world of Andrew Tate s Dog  on Ethereum.');
  const [template2memecaption,setTemplate2memecaption]=useState('COIN  Only on Ethereum.');
  const [template2info,setTemplate2info]=useState('the meme coin $  here to wag the crypto markets tail!');
  const [template2tokenAddress,setTemplate2tokenAddress]=useState('  0xf3995F5a2fAa01D909afd7E17EB94c2Bd9ee8d50');
  const [template2uniswap,setTemplateuniswap]=useState('https://uniswap.org/');
 
  const [template2removebuttonuniswap,setTemplate2Remvebuttonuniswap]=useState(false);
  const [template2removebuttonunicmc,setTemplate2Remvebuttonunicmc]=useState(false);
  const [template2removebuttoncoingecko,setTemplate2Remvebuttoncoingecko]=useState(false);



  // ----------------------------------------------------------------T3---------------------------------------------------------------------------------------------//
  const [template3telegramlink,setTemplate3telegramlink]=useState('https://web.telegram.org/');
  const [template3twitterlink,setTemplate3twitterlink]=useState('https://twitter.com/?lang=en');
  const [template3heading,setTemplate3Heading]=useState('Welgome do da offigial bage of  dogen!');
  const [template3section1,setTemplate3section1]=useState('1.UNSTOPPABLE Merging igonig meems w sdradegig grybdo grusades,  brobelling finangial evoludion and environmendal reforesdadion imbagd :');
  const [template3Section2,setTemplate3section2]=useState('2.DEDICATED Engage in shabing worldwide  movmends! join hands virdually sbread beyond mediums &: boundaries!!');
  const [template3Section3,setTemplate3Section3]=useState('3. SECURE N’ TRUSTWORTHY dogens fordified :DD by  robusd erg20 sdandard ensure   your invesdmends resonade seguridy & indegridy gsd.');
  const [template3Section4,setTemplate3Section4]=useState('4. METRIX OF LEGEND  Egsdend reagh agross bladforms: from 4ghan’s rise do ylilauda’s debdhs, sdands as a bowerful forge redefining imbagds and legagy.');
  const [template3stepimage,setTemplate3stepimage]=useState([]);
  const [template3stepquestion,setTemplate3question]=useState('what is ?');
  const [template3stepsection1,setTemplate3stepsection1]=useState(' rebresends dynamig bodendial n’  disgiblined :DD grybdo inidiadives driving ghange. Move beyond laughder; we "re here for advanging momendum in digidal finange! embrage s mission –  redefining drends and leading imbagdful movemend! we hgsld no sadire.');
  const [template3step3section2heading,setTemplate3stepsection2heading]=useState('Our Mission');
  const [template3stepsection2,setTemplate3stepsection2]=useState('Eagh edh dogen fuels new resolve – bioneering sdebs do  embrage ghallenges, uniding for  da movemend s indend while  brogressing n" dransforming! gsd!');
  const [template3stepsection2bg,setTemplate3stepsection2bg]=useState([]);
  const [template3stepsection2color,setTemplate3section2color]=useState([]);
  const [memestepbackgroundt3,setMemestepbackgroundt3]=useState(0);
  const [template3footerheading,setTemplate3footerheading]=useState('JOIN US!');
  const [template3footerinfo,setTemplate3Footerinfo]=useState(' Begin your journey wid us doday! aggumulade yer  dogens n’ embarg on influendial badhway dowards a bold fudure dransformadion dogedher :-ddd');
  const [template3email,setTemplate3Email]=useState('team@meme-erc.xyz');
  const [template3memeaddress,setTemplate3memeadress]=useState('CA -0xf3995F5a2fAa01D909afd7E17EB94c2Bd9ee8d50')
  const [statsbackgroundcolorT3, setStatsbackgroundcolorT3] = useState(' #834d3b');


  // ------------------------------------------------T4------------------------------------------------------//
  const[t4navbutton,setT4Navbutton]=useState('BUY $PREMT');
  const [t4memeaddress,setT4memeadress]=useState('CA -0xf3995F5a2fAa01D909afd7E17EB94c2Bd9ee8d50');
  const [t4caption,setT4caption]=useState('mek memes gret agen');
  const [t4mainbutton1,setT4mainbutton1]=useState('BUY $PREMT')
  const [t4mainbutton2,setT4mainbutton2]=useState('PREMT ON X');
  const [t4token,setT4token]=useState('MEMES');
  const [t4step1,setT4step1]=useState('How to buy');
  const [t4step2,setT4step2]=useState('Download Phantom or your wallet of choice from the app store or Google Play for free. Desktop users, download the Google Chrome extension by going to Phantom.'); 
  const [t4step3,setT4step3]=useState('Have SOL in your wallet to switch to $PREMT. If you don’t have any SOL, you can buy directly on Phantom, transfer from another wallet, or buy on another exchange and send it to your wallet.');
  const [t4step4,setT4step4]=useState('Connect to Jupiter. Go to Jupiter in chrome or on the browser inside your Phantom app. Connect your wallet. Paste the $PREMT token address into Jupiter, select $PREMT, and confirm. When Phantom prompts you for a wallet signature, sign.');
  const [t4step5,setT4step5]=useState('Switch SOL for $PREMT. We have Zero taxes so you don’t need to worry about buying with a specific slippage.');
  const [t4footer1,setT4footer1]=useState('Have you caught wind of the recent updates regarding Richard Johnson? It seems like he is contemplating the launch of his very own digital currency called "JohnsonCoin ! Speculation suggests that it could potentially be as valuable as his Facebook security question. The buzz around this development is growing, and many are eage  to see how this venture unfolds. Stay tuned for more updates on this intriguing development.');
  const [t4footer2,setT4footer2]=useState('Have you had a chance to dive into the latest design trends in the tech industry? It appears that minimalist interfaces and bold typography are taking center stage. Designers are experimenting with vibrant color palettes and unconventional layouts to create unique user experiences. Keep an eye out for how these trends evolve in the coming months.');
  const [t4footer3,setT4footer3]=useState('Are you familiar with the rise of voice-activated technology in smart devices? From virtual assistants to smart speakers,voice commands are becoming increasingly popular for hands-free interactions. The convenience and accessibility of voice control are reshaping the way users interact with technology. Watch out for the next wave of innovations in this space.');
  const [t4footer4,setT4footer4]=useState('IN THE NEWS');
  const [t4stepimage,setT4stepImage]=useState([]);



  // ---------------------------------------------------------------T5------------------------------------------//
  const [t5Nav1,setT5Nav1]=useState('Powered by Trend');
  const [t5nav2,sett5Nav2]=useState('Buy');
  const [t5about1,setT5Aboutt1]=useState('PUT ON A HELMET');
  const [t5about2,setT5About2]=useState(`Join the ${memecoinname} army by putting on a helmet using our tool.`);
  const [t5Token1,setT5token1]=useState('TOKENOMICS');
  const [t5Token3,setT5token3]=useState(`${memecoinname} made 555 Million tokens but`)
  const [t5Token4,setT5token4]=useState('he s a greedy son of a bitch and wants to keep them all to himself');
  const [t5Token5,setT5token5]=useState(`555,555,555 ${memecoinname}`)
  const [t5Token6,setT5token6]=useState('0xf3995F5a2fAa01D909afd7E17EB94c2Bd9ee8d50');
  const [t5step1,setT5Step1]=useState('Visit phantom.app and follow the simple steps to create a new account with the Phantom app or browser extension');
  const [t5step2,setT5Step2]=useState('Tap the BUY button in the app to purchase Solana, or deposit $SOL to your Phantom  wallet from the crypto  exchange of your choice');
  const [t5step3,setT5Step3]=useState(`Tap the SWAP icon in your Phantom wallet and paste the ${memecoinname} token address. Swap your $SOL for ${memecoinname}.`);
  const [t5step4,setT5Step4]=useState( `Welcome to the #${memecoinname} Army!`);




  const [selectedLanguagememetoken, setselectedLanguagememetoken] = useState("");
  const [webLanguage, setwebLanguage] = useState("");
  return (
    <MyContext.Provider value={{
      selectedLanguagememetoken, setselectedLanguagememetoken,
      memetokencaption,setMemetokenCaption,
      memetokenbuy,setMemetokenBuy,
           
      webLanguage, setwebLanguage,
      t5Nav1,setT5Nav1,
      t5nav2,sett5Nav2,
      t5about1,setT5Aboutt1,
      t5about2,setT5About2,
      t5Token1,setT5token1,
      
      t5Token3,setT5token3,
      t5Token4,setT5token4,
      t5Token5,setT5token5,
      t5Token6,setT5token6,
      t5step1,setT5Step1,
      t5step2,setT5Step2,
      t5step3,setT5Step3,
      t5step4,setT5Step4,


      t4navbutton,setT4Navbutton,
      t4memeaddress,setT4memeadress,
      t4caption,setT4caption,
      t4mainbutton1,setT4mainbutton1,
      t4mainbutton2,setT4mainbutton2,
      t4token,setT4token,
      t4step1,setT4step1,
      t4step2,setT4step2,
      t4step3,setT4step3,
      t4step4,setT4step4,
      t4step5,setT4step5,
      t4footer1,setT4footer1,
      t4footer2,setT4footer2,
      t4footer3,setT4footer3,
      t4footer4,setT4footer4,
      t4stepimage,setT4stepImage,


      

      statsbackgroundcolorT3, setStatsbackgroundcolorT3,
      template3memeaddress,setTemplate3memeadress,
      template3email,setTemplate3Email,
      memestepbackgroundt3,setMemestepbackgroundt3,
      template3footerinfo,setTemplate3Footerinfo,
      template3footerheading,setTemplate3footerheading,
      template3stepsection2bg,setTemplate3stepsection2bg,
      template3stepsection2color,setTemplate3section2color,
      template3stepsection2,setTemplate3stepsection2,
      template3stepquestion,setTemplate3question,
      template3step3section2heading,setTemplate3stepsection2heading,
      template3stepsection1,setTemplate3stepsection1,
      template3stepimage,setTemplate3stepimage,
      template3section1,setTemplate3section1,
      template3Section2,setTemplate3section2,
      template3Section3,setTemplate3Section3,
      template3Section4,setTemplate3Section4,
      template3telegramlink,setTemplate3telegramlink,
      template3twitterlink,setTemplate3twitterlink,
      template3uniswaplink,setTemplate3uniswaplink,
      template3heading,setTemplate3Heading,



      template2removebuttonuniswap,setTemplate2Remvebuttonuniswap,
      template2removebuttonunicmc,setTemplate2Remvebuttonunicmc,
      template2removebuttoncoingecko,setTemplate2Remvebuttoncoingecko,
      template2coingecko,setTemplate2coingecko,
      template2cmc,setTemplate2cmc,
      template2uniswap,setTemplateuniswap,
      template2tokenAddress,setTemplate2tokenAddress,
      template2info,setTemplate2info,
      template2memecaption,setTemplate2memecaption,
      template2caption,setTemplate2Caption,
      template2twitterlink,setTemplate2twitterlink,
      template2telegramlink,setTemplate2telegramlink,
      template2tokentax,setTemplate2tokentax,
      template2tokenlp,setTemplate2tokenlp,
      template2tokenMeme,setTemplate2tokenMeme,
      template2tokentatesupply,setTemplate2tokentatesupply,
      template2tokensupply,setTemplate2tokensupply,
      template2tokenheading,setTemplate2tokenheading,
      template2step1,setTemplate2step1,
      template2step3,setTemplate2step3,
      template2step2,setTemplate2step2,
      template2step4,setTemplate2step4,
      template2stepheading,setTemplate2stepheading,
      template2footerlogo,setTemplate2footerlogo,
      template2footeringo,setTemplate2footerinfo,

      template1footerabout,setTemplate1footerAbout,
      template1tokenomisupply,setTemplate1tokenomisupply,
      template1tokenomicontract,setTemplate1tokenomicontract,
      template1tokenomiTax,setTemplate1tokenomiTax,
      template1footerImage,setTemplate1FooterImage,
      template1stepcontract,setTemplatestepcontract,
      template1stepAdd,setTemplate1Add,

      template1tokenomics,setTemplate1Tokenomics,
      template1tokenomicssub1,setTemplate1tokenomicssub1,
      template1tokenomicssub2,setTemplate1tokenomicssub2,
      template1tokenomicssub3,setTemplate1tokenomicssub3,
      template1tokenimage2,setTemplate1tokenimage2,
      template1tokenimage3,setTemplate1tokenimage3,
      template1Info,setTemplate1Info,
      template1caption,setTemplate1caption,

      
      showSites,setShowSites,
      selectMemeCoinTemplates,setSelectMemeCoinTemplates,
      modalShow, setModalShow,
      memebuttoncolor,setMemebuttoncolor,
      subscriptionStatus, setSubscriptionStatus,
      purchaseplane,setPurchaseplane,
      uniqueID,setUniqueID, 
      memecloudimage,setMemecloudImage, 
      memefooterfontSize,setMemefooterfontSize,
      memestepfontSize,setMemestepfontSize,
      memetokenfontSize,setMemetokenfontSize,
      memeaboutfontSize,setMemeaboutfontsize,
      memeMainfontsize,setMemeMainfontsize,
      memenavfontsize,setMemenavfontsize,
      memefootertextfont,setMemefootertextfont,
      memefootertextcolor,setMemefootertextcolore,
      tokenvalubgcolor,settokenvalubgcolor,
      memefootrbackground,setMemefootrbackground,
      memefooterbgcolor,setMemefooterbgcolor,
      memecustmonapi, setMemecustmonapi,
      domainupdate, setDomianupdate,
      custmondomian,setcustmondomian,
      memestepbackground,setMemestepbackground,
      memestepbgcolor,setMemestepbgcolor,
      memetokenomicsbackground,setMemetokenomicsbackground,
      memetokenomicsbgcolor,setMemetokenomicsbgcolor,
      memeaboutbackground,setMemeaboutbackground,
      memeherobackground,setMemeherobackground,
      memeherobgcolor,setMemeherobgcolor,
      memebackground,setMemebackground,
      memenavbarbgcolor,setMemenavbarbgcolor,
      publishsite,setPublishsite,
      memefooterbgimage, setMemefooterbgimage,
      memestepbgimage,setMemestepbgimage,
      memeaboutbgimage,setmemeAboutbgimage,
      memetwitterlink,setMemetwitterlink,
      memetelegramlink,setMemetelegramlink,
      memeYoutubelink,setMemeYoutubelink,
      memetiktoklink,setMemetiktoklink,
      memeinstagramlink,setMemeinstagramlink,
      memewarpcastlink,setMemewarpcastlink,
      memefooterabout, setMemefooterabout,
      memeherobutton,setmemeHerobutton,
      step1,setStep1,
      step2,setStep2,
      step3,setStep3,
      step4,setStep4,
      stepheading,setStepheading,
      newiconsImage,setNewiconImage,
      iSremove1, setIsremove1,
      iSremove2, setIsremove2,
      iSremove3, setIsremove3,
      iSremove4, setIsremove4,
      iSremove5, setIsremove5,
      iSremove6, setIsremove6,
      iSremove, setIsremove,
      newaddtokest, setNewaddtokesst,
      removelogotokan, setRemovelogotoken,
      newdomain, setNewdomain,
      subdomain, setSubdomain,
      subdomain1, setSubdomain1,
      memecoinsteptextcolor, setMemecoinsteptextcolor,
      memecoinstepfont, setMemecoinstepfont,
      memecointoketextcolor, setMemecointoketextcolor,
      memecointokenfont, setMemecointokenfont,
      memecoinvisiontextcolor, setMemecoinvisiontextcolor,
      memecoinvisionfont, setMemecoinvisionfont,
      memecoinsection, setMemecoinsection,
      memecoinaboutfont, setMemecoinaboutfont,
      memecoinabouttextcolor, setMemecoinabouttextcolor,
      memenavtextcolor, setMemenavtextcolor,
      memecoinanvfont, setMemecoinanvfont,
      memecointokonomicsbgimage, setMemecointokonomicsbgimage,
      memeaboutbgcolor, setMemeaboutbgcolor,
      memenavbgcolor, setMemenavbgcolor,
      memecoinContractImage, setMemecoinContractImage,
      memecoinDatabaseImage, setMemecoinDatabaseImage,
      memecoinCirculatingImage, setMemecoinCirculatingImage,
      memecoinBurnedImage, setMemecoinBurnedImage,
      memecoinLiquidityImage, setMemecoinLiquidityImage,
      memecoinRenouncedImage, setMemecoinRenouncedImage,
      memecointokenomicsimage, setMemecointokenomicsimage,
      memecoinavisionleftimage, setMemecoinavisionleftimage,
      memecoinavisionrightimage, setMemecoinavisionrightimage,
      memecoinaboutrightimage, setMemecoinaboutrightimage,
      memecoinaboutleftimage, setMemecoinaboutleftimage,
      memecoinAminitionImage, setMemecoinAminationImage,
      memecoinaboutbgImage, setMemecoinaboutbgIamge,
      randomId, setRandomId,
      memecoinlogoImage, setMemecoinlogoImage,
      memecoincontractaddress, setMemecoincontractAddress,
      memecoinInitialsupply, setMemecoinInitialsupply,
      memecoinCirculatingSupply, setMemecoinCirculatingSupply,
      memecoinSupplyBurned, setMemecoinSupplyBurned,
      memecoinLiquidity, setMemecoinLiquidity,
      memecoinname, SetMemecoinName,
      memecoinsymbol, setMemecoinSymbol,
      memecoinNavbar, setMemecoinNavbar,
      memecoinAbout, setMemecoinAbout,
      memeCaption, setMemecaption,
      memecoinValue, setMemecoinValue,
      contractRenounced,setContractRenounced,

      purchaseData, setPurchaseData,
      expiredate, setExpiredate,
      websiteName,setWebsiteName,







      featureimages, setFeatureImages,
      introImages, setIntroIamge,
      buttonshowdoblur, setButtonshowdoblur,
      buttonshowdowcolor, setButtonshowdocolor,
      buttonshowdospread, setButtonshowdospread,
      buttonshowdoleft, setButtonshowdoleft,
      buttonshowdoTop, setButtonshowdoTop,
      featureIcon1, setfeatureIcon1,
      featureIcon2, setfeatureIcon2,
      featureIcon3, setfeatureIcon3,
      featureIcon4, setfeatureIcon4,
      memecoin, setMemecoin,
      newnavbarmenu, setNewnavbarmenu,
      alignMedia, setAlignMedia,
      imagePosition, setImagePosition,
      selectedFeature, setSelectedFeature,
      buttonvarticalpadding, setButtonvarticalpadding,
      buttonhorizantlepadding, setButtonhorizantlepadding,
      Animated, setAnimated,
      selectedStats, setSelectedStats,
      selectedLogo, setSelectedLogo,
      selectedFaq, setSelectedFaq,
      selectedFooter, setSelectedFooter,
      showdovalue, setshowdovalue,
      butttonshowdo, setButtonshowdo,
      buttonborder, setbuttonborder,
      buttonborderradius, setButtonborderradios,
      buttonbordercolor, setbuttonbordercolor,
      buttoncolor, setButtoncolor,
      button1font, setButton1font,
      buttontext, setbuttontext,
      selectedHero, setSelectedHero,
      statsbordercolor, setStatsbordercolor,
      statsshowdowrange, setStatsshowdowrange,
      statsborder, setStatsborder,
      statsborderradius, setStatsborderradius,
      statsbackgroundcolor, setStatsbackgroundcolor,
      statevaluecolor, setStatsvaluecolor,
      statslabelcolor, setStatslabelcolor,
      captioncolor, setCaptioncolor,
      selectedLi, setSelectedLi,
      checked, setChecked,
      conatcmenuborder, setcontactmenuborder,
      contactheadingcolor, setContactheadingcolor,
      contenttextheadingcolor, setContenttextheadingcolor,
      contenttextinfocolor, setContenttextinfocolor,
      statethree, setStatsthree,
      statetwo, setStatstwo,
      stateOne, setStatsOne,
      footerMargintop, setFooterMarginTop,
      footerMarginBottom, setFooterMarginBottom,
      faqMargintop, setFaqMarginTop,
      faqMarginBottom, setFaqMarginBottom,
      statsMarginBottom, setStatsMarginBottom,
      statsMarginTop, setStatsMarginTop,
      serviceMarginBottom, setServiceMarginBottom,
      serviceMarginTop, setServiceMarginTop,
      trustmargintop, setTrustmargintop,
      trustmarginbottom, setTrustmarginBottom,
      marginTop, setMarginTop,
      marginBottom, setmarginBottom,
      intromargintop, setIntromargintop,
      intromarginbottom, setIntromarginbottom,
      showdowrange, setShowdorange,
      footerbodytextfont, setFooterbodytextfont,
      faqtitletextfont, setFaqtitletextfont,
      faqbodytextfont, setFaqbodytextfont,
      statsbodytextfont, setStatsbodytextfont,
      statstitletextfont, setStatstitletextfont,
      serviceBodytextfont, setserviceBodytextfont,
      servicetitletextfont, setServicestitletextfont,
      trusttitletextfont, setTrusttitletextfont,
      trusttextfont, setTrusttextfont,
      introinfotextfont, setintroinfotextfont,
      introtextfont, setIntrotextfont,
      newfooterhead, setNewfooterhead,
      newfooteritem, setNewfooteritem,
      faqCaption, setFaqCaption,
      footerLinkgroups, setfooterLinkgroups,
      statscaption, setStatsCaption,
      servicecaption, setServicesCaption,
      trustCaption, setTrustCaption,
      introcaption, setIntrocaption,
      footerlegalhead, setFooterleagalhead,
      footerCompanyhead, setFooterCompanyhead,
      footerfeaturehead, setFooterfeaturehead,
      headertextfont, setHeadertextfont,
      selectedOption1, setSelectedOption1,
      intromenutextcolor, setIntromenutextcolor,
      intromenubgcolor, setIntromenubgcolor,
      newBackgroundcolor, setNewbackgroundcolor,
      colorheaderDiv, setcolorheaderdiv,
      newservicesImage, setNewserviceImage,
      headernotice, setheadernotice,
      newlogo, setNewlogo,
      newtrustlogo, setNewtrustlogo,
      newfootersocialIcon, setNewfootersocialIcon,
      newFaq, setNewFaq,
      newlegacy1, setNewlgacy1,
      newlegacy, setNewlgacy,
      removelegacy, setRemovelegacy,
      removelegacynum, setremoveleagacynum,
      trustLogo, settTrustlogos,
      newservicesinfo, setNewservicesinfo,
      newservicesheading, setNewservicesheading,
      introbuttonapprovel, setIntrobuttonapprovel,
      addintrobutton, setAddintrobutton,
      addbuttonapprovel, setAddbuttonapprovel,
      removeSocialIcon1, setRemoveSocialIcon1,
      removeSocialIcon2, setRemoveSocialIcon2,
      removeSocialIcon3, setRemoveSocialIcon3,
      removeSocialIcon4, setRemoveSocialIcon4,
      navAddbutton, setNavaddbutton,
      footeryear, setFooteryear,
      faqtitle, setFaqtitle,
      faqIntro, setFaqIntro,
      newIntroImage, setNewIntroImage,
      leagacydiv, setLeagacydiv,
      addnavmenu, setAddnavmenu,
      navadddiv, setNavnavadddiv,
      removelogos, setRemovelogos,
      removeFaq1, setRemovefaq1,
      removeFaq2, setRemovefaq2,
      removeFaq3, setRemovefaq3,
      removeFaq4, setRemovefaq4,
      statsnumremove1, setStatsnumremove1,
      statsnumremove2, setStatsnumremove2,
      statsnumremove3, setStatsnumremove3,
      serviceremove1, settServiceremove1,
      serviceremove2, settServiceremove2,
      serviceremove3, settServiceremove3,
      serviceremove4, settServiceremove4,
      introbtn1remove, setIntrobtnremove1,
      introbtn2remove, setIntrobtnremove2,
      navbarremove, setNavbarremove,
      navremovebtn1, setNavremovebtn1,
      navremovebtn2, setNavremovebtn2,
      navbuttonincraese, setNavbuttonincrease,
      inttrobtn1, setIntrobtn1,
      introbtn2, setIntrobtn2,
      statsintro, setStatsinto,
      statsnumber1, setStatsnumber1,
      statsnumber2, setStatsnumber2,
      statsnumber3, setStatsnumber3,
      statsnumber4, setStatsnumber4,
      statsnumber5, setStatsnumber5,
      statsnumber6, setStatsnumber6,
      navbtn1, setNavbtn1,
      navbtn2, setNavbtn2,
      faqA1, setfaqA1,
      faqA2, setfaqA2,
      faqA3, setfaqA3,
      faqA4, setfaqA4,
      faqQ1, setFaqQ1,
      faqQ2, setFaqQ2,
      faqQ3, setFaqQ3,
      faqQ4, setFaqQ4,
      serviceHeading1, setServiceHeading1,
      serviceinfo1, setServiceinfo1,
      serviceHeading2, setServiceHeading2,
      serviceinfo2, setServiceinfo2,
      serviceHeading3, setServiceHeading3,
      serviceinfo3, setServiceinfo3,
      serviceHeading4, setServiceHeading4,
      serviceinfo4, setServiceinfo4,
      servicesIntro1, setServicesIntro1,
      servicesIntro, setservicesIntro,
      menu1, setmenu1,
      headerdivtextcolor, setHeaderDivTextColor,
      headerdivtext, setHeaderDivText,
      headerdivbgcolor, setHeaderdivbgcolor,
      navbartitle, setNavbartitle,
      headerdiv, setHeaderdiv,
      section1, setsection1,
      section2, setsection2,
      section3, setsection3,
      section4, setsection4,
      section5, setsection5,
      section6, setsection6,
      section7, setsection7,
      contentdesing, setContentdesing,
      mainnavbarbutton, setMainnavbarbutton,
      images, setImages,
      serachtearm1, setSearchteam,
      searchTerm, setSearchTerm,
      visibleComponents, setVisibleComponents,
      footercompany, setfootercompany,
      footerlegal, setfooterlegal,
      footerfeature, setfooterfeature,
      serv_list_obj, Setserv_list_obj,
      introo, setIntroo,
      footerfeatures, setfooterfeatures,
      navbarmenu, setnavbarmenu,
      footertext, setfootertext,
      footercolor, setfootercolor,
      showWebsite, setShowWebsite,
      loading, setLoading,
      location, setLocation,
      title, setTitle,
      intro, setIntro,
      about, setAbout,
      services, setServices,
      portfolio, setPortfolio,
      reviews, setReviews,
      contact, setContact,
      faq, setFaq,
      menu, setmenu,
      byTheNumber, setByTheNumber,
      navbartextcolor, setnavbartextcolor,
      welcometilte, setwelcometitle,
      titlecolor, settitlecolor,
      introcolor, setintrocolor,
      introtext, setintrotext,
      legacycolor, setlegacycolor,
      featurecolor, setfeaturecolor,
      featuretext, setfeaturetext,
      faqcolor, setfaqcolor,
      faqtext, setfaqtext,
      legacytext, setlegacytext,
      serviceheader, setserviceheader,
      legacy, setleagacy,
      trustcolor, settrustcolor,
      trusttext, settrusttextcolor,
      trust, settrust,
      trsuttitle, settrusttile,

    }}>
      {children}
    </MyContext.Provider>
  );
};

export const useMyContext = () => {
  return useContext(MyContext);
};

export default MyProvider;
