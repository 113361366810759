



// new code -------------------------------------------------------------


import * as React from 'react'
import { useContext, useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faCircleQuestion, faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import WebPages from '../../Webpages';
import MyContext from "../../../context/MyContext";
import MainNavBar from "../../MainNavBar";
import FeatureDessing from "./featureDesing";
import ContentDesign from "../../ContentDesign";
import CaptionTitle from "../../CaptionTitle";
import Theme from "../../Theme";
// import * as React from 'react'
import { FaBeer } from "react-icons/fa";
import IconPicker from 'react-icons-picker'

const FeatureEdit = () => {
  const { serviceHeading1, setServiceHeading1,
    serviceinfo1, setServiceinfo1,
    serviceHeading2, setServiceHeading2,
    serviceinfo2, setServiceinfo2,
    serviceHeading3, setServiceHeading3,
    serviceinfo3, setServiceinfo3,
    serviceHeading4, setServiceHeading4,
    serviceinfo4, setServiceinfo4,
    servicesIntro1, setServicesIntro1,
    servicesIntro, setservicesIntro,
    serviceheader, setserviceheader,
    contentdesing, mainnavbarbutton,
    services, setServices,newlegacy,
    serviceremove1, settServiceremove1,
    serviceremove2, settServiceremove2,
    serviceremove3, settServiceremove3,
    serviceremove4, settServiceremove4,
    newservicesinfo, setNewservicesinfo,
    newservicesheading, setNewservicesheading,
    newservicesImage,setNewserviceImage,
    servicecaption,setServicesCaption,
    featureIcon1,setfeatureIcon1,
      featureIcon2,setfeatureIcon2,
      featureIcon3,setfeatureIcon3,
      featureIcon4,setfeatureIcon4,
      
  } = useContext(MyContext);
  const [value, setValue] = useState("FaUsers")
  const [service, setservice] = useState(true);
  const [content, setContent] = useState(services?.services_section_intro?.intro || '');
  const [isVisiable,setIsvisiable]=useState(false);

  console.log('Newservices', value)
  const handelserviceedit = () => {
    setservice(true)
  };
  
  const handelservicedesing = () => {
    setservice(false)
  }

  const handleChangeIntro = (index, property, value) => {
    if (index == 0) {
      const updatedServices = [...services];
      updatedServices[index][property] = value;
      setServices(updatedServices);
    }


  };


  console.log(services, 'service');



  console.log('Newservices', servicesIntro, typeof services)


  const contentEditableRef = useRef(null);
  const [caretPosition, setCaretPosition] = useState(0);
  const contentEditableRef1 = useRef(null);
  const [caretPosition1, setCaretPosition1] = useState(0);


  const contentEditableRef2 = useRef(null);
  const [caretPosition2, setCaretPosition2] = useState(0);
  const contentEditableRef3 = useRef(null);
  const [caretPosition3, setCaretPosition3] = useState(0);
  const contentEditableRef4 = useRef(null);
  const [caretPosition4, setCaretPosition4] = useState(0);
  const contentEditableRef5 = useRef(null);
  const [caretPosition5, setCaretPosition5] = useState(0);
  const contentEditableRef6 = useRef(null);
  const [caretPosition6, setCaretPosition6] = useState(0);
  const contentEditableRef7 = useRef(null);
  const [caretPosition7, setCaretPosition7] = useState(0);
  const contentEditableRef8 = useRef(null);
  const [caretPosition8, setCaretPosition8] = useState(0);
  const contentEditableRef9 = useRef(null);
  const [caretPosition9, setCaretPosition9] = useState(0);
  const [servicecount, setServicecount] = useState(0);




  const handleTrust = () => {
    const content = contentEditableRef.current.textContent;
    setserviceheader(content);
    setCaretPosition(getCaretPosition());
  };


  const handleIntro1 = (e) => {
    const content = contentEditableRef1.current.textContent;
    setServicesIntro1(content);
    setCaretPosition1(getCaretPosition1());

  };

  const handleheading1 = (e) => {
    const content = contentEditableRef2.current.textContent;
    setServiceHeading1(content);
    setCaretPosition2(getCaretPosition2());

  };
  const handleinfo1 = (e) => {
    const content = contentEditableRef3.current.textContent;
    setServiceinfo1(content);
    setCaretPosition3(getCaretPosition2());

  };
  const handleheading2 = (e) => {
    const content = contentEditableRef4.current.textContent;
    setServiceHeading2(content);
    setCaretPosition4(getCaretPosition4());

  };
  const handleinfo2 = (e) => {
    const content = contentEditableRef5.current.textContent;
    setServiceinfo2(content);
    setCaretPosition5(getCaretPosition5());

  };

  const handleheading3 = (e) => {
    const content = contentEditableRef6.current.textContent;
    setServiceHeading3(content);
    setCaretPosition6(getCaretPosition6());

  };

  const handleinfo3 = (e) => {
    const content = contentEditableRef7.current.textContent;
    setServiceinfo3(content);
    setCaretPosition7(getCaretPosition7());

  };

  const handleheading4 = (e) => {
    const content = contentEditableRef8.current.textContent;
    setServiceHeading4(content);
    setCaretPosition8(getCaretPosition8());

  };
  const handleinfo4 = (e) => {
    const content = contentEditableRef9.current.textContent;
    setServiceinfo4(content);
    setCaretPosition9(getCaretPosition9());

  };

  useEffect(() => {
    setCaretToEnd();
  }, [serviceheader]);

  useEffect(() => {
    setCaretToEnd1();
  }, [servicesIntro1]);

  useEffect(() => {
    setCaretToEnd2();
  }, [serviceHeading1]);

  useEffect(() => {
    setCaretToEnd3();
  }, [serviceinfo1]);
  useEffect(() => {
    setCaretToEnd4();
  }, [serviceHeading2]);

  useEffect(() => {
    setCaretToEnd5();
  }, [serviceinfo2]);
  useEffect(() => {
    setCaretToEnd6();
  }, [serviceHeading3]);
  useEffect(() => {
    setCaretToEnd7();
  }, [serviceinfo3]);
  useEffect(() => {
    setCaretToEnd8();
  }, [serviceHeading4]);
  useEffect(() => {
    setCaretToEnd9();
  }, [serviceinfo4]);
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Backspace') {
        setCaretPosition(getCaretPosition());
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Backspace') {
        setCaretPosition1(getCaretPosition1());
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Backspace') {
        setCaretPosition2(getCaretPosition2());
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Backspace') {
        setCaretPosition3(getCaretPosition3());
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Backspace') {
        setCaretPosition4(getCaretPosition4());
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Backspace') {
        setCaretPosition5(getCaretPosition5());
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Backspace') {
        setCaretPosition6(getCaretPosition6());
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Backspace') {
        setCaretPosition7(getCaretPosition7());
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Backspace') {
        setCaretPosition8(getCaretPosition8());
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Backspace') {
        setCaretPosition9(getCaretPosition9());
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);




  const getCaretPosition = () => {
    const selection = window.getSelection();
    if (selection && selection.rangeCount > 0) {
      return selection.getRangeAt(0).startOffset;
    }
    return 0;
  };
  const getCaretPosition1 = () => {
    const selection = window.getSelection();
    if (selection && selection.rangeCount > 0) {
      return selection.getRangeAt(0).startOffset;
    }
    return 0;
  };
  const getCaretPosition2 = () => {
    const selection = window.getSelection();
    if (selection && selection.rangeCount > 0) {
      return selection.getRangeAt(0).startOffset;
    }
    return 0;
  }; const getCaretPosition3 = () => {
    const selection = window.getSelection();
    if (selection && selection.rangeCount > 0) {
      return selection.getRangeAt(0).startOffset;
    }
    return 0;
  }; const getCaretPosition4 = () => {
    const selection = window.getSelection();
    if (selection && selection.rangeCount > 0) {
      return selection.getRangeAt(0).startOffset;
    }
    return 0;
  }; const getCaretPosition5 = () => {
    const selection = window.getSelection();
    if (selection && selection.rangeCount > 0) {
      return selection.getRangeAt(0).startOffset;
    }
    return 0;
  }; const getCaretPosition6 = () => {
    const selection = window.getSelection();
    if (selection && selection.rangeCount > 0) {
      return selection.getRangeAt(0).startOffset;
    }
    return 0;
  }; const getCaretPosition7 = () => {
    const selection = window.getSelection();
    if (selection && selection.rangeCount > 0) {
      return selection.getRangeAt(0).startOffset;
    }
    return 0;
  }; const getCaretPosition8 = () => {
    const selection = window.getSelection();
    if (selection && selection.rangeCount > 0) {
      return selection.getRangeAt(0).startOffset;
    }
    return 0;
  }; const getCaretPosition9 = () => {
    const selection = window.getSelection();
    if (selection && selection.rangeCount > 0) {
      return selection.getRangeAt(0).startOffset;
    }
    return 0;
  };

  const setCaretToEnd = () => {
    const range = document.createRange();
    const sel = window.getSelection();
    const lastChild = contentEditableRef.current.lastChild;
    if (lastChild && lastChild.nodeType === Node.TEXT_NODE) {
      const textLength = lastChild.textContent.length;
      const offset = Math.min(caretPosition, textLength);
      range.setStart(lastChild, offset);
      range.collapse(true);
      sel.removeAllRanges();
      sel.addRange(range);
    }
  };
  const setCaretToEnd1 = () => {
    const range = document.createRange();
    const sel = window.getSelection();
    const lastChild = contentEditableRef1.current.lastChild;
    if (lastChild && lastChild.nodeType === Node.TEXT_NODE) {
      const textLength = lastChild.textContent.length;
      const offset = Math.min(caretPosition1, textLength);
      range.setStart(lastChild, offset);
      range.collapse(true);
      sel.removeAllRanges();
      sel.addRange(range);
    }
  };
  const setCaretToEnd2 = () => {
    const range = document.createRange();
    const sel = window.getSelection();
    const lastChild = contentEditableRef2.current.lastChild;
    if (lastChild && lastChild.nodeType === Node.TEXT_NODE) {
      const textLength = lastChild.textContent.length;
      const offset = Math.min(caretPosition2, textLength);
      range.setStart(lastChild, offset);
      range.collapse(true);
      sel.removeAllRanges();
      sel.addRange(range);
    }
  }; const setCaretToEnd3 = () => {
    const range = document.createRange();
    const sel = window.getSelection();
    const lastChild = contentEditableRef3.current.lastChild;
    if (lastChild && lastChild.nodeType === Node.TEXT_NODE) {
      const textLength = lastChild.textContent.length;
      const offset = Math.min(caretPosition3, textLength);
      range.setStart(lastChild, offset);
      range.collapse(true);
      sel.removeAllRanges();
      sel.addRange(range);
    }
  }; const setCaretToEnd4 = () => {
    const range = document.createRange();
    const sel = window.getSelection();
    const lastChild = contentEditableRef4.current.lastChild;
    if (lastChild && lastChild.nodeType === Node.TEXT_NODE) {
      const textLength = lastChild.textContent.length;
      const offset = Math.min(caretPosition4, textLength);
      range.setStart(lastChild, offset);
      range.collapse(true);
      sel.removeAllRanges();
      sel.addRange(range);
    }
  }; const setCaretToEnd5 = () => {
    const range = document.createRange();
    const sel = window.getSelection();
    const lastChild = contentEditableRef5.current.lastChild;
    if (lastChild && lastChild.nodeType === Node.TEXT_NODE) {
      const textLength = lastChild.textContent.length;
      const offset = Math.min(caretPosition5, textLength);
      range.setStart(lastChild, offset);
      range.collapse(true);
      sel.removeAllRanges();
      sel.addRange(range);
    }
  }; const setCaretToEnd6 = () => {
    const range = document.createRange();
    const sel = window.getSelection();
    const lastChild = contentEditableRef6.current.lastChild;
    if (lastChild && lastChild.nodeType === Node.TEXT_NODE) {
      const textLength = lastChild.textContent.length;
      const offset = Math.min(caretPosition6, textLength);
      range.setStart(lastChild, offset);
      range.collapse(true);
      sel.removeAllRanges();
      sel.addRange(range);
    }
  }; const setCaretToEnd7 = () => {
    const range = document.createRange();
    const sel = window.getSelection();
    const lastChild = contentEditableRef7.current.lastChild;
    if (lastChild && lastChild.nodeType === Node.TEXT_NODE) {
      const textLength = lastChild.textContent.length;
      const offset = Math.min(caretPosition7, textLength);
      range.setStart(lastChild, offset);
      range.collapse(true);
      sel.removeAllRanges();
      sel.addRange(range);
    }
  }; const setCaretToEnd8 = () => {
    const range = document.createRange();
    const sel = window.getSelection();
    const lastChild = contentEditableRef8.current.lastChild;
    if (lastChild && lastChild.nodeType === Node.TEXT_NODE) {
      const textLength = lastChild.textContent.length;
      const offset = Math.min(caretPosition8, textLength);
      range.setStart(lastChild, offset);
      range.collapse(true);
      sel.removeAllRanges();
      sel.addRange(range);
    }
  }; const setCaretToEnd9 = () => {
    const range = document.createRange();
    const sel = window.getSelection();
    const lastChild = contentEditableRef9.current.lastChild;
    if (lastChild && lastChild.nodeType === Node.TEXT_NODE) {
      const textLength = lastChild.textContent.length;
      const offset = Math.min(caretPosition9, textLength);
      range.setStart(lastChild, offset);
      range.collapse(true);
      sel.removeAllRanges();
      sel.addRange(range);
    }
  };


  console.log('services?.services_section_intro?.intro', services?.services_section_intro?.intro, typeof services?.services_section_intro?.intro)
  const handleInputChange = (event) => {
    setContent(event.target.value);


  };

  const handelService1 = () => {
    settServiceremove1(true)
  };
  const handelService2 = () => {
    settServiceremove2(true)
  };
  const handelService3 = () => {
    settServiceremove3(true)
  };
  const handelService4 = () => {
    settServiceremove4(true)
  };
  const handelSericecount = () => {
    if (servicecount < 4) {
      setServicecount(servicecount + 1)
    } else {
      setServicecount(0)
    }
  };

  const handelAddservices = () => {

    switch (servicecount) {
      case 1:
        settServiceremove1(false);
        setServiceHeading1('');
        setServiceinfo1('');
        break;
      case 2:
        settServiceremove2(false);
        setServiceHeading2('');
        setServiceinfo2('');
        break;
      case 3:
        settServiceremove3(false);
        setServiceHeading3('');
        setServiceinfo3('');
        break;
      case 4:
        settServiceremove4(false);
        setServiceHeading4('');
        setServiceinfo4('');
        break;
      default:
        console.log('Error')
    }


  };
  const handelAddnewservicesinput = () => {
    setNewservicesheading(prevMenu => [...prevMenu, newservicesheading]);
    setNewservicesheading([...newservicesheading,{heading:'',info:''}])
  };
  const handelNewerivesheading = (index,value) => {
    const updatedMenu = [...newservicesheading];
    updatedMenu[index].heading =value;
    setNewservicesheading(updatedMenu);
  };
  const handelNewerivesInfo = ( index,value) => {
    const updatedMenu = [...newservicesheading];
    updatedMenu[index].info =value;
    setNewservicesheading(updatedMenu);
  };
  const handelRemoveNewService = (indexToRemove) => {
    setNewservicesheading(prevMenu => {
      const updatedMenu = [...prevMenu];
      updatedMenu.splice(indexToRemove, 1);
      return updatedMenu;

    });

  };
  console.log('newservicesheading', newservicesheading, newservicesheading.length,serviceremove1,serviceremove2,serviceremove3,serviceremove4);

  const handleNewserviceImage = (index) => {
    // Open file picker to select an image
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = (e) => {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const updatedTrustLogo = [...newservicesImage];
        updatedTrustLogo[index] = event.target.result;
        setNewserviceImage(updatedTrustLogo);
        console.log('updatedTrustLogo',updatedTrustLogo)
      };

      reader.readAsDataURL(file);
    };
    input.click();
  };

  const HandelServicesCaption=(Caption)=>{
    setServicesCaption(Caption.target.value)
  };
  return (
    <div>


      <div className='container-fluid d-flex flex-column' style={{ height: '100vh', overflow: 'auto' }}>
        <div>
          <MainNavBar />
        </div>
        <div className='flex-grow-1 d-flex flex-row mt-1' style={{ overflow: 'hidden' }}>
          <div style={{ width: '400px' }} >
            {mainnavbarbutton == false ?
              <div className='editing_wrapper__Wijfx h-100  overflow-auto'>
                <ContentDesign headerText="Features" />
                {contentdesing == false ?
                  <div >
                    <div className="editing_content__RXzxk">
                      <div className="form_form__Sku4X" >
                        <div className="form_form__Sku4X">
                          <label class="form_label__27psn">Header</label>
                          <div className="form_group__kDXNb">
                            <div className="form_form__Sku4X">
                              <div class="form_more__6E9XQ" type="button" id="radix-:rer:" aria-haspopup="menu" aria-expanded="false" data-state="closed"><svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.625 2.5C8.625 3.12132 8.12132 3.625 7.5 3.625C6.87868 3.625 6.375 3.12132 6.375 2.5C6.375 1.87868 6.87868 1.375 7.5 1.375C8.12132 1.375 8.625 1.87868 8.625 2.5ZM8.625 7.5C8.625 8.12132 8.12132 8.625 7.5 8.625C6.87868 8.625 6.375 8.12132 6.375 7.5C6.375 6.87868 6.87868 6.375 7.5 6.375C8.12132 6.375 8.625 6.87868 8.625 7.5ZM7.5 13.625C8.12132 13.625 8.625 13.1213 8.625 12.5C8.625 11.8787 8.12132 11.375 7.5 11.375C6.87868 11.375 6.375 11.8787 6.375 12.5C6.375 13.1213 6.87868 13.625 7.5 13.625Z" fill="#8c8c8c" fill-rule="evenodd" clip-rule="evenodd"></path></svg></div>
                              <div class="form_row__2+BJ3">
                                <div class="form_field__d31bq form_size--2x2__J8UQE"><div class="picker_preview__QSKw4"><svg xmlns="http://www.w3.org/2000/svg" height="22" viewBox="0 0 24 24" width="22" fill="#bbb"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm-1-4h2v2h-2zm1.61-9.96c-2.06-.3-3.88.97-4.43 2.79-.18.58.26 1.17.87 1.17h.2c.41 0 .74-.29.88-.67.32-.89 1.27-1.5 2.3-1.28.95.2 1.65 1.13 1.57 2.1-.1 1.34-1.62 1.63-2.45 2.88 0 .01-.01.01-.01.02-.01.02-.02.03-.03.05-.09.15-.18.32-.25.5-.01.03-.03.05-.04.08-.01.02-.01.04-.02.07-.12.34-.2.75-.2 1.25h2c0-.42.11-.77.28-1.07.02-.03.03-.06.05-.09.08-.14.18-.27.28-.39.01-.01.02-.03.03-.04.1-.12.21-.23.33-.34.96-.91 2.26-1.65 1.99-3.56-.24-1.74-1.61-3.21-3.35-3.47z"></path></svg></div></div>
                                <div className="form_group__kDXNb">
                                  <div className="form_form__Sku4X" style={{ maxWidth: "89%" }}>
                                    <div class="form_field__d31bq" >
                                      <textarea placeholder="Caption" style={{ height: "32px" }} value={servicecaption} onChange={(e)=>HandelServicesCaption(e)}></textarea>
                                      </div>
                                    <div class="form_field__d31bq"><div class=""><div contenteditable="true" translate="no" class="tiptap ProseMirror form_value__oIwpW form_tiptap__s0JN1" tabindex="0" ref={contentEditableRef} onInput={handleTrust}> {serviceheader}</div></div></div>
                                    <div class="form_field__d31bq"><div class=""><div contenteditable="true" translate="no" class="tiptap ProseMirror form_value__oIwpW form_tiptap__s0JN1" tabindex="0" ref={contentEditableRef1} onInput={handleIntro1} >{servicesIntro1}</div></div></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <label class="form_label__27psn">Buttons</label>
                        <div className='form_sortable__FLtVI'>
                          <div className='form_listItem__puOqW'>
                            <div class="form_headerHandle__33dpI" tabindex="0" role="button" aria-describedby="rbd-hidden-text-4-hidden-text-17" data-rbd-drag-handle-draggable-id="draggable-0" data-rbd-drag-handle-context-id="4" draggable="false"></div>
                            <div className='form_headerRemove__mJ7no'>
                              <svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 0 24 24" width="14" fill="currentColor"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"></path></svg>
                            </div>
                            <div className="form_form__Sku4X">
                              <div className='form_row__2\+BJ3 form_hasMore__JC1Yq ' style={{ width: "92%" }}>
                                <div className='form_field__d31bq'>
                                  <textarea style={{ height: 32, padding: 5 }}>Our Menu</textarea>
                                </div>
                                <div className='form_field__d31bq'>
                                  <input type="url" placeholder="/your-page" />
                                </div>
                                <div class="form_more__6E9XQ" type="button" id="radix-:rhl:" aria-haspopup="menu" aria-expanded="false" data-state="closed"><svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.625 2.5C8.625 3.12132 8.12132 3.625 7.5 3.625C6.87868 3.625 6.375 3.12132 6.375 2.5C6.375 1.87868 6.87868 1.375 7.5 1.375C8.12132 1.375 8.625 1.87868 8.625 2.5ZM8.625 7.5C8.625 8.12132 8.12132 8.625 7.5 8.625C6.87868 8.625 6.375 8.12132 6.375 7.5C6.375 6.87868 6.87868 6.375 7.5 6.375C8.12132 6.375 8.625 6.87868 8.625 7.5ZM7.5 13.625C8.12132 13.625 8.625 13.1213 8.625 12.5C8.625 11.8787 8.12132 11.375 7.5 11.375C6.87868 11.375 6.375 11.8787 6.375 12.5C6.375 13.1213 6.87868 13.625 7.5 13.625Z" fill="#8c8c8c" fill-rule="evenodd" clip-rule="evenodd"></path></svg></div>
                              </div>

                            </div>
                          </div>
                          <button class="button1 button--small " style={{ alignSelf: 'flex-end', marginTop: '15px' }}>Add Buttons</button>

                        </div>
                        <label class="form_label__27psn">Items</label>

                       
                        {serviceremove1 == false ?
                          <div className='form_listItem__puOqW'>
                            <div class="form_headerHandle__33dpI" tabindex="0" role="button" aria-describedby="rbd-hidden-text-4-hidden-text-17" data-rbd-drag-handle-draggable-id="draggable-0" data-rbd-drag-handle-context-id="4" draggable="false"></div>
                            <div className='form_headerRemove__mJ7no' onClick={() => handelService1()}>
                              <svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 0 24 24" width="14" fill="currentColor"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"></path></svg>
                            </div>
                            <div className="form_form__Sku4X">

                              <div className='d-flex ' >
                                <div class="form_field__d31bq form_size--1x1__H0EdC" style={{ style: "92%", flexGrow: 0 }}>
                                  <div class="picker_preview__QSKw4 picker_invert__zNRsC">
                                  <IconPicker  value={featureIcon1} onChange={(v) => setfeatureIcon1(v)} pickButtonStyle={{borderStyle:'none'}} className='IconPiker' modalWrapperStyle={{position:'absolute',width:'40vw',backgroundColor:"white",fontFamily: 'sans-serif',height: '73vh',boxShadow: 'rgba(0, 0, 0, 0.2) 0px 11px 15px -7px, rgba(0, 0, 0, 0.14) 0px 24px 38px 3px, rgba(0, 0, 0, 0.12) 0px 9px 46px 8px'}}></IconPicker>  
                                  {/* <img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGNsYXNzPSJpY29uIiBmaWxsPSJjdXJyZW50Q29sb3IiIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMTMuNS42N3MuNzQgMi42NS43NCA0LjhjMCAyLjA2LTEuMzUgMy43My0zLjQxIDMuNzMtMi4wNyAwLTMuNjMtMS42Ny0zLjYzLTMuNzNsLjAzLS4zNkM1LjIxIDcuNTEgNCAxMC42MiA0IDE0YzAgNC40MiAzLjU4IDggOCA4czgtMy41OCA4LThDMjAgOC42MSAxNy40MSAzLjggMTMuNS42N3pNMTEuNzEgMTljLTEuNzggMC0zLjIyLTEuNC0zLjIyLTMuMTQgMC0xLjYyIDEuMDUtMi43NiAyLjgxLTMuMTIgMS43Ny0uMzYgMy42LTEuMjEgNC42Mi0yLjU4LjM5IDEuMjkuNTkgMi42NS41OSA0LjA0IDAgMi42NS0yLjE1IDQuOC00LjggNC44eiIvPjwvc3ZnPg==" alt="Icon Preview" style={{ maxWidth: '40px', maxHeight: 22 }} /> */}
                                  </div>
                                </div>
                                <div className="form_group__kDXNb ">
                                  <div className="form_form__Sku4X">
                                    <div class="form_field__d31bq"><div class=""><div contenteditable="true" translate="no" class="tiptap ProseMirror form_value__oIwpW form_tiptap__s0JN1" tabindex="0" ref={contentEditableRef2} onInput={handleheading1}>{serviceHeading1}</div></div></div>
                                    <div class="form_field__d31bq"><div class=""><div contenteditable="true" translate="no" class="tiptap ProseMirror form_value__oIwpW form_tiptap__s0JN1" tabindex="0" ref={contentEditableRef3} onInput={handleinfo1}>{serviceinfo1}</div></div></div>
                                  </div>
                                </div>
                              </div>


                            </div>
                          </div> : null}

                        {serviceremove2 == false ?
                          <div className='form_listItem__puOqW'>
                            <div class="form_headerHandle__33dpI" tabindex="0" role="button" aria-describedby="rbd-hidden-text-4-hidden-text-17" data-rbd-drag-handle-draggable-id="draggable-0" data-rbd-drag-handle-context-id="4" draggable="false"></div>
                            <div className='form_headerRemove__mJ7no' onClick={() => handelService2()}>
                              <svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 0 24 24" width="14" fill="currentColor"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"></path></svg>
                            </div>
                            <div className="form_form__Sku4X">

                              <div className='d-flex ' >
                                <div class="form_field__d31bq form_size--1x1__H0EdC" style={{ style: "92%", flexGrow: 0 }}>
                                  <div class="picker_preview__QSKw4 picker_invert__zNRsC">
                                  <IconPicker value={featureIcon2} onChange={(v) => setfeatureIcon2(v)} pickButtonStyle={{borderStyle:'none'}} className='IconPiker' modalWrapperStyle={{position:'absolute',width:'40vw',backgroundColor:"white",fontFamily: 'sans-serif',height: '73vh',boxShadow: 'rgba(0, 0, 0, 0.2) 0px 11px 15px -7px, rgba(0, 0, 0, 0.14) 0px 24px 38px 3px, rgba(0, 0, 0, 0.12) 0px 9px 46px 8px'}}></IconPicker>  
                                    {/* <img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGNsYXNzPSJpY29uIiBmaWxsPSJjdXJyZW50Q29sb3IiIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMTMuNS42N3MuNzQgMi42NS43NCA0LjhjMCAyLjA2LTEuMzUgMy43My0zLjQxIDMuNzMtMi4wNyAwLTMuNjMtMS42Ny0zLjYzLTMuNzNsLjAzLS4zNkM1LjIxIDcuNTEgNCAxMC42MiA0IDE0YzAgNC40MiAzLjU4IDggOCA4czgtMy41OCA4LThDMjAgOC42MSAxNy40MSAzLjggMTMuNS42N3pNMTEuNzEgMTljLTEuNzggMC0zLjIyLTEuNC0zLjIyLTMuMTQgMC0xLjYyIDEuMDUtMi43NiAyLjgxLTMuMTIgMS43Ny0uMzYgMy42LTEuMjEgNC42Mi0yLjU4LjM5IDEuMjkuNTkgMi42NS41OSA0LjA0IDAgMi42NS0yLjE1IDQuOC00LjggNC44eiIvPjwvc3ZnPg==" alt="Icon Preview" style={{ maxWidth: '40px', maxHeight: 22 }} /> */}
                                  </div>
                                </div>
                                <div className="form_group__kDXNb ">
                                  <div className="form_form__Sku4X">
                                    <div class="form_field__d31bq"><div class=""><div contenteditable="true" translate="no" class="tiptap ProseMirror form_value__oIwpW form_tiptap__s0JN1" tabindex="0" ref={contentEditableRef4} onInput={handleheading2}>{serviceHeading2}</div></div></div>
                                    <div class="form_field__d31bq"><div class=""><div contenteditable="true" translate="no" class="tiptap ProseMirror form_value__oIwpW form_tiptap__s0JN1" tabindex="0" ref={contentEditableRef5} onInput={handleinfo2}>{serviceinfo2}</div></div></div>
                                  </div>
                                </div>
                              </div>




                            </div>
                          </div> : null}

                        {serviceremove3 == false ?
                          <div className='form_listItem__puOqW'>
                            <div class="form_headerHandle__33dpI" tabindex="0" role="button" aria-describedby="rbd-hidden-text-4-hidden-text-17" data-rbd-drag-handle-draggable-id="draggable-0" data-rbd-drag-handle-context-id="4" draggable="false"></div>
                            <div className='form_headerRemove__mJ7no' onClick={() => handelService3()}>
                              <svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 0 24 24" width="14" fill="currentColor"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"></path></svg>
                            </div>
                            <div className="form_form__Sku4X">

                              <div className='d-flex ' >
                                <div class="form_field__d31bq form_size--1x1__H0EdC" style={{ style: "92%", flexGrow: 0 }}>
                                  <div class="picker_preview__QSKw4 picker_invert__zNRsC">
                                  <IconPicker value={featureIcon3} onChange={(v) => setfeatureIcon3(v)} pickButtonStyle={{borderStyle:'none'}} className='IconPiker' modalWrapperStyle={{position:'absolute',width:'40vw',backgroundColor:"white",fontFamily: 'sans-serif',height: '73vh',boxShadow: 'rgba(0, 0, 0, 0.2) 0px 11px 15px -7px, rgba(0, 0, 0, 0.14) 0px 24px 38px 3px, rgba(0, 0, 0, 0.12) 0px 9px 46px 8px'}}></IconPicker>  
                                    {/* <img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGNsYXNzPSJpY29uIiBmaWxsPSJjdXJyZW50Q29sb3IiIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMTMuNS42N3MuNzQgMi42NS43NCA0LjhjMCAyLjA2LTEuMzUgMy43My0zLjQxIDMuNzMtMi4wNyAwLTMuNjMtMS42Ny0zLjYzLTMuNzNsLjAzLS4zNkM1LjIxIDcuNTEgNCAxMC42MiA0IDE0YzAgNC40MiAzLjU4IDggOCA4czgtMy41OCA4LThDMjAgOC42MSAxNy40MSAzLjggMTMuNS42N3pNMTEuNzEgMTljLTEuNzggMC0zLjIyLTEuNC0zLjIyLTMuMTQgMC0xLjYyIDEuMDUtMi43NiAyLjgxLTMuMTIgMS43Ny0uMzYgMy42LTEuMjEgNC42Mi0yLjU4LjM5IDEuMjkuNTkgMi42NS41OSA0LjA0IDAgMi42NS0yLjE1IDQuOC00LjggNC44eiIvPjwvc3ZnPg==" alt="Icon Preview" style={{ maxWidth: '40px', maxHeight: 22 }} /> */}
                                  </div>
                                </div>
                                <div className="form_group__kDXNb ">
                                  <div className="form_form__Sku4X">
                                    <div class="form_field__d31bq"><div class=""><div contenteditable="true" translate="no" class="tiptap ProseMirror form_value__oIwpW form_tiptap__s0JN1" tabindex="0" ref={contentEditableRef6} onInput={handleheading3}>{serviceHeading3}</div></div></div>
                                    <div class="form_field__d31bq"><div class=""><div contenteditable="true" translate="no" class="tiptap ProseMirror form_value__oIwpW form_tiptap__s0JN1" tabindex="0" ref={contentEditableRef7} onInput={handleinfo3}>{serviceinfo3}</div></div></div>
                                  </div>
                                </div>
                              </div>





                            </div>
                          </div> : null}

                        {serviceremove4 == false ?
                          <div className='form_listItem__puOqW'>
                            <div class="form_headerHandle__33dpI" tabindex="0" role="button" aria-describedby="rbd-hidden-text-4-hidden-text-17" data-rbd-drag-handle-draggable-id="draggable-0" data-rbd-drag-handle-context-id="4" draggable="false"></div>
                            <div className='form_headerRemove__mJ7no' onClick={() => handelService4()}>
                              <svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 0 24 24" width="14" fill="currentColor"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"></path></svg>
                            </div>
                            <div className="form_form__Sku4X">

                              <div className='d-flex ' >
                                <div class="form_field__d31bq form_size--1x1__H0EdC" style={{ style: "92%", flexGrow: 0 }}>
                                  <div class="picker_preview__QSKw4 picker_invert__zNRsC">
                                  <IconPicker value={featureIcon4} onChange={(v) => setfeatureIcon4(v)} pickButtonStyle={{borderStyle:'none'}} className='IconPiker' modalWrapperStyle={{position:'absolute',width:'40vw',backgroundColor:"white",fontFamily: 'sans-serif',height: '73vh',boxShadow: 'rgba(0, 0, 0, 0.2) 0px 11px 15px -7px, rgba(0, 0, 0, 0.14) 0px 24px 38px 3px, rgba(0, 0, 0, 0.12) 0px 9px 46px 8px'}}></IconPicker>  
                                    {/* <img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGNsYXNzPSJpY29uIiBmaWxsPSJjdXJyZW50Q29sb3IiIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMTMuNS42N3MuNzQgMi42NS43NCA0LjhjMCAyLjA2LTEuMzUgMy43My0zLjQxIDMuNzMtMi4wNyAwLTMuNjMtMS42Ny0zLjYzLTMuNzNsLjAzLS4zNkM1LjIxIDcuNTEgNCAxMC42MiA0IDE0YzAgNC40MiAzLjU4IDggOCA4czgtMy41OCA4LThDMjAgOC42MSAxNy40MSAzLjggMTMuNS42N3pNMTEuNzEgMTljLTEuNzggMC0zLjIyLTEuNC0zLjIyLTMuMTQgMC0xLjYyIDEuMDUtMi43NiAyLjgxLTMuMTIgMS43Ny0uMzYgMy42LTEuMjEgNC42Mi0yLjU4LjM5IDEuMjkuNTkgMi42NS41OSA0LjA0IDAgMi42NS0yLjE1IDQuOC00LjggNC44eiIvPjwvc3ZnPg==" alt="Icon Preview" style={{ maxWidth: '40px', maxHeight: 22 }} /> */}
                                  </div>
                                </div>
                                <div className="form_group__kDXNb ">
                                  <div className="form_form__Sku4X">
                                    <div class="form_field__d31bq"><div class=""><div contenteditable="true" translate="no" class="tiptap ProseMirror form_value__oIwpW form_tiptap__s0JN1" tabindex="0" ref={contentEditableRef8} onInput={handleheading4}>{serviceHeading4}</div></div></div>
                                    <div class="form_field__d31bq"><div class=""><div contenteditable="true" translate="no" class="tiptap ProseMirror form_value__oIwpW form_tiptap__s0JN1" tabindex="0" ref={contentEditableRef9} onInput={handleinfo4}>{serviceinfo4}</div></div></div>
                                  </div>
                                </div>
                              </div>





                            </div>
                          </div> : null}

                        {newservicesheading.map((item, index) => {
                          
                         return <div className='form_listItem__puOqW' key={index}>
                            <div class="form_headerHandle__33dpI" tabindex="0" role="button" aria-describedby="rbd-hidden-text-4-hidden-text-17" data-rbd-drag-handle-draggable-id="draggable-0" data-rbd-drag-handle-context-id="4" draggable="false"></div>
                            <div className='form_headerRemove__mJ7no' onClick={() => handelRemoveNewService(index)}>
                              <svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 0 24 24" width="14" fill="currentColor"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"></path></svg>
                            </div>
                            <div className="form_form__Sku4X">

                              <div className='d-flex ' >
                                <div class="form_field__d31bq form_size--1x1__H0EdC" style={{ style: "92%", flexGrow: 0 }}>
                                  <div class="picker_preview__QSKw4 picker_invert__zNRsC">
                                    <img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGNsYXNzPSJpY29uIiBmaWxsPSJjdXJyZW50Q29sb3IiIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMTMuNS42N3MuNzQgMi42NS43NCA0LjhjMCAyLjA2LTEuMzUgMy43My0zLjQxIDMuNzMtMi4wNyAwLTMuNjMtMS42Ny0zLjYzLTMuNzNsLjAzLS4zNkM1LjIxIDcuNTEgNCAxMC42MiA0IDE0YzAgNC40MiAzLjU4IDggOCA4czgtMy41OCA4LThDMjAgOC42MSAxNy40MSAzLjggMTMuNS42N3pNMTEuNzEgMTljLTEuNzggMC0zLjIyLTEuNC0zLjIyLTMuMTQgMC0xLjYyIDEuMDUtMi43NiAyLjgxLTMuMTIgMS43Ny0uMzYgMy42LTEuMjEgNC42Mi0yLjU4LjM5IDEuMjkuNTkgMi42NS41OSA0LjA0IDAgMi42NS0yLjE1IDQuOC00LjggNC44eiIvPjwvc3ZnPg==" alt="Icon Preview" style={{ maxWidth: '40px', maxHeight: 22 }} />
                                  </div>
                                </div>
                                <div className="form_group__kDXNb ">
                                  <div className="form_form__Sku4X">
                                    <div class="form_field__d31bq"><div class="">
                                      <textarea style={{ position: 'relative', height: -10, resize: 'none', background: 'transperant', borderStyle: 'none', width: '100%', overflow: 'hidden' }} contenteditable="true" translate="no" class="tiptap ProseMirror form_value__oIwpW form_tiptap__s0JN1" tabindex="0"  value={item.heading} onChange={(e)=>handelNewerivesheading(index,e.target.value)} ></textarea>
                                    </div></div>
                                    <div class="form_field__d31bq">
                                      <textarea style={{ position: 'relative', height: 90, resize: 'none', background: 'transperant', borderStyle: 'none', width: '100%', overflow: 'hidden' }} contenteditable="true" translate="no" class="tiptap ProseMirror form_value__oIwpW form_tiptap__s0JN1" tabindex="0" value={item.info} onChange={(e)=>handelNewerivesInfo(index,e.target.value)} ></textarea>

                                    </div></div>
                                </div>
                              </div>
                            </div>





                          </div>

                      })}

                      </div>
                      {(((serviceremove1==true&&serviceremove2==true)&&(serviceremove3==true&&serviceremove4==true))&&(newservicesheading.length==0)) ?
                          <div className='form_emptyList__KNV3N'>
                            <div>Your list is empty</div>
                            <button class="button1 button--small" onClick={ handelAddnewservicesinput} >Add Item2</button>
                          </div>
                          : <button class="button1 button--small" style={{ position: 'relative', left: 280 }} onClick={() => handelAddnewservicesinput()}>Add Item1</button>}
                           


                          <br/>
                          <br/>
                           <div className="form_field__d31bq ">
                          <div className="media_preview__okAyH">
                            <label>Picture</label>
                            <div className="media_value__\+4KKG ">
                              {newservicesImage.length>0?
                               <div>
                               {newservicesImage.map((image, index) => (
                                // eslint-disable-next-line jsx-a11y/img-redundant-alt
                                <img
                                onClick={()=>handleNewserviceImage(0)}
                                  key={index}
                                  style={{ height: 30, width: 40 }}
                                  src={image}
                                  alt={`Image ${index}`}
                                  className="media_image__kN9DM"
                                />
                              ))}</div>
                            //  <img  style={{height:30,width:40}}  alt="" className="media_image__kN9DM" />
                                  :
                               <img onClick={()=>handleNewserviceImage(0)} style={{height:30,width:40}}  alt="" className="media_image__kN9DM" />
                              } 
                              </div>
                          </div>
                        </div>
                    </div>
                  </div> : <FeatureDessing />}

              </div> : <div className='editing_wrapper__Wijfx h-100  overflow-auto'><Theme/></div>}
          </div>
          <div className=' h-100 overflow-auto editing_wrapper__Wijfxnew' style={{ width: 'calc(100% - 400px)' }}>
            <WebPages />
          </div>
        </div>
      </div>
    </div>
  )
};

export default FeatureEdit;

