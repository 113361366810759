import React, { useContext, useState } from "react";
import vector from '../assets/images/Vector.svg'
import { transferBasicPayment, transferGoldPayment, transferPlatinumPayment } from '../ContractAction/PaymentContractAction'
import landingbackground from '../assets/images/backgroundpng.png'
import MyContext from "../context/MyContext";





const SubscriptionsMeme = () => {
    const { randomId, subscriptionStatus } = useContext(MyContext);
    console.log('subscriptionStatus', randomId)

    const [isBasicLoading, setIsBasicLoading] = useState(false);
    const [isGoldLoading, setIsGoldLoading] = useState(false);
    const [isPlatinumLoading, setIsPlatinumLoading] = useState(false);

    const handleBuyNowClick = async (paymentFunction, setLoadingState, randomId) => {
        setLoadingState(true); // Start loading
        try {
            const result = await paymentFunction(randomId);
            // Handle the result if needed
        } catch (error) {
            console.error("Transaction failed:", error);
        } finally {
            setLoadingState(false); // Stop loading
        }
    };

    // const handleGoldBuyNowClick = async (randomId) => {
    //     setIsLoading(true); // Start loading
    //     try {
    //         const result = await transferGoldPayment(randomId);
    //     } catch (error) {
    //         console.error("Transaction failed:", error);
    //     } finally {
    //         setIsLoading(false); // Stop loading
    //     }
    // };

    // const handlePlatinumBuyNowClick = async (randomId) => {
    //     setIsLoading(true); // Start loading
    //     try {
    //         const result = await transferPlatinumPayment(randomId);
    //     } catch (error) {
    //         console.error("Transaction failed:", error);
    //     } finally {
    //         setIsLoading(false); // Stop loading
    //     }
    // };

    return (
        <>
            <section className='_cc2950fb ' id='Pricing' style={{
                backgroundImage: `url(${landingbackground})`,
                width: '100%',
                position: 'relative',
                maxWidth: '100%',
                overflow: 'hidden',
                height: '100%',
                objectFit: 'cover',
                minHeight: '100vh',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
            }}>
                <div className='container' >
                    <h1 className='core-features'>How much does it cost?</h1>
                    <p className='subscription-plan'>Subscription Plan</p>
                </div>
                <div className='container'>
                    <div className='row'>

                        <div className='col-lg-4 col-md-6'>
                            <div className='cardedit p-3 d-flex flex-column justify-content-between ' >
                                <div>
                                    <div className='platinum'>Basic</div>
                                    <div className="usdt-parent">
                                        <div className="usdt">12 USDC</div>
                                        <div className="yr">/website</div>
                                    </div>
                                    <div className='d-flex flex-column' style={{ gap: '16px 0px' }}>
                                        {/* <div style={{ textAlign: 'left' }}>
                                                <img src={maticlogo} style={{ height: '32px', width: 'auto' }} alt='' />
                                            </div> */}
                                        <br />
                                        <div className='d-flex flex-column mb-4 h-100' style={{ gap: '12px 0px' }}>
                                            <div className="vector-parent">
                                                <img className="vector-icon" alt="" src={vector} />
                                                <div className="create-a-website">Create a Website at no cost.</div>
                                            </div>
                                            <div className="vector-parent">
                                                <img className="vector-icon" alt="" src={vector} />
                                                <div className="create-a-website">Download HTML Code.</div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                {isBasicLoading  ? (
                                    <div className="loader-container">
                                        <div className="loader"></div>
                                        <div>Loading...</div>
                                    </div>
                                ) : (
                                    <button disabled={isGoldLoading || isPlatinumLoading} className='buy-now-button' onClick={() => handleBuyNowClick(transferBasicPayment, setIsBasicLoading, randomId)}>
                                        <span className="buy-now">Buy Now</span>
                                    </button>
                                )}

                            </div>
                        </div>
                        <div className='col-lg-4 col-md-6'>
                            <div className='cardedit p-3 d-flex flex-column justify-content-between ' >
                                <div>
                                    <div className='platinum'>Gold</div>
                                    <div className="usdt-parent">
                                        <div className="usdt">120 USDC</div>
                                        <div className="yr">/6mo</div>
                                    </div>
                                    <div className='d-flex flex-column' style={{ gap: '16px 0px' }}>
                                        <div style={{ textAlign: 'left' }}>
                                            {/* <img src={maticlogo} style={{ height: '32px', width: 'auto' }} alt='' /> */}
                                        </div>
                                        <br />
                                        <div className='d-flex flex-column mb-4 h-100' style={{ gap: '12px 0px' }}>
                                            <div className="vector-parent">
                                                <img className="vector-icon" alt="" src={vector} />
                                                <div className="create-a-website">Create a Website at no cost.</div>
                                            </div>
                                            <div className="vector-parent">
                                                <img className="vector-icon" alt="" src={vector} />
                                                <div className="create-a-website">Host with your custom domain.</div>
                                            </div>
                                            <div className="vector-parent">
                                                <img className="vector-icon" alt="" src={vector} />
                                                <div className="create-a-website">Host with Trendify.ai subdomain.</div>
                                            </div>
                                            {/* <div className="vector-parent">
                                                <img className="vector-icon" alt="" src={vector} />
                                                <div className="create-a-website">Make unlimited changes and re-host as needed.</div>
                                            </div> */}

                                        </div>
                                    </div>
                                </div>
                                {isGoldLoading  ? (
                                    <div className="loader-container">
                                        <div className="loader"></div>
                                        <div>Loading...</div>
                                    </div>
                                ) : (
                                    <button disabled={isBasicLoading || isPlatinumLoading} className='buy-now-button' onClick={() => handleBuyNowClick(transferGoldPayment, setIsGoldLoading, randomId)}>
                                        <span className="buy-now">Buy Now</span>
                                    </button>
                                )}

                            </div>
                        </div>

                        <div className='col-lg-4 col-md-6'>
                            <div className='cardedit p-3 d-flex flex-column justify-content-between ' >
                                <div>
                                    <div className='platinum'>Platinum</div>
                                    <div className="usdt-parent">
                                        <div className="usdt">215 USDC</div>
                                        <div className="yr">/yr</div>
                                    </div>
                                    <div className='d-flex flex-column' style={{ gap: '16px 0px' }}>
                                        {/* <div style={{ textAlign: 'left' }}>
                                                <img src={maticlogo} style={{ height: '32px', width: 'auto' }} alt='' />
                                            </div> */}
                                        <br />
                                        <div className='d-flex flex-column mb-4 h-100' style={{ gap: '12px 0px' }}>
                                            <div className="vector-parent">
                                                <img className="vector-icon" alt="" src={vector} />
                                                <div className="create-a-website">Create a Website at no cost.</div>
                                            </div>
                                            <div className="vector-parent">
                                                <img className="vector-icon" alt="" src={vector} />
                                                <div className="create-a-website">Host with your custom domain.</div>
                                            </div>
                                            <div className="vector-parent">
                                                <img className="vector-icon" alt="" src={vector} />
                                                <div className="create-a-website">Host with Trendify.ai subdomain.</div>
                                            </div>
                                            {/* <div className="vector-parent">
                                                <img className="vector-icon" alt="" src={vector} />
                                                <div className="create-a-website">Make unlimited changes and re-host as often as you like.</div>
                                            </div> */}

                                        </div>
                                    </div>
                                </div>
                                {isPlatinumLoading  ? (
                                    <div className="loader-container">
                                        <div className="loader"></div>
                                        <div>Loading...</div>
                                    </div>
                                ) : (
                                    <button disabled={isBasicLoading || isGoldLoading} className='buy-now-button' onClick={() => handleBuyNowClick(transferPlatinumPayment, setIsPlatinumLoading, randomId)}>
                                        <span className="buy-now">Buy Now</span>
                                    </button>
                                )}

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
};

export default SubscriptionsMeme;