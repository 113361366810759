import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { BsJustify } from 'react-icons/bs';
import gddns from '../assets/images/GoDaddyAREC.png'
import gssl from '../assets/images/GenerateSSL.png'

const DNSDoc = () => {
  return (
   <Box sx={{
    minHeight:'100vh',
    width:'100vw',
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    backgroundColor:'#f5f5f5',
   }}>
   <Card sx={{ minWidth: 275, margin:'0 auto'}}>
   <CardContent>
     <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
       Instructions for Setting Up DNS and SSL
     </Typography>
     <Typography variant="h5" component="div">
       Step 1:
     </Typography>
     <Typography sx={{ mb: 1.5 }} color="text.secondary">
       Set up a type A Record
     </Typography>
     <Typography variant="body2">
       With your DNS provider, create a <b>type A Record</b> with the following details:
       <ul>
         <li><strong>Host:</strong> @</li>
         <li><strong>IP Address:</strong> 45.32.157.183</li>
         <li><strong>TTL:</strong> 600</li>
         <li>After adding the record, <b>click Save.</b></li>
       </ul>
   <img src={gddns} alt=""/>
     </Typography>
     <Typography variant="h5" component="div">
       Step 2:
     </Typography>
     <Typography variant='body2'>
        <ul>
       <li><strong>Generate SSL Certificate:</strong> After successfully adding the record, go to Dashboard and click on Generate.</li>
       </ul>
     </Typography>
     <img src={gssl} alt=""/>
     {/* <Typography variant="body2">
       Follow the instructions to generate an SSL certificate for your domain.
     </Typography> */}
   </CardContent>
   {/* <CardActions>
     <Button size="small">Learn More</Button>
   </CardActions> */}
 </Card>
   </Box>
  );
};

export default DNSDoc;
