import React, { useContext, useEffect, useState } from "react";
import MyContext from "../../context/MyContext";
const EditHero = () => {

    const { inttrobtn1, setIntrobtn1,
        introbtn2, setIntrobtn2,
        menu1, Section2,
        images, title,
        intro, menu,buttonshowdoblur,
        titlecolor, welcometilte,
        introtext, introcolor,introImages,
        introbtn1remove,setIntrobtnremove1,
        introbtn2remove,setIntrobtnremove2, 
        introdiv, setIntrodiv, intromenutextcolor,
        addintrobutton, introbuttonapprovel,
      intromenubgcolor,setIntromenubgcolor,buttonshowdospread,
      newIntroImage,setNewIntroImage, buttonvarticalpadding,buttonhorizantlepadding, 
       selectedOption1 ,introcaption ,buttonshowdoleft,buttonshowdoTop,
       introtextfont , introinfotextfont, butttonshowdo,buttonshowdowcolor,
      conatcmenuborder,setcontactmenuborder,  buttoncolor, buttonborder,buttonborderradius,buttonbordercolor,
      intromargintop,intromarginbottom, captioncolor, button1font, button2font,
      contenttextheadingcolor,mainnavbarbutton,contenttextinfocolor, contactheadingcolor,selectedHero, setSelectedHero,
    } = useContext(MyContext);

    const [randomIndex, setRandomIndex] = useState();


    useEffect(() => {
        const randomIndex = Math.floor(Math.random() * 5);
        setRandomIndex(randomIndex);
    }, [images]);
    // const randomIndex = Math.floor(Math.random() * 5);
    const Imageupdate = images;

    const handelupdate = () => {
        return Imageupdate
    }

    useEffect(() => {
        handelupdate()
    }, [])
     console.log('Margin', intromargintop,intromarginbottom,introtextfont,butttonshowdo,);
     const hexToRgb = (hex) => {
        const bigint = parseInt(hex.slice(1), 16);
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;
        return `${r}, ${g}, ${b}`;
      };
      console.log('introImages',selectedHero)
    return (
        <div style={{ backgroundColor: `${introcolor}`, }} >
           {selectedHero === null && ( 
            <div class="container" ref={Section2} >
                <div class="row" style={{ marginTop: `${intromargintop}px`, marginBottom: `${intromarginbottom}px` }}>
                    <div class="col-md-6 d-flex align-items-center flex-column justify-content-center">
                        <div style={{ width: "100%", }}>
                        {selectedOption1 == 'A' ?
                                <button class="mt-5 btn  menu" style={{ fontSize: 12, color: `${intromenutextcolor}`, backgroundColor: `2${intromenubgcolor}`,border:`2px solid ${conatcmenuborder}`,borderRadius:30 ,}}>{menu}--<span style={{color:`${contactheadingcolor}`}}>{menu1}</span></button>
                                :
                                <div>
                                    <button class="mt-5 btn  menu" style={{ fontSize: 12, borderStyle: 'none', color: `${intromenutextcolor}`, backgroundColor: `${intromenubgcolor}` }}>{menu}</button>
                                    <button class="mt-5 btn  menu" style={{ fontSize: 12, borderStyle: 'none' }}>{menu1}</button>
                                </div>

                            }
                        </div>
                        <p style={{ color: captioncolor?`${captioncolor}`:'#9c36b5', position: "relative", }}>{introcaption}</p>
                        <h1 style={{ fontSize: 48, fontWeight: '780', color: `${contenttextheadingcolor}`, fontFamily: `${introtextfont}` }}> {title}</h1>
                        <p class="fs-0" style={{ color: `${contenttextinfocolor}`, fontFamily: `${introinfotextfont}` }}>
                            {intro}
                        </p>

                    </div>
                    <div class="col-md-6 p-4">
                        {newIntroImage.length > 0 ?
                            <div>
                                {newIntroImage.map((image, index) => (
                                    // eslint-disable-next-line jsx-a11y/img-redundant-alt
                                    <img
                                        style={{ height: '390px', borderRadius: 1, width: '100%', borderRadius: '10px' }}
                                        key={index}
                                        src={image}
                                        alt={`Image ${index}`}
                                        className="img-fluid shadow"
                                    />
                                ))}</div>
                            :
                            <img style={{ height: '390px', borderRadius: 1, width: '100%', borderRadius: '10px' }} src={introImages} className="img-fluid shadow" alt="" />

                        }

                    </div>
                    <div class="twobutton" style={{ width: "100%", color: `${introtext}`, display: 'flex', gap:"0px 10px"}}>
                        <a href="#"><button class=" buttoncolor" style={{ display: introbtn1remove ? 'none' : '' ,fontFamily: `${button1font}`,backgroundColor:`${buttoncolor}`,border:`${buttonborder}px solid ${buttonbordercolor}`,borderRadius:`${buttonborderradius}px`,boxShadow: `${buttonshowdoleft}px ${buttonshowdoTop}px ${butttonshowdo}px ${buttonshowdospread}px rgba(${hexToRgb(buttonshowdowcolor)}, 0.${buttonshowdoblur})`, paddingTop:`${buttonvarticalpadding}px`,paddingBottom:`${buttonvarticalpadding}px`,paddingLeft:`${buttonhorizantlepadding}px`,paddingRight:`${buttonhorizantlepadding}px`}}>{inttrobtn1}</button></a>
                        <a href="#"><button class="buttoncolor" style={{ display: introbtn2remove ? 'none' : '' ,fontFamily: `${button1font}`,backgroundColor:`${buttoncolor}`,border:`${buttonborder}px solid ${buttonbordercolor}`,borderRadius:`${buttonborderradius}px`,boxShadow: `${buttonshowdoleft}px ${buttonshowdoTop}px ${butttonshowdo}px ${buttonshowdospread}px rgba(${hexToRgb(buttonshowdowcolor)}, 0.${buttonshowdoblur})`,width:'100%', paddingTop:`${buttonvarticalpadding}px`,paddingBottom:`${buttonvarticalpadding}px`,paddingLeft:`${buttonhorizantlepadding}px`,paddingRight:`${buttonhorizantlepadding}px`}}>{introbtn2}</button></a>
                        {addintrobutton.map((button, index) => (
                            <a href="#" key={index} style={{ display: introbuttonapprovel == false ? 'none' : '', }}><button class="buttoncolor" style={{fontFamily:`${button1font}`,backgroundColor:`${buttoncolor}`,border:`${buttonborder}px solid ${buttonbordercolor}`,borderRadius:`${buttonborderradius}px`,boxShadow:`0px 0px ${butttonshowdo}px 0px rgba(21, 21, 25, 0.21)`,width:"100%",paddingTop:`${buttonvarticalpadding}px`,paddingBottom:`${buttonvarticalpadding}px`,paddingLeft:`${buttonhorizantlepadding}px`,paddingRight:`${buttonhorizantlepadding}px` }} >{button}</button></a>

                        ))}
                    </div>

                </div>
            </div>
            )}
            {selectedHero === 0 && (<div class="container" ref={Section2}>
                <div class="row" style={{ marginTop: `${intromargintop}px`, marginBottom: `${intromarginbottom}px` }}>
                    <div class="col-md-6 d-flex align-items-center flex-column justify-content-center">
                        <div style={{ width: "100%", }}>
                        {selectedOption1 == 'A' ?
                                <button class="mt-5 btn  menu" style={{ fontSize: 12, color: `${intromenutextcolor}`, backgroundColor: `2${intromenubgcolor}`,border:`2px solid ${conatcmenuborder}`,borderRadius:30 ,}}>{menu}--<span style={{color:`${contactheadingcolor}`}}>{menu1}</span></button>
                                :
                                <div>
                                    <button class="mt-5 btn  menu" style={{ fontSize: 12, borderStyle: 'none', color: `${intromenutextcolor}`, backgroundColor: `${intromenubgcolor}` }}>{menu}</button>
                                    <button class="mt-5 btn  menu" style={{ fontSize: 12, borderStyle: 'none' }}>{menu1}</button>
                                </div>

                            }
                        </div>
                        <p style={{ color: captioncolor?`${captioncolor}`:'#9c36b5', position: "relative", }}>{introcaption}</p>
                        <h1 style={{ fontSize: 48, fontWeight: '780', color: `${contenttextheadingcolor}`, fontFamily: `${introtextfont}` }}> {title}</h1>
                        <p class="fs-0" style={{ color: `${contenttextinfocolor}`, fontFamily: `${introinfotextfont}` }}>
                            {intro}
                        </p>
                        <div class="twobutton" style={{ width: "100%", color: `${introtext}`, display: 'flex', gap:"0px 10px"}}>
                        <a href="#"><button class=" buttoncolor" style={{ display: introbtn1remove ? 'none' : '' ,fontFamily: `${button1font}`,backgroundColor:`${buttoncolor}`,border:`${buttonborder}px solid ${buttonbordercolor}`,borderRadius:`${buttonborderradius}px`,boxShadow: `${buttonshowdoleft}px ${buttonshowdoTop}px ${butttonshowdo}px ${buttonshowdospread}px rgba(${hexToRgb(buttonshowdowcolor)}, 0.${buttonshowdoblur})`, paddingTop:`${buttonvarticalpadding}px`,paddingBottom:`${buttonvarticalpadding}px`,paddingLeft:`${buttonhorizantlepadding}px`,paddingRight:`${buttonhorizantlepadding}px`}}>{inttrobtn1}</button></a>
                        <a href="#"><button class="buttoncolor" style={{ display: introbtn2remove ? 'none' : '' ,fontFamily: `${button1font}`,backgroundColor:`${buttoncolor}`,border:`${buttonborder}px solid ${buttonbordercolor}`,borderRadius:`${buttonborderradius}px`,boxShadow: `${buttonshowdoleft}px ${buttonshowdoTop}px ${butttonshowdo}px ${buttonshowdospread}px rgba(${hexToRgb(buttonshowdowcolor)}, 0.${buttonshowdoblur})`,width:'100%', paddingTop:`${buttonvarticalpadding}px`,paddingBottom:`${buttonvarticalpadding}px`,paddingLeft:`${buttonhorizantlepadding}px`,paddingRight:`${buttonhorizantlepadding}px`}}>{introbtn2}</button></a>
                        {addintrobutton.map((button, index) => (
                            <a href="#" key={index} style={{ display: introbuttonapprovel == false ? 'none' : '', }}><button class="buttoncolor" style={{fontFamily:`${button1font}`,backgroundColor:`${buttoncolor}`,border:`${buttonborder}px solid ${buttonbordercolor}`,borderRadius:`${buttonborderradius}px`,boxShadow:`0px 0px ${butttonshowdo}px 0px rgba(21, 21, 25, 0.21)`,width:"100%",paddingTop:`${buttonvarticalpadding}px`,paddingBottom:`${buttonvarticalpadding}px`,paddingLeft:`${buttonhorizantlepadding}px`,paddingRight:`${buttonhorizantlepadding}px` }} >{button}</button></a>

                        ))}
                    </div>
                    </div>
                    <div class="col-md-6 p-4">
                        <div className="_f497df412">
                        {newIntroImage.length > 0 ?
                            <div>
                                {newIntroImage.map((image, index) => (
                                    // eslint-disable-next-line jsx-a11y/img-redundant-alt
                                    <img
                                        style={{ height: '390px', borderRadius: 1, width: '100%', borderRadius: '10px' }}
                                        key={index}
                                        src={image}
                                        alt={`Image ${index}`}
                                        className="img-fluid shadow"
                                    />
                                ))}</div>
                            :
                            <img style={{ height: '390px', borderRadius: 1, width: '100%', borderRadius: '10px' }} src={introImages} className="img-fluid shadow" alt="" />

                        }
                        </div>
                    </div>


                </div>
            </div>
            )}


            {selectedHero === 1 && (<div class="container" ref={Section2}>
                <div class="row" style={{ marginTop: `${intromargintop}px`, marginBottom: `${intromarginbottom}px` }}>
                    <div class="col-md-12 d-flex align-items-center flex-column justify-content-center">
                        <div className="text-center" style={{ width: "100%", }}>
                        {selectedOption1 == 'A' ?
                                <button class="mt-5 btn  menu" style={{ fontSize: 12, color: `${intromenutextcolor}`, backgroundColor: `2${intromenubgcolor}`,border:`2px solid ${conatcmenuborder}`,borderRadius:30 ,}}>{menu}--<span style={{color:`${contactheadingcolor}`}}>{menu1}</span></button>
                                :
                                <div>
                                    <button class="mt-5 btn  menu" style={{ fontSize: 12, borderStyle: 'none', color: `${intromenutextcolor}`, backgroundColor: `${intromenubgcolor}` }}>{menu}</button>
                                    <button class="mt-5 btn  menu" style={{ fontSize: 12, borderStyle: 'none' }}>{menu1}</button>
                                </div>

                            }
                        </div>
                        <p style={{ color: captioncolor?`${captioncolor}`:'#9c36b5', position: "relative", }}>{introcaption}</p>
                        <h1 style={{ fontSize: 48, fontWeight: '780', color: `${contenttextheadingcolor}`, fontFamily: `${introtextfont}` }}> {title}</h1>
                        <p class="fs-0" style={{ color: `${contenttextinfocolor}`, fontFamily: `${introinfotextfont}` }}>
                            {intro}
                        </p>

                        <div class="twobutton d-flex align-items-center justify-content-center" style={{ width: "100%", color: `${introtext}`, display: 'flex',gap:"0px 10px" }}>
                            <a href="#"><button class=" buttoncolor" style={{ display: introbtn1remove ? 'none' : '',fontFamily: `${button1font}`,backgroundColor:`${buttoncolor}`,border:`${buttonborder}px solid ${buttonbordercolor}`,borderRadius:`${buttonborderradius}px`,boxShadow:`0px 0px ${butttonshowdo}px 0px rgba(21, 21, 25, 0.21)` ,width: '100%' ,paddingTop:`${buttonvarticalpadding}px`,paddingBottom:`${buttonvarticalpadding}px`,paddingLeft:`${buttonhorizantlepadding}px`,paddingRight:`${buttonhorizantlepadding}px` }}>{inttrobtn1}</button></a>
                            <a href="#"><button class="buttoncolor" style={{ display: introbtn2remove ? 'none' : '',fontFamily: `${button1font}`,backgroundColor:`${buttoncolor}`,border:`${buttonborder}px solid ${buttonbordercolor}`,borderRadius:`${buttonborderradius}px`,boxShadow:`0px 0px ${butttonshowdo}px 0px rgba(21, 21, 25, 0.21)`,width:'100%', paddingTop:`${buttonvarticalpadding}px`,paddingBottom:`${buttonvarticalpadding}px`,paddingLeft:`${buttonhorizantlepadding}px`,paddingRight:`${buttonhorizantlepadding}px` }}>{introbtn2}</button></a>
                            {addintrobutton.map((button, index) => (
                                <a href="#" key={index} style={{ display: introbuttonapprovel == false ? 'none' : '',}}><button class="buttoncolor" style={{fontFamily:`${button1font}`,backgroundColor:`${buttoncolor}`,border:`${buttonborder}px solid ${buttonbordercolor}`,borderRadius:`${buttonborderradius}px`,boxShadow:`0px 0px ${butttonshowdo}px 0px rgba(21, 21, 25, 0.21)`,width:"100%",paddingTop:`${buttonvarticalpadding}px`,paddingBottom:`${buttonvarticalpadding}px`,paddingLeft:`${buttonhorizantlepadding}px`,paddingRight:`${buttonhorizantlepadding}px` }}>{button}</button></a>

                            ))}
                        </div>

                    </div>
                    <div class="col-md-12 p-4">
                    {newIntroImage.length > 0 ?
                            <div>
                                {newIntroImage.map((image, index) => (
                                    // eslint-disable-next-line jsx-a11y/img-redundant-alt
                                    <img
                                        style={{ height: '390px', borderRadius: 1, width: '100%', borderRadius: '10px' }}
                                        key={index}
                                        src={image}
                                        alt={`Image ${index}`}
                                        className="img-fluid shadow"
                                    />
                                ))}</div>
                            :
                            <img style={{ height: '390px', borderRadius: 1, width: '100%', borderRadius: '10px' }} src={introImages} className="img-fluid shadow" alt="" />

                        }

                    </div>
                </div>
            </div>
            )}



            {selectedHero === 2 && (<div class="container" ref={Section2}>
                <div class="row" style={{ marginTop: `${intromargintop}px`, marginBottom: `${intromarginbottom}px` }}>
                    <div class="col-md-6 d-flex align-items-center flex-column justify-content-center">
                        <div style={{ width: "100%", }}>
                        {selectedOption1 == 'A' ?
                                <button class="mt-5 btn  menu" style={{ fontSize: 12, color: `${intromenutextcolor}`, backgroundColor: `2${intromenubgcolor}`,border:`2px solid ${conatcmenuborder}`,borderRadius:30 ,}}>{menu}--<span style={{color:`${contactheadingcolor}`}}>{menu1}</span></button>
                                :
                                <div>
                                    <button class="mt-5 btn  menu" style={{ fontSize: 12, borderStyle: 'none', color: `${intromenutextcolor}`, backgroundColor: `${intromenubgcolor}` }}>{menu}</button>
                                    <button class="mt-5 btn  menu" style={{ fontSize: 12, borderStyle: 'none' }}>{menu1}</button>
                                </div>

                            }
                        </div>
                        <p style={{ color: captioncolor?`${captioncolor}`:'#9c36b5', position: "relative", }}>{introcaption}</p>
                        <h1 style={{ fontSize: 48, fontWeight: '780', color: `${contenttextheadingcolor}`, fontFamily: `${introtextfont}` }}> {title}</h1>
                        <p class="fs-0" style={{ color: `${contenttextinfocolor}`, fontFamily: `${introinfotextfont}` }}>
                            {intro}
                        </p>
                        <div class="twobutton" style={{ width: "100%", color: `${introtext}`, display: 'flex',gap:"0px 10px" }}>
                            <a href="#"><button class=" buttoncolor" style={{ display: introbtn1remove ? 'none' : '' ,fontFamily: `${button1font}`,backgroundColor:`${buttoncolor}`,border:`${buttonborder}px solid ${buttonbordercolor}`,borderRadius:`${buttonborderradius}px`,boxShadow:`0px 0px ${butttonshowdo}px 0px rgba(21, 21, 25, 0.21)`,width:'100%',paddingTop:`${buttonvarticalpadding}px`,paddingBottom:`${buttonvarticalpadding}px`,paddingLeft:`${buttonhorizantlepadding}px`,paddingRight:`${buttonhorizantlepadding}px`}}>{inttrobtn1}</button></a>
                            <a href="#"><button class="buttoncolor" style={{ display: introbtn2remove ? 'none' : '' ,fontFamily: `${button1font}`,backgroundColor:`${buttoncolor}`,border:`${buttonborder}px solid ${buttonbordercolor}`,borderRadius:`${buttonborderradius}px`,boxShadow:`0px 0px ${butttonshowdo}px 0px rgba(21, 21, 25, 0.21)`,width:'100%',paddingTop:`${buttonvarticalpadding}px`,paddingBottom:`${buttonvarticalpadding}px`,paddingLeft:`${buttonhorizantlepadding}px`,paddingRight:`${buttonhorizantlepadding}px`}}>{introbtn2}</button></a>
                            {addintrobutton.map((button, index) => (
                                <a href="#" key={index} style={{ display: introbuttonapprovel == false ? 'none' : '', }}><button class="buttoncolor" style={{fontFamily:`${button1font}`,backgroundColor:`${buttoncolor}`,border:`${buttonborder}px solid ${buttonbordercolor}`,borderRadius:`${buttonborderradius}px`,boxShadow:`0px 0px ${butttonshowdo}px 0px rgba(21, 21, 25, 0.21)`,width:"100%",paddingTop:`${buttonvarticalpadding}px`,paddingBottom:`${buttonvarticalpadding}px`,paddingLeft:`${buttonhorizantlepadding}px`,paddingRight:`${buttonhorizantlepadding}px` }} >{button}</button></a>

                            ))}
                        </div>
                    </div>
                    <div class="col-md-6 p-4">
                        <div className="_f497df413">
                        {newIntroImage.length > 0 ?
                            <div>
                                {newIntroImage.map((image, index) => (
                                    // eslint-disable-next-line jsx-a11y/img-redundant-alt
                                    <img
                                        style={{ height: '390px', borderRadius: 1, width: '100%', borderRadius: '10px' }}
                                        key={index}
                                        src={image}
                                        alt={`Image ${index}`}
                                        className="img-fluid shadow"
                                    />
                                ))}</div>
                            :
                            <img style={{ height: '390px', borderRadius: 1, width: '100%', borderRadius: '10px' }} src={introImages} className="img-fluid shadow" alt="" />

                        }
                        </div>
                    </div>


                </div>
            </div>
            )}

            {selectedHero === 3 && (<div class="container py-0" style={{ marginTop: "11px" }} ref={Section2}>
                <div class="row" style={{ marginTop: `${intromargintop}px`, marginBottom: `${intromarginbottom}px` }}>
                    <div class="col-md-6 d-flex align-items-center flex-column justify-content-center">
                        <div style={{ width: "100%", }}>
                        {selectedOption1 == 'A' ?
                                <button class="mt-5 btn  menu" style={{ fontSize: 12, color: `${intromenutextcolor}`, backgroundColor: `2${intromenubgcolor}`,border:`2px solid ${conatcmenuborder}`,borderRadius:30 ,}}>{menu}--<span style={{color:`${contactheadingcolor}`}}>{menu1}</span></button>
                                :
                                <div>
                                    <button class="mt-5 btn  menu" style={{ fontSize: 12, borderStyle: 'none', color: `${intromenutextcolor}`, backgroundColor: `${intromenubgcolor}` }}>{menu}</button>
                                    <button class="mt-5 btn  menu" style={{ fontSize: 12, borderStyle: 'none' }}>{menu1}</button>
                                </div>

                            }
                        </div>
                        <p style={{ color: captioncolor?`${captioncolor}`:'#9c36b5', position: "relative", }}>{introcaption}</p>
                        <h1 style={{ fontSize: 48, fontWeight: '780', color: `${contenttextheadingcolor}`, fontFamily: `${introtextfont}` }}> {title}</h1>
                        <p class="fs-0" style={{ color: `${contenttextinfocolor}`, fontFamily: `${introinfotextfont}` }}>
                            {intro}
                        </p>
                        <div class="twobutton" style={{ width: "100%", color: `${introtext}`, display: 'flex',gap:"0px 10px" }}>
                            <a href="#"><button class=" buttoncolor" style={{ display: introbtn1remove ? 'none' : '' ,fontFamily: `${button1font}`,backgroundColor:`${buttoncolor}`,border:`${buttonborder}px solid ${buttonbordercolor}`,borderRadius:`${buttonborderradius}px`,boxShadow:`0px 0px ${butttonshowdo}px 0px rgba(21, 21, 25, 0.21)`,width:'100%',paddingTop:`${buttonvarticalpadding}px`,paddingBottom:`${buttonvarticalpadding}px`,paddingLeft:`${buttonhorizantlepadding}px`,paddingRight:`${buttonhorizantlepadding}px`}}>{inttrobtn1}</button></a>
                            <a href="#"><button class="buttoncolor" style={{ display: introbtn2remove ? 'none' : '',fontFamily: `${button1font}`,backgroundColor:`${buttoncolor}`,border:`${buttonborder}px solid ${buttonbordercolor}`,borderRadius:`${buttonborderradius}px`,boxShadow:`0px 0px ${butttonshowdo}px 0px rgba(21, 21, 25, 0.21)`,width:'100%',paddingTop:`${buttonvarticalpadding}px`,paddingBottom:`${buttonvarticalpadding}px`,paddingLeft:`${buttonhorizantlepadding}px`,paddingRight:`${buttonhorizantlepadding}px` }}>{introbtn2}</button></a>
                            {addintrobutton.map((button, index) => (
                                <a href="#" key={index} style={{ display: introbuttonapprovel == false ? 'none' : '' ,}}><button class="buttoncolor" style={{fontFamily:`${button1font}`,backgroundColor:`${buttoncolor}`,border:`${buttonborder}px solid ${buttonbordercolor}`,borderRadius:`${buttonborderradius}px`,boxShadow:`0px 0px ${butttonshowdo}px 0px rgba(21, 21, 25, 0.21)`,width:"100%",paddingTop:`${buttonvarticalpadding}px`,paddingBottom:`${buttonvarticalpadding}px`,paddingLeft:`${buttonhorizantlepadding}px`,paddingRight:`${buttonhorizantlepadding}px` }}>{button}</button></a>

                            ))}
                        </div>
                    </div>
                    <div class="col-md-6 ">
                        <div className="_f497df41">
                        {newIntroImage.length > 0 ?
                            <div>
                                {newIntroImage.map((image, index) => (
                                    // eslint-disable-next-line jsx-a11y/img-redundant-alt
                                    <img
                                        style={{ height: '390px', borderRadius: 1, width: '100%', borderRadius: '10px' }}
                                        key={index}
                                        src={image}
                                        alt={`Image ${index}`}
                                        className="img-fluid shadow"
                                    />
                                ))}</div>
                            :
                            <img style={{ height: '390px', borderRadius: 1, width: '100%', borderRadius: '10px' }} src={introImages} className="img-fluid shadow" alt="" />

                        }
                        </div>

                    </div>


                </div>
            </div>
            )}

            {selectedHero === 4 && (<div class="container" ref={Section2}>
                <div class="row" style={{ marginTop: `${intromargintop}px`, marginBottom: `${intromarginbottom}px` }}>
                    <div class="col-md-6 d-flex align-items-center flex-column justify-content-center">
                        <div style={{ width: "100%", }}>
                        {selectedOption1 == 'A' ?
                                <button class="mt-5 btn  menu" style={{ fontSize: 12, color: `${intromenutextcolor}`, backgroundColor: `2${intromenubgcolor}`,border:`2px solid ${conatcmenuborder}`,borderRadius:30 ,}}>{menu}--<span style={{color:`${contactheadingcolor}`}}>{menu1}</span></button>
                                :
                                <div>
                                    <button class="mt-5 btn  menu" style={{ fontSize: 12, borderStyle: 'none', color: `${intromenutextcolor}`, backgroundColor: `${intromenubgcolor}` }}>{menu}</button>
                                    <button class="mt-5 btn  menu" style={{ fontSize: 12, borderStyle: 'none' }}>{menu1}</button>
                                </div>

                            }
                        </div>
                        <p style={{ color: captioncolor?`${captioncolor}`:'#9c36b5', position: "relative", }}>{introcaption}</p>
                        <h1 style={{ fontSize: 48, fontWeight: '780', color: `${contenttextheadingcolor}`, fontFamily: `${introtextfont}` }}> {title}</h1>
                        <p class="fs-0" style={{ color: `${contenttextinfocolor}`, fontFamily: `${introinfotextfont}` }}>
                            {intro}
                        </p>
                        <div class="twobutton" style={{ width: "100%", color: `${introtext}`, display: 'flex',gap:"0px 10px" }}>
                            <a href="#"><button class=" buttoncolor" style={{ display: introbtn1remove ? 'none' : '',fontFamily: `${button1font}`,backgroundColor:`${buttoncolor}`,border:`${buttonborder}px solid ${buttonbordercolor}`,borderRadius:`${buttonborderradius}px`,boxShadow:`0px 0px ${butttonshowdo}px 0px rgba(21, 21, 25, 0.21)`,with:'100%',paddingTop:`${buttonvarticalpadding}px`,paddingBottom:`${buttonvarticalpadding}px`,paddingLeft:`${buttonhorizantlepadding}px`,paddingRight:`${buttonhorizantlepadding}px` }}>{inttrobtn1}</button></a>
                            <a href="#"><button class="buttoncolor" style={{ display: introbtn2remove ? 'none' : '',fontFamily: `${button1font}`,backgroundColor:`${buttoncolor}`,border:`${buttonborder}px solid ${buttonbordercolor}`,borderRadius:`${buttonborderradius}px`,boxShadow:`0px 0px ${butttonshowdo}px 0px rgba(21, 21, 25, 0.21)`,width:'100%', paddingTop:`${buttonvarticalpadding}px`,paddingBottom:`${buttonvarticalpadding}px`,paddingLeft:`${buttonhorizantlepadding}px`,paddingRight:`${buttonhorizantlepadding}px` }}>{introbtn2}</button></a>
                            {addintrobutton.map((button, index) => (
                                <a href="#" key={index} style={{ display: introbuttonapprovel == false ? 'none' : '',}}><button class="buttoncolor"  style={{fontFamily:`${button1font}`,backgroundColor:`${buttoncolor}`,border:`${buttonborder}px solid ${buttonbordercolor}`,borderRadius:`${buttonborderradius}px`,boxShadow:`0px 0px ${butttonshowdo}px 0px rgba(21, 21, 25, 0.21)`,width:"100%",paddingTop:`${buttonvarticalpadding}px`,paddingBottom:`${buttonvarticalpadding}px`,paddingLeft:`${buttonhorizantlepadding}px`,paddingRight:`${buttonhorizantlepadding}px` }}>{button}</button></a>

                            ))}
                        </div>
                    </div>
                    <div class="col-md-6 ">
                        <div className="_f497df414">
                        {newIntroImage.length > 0 ?
                            <div>
                                {newIntroImage.map((image, index) => (
                                    // eslint-disable-next-line jsx-a11y/img-redundant-alt
                                    <img
                                        style={{ height: '390px', borderRadius: 1, width: '100%', borderRadius: '10px' }}
                                        key={index}
                                        src={image}
                                        alt={`Image ${index}`}
                                        className="img-fluid shadow"
                                    />
                                ))}</div>
                            :
                            <img style={{ height: '390px', borderRadius: 1, width: '100%', borderRadius: '10px' }} src={introImages} className="img-fluid shadow" alt="" />

                        }
                        </div>
                    </div>


                </div>
            </div>
            )}
        </div>
    );
};

export default EditHero