import React, { useContext } from "react";
import MyContext from "../context/MyContext";

const Services = () => {

    const { byTheNumber, legacycolor,
        legacytext, legacy, removelegacy,
        statsintro, setStatsinto,
        statsnumber1, setStatsnumber1,
        statsnumber2, setStatsnumber2,
        statsnumber3, setStatsnumber3,
        statsnumber4, setStatsnumber4,
        statsnumber5, setStatsnumber5,
        statsnumber6, setStatsnumber6,
        statsnumremove1, setStatsnumremove1,
        statsnumremove2, setStatsnumremove2,
        leagacydiv, setLeagacydiv,
        statscaption,
        statsnumremove3, setStatsnumremove3,
        newlegacy, setNewlgacy,
        stateOne, statetwo,
        statethree,
        statstitletextfont, statsbodytextfont,
        statsMarginBottom, statsMarginTop,
        captioncolor, statsbordercolor,
        contenttextheadingcolor, mainnavbarbutton,
        contenttextinfocolor, statsshowdowrange,
        statevaluecolor, setStatsvaluecolor,
        statsbackgroundcolor, statsborderradius,
        statslabelcolor, setstatslabelcolor,
        setStatslabelcolor, statsborder,
        selectedStats, setSelectedStats,
    } = useContext(MyContext)

    console.log('byTheNumber', byTheNumber)
    console.log('newlegacy', statsborder, statsborderradius, statsbordercolor)

    return (
        <div >
            {selectedStats == null && (
                <div class="container " style={{ backgroundColor: `${legacycolor}`, color: `${legacytext}`, minWidth:'100%',padding:'50px',paddingTop:'0px'}}>
                    <div style={{ marginTop: `${statsMarginTop}px`, marginBottom: `${statsMarginBottom}px` }}>
                        <p class='text-center' style={{ color: `${captioncolor}` }}>{statscaption}</p>
                        <h2 class="text-center heading" style={{ color: `${contenttextheadingcolor}`, fontFamily: `${statstitletextfont}` }} >{legacy}</h2>
                        <p class="text-center " style={{ color: `${contenttextinfocolor}`, fontFamily: `${statsbodytextfont}` }}>
                            {statsintro}
                        </p>
                        <div class="cards row d-flex align-items-center justify-content-evenly">


                            <div class="card col-md-4" style={{ width: "18rem", display: statsnumremove1 ? 'none' : '',backgroundColor: `${statsbackgroundcolor}`, border: `${statsborder}px solid ${statsbordercolor}`, borderRadius: `${statsborderradius}px`, boxShadow: statsshowdowrange ? `0px 0px ${statsshowdowrange}px 0px rgba(21, 21, 25, 0.21` : '0px 19px 30px 8px rgba(21, 21, 25, 0.21)' }}>
                                <div class="card-body d-flex align-items-center justify-content-center flex-column">
                                    <h5 class=" card-title " style={{ color: statevaluecolor ? `${statevaluecolor}` : `${contenttextheadingcolor}`, fontFamily: `${statsbodytextfont}` }}>{stateOne.prefix}{statsnumber2}{stateOne.suffix}</h5>
                                    <p class="card-text " style={{ color: statslabelcolor ? `${statslabelcolor}` : `${contenttextinfocolor}`, fontFamily: `${statsbodytextfont}` }} >
                                        {statsnumber1}
                                    </p>
                                </div>
                            </div>

                            <div class="card col-md-4" style={{ width: "18rem", display: statsnumremove2 ? 'none' : '', backgroundColor: `${statsbackgroundcolor}`, border: `${statsborder}px  solid ${statsbordercolor}`, borderRadius: `${statsborderradius}px`, boxShadow: statsshowdowrange ? `0px 0px ${statsshowdowrange}px 0px rgba(21, 21, 25, 0.21` : '0px 19px 30px 8px rgba(21, 21, 25, 0.21)' }}>
                                <div class="card-body d-flex align-items-center justify-content-center flex-column">
                                    <h5 class=" card-title " style={{ color: statevaluecolor ? `${statevaluecolor}` : `${contenttextheadingcolor}`, fontFamily: `${statsbodytextfont}` }} >{statetwo.prefix}{statsnumber4}{statetwo.suffix}</h5>
                                    <p class="card-text " style={{ color: statslabelcolor ? `${statslabelcolor}` : `${contenttextinfocolor}`, fontFamily: `${statsbodytextfont}` }} >
                                        {statsnumber3}
                                    </p>
                                </div>
                            </div>

                            <div class="card col-md-4" style={{ width: "18rem", display: statsnumremove3 ? 'none' : '', backgroundColor: `${statsbackgroundcolor}`, border: `${statsborder}px solid ${statsbordercolor}`, borderRadius: `${statsborderradius}px`, boxShadow: statsshowdowrange ? `0px 0px ${statsshowdowrange}px 0px rgba(21, 21, 25, 0.21` : '0px 19px 30px 8px rgba(21, 21, 25, 0.21)' }}>
                                <div class="card-body d-flex align-items-center justify-content-center flex-column">
                                    <h5 class=" card-title " style={{ color: statevaluecolor ? `${statevaluecolor}` : `${contenttextheadingcolor}`, fontFamily: `${statsbodytextfont}` }}>{statethree.prefix}{statsnumber6}{statethree.suffix}</h5>
                                    <p class="card-text " style={{ color: statslabelcolor ? `${statslabelcolor}` : `${contenttextinfocolor}`, fontFamily: `${statsbodytextfont}` }} >
                                        {statsnumber5}
                                    </p>
                                </div>
                            </div>
                            {newlegacy.map((item, index) => (
                                <div class="card col-md-4" style={{ width: "18rem", backgroundColor: `${statsbackgroundcolor}`, boxShadow: statsshowdowrange ? `0px 0px ${statsshowdowrange}px 0px rgba(21, 21, 25, 0.21` : '0px 19px 30px 8px rgba(21, 21, 25, 0.21)', border: `${statsborder}px solid ${statsbordercolor}`, borderRadius: `${statsborderradius}px` }} key={index}>
                                    <div class="card-body d-flex align-items-center justify-content-center flex-column">
                                        <h5 class=" card-title " style={{ color: statevaluecolor ? `${statevaluecolor}` : `${contenttextheadingcolor}`, fontFamily: `${statsbodytextfont}`, }} >{item.prefix}{item.value}{item.suffix}</h5>
                                        <p class="card-text " style={{ color: statslabelcolor ? `${statslabelcolor}` : `${contenttextinfocolor}`, fontFamily: `${statsbodytextfont}` }}>
                                            {item.label}
                                        </p>
                                    </div>
                                </div>
                            ))}
                        </div>


                    </div>
                </div>
            )}

            {selectedStats === 0 && (
            <div class="container " style={{ backgroundColor: `${legacycolor}`, color: `${legacytext}`, minWidth:'100%',padding:'50px',paddingTop:'0px'}}>
                <div style={{ marginTop: `${statsMarginTop}px`, marginBottom: `${statsMarginBottom}px` }}>
                    <p class='text-center' style={{ color: '#9c36b5' }}>{statscaption}</p>
                    <h2 class="text-center heading" style={{ color: `${legacytext}`, fontFamily: `${statstitletextfont}` }} >{legacy}</h2>
                    <p class="text-center " style={{ color: `${legacytext}`, fontFamily: `${statsbodytextfont}` }}>
                        {statsintro}
                    </p>
                    <div class="sidecard">
                        <div class="sidecard-child" style={{ width: "18rem", display: statsnumremove1 ? 'none' : '', backgroundColor: `${statsbackgroundcolor}`, border: `${statsborder}px solid ${statsbordercolor}`, borderRadius: `${statsborderradius}px`, boxShadow: statsshowdowrange ? `0px 0px ${statsshowdowrange}px 0px rgba(21, 21, 25, 0.21` : '0px 19px 30px -10px rgba(21, 21, 25, 0.21)' }}>
                            <div class="countparent">
                                <span data-value="10000" class="countup" style={{ color: statevaluecolor ? `${statevaluecolor}` : `${contenttextheadingcolor}`, fontFamily: `${statsbodytextfont}` }}>{stateOne.prefix}{statsnumber2}{stateOne.suffix}</span>
                            </div>
                            <span class="countspantext"  style={{ color: statslabelcolor ? `${statslabelcolor}` : `${contenttextinfocolor}`, fontFamily: `${statsbodytextfont}` }}>   {statsnumber1}</span>
                        </div>
                        <div class="sidecard-child" style={{ width: "18rem", display: statsnumremove2 ? 'none' : '', backgroundColor: `${statsbackgroundcolor}`, border: `${statsborder}px solid ${statsbordercolor}`, borderRadius: `${statsborderradius}px`, boxShadow: statsshowdowrange ? `0px 0px ${statsshowdowrange}px 0px rgba(21, 21, 25, 0.21` : '0px 19px 30px -10px rgba(21, 21, 25, 0.21)' }}>
                            <div class="countparent">
                                <span data-value="50" class="countup" style={{ color: statevaluecolor ? `${statevaluecolor}` : `${contenttextheadingcolor}`, fontFamily: `${statsbodytextfont}` }}>{statetwo.prefix}{statsnumber4}{statetwo.suffix}</span>
                            </div>
                            <span class="countspantext" style={{ color: statslabelcolor ? `${statslabelcolor}` : `${contenttextinfocolor}`, fontFamily: `${statsbodytextfont}` }}>{statsnumber3}</span>
                        </div>
                        <div class="sidecard-child" style={{ width: "18rem", display: statsnumremove3 ? 'none' : '', backgroundColor: `${statsbackgroundcolor}`, border: `${statsborder}px solid ${statsbordercolor}`, borderRadius: `${statsborderradius}px`, boxShadow: statsshowdowrange ? `0px 0px ${statsshowdowrange}px 0px rgba(21, 21, 25, 0.21` : '0px 19px 30px -10px rgba(21, 21, 25, 0.21)' }}>
                            <div class="countparent">
                                <span data-value="15" class="countup" style={{ color: statevaluecolor ? `${statevaluecolor}` : `${contenttextheadingcolor}`, fontFamily: `${statsbodytextfont}` }}>{statethree.prefix}{statsnumber6}{statethree.suffix}</span>
                            </div>
                            <span class="countspantext" style={{ color: statslabelcolor ? `${statslabelcolor}` : `${contenttextinfocolor}`, fontFamily: `${statsbodytextfont}` }}>{statsnumber5}</span>
                        </div>
                        {newlegacy.map((item, index) => (
                                 <div class="sidecard-child" style={{ width: "18rem", display: statsnumremove3 ? 'none' : '', backgroundColor: `${statsbackgroundcolor}`, border: `${statsborder}px solid ${statsbordercolor}`, borderRadius: `${statsborderradius}px`, boxShadow: statsshowdowrange ? `0px 0px ${statsshowdowrange}px 0px rgba(21, 21, 25, 0.21` : '0px 19px 30px -10px rgba(21, 21, 25, 0.21)' }}>
                                 <div class="countparent">
                                     <span data-value="15" class="countup" style={{ color: statevaluecolor ? `${statevaluecolor}` : `${contenttextheadingcolor}`, fontFamily: `${statsbodytextfont}` }}>{item.prefix}{item.value}{item.suffix}</span>
                                 </div>
                                 <span class="countspantext" style={{ color: statslabelcolor ? `${statslabelcolor}` : `${contenttextinfocolor}`, fontFamily: `${statsbodytextfont}` }}>{item.label}</span>
                             </div>
                            ))}
                    </div>
                </div>
            </div>
        )}
            {selectedStats === 1 && (
            <div class="container " style={{ backgroundColor: `${legacycolor}`, color: `${legacytext}`, minWidth:'100%',padding:'50px',paddingTop:'0px'}}>
                <div style={{ marginTop: `${statsMarginTop}px`, marginBottom: `${statsMarginBottom}px` }}>
                    <p class='text-center' style={{ color: `${captioncolor}` }}>{statscaption}</p>
                    <h2 class="text-center heading" style={{ color: `${legacytext}`, fontFamily: `${statstitletextfont}` }} >{legacy}</h2>
                    <p class="text-center " style={{ color: `${legacytext}`, fontFamily: `${statsbodytextfont}` }}>
                        {statsintro}
                    </p>
                    <div class="d-flex flex-wrap">
                        <div class="sidecard-child" style={{ width: "18rem", display: statsnumremove1 ? 'none' : '', backgroundColor: `${statsbackgroundcolor}`, border: `${statsborder}px solid ${statsbordercolor}`, borderRadius: `${statsborderradius}px`, boxShadow: statsshowdowrange ? `0px 0px ${statsshowdowrange}px 0px rgba(21, 21, 25, 0.21` : '0px 19px 30px -10px rgba(21, 21, 25, 0.21)' ,borderRight:'none'}}>
                            <div class="countparent">
                                <span data-value="10000" class="countup" style={{ color: statevaluecolor ? `${statevaluecolor}` : `${contenttextheadingcolor}`, fontFamily: `${statsbodytextfont}` }}>{stateOne.prefix}{statsnumber2}{stateOne.suffix}</span>
                            </div>
                            <span class="countspantext" style={{ color: statslabelcolor ? `${statslabelcolor}` : `${contenttextinfocolor}`, fontFamily: `${statsbodytextfont}` }}>{statsnumber1}</span>
                        </div>
                        <div class="sidecard-child" style={{ width: "18rem", display: statsnumremove2 ? 'none' : '', backgroundColor: `${statsbackgroundcolor}`, border: `${statsborder}px solid ${statsbordercolor}`, borderRadius: `${statsborderradius}px`, boxShadow: statsshowdowrange ? `0px 0px ${statsshowdowrange}px 0px rgba(21, 21, 25, 0.21` : '0px 19px 30px -10px rgba(21, 21, 25, 0.21)' ,borderRight:'none'}}>
                            <div class="countparent">
                                <span data-value="50" class="countup" style={{ color: statevaluecolor ? `${statevaluecolor}` : `${contenttextheadingcolor}`, fontFamily: `${statsbodytextfont}` }}>{statetwo.prefix}{statsnumber4}{statetwo.suffix}</span>
                            </div>
                            <span class="countspantext" style={{ color: statslabelcolor ? `${statslabelcolor}` : `${contenttextinfocolor}`, fontFamily: `${statsbodytextfont}` }} >{statsnumber3}</span>
                        </div>
                        <div class="sidecard-child" style={{ width: "18rem", display: statsnumremove3 ? 'none' : '', backgroundColor: `${statsbackgroundcolor}`, border: `${statsborder}px solid ${statsbordercolor}`, borderRadius: `${statsborderradius}px`, boxShadow: statsshowdowrange ? `0px 0px ${statsshowdowrange}px 0px rgba(21, 21, 25, 0.21` : '0px 19px 30px -10px rgba(21, 21, 25, 0.21)' ,borderRight:'none'}}>
                            <div class="countparent">
                                <span data-value="15" class="countup" style={{ color: statevaluecolor ? `${statevaluecolor}` : `${contenttextheadingcolor}`, fontFamily: `${statsbodytextfont}` }}>{statethree.prefix}{statsnumber6}{statethree.suffix}</span>
                            </div>
                            <span class="countspantext" style={{ color: statslabelcolor ? `${statslabelcolor}` : `${contenttextinfocolor}`, fontFamily: `${statsbodytextfont}` }}>{statsnumber5}</span>
                        </div>

                        {newlegacy.map((item, index) => (
                                 <div key={index} class="sidecard-child" style={{ width: "18rem", display: statsnumremove3 ? 'none' : '', backgroundColor: `${statsbackgroundcolor}`, border: `${statsborder}px solid ${statsbordercolor}`, borderRadius: `${statsborderradius}px`, boxShadow: statsshowdowrange ? `0px 0px ${statsshowdowrange}px 0px rgba(21, 21, 25, 0.21` : '0px 19px 30px -10px rgba(21, 21, 25, 0.21)' ,borderRight:'none'}}>
                                 <div class="countparent">
                                     <span data-value="15" class="countup" style={{ color: statevaluecolor ? `${statevaluecolor}` : `${contenttextheadingcolor}`, fontFamily: `${statsbodytextfont}` }}>{item.prefix}{item.value}{item.suffix}</span>
                                 </div>
                                 <span class="countspantext" style={{ color: statslabelcolor ? `${statslabelcolor}` : `${contenttextinfocolor}`, fontFamily: `${statsbodytextfont}` }}>{item.label}</span>
                             </div>
                            ))}
                    </div>
                </div>
            </div>
        )}
            {selectedStats === 2 && (
            <div class="container " style={{ backgroundColor: `${legacycolor}`, color: `${legacytext}`, minWidth:'100%',padding:'50px',paddingTop:'0px'}}>
                <div style={{ marginTop: `${statsMarginTop}px`, marginBottom: `${statsMarginBottom}px`, minWidth:'100%'}}>
                    <p class='text-center' style={{ color: `${captioncolor}`  }}>{statscaption}</p>
                    <h2 class="text-center heading" style={{ color: `${legacytext}`, fontFamily: `${statstitletextfont}` }} >{legacy}</h2>
                    <p class="text-center " style={{ color: `${legacytext}`, fontFamily: `${statsbodytextfont}` }}>
                        {statsintro}
                    </p>
                    <div class="d-flex flex-wrap " style={{ margin: "0 -16px" }}>
                        <div class="separatedcards" style={{ width: "18rem", display: statsnumremove1 ? 'none' : '', backgroundColor: `${statsbackgroundcolor}`, border: `${statsborder}px solid ${statsbordercolor}`, borderRadius: `${statsborderradius}px`, boxShadow: statsshowdowrange ? `0px 0px ${statsshowdowrange}px 0px rgba(21, 21, 25, 0.21` : '0px 19px 30px -10px rgba(21, 21, 25, 0.21)' }} >
                            <div class="countparent" >
                                <span data-value="10000" class="countup" style={{ color: statevaluecolor ? `${statevaluecolor}` : `${contenttextheadingcolor}`, fontFamily: `${statsbodytextfont}` }}>{stateOne.prefix}{statsnumber2}{stateOne.suffix}</span>
                            </div>
                            <span class="countspantext" style={{ color: statslabelcolor ? `${statslabelcolor}` : `${contenttextinfocolor}`, fontFamily: `${statsbodytextfont}` }}>{statsnumber1}</span>
                        </div><div class="separatedcards" style={{ width: "18rem", display: statsnumremove1 ? 'none' : '', backgroundColor: `${statsbackgroundcolor}`, border: `${statsborder}px solid ${statsbordercolor}`, borderRadius: `${statsborderradius}px`, boxShadow: statsshowdowrange ? `0px 0px ${statsshowdowrange}px 0px rgba(21, 21, 25, 0.21` : '0px 19px 30px -10px rgba(21, 21, 25, 0.21)' }}>
                            <div class="countparent">
                                <span data-value="50" class="countup" style={{ color: statevaluecolor ? `${statevaluecolor}` : `${contenttextheadingcolor}`, fontFamily: `${statsbodytextfont}` }}>{statetwo.prefix}{statsnumber4}{statetwo.suffix}</span>
                            </div>
                            <span class="countspantext" style={{ color: statslabelcolor ? `${statslabelcolor}` : `${contenttextinfocolor}`, fontFamily: `${statsbodytextfont}` }}>{statsnumber3}</span>
                        </div>
                        <div class="separatedcards"style={{ width: "18rem", display: statsnumremove1 ? 'none' : '', backgroundColor: `${statsbackgroundcolor}`, border: `${statsborder}px solid ${statsbordercolor}`, borderRadius: `${statsborderradius}px`, boxShadow: statsshowdowrange ? `0px 0px ${statsshowdowrange}px 0px rgba(21, 21, 25, 0.21` : '0px 19px 30px -10px rgba(21, 21, 25, 0.21)' }}>
                            <div class="countparent">
                                <span data-value="15" class="countup" style={{ color: statevaluecolor ? `${statevaluecolor}` : `${contenttextheadingcolor}`, fontFamily: `${statsbodytextfont}` }}>{statethree.prefix}{statsnumber6}{statethree.suffix}</span>
                            </div>
                            <span class="countspantext" style={{ color: statslabelcolor ? `${statslabelcolor}` : `${contenttextinfocolor}`, fontFamily: `${statsbodytextfont}` }}>{statsnumber5}</span>
                        </div>

                        {newlegacy.map((item, index) => (
                                <div  key={index} class="separatedcards"style={{ width: "18rem", display: statsnumremove1 ? 'none' : '', backgroundColor: `${statsbackgroundcolor}`, border: `${statsborder}px solid ${statsbordercolor}`, borderRadius: `${statsborderradius}px`, boxShadow: statsshowdowrange ? `0px 0px ${statsshowdowrange}px 0px rgba(21, 21, 25, 0.21` : '0px 19px 30px -10px rgba(21, 21, 25, 0.21)' }}>
                                <div class="countparent">
                                    <span data-value="15" class="countup" style={{ color: statevaluecolor ? `${statevaluecolor}` : `${contenttextheadingcolor}`, fontFamily: `${statsbodytextfont}` }}>{item.prefix}{item.value}{item.suffix}</span>
                                </div>
                                <span class="countspantext" style={{ color: statslabelcolor ? `${statslabelcolor}` : `${contenttextinfocolor}`, fontFamily: `${statsbodytextfont}` }}>{item.label}</span>
                            </div>
                            ))}
                    </div>
                </div>
            </div>
        )}

        </div>
    );
};

export default Services;